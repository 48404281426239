import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { DrawerComponent } from "../../common/plugins/metronic/components";
import CompanyPresenceComponent from "./CompanyPresenceComponent";

const CompanyPresenceDrawer = ({ container }) => {

    useEffect(() => {
        setTimeout(() => DrawerComponent.bootstrap(), 500);
    }, []);

    return (
        <div
            id="company_presence_drawer"
            data-kt-drawer="true"
            data-kt-drawer-activate="true"
            data-kt-drawer-toggle="#company_presence_drawer_button"
            data-kt-drawer-close="#company_presence_drawer_close"
            data-kt-drawer-width="{default:'350px', 'md': '500px'}"
            data-kt-drawer-permanent="true"
            style={{ zIndex: 999999 }}
        >
            <div class="card w-100 rounded-0 m-0">
                <div class="card-header rounded-0 pe-5">
                    <div class="card-title">
                        <div class="btn btn-sm btn-icon btn-active-light-primary ps-0 me-3" id="company_presence_drawer_close">
                            <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                        </div>
                        <div class="d-flex justify-content-center flex-column me-3">
                            <span class="fs-4 fw-bold text-gray-900 me-1 lh-1">{container.localeManager.strings.liveCompanyStatus}</span>
                        </div>
                    </div>
                </div>
                <div class="card-body hover-scroll-overlay-y">
                    <CompanyPresenceComponent container={container} />
                </div>
            </div>
        </div>
    )
}

export default CompanyPresenceDrawer;