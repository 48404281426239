/* global overwolf */
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardDeck,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Progress,
  Row,
  Spinner,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import EnterpriseService from "../common/services/enterprise-service";
import CustomBadge from "./ui/CustomBadge";
import config from "../config";
import PersonalBadges from "./enterprise/PersonalBadges";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProfileCardV2 = ({
  container,
  user,
  avatarSize = 150,
  showLevel = true,
  showProgressBar = true,
  company,
  role = null,
  showLeaderboards = true,
}) => {
  const es = new EnterpriseService();
  const [levelInfo, setLevelInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [leaderboards, setLeaderboards] = useState(null);

  useEffect(() => {
    if (user != null) {
      if (config.VTC_HUB_PROFILE_SHOW_LEVEL && showLevel) getLevelInfo();

      if (config.VTC_HUB_PROFILE_SHOW_LEVEL && showLeaderboards)
        getLeaderboards();
    }
  }, [user]);

  const getLevelInfo = async () => {
    const result = await es.getUserLevelInfo(user.id);
    setLevelInfo(result);
  };

  const getLeaderboards = async () => {
    const result = await es.getUserLeaderboards(user.id);
    setLeaderboards(result);
  };

  const getHPPoints = () => {
    if (user.hardcore_points != null && user.hardcore_points > 0) {
      return (
        <span className="ms-10">
          <FontAwesomeIcon icon="trophy" className="mx-2"></FontAwesomeIcon> {container.localeManager.numeral(user.hardcore_points).format()} HP
        </span>
      );
    }
  };

  const renderProfile = () => {
    let pointsToNextLevel = 0;
    let percentage = 0;

    if (levelInfo != null) {
      pointsToNextLevel =
        levelInfo.max_points_current_level - levelInfo.total_points;
      percentage =
        (100 * (levelInfo.total_points - levelInfo.min_points_current_level)) /
        (levelInfo.max_points_current_level -
          levelInfo.min_points_current_level);
    }

    return (
      <>
        <div className="d-flex flex-wrap flex-sm-nowrap">
          <div class="me-3 min-w-150px w-170px">
            <img
              className={`h-${avatarSize}px rounded`}
              src={user.avatar_url}
              alt="image"
            />
          </div>
          <div className="flex-grow-1">
            <div className="d-flex flex-wrap flex-stack">
              <div className="d-flex flex-column flex-grow-1 pe-0 pe-md-8 mb-3 mb-md-0">
                <div class="d-flex align-items-center mb-2">
                  <Link
                    to={`/user/${user.id}`}
                    class="text-gray-900 text-hover-primary fs-2 fw-bolder me-1"
                  >
                    {user.name}
                  </Link>
                  {user.avg_delivery_rating && getDeliveryRatingStars()}
                </div>
                {config.VTC_HUB_PROFILE_SHOW_LEVEL &&
                  showLevel &&
                  levelInfo != null && (
                    <div className="mt-3 d-flex align-items-center">
                      <CustomBadge className="level" color="primary">
                        {container.localeManager.formatString(
                          container.localeManager.strings.levelFormatted,
                          user.level
                        )}
                      </CustomBadge>{" "}
                      <span className="ms-3 fs-2">
                        {container.localeManager.formatString(
                          container.localeManager.strings.pointsFormatted,
                          container.localeManager.numeral(user.points).format()
                        )}
                        {getHPPoints()}
                      </span>
                    </div>
                  )}
              </div>
              {company != null && (
                <div className="d-flex flex-wrap">
                  <div className="text-center d-flex align-items-center">
                    <div>
                      <img
                        src={company.avatar_url}
                        className="avatar rounded h-100px me-3"
                      />
                    </div>
                    <div>
                      <h4 className="mb-0">
                        <Link to={`/company/${company.id}`}>
                          {company.name}
                        </Link>
                      </h4>
                      {role != null && (
                        <span style={{ color: role.color }}>{role.name}</span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {config.VTC_HUB_PROFILE_SHOW_LEVEL &&
              showLevel &&
              levelInfo != null && (
                <div className="d-flex flex-wrap flex-stack mt-3">
                  <div className="d-flex flex-column flex-grow-1">
                    <div className="d-flex flex-wrap">
                      <div className="fw-bold fs-4 text-gray-600">
                        {container.localeManager.formatString(
                          container.localeManager.strings
                            .pointsToNextLevelFormatted,
                          [
                            container.localeManager
                              .numeral(pointsToNextLevel)
                              .format(),
                            container.localeManager
                              .numeral(user.level + 1)
                              .format(),
                          ]
                        )}
                      </div>
                      {showProgressBar && (
                        <div className="mt-1 w-100 me-0 me-md-3">
                          <div class="progress bg-light">
                            <div
                              class="progress-bar bg-primary"
                              role="progressbar"
                              style={{ width: `${percentage}%` }}
                              aria-valuenow={levelInfo.total_points}
                            ></div>
                          </div>
                          <div className="mt-1 w-100 d-flex justify-content-between text-gray-600">
                            <span>
                              {container.localeManager
                                .numeral(levelInfo.min_points_current_level)
                                .format()}
                            </span>
                            <span>
                              {container.localeManager
                                .numeral(levelInfo.max_points_current_level)
                                .format()}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {showLeaderboards && leaderboards != null && (
                    <div className="d-flex flex-wrap">
                      {leaderboards != null &&
                        renderLeaderboardsPosition("weekly")}
                      {leaderboards != null &&
                        renderLeaderboardsPosition("monthly")}
                    </div>
                  )}
                </div>
              )}
            {config.VTC_HUB_PROFILE_SHOW_LEVEL && showLevel && (
              <div className="my-3 w-100">
                <PersonalBadges
                  container={container}
                  user={user}
                ></PersonalBadges>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderLeaderboardsPosition = (period) => {
    if (leaderboards[period] != undefined && leaderboards[period] != null) {
      return (
        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
          <div class="d-flex align-items-center">
            <div
              class="fs-2 fw-bolder text-primary counted"
              data-kt-countup="true"
              data-kt-countup-value="4500"
              data-kt-countup-prefix="$"
            >
              #
              {container.localeManager
                .numeral(leaderboards[period].position)
                .format()}
            </div>
          </div>
          <div class="fw-bold fs-6 text-gray-600">
            {container.localeManager.strings[period]}
          </div>
        </div>
      );
    }
  };

  const getDeliveryRatingStars = () => {
    let color = "";

    if (user.avg_delivery_rating >= 4) color = "text-success";

    if (user.avg_delivery_rating < 4) color = "text-warning";

    if (user.avg_delivery_rating < 3) color = "text-danger";

    let stars = [];

    for (let index = 1; index <= 5; index++) {
      let icon = "star";

      if (index <= user.avg_delivery_rating) icon = "star";
      else if (index - user.avg_delivery_rating <= 0.5)
        icon = "star-half-stroke";
      else icon = ["far", "star"];

      stars.push(
        <FontAwesomeIcon icon={icon} className={color}></FontAwesomeIcon>
      );
    }

    const $div = (
      <div className="ms-3">
        <div id="avg_delivery_rating">{stars}</div>
        <UncontrolledTooltip target="avg_delivery_rating">
          {container.localeManager.strings.avgDeliveryRating}
        </UncontrolledTooltip>
      </div>
    );

    return $div;
  };

  return (
    <>
      {user != null && (
        <>
          <Card className="mx-0">
            <CardBody>{renderProfile()}</CardBody>
          </Card>
        </>
      )}
    </>
  );
};

export default ProfileCardV2;
