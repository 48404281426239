import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import EnterpriseService from '../../common/services/enterprise-service';
import SingleMemberBadge from './SingleMemberBadge';

const MemberBadges = ({ company, container, month, year }) => {

    const es = new EnterpriseService();
    const [badges, setBadges] = useState([]);

    useEffect(async () => {
        const result = await es.getMembersBadgesMonthly(company, month, year);
        setBadges(result);
    }, [month, year]);

    const getPeriodDescription = (month, year) => {
        const date = container.localeManager.moment().month(month).year(year);
        return date.format("MMMM YYYY");
    };

    return (
        <>
            {badges.length > 0 &&
                <Row className='mt-3 gx-0'>
                    <Col className='bg-light rounded py-3'>
                        <div className={`grid cols-3 gap-3 mx-3`}>
                            {badges.map((m) => <SingleMemberBadge container={container} badge={m}></SingleMemberBadge>)}
                        </div>
                    </Col>
                </Row>
            }
        </>
    )
};

export default MemberBadges;