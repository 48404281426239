import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Table } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import UserDefinedUnits from "../../common/utils/UserDefinedUnits";
import { EnterpriseContext } from "./EnterpriseContext";
import { Link } from "react-router-dom";

const DailyCompanyMemberStats = ({ container, company }) => {

    const es = new EnterpriseService();
    const [membersStats, setMembersStats] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [searchParameters, setSearchParameters] = useState({ sortingField: 'driven_distance', sortingDirection: 'desc', paginate: true, perPage: 5, page: 1, period: 'daily', date: new Date() });
    const navigate = useNavigate();

    const { globalContext } = useContext(EnterpriseContext);

    const member = globalContext.member;

    useEffect(() => {
        getMembersStats();
    }, []);

    useEffect(() => {
        getMembersStats();
    }, [searchParameters.page]);

    const getMembersStats = async () => {
        const result = await es.getMembersCompanyStatsByPeriod(company.id, searchParameters);

        setMembersStats(result.data);
        setTotalPages(result.last_page);
    }

    return (
        <>
            {membersStats.length > 0 &&
                <div className="mt-3">
                    <div className="d-flex align-items-center mb-3">
                        <div className="flex-grow-1">
                            <h5>{container.localeManager.strings.dailyStats}</h5>
                        </div>
                        {totalPages > 1 &&
                            <div>
                                <Button className="me-1" onClick={() => setSearchParameters({ ...searchParameters, page: searchParameters.page - 1 })} disabled={searchParameters.page == 1} color="transparent" size="sm"><FontAwesomeIcon className="m-0" icon="chevron-left"></FontAwesomeIcon></Button>
                                <Button onClick={() => setSearchParameters({ ...searchParameters, page: searchParameters.page + 1 })} disabled={searchParameters.page == totalPages} color="transparent" size="sm"><FontAwesomeIcon className="m-0" icon="chevron-right"></FontAwesomeIcon></Button>
                            </div>
                        }
                    </div>
                    <Table responsive striped size="sm" className="table-row-dashed table-row-gray-300 align-middle gs-0" hover>
                        <thead>
                            <tr className="fw-bolder text-gray-700 bg-light">
                                <th className="ps-4">{container.localeManager.strings.member}</th>
                                <th>{container.localeManager.strings.distance}
                                </th>
                                <th>{container.localeManager.strings.mass}
                                </th>
                                <th>{container.localeManager.strings.revenue}
                                </th>
                                <th>{container.localeManager.strings.jobs}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {membersStats.map(m => {
                                return (
                                    <tr>
                                        <td className="ps-4">
                                            <Link to={`/user/${m.user_id}`}>{m.name}</Link>
                                        </td>
                                        <td>{UserDefinedUnits.convertDistance(member, null, m.driven_distance_km)}</td>
                                        <td>{UserDefinedUnits.convertWeight(member, null, m.cargo_mass_t)}</td>
                                        <td>{container.localeManager.numeral(m.revenue).format('0,0')} {company.currency}</td>
                                        <td>{m.jobs}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            }
        </>
    )
}

export default DailyCompanyMemberStats;