import React from "react";
import { render } from "react-dom";
import "./App.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import Cookies from "js-cookie";
import EnterpriseService from "./common/services/enterprise-service";
import LocaleManager from "./locales/localeManager";
import { EnterpriseContextProvider } from "./components/enterprise/EnterpriseContext";
import truckyService from "./common/services/trucky-service";
import "react-toastify/dist/ReactToastify.css";
import truckyapiClientService from "./common/services/truckyapi-client-service";
import eventBus from "./common/services/event-bus";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import RealTimeService from "./common/services/realtime-service";
import ReactGA from "react-ga4";
import { ErrorBoundary } from "react-error-boundary";
import Plausible from "plausible-tracker";

/*const { trackPageview, enableAutoOutboundTracking, enableAutoPageviews } =
  Plausible({
    domain: "hub.truckyapp.com",
    apiHost: "https://analytics.truckyapp.com",
  });

enableAutoOutboundTracking();
enableAutoPageviews();
*/

if (screen.orientation && screen.orientation.unlock)
  screen.orientation.unlock();

library.add(fab, fas, far);

const localeManager = new LocaleManager();
localeManager.initialize().then(() => {
  window.localeManager = localeManager;

  document
    .getElementsByTagName("body")[0]
    .setAttribute("data-language", localeManager.language);

  if (truckyService.getToken()) {
    loadingScreen();
    loadUser().then(renderApp);
  } else {
    renderApp();
  }
});

window.settings = {};
window.eventBus = {
  trigger: () => {},
};

const es = new EnterpriseService();

const container = {
  localeManager: localeManager,
  dataService: {
    enterpriseService: es,
    data: {
      notifications: [],
      companyPresenceChannelUsers: [],
      activePlans: [],
      getPatreonTier: function () {
        if (
          this.isPatron() &&
          this.patreonMember.tier &&
          this.patreonMember.tier != null
        )
          return this.patreonMember.tier.title;
        else return "";
      },
      isPatron: function () {
        return (
          this.patreonMember &&
          this.patreonMember != null &&
          this.patreonMember.patron_status == "active_patron"
        );
      },
      patreonMember: null,
      enablePremiumFeature: function () {
        if (
          es.member &&
          es.member.additiona_roles &&
          es.member.additiona_roles.length > 0
        )
          return true;

        return (
          this.isPatron() &&
          this.patreonMember.tier &&
          this.patreonMember.tier != null &&
          this.patreonMember.tier.amount_cents >= 300
        );
      },
    },
  },
  eventBus: eventBus,
};

container.realTimeService = new RealTimeService(es, container);

async function loadUser() {
  await es.loadEnterpriseMemberData();
  const result = await truckyapiClientService.getPatreonMember(
    truckyService.getToken()
  );
  container.dataService.data.patreonMember = result.response;

  if (es.member != null) {
    container.realTimeService.connectToEcho();
  }
}

function loadingScreen() {
  return render(
    <div class="d-flex flex-column flex-center flex-column-fluid">
      <div class="d-flex flex-column flex-center text-center p-10">
        <div class="card card-flush w-md-650px py-5">
          <div class="card-body py-15 py-lg-20">
            <h1 class="fw-bolder text-gray-900">
              <img src="/assets/aside-logo.png" className="mb-7" />
              {localeManager.strings.loading}
            </h1>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("root")
  );
}

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div class="d-flex flex-column flex-center flex-column-fluid">
      <div class="d-flex flex-column flex-center text-center p-10 mt-20">
        <div class="card card-flush w-md-650px py-5">
          <div class="card-body py-15 py-lg-20">
            <h1 class="fw-bolder text-gray-900">
              <p>Something went wrong:</p>
              <pre style={{ color: "red" }}>{error.message}</pre>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

function renderApp() {
  render(
    <ErrorBoundary fallbackRender={fallbackRender}>
      <EnterpriseContextProvider container={container}>
        <App container={container} />
      </EnterpriseContextProvider>
    </ErrorBoundary>,
    document.getElementById("root")
  );
}

ReactGA.initialize("G-X79PYBTN0B");

/*trackPageview();*/

//serviceWorkerRegistration.unregister();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
