import { Card, CardBody, CardTitle, Row, Col, Button, CardHeader, Table, Badge, Spinner, ListGroup, ListGroupItem, FormGroup, Label, Input, InputGroup } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import EnterpriseService from '../../common/services/enterprise-service';
import LoadingOverlay from 'react-loading-overlay';
import { EnterpriseContext } from './EnterpriseContext';
import Tips from '../Tips';
import { toast, ToastContainer } from "react-toastify";
import EnterpriseNavBar from './EnterpriseNavBar';
import { faExchange } from '@fortawesome/free-solid-svg-icons';
import CustomBadge from '../ui/CustomBadge';
import UserDefinedUnits from '../../common/utils/UserDefinedUnits';
import VenatusLeaderboardAd from '../../ads/VenatusLeaderboardAd';
import DesktopTakeover from '../../ads/DesktopTakeover';
import BoxDynamicAd from '../../ads/BoxDynamicAd';
import truckyService from '../../common/services/trucky-service';

const VehicleDetail = ({ container }) => {

    const { globalContext } = useContext(EnterpriseContext);

    const company = globalContext.company;
    const member = globalContext.member;

    const [vehicle, setVehicle] = useState(null);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const es = new EnterpriseService();
    const [garages, setGarages] = useState([]);
    const [members, setMembers] = useState([]);
    const [mechanics, setMechanics] = useState([]);
    const [mechanic, setMechanic] = useState(0);

    useEffect(() => {
        truckyService.setDocumentTitle('Vehicle Details');
    }, []);

    useEffect(() => {
        getVehicle();

        if (es.can(member, 'vehicles.assign')) {
            getGarages();
            getMembers();
        }
    }, []);

    const getMechanics = async (vehicle) => {
        const result = await es.getGarageMechanics(company.id, vehicle.garage.id);
        setMechanics(result.filter(m => m.skill == vehicle.maintenance_needed));
    };

    const getVehicle = async () => {
        setLoading(true);
        const result = await es.getVehicle(company, id);
        setVehicle(result);

        if (es.can(member, 'garages.maintenances') && result != null && result.status == 'need_maintenance') {
            getMechanics(result);
        }

        setLoading(false);
    }

    const getMembers = async () => {
        const result = await es.getMembers(company, { pagination: 1, perPage: 1000 });
        setMembers(result.data);
    };

    const getGarages = async () => {
        const result = await es.getGarages(company);
        setGarages(result);
    };

    const submitEdit = async () => {
        const result = await es.updateVehicle(company, vehicle);
        if (result.success) {
            getVehicle();
            toast.success(container.localeManager.strings.vehicleUpdated);
        }
        else {
            toast.error(result.message);
        }
    }

    const getStatus = () => {
        if (vehicle.status == 'active') {
            return (<CustomBadge color="success"><FontAwesomeIcon icon="check"></FontAwesomeIcon>{container.localeManager.strings.active}</CustomBadge>);
        }
        if (vehicle.status == 'under_maintenance') {
            const maintenanceEnd = container.localeManager.moment(vehicle.maintenance_end_date).local().format('LLL');
            return (
                <>
                    <CustomBadge color="danger"><FontAwesomeIcon icon="wrench"></FontAwesomeIcon>{container.localeManager.strings.under_maintenance} - {maintenanceEnd}</CustomBadge>
                </>
            );
        }
        if (vehicle.status == 'need_maintenance') {
            return (
                <>
                    <CustomBadge color="warning"><FontAwesomeIcon icon="wrench"></FontAwesomeIcon>{container.localeManager.strings.need_maintenance} - {container.localeManager.strings[vehicle.maintenance_needed]}</CustomBadge>
                </>
            );
        }
    }

    const startMaintenance = async () => {
        setLoading(true);
        const result = await es.assignVehicleToMechanic(company, { mechanic_id: mechanic, vehicle_id: vehicle.id, maintenance_type: vehicle.maintenance_needed });
        if (result.success) {
            toast.success(container.localeManager.strings.maintenanceStarted);
        }
        else {
            toast.error(result.message);
        }
        getVehicle();
    };

    return (
        <LoadingOverlay
            active={loading}
            spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
        >
            {!window.overwolf &&
                <DesktopTakeover container={container} className="my-3"></DesktopTakeover>
            }
            <Row noGutters className="">
                <Col>
                    <Row noGutters>
                        <Col>
                            <Card className="h-100">
                                <CardBody>
                                    {window.overwolf &&
                                        <div class="mb-3">
                                            <Button size="sm" onClick={() => navigate(-1)} color="secondary"><FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>{container.localeManager.strings.back}</Button>
                                        </div>
                                    }
                                    {vehicle != null &&
                                        <>
                                            <h5 className='d-inline-block w-100 mb-1'>
                                                #{vehicle.id} - {vehicle.model.brand.name} - {vehicle.model.name}
                                                <div className="float-end d-flex">
                                                    {getStatus()}
                                                </div>
                                            </h5>
                                            <Row>
                                                <Col className='text-center d-flex align-items-center justify-content-center col-12 col-md-6'>
                                                    <img style={{ width: 150 }} src={vehicle.model.brand.logo_url} className='me-3' />
                                                    <img style={{ width: 200 }} src={vehicle.model.photo_url} />
                                                </Col>
                                                {es.can(member, 'vehicles.assign') &&
                                                    <Col>
                                                        {garages.length > 0 &&
                                                            <>
                                                                <FormGroup>
                                                                    <Label sm="auto">{container.localeManager.strings.moveToGarage}</Label>
                                                                    <Input value={vehicle.garage_id} type="select" onChange={(e) => setVehicle({ ...vehicle, edited: true, garage_id: e.target.value })}>
                                                                        {garages.map(g => {
                                                                            return (
                                                                                <option value={g.id}>{g.city.name}</option>
                                                                            )
                                                                        })}
                                                                    </Input>
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label sm="auto">{container.localeManager.strings.assignToUser}</Label>
                                                                    <Input value={vehicle.assigned_to_user_id} type="select" onChange={(e) => setVehicle({ ...vehicle, edited: true, assigned_to_user_id: e.target.value })}>
                                                                        <option value="">{container.localeManager.strings.notAssigned}</option>
                                                                        {members.map(g => {
                                                                            return (
                                                                                <option value={g.id}>{g.name}</option>
                                                                            )
                                                                        })}
                                                                    </Input>
                                                                </FormGroup>
                                                                {/**
                                                                <FormGroup>
                                                                    <label class="form-check form-switch form-check-custom form-check-solid">
                                                                        <input onChange={(e) => setVehicle({ ...vehicle, edited: true, has_insurance: e.target.checked })} class="form-check-input" type="checkbox" checked={vehicle.has_insurance} />
                                                                        <span class="form-check-label">{container.localeManager.strings.enableInsurance}</span>
                                                                    </label>
                                                                </FormGroup>
                                                                    */}
                                                                <FormGroup>
                                                                    <Button size="sm" onClick={submitEdit} color="primary">{container.localeManager.strings.save}</Button>
                                                                </FormGroup>
                                                            </>
                                                        }
                                                    </Col>
                                                }
                                            </Row>
                                            {es.can(member, 'garages.maintenances') && vehicle.status == 'need_maintenance' &&
                                                <div className='mt-3'>
                                                    <FormGroup row>
                                                        <Label sm="auto">{container.localeManager.strings.mechanics}</Label>
                                                        <Col sm={6}>
                                                            <Input onChange={(e) => setMechanic(e.target.value)} type="select">
                                                                <option value="0">{container.localeManager.strings.assignVehicleMechanic}</option>
                                                                {mechanics.map(m => {
                                                                    return (
                                                                        <option disabled={m.vehicle_id != null} value={m.id}>{m.name} - {container.localeManager.strings.speed}: {m.speed}</option>
                                                                    )
                                                                })}
                                                            </Input>
                                                        </Col>
                                                        <Col sm="auto">
                                                            <Button disabled={mechanic == 0} size='sm' onClick={startMaintenance} color='warning'>{container.localeManager.strings.startMaintenance}</Button>
                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                            }
                                            <div className="grid cols-2 no-gap mt-3 cards-h-no-style">
                                                <Card className='text-center'>
                                                    <CardHeader>
                                                        {container.localeManager.strings.driver}
                                                    </CardHeader>
                                                    <CardBody className='d-flex align-items-center justify-content-center'>
                                                        {vehicle.driver != null &&
                                                            <>
                                                                <div className="text-center d-flex align-items-center">
                                                                    <div>
                                                                        <img src={vehicle.driver.avatar_url} className="avatar rounded h-50px min-w-50px me-3" />
                                                                    </div>
                                                                    <div>
                                                                        {vehicle.driver.name}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        {vehicle.driver == null &&
                                                            <h5>{container.localeManager.strings.notAssigned}</h5>
                                                        }
                                                    </CardBody>
                                                </Card>
                                                <Card className='text-center'>
                                                    <CardHeader>
                                                        {container.localeManager.strings.garage}
                                                    </CardHeader>
                                                    <CardBody className='d-flex align-items-center justify-content-center'>
                                                        <h5>{vehicle.garage.city.name} ({vehicle.garage.vehicles_count} / {vehicle.garage.max_vehicles})</h5>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className="grid cols-3 no-gap cards-h-no-style">
                                                <Card className='text-center'>
                                                    <CardHeader>
                                                        {container.localeManager.strings.odometer}
                                                    </CardHeader>
                                                    <CardBody className='d-flex align-items-center justify-content-center'>
                                                        <h5>{UserDefinedUnits.convertDistance(member, null, vehicle.odometer_km)}</h5>
                                                    </CardBody>
                                                </Card>
                                                <Card className='text-center'>
                                                    <CardHeader>
                                                        {container.localeManager.strings.price}
                                                    </CardHeader>
                                                    <CardBody className='d-flex align-items-center justify-content-center'>
                                                        <h5>{container.localeManager.numeral(vehicle.price).format('0,0')} {vehicle.company.currency}</h5>
                                                    </CardBody>
                                                </Card>
                                                <Card className='text-center'>
                                                    <CardHeader>
                                                        {container.localeManager.strings.boughtOn}
                                                    </CardHeader>
                                                    <CardBody className='d-flex align-items-center justify-content-center'>
                                                        <h5>{container.localeManager.moment(vehicle.created_at).format('l')}</h5>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className="grid cols-2 no-gap">
                                                <Card>
                                                    <CardHeader>
                                                        {container.localeManager.strings.statistics}
                                                    </CardHeader>
                                                    <CardBody>
                                                        <ListGroup flush>
                                                            <ListGroupItem>
                                                                {container.localeManager.strings.revenue}
                                                                <span className="float-end">{container.localeManager.numeral(vehicle.total_revenue).format('0,0')} {vehicle.company.currency}</span>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                {container.localeManager.strings.fuelUsed}
                                                                <span className="float-end">{UserDefinedUnits.convertVolume(member, null, vehicle.total_fuel_used_l)}</span>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                {container.localeManager.strings.damageCost}
                                                                <span className="float-end">{container.localeManager.numeral(vehicle.total_damage_cost).format('0,0')} {vehicle.company.currency}</span>
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <CardHeader>
                                                        {container.localeManager.strings.nextMaintenance}
                                                    </CardHeader>
                                                    <CardBody>
                                                        <ListGroup flush>
                                                            <ListGroupItem>
                                                                {container.localeManager.strings.general}
                                                                <span className="float-end">{UserDefinedUnits.convertDistance(member, null, vehicle.next_maintenance.general)}</span>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                {container.localeManager.strings.tires}
                                                                <span className="float-end">{UserDefinedUnits.convertDistance(member, null, vehicle.next_maintenance.tires)}</span>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                {container.localeManager.strings.engine}
                                                                <span className="float-end">{UserDefinedUnits.convertDistance(member, null, vehicle.next_maintenance.engine)}</span>
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <Row>
                                                <Col>
                                                    {vehicle.maintenances && vehicle.maintenances.length > 0 &&
                                                        <>
                                                            <h5 className='mt-3'>{container.localeManager.strings.maintenanceEvents}</h5>
                                                            <Table responsive striped size="sm" className="table-row-dashed table-row-gray-300 align-middle gs-0" hover>
                                                                <thead>
                                                                    <tr className="fw-bolder text-gray-700 bg-light">
                                                                        <th scope='col' className="ps-4">{container.localeManager.strings.date}</th>
                                                                        <th scope='col'>{container.localeManager.strings.type}</th>
                                                                        <th scope='col'>{container.localeManager.strings.price}</th>
                                                                        <th scope='col'>{container.localeManager.strings.odometer}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {vehicle.maintenances.map(m => {
                                                                        return (
                                                                            <tr>
                                                                                <td className="ps-2">{container.localeManager.moment(m.created_at).format('l')}</td>
                                                                                <td>{container.localeManager.strings[m.type]}</td>
                                                                                <td>{container.localeManager.numeral(m.price).format('0,0')} {m.currency}</td>
                                                                                <td>{UserDefinedUnits.convertDistance(member, null, m.odometer_km)}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        <aside className="ad-column-placeholder">
                            <Tips localeManager={container.localeManager}></Tips>
                            {!window.overwolf &&
                        <BoxDynamicAd container={container} className="my-3" />
                    }
                        </aside>
                    </Row>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Col>
            </Row>
            {!window.overwolf &&
                <VenatusLeaderboardAd container={container} className="my-3"></VenatusLeaderboardAd>
            }
        </LoadingOverlay >
    );
}

export default VehicleDetail;