import Cookies from "js-cookie";
import config from "../../config";

function writeLog(message, window = "MAIN") {
  console.log("[TRUCKY] - " + window + " - " + message);
}

function writeWarning(message, window = "MAIN") {
  console.log("[TRUCKY] - " + window + " - WARNING - " + message);
}

function getToken() {
  if (localStorage != null) {
    if (localStorage.getItem("token")) {
      return localStorage.getItem("token");
    } else if (Cookies.get("token")) {
      return Cookies.get("token");
    }
  } else if (Cookies.get("token")) {
    return Cookies.get("token");
  }
  return false;
}

function logError(error, stack) {
  writeLog(JSON.stringify(error));
  writeLog(JSON.stringify(stack));
}

function setCacheData(key, value) {
  if (value != undefined && value != null) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

function existsInCache(key) {
  return localStorage.getItem(key) != null;
}

function getDataFromCache(key) {
  if (localStorage.getItem(key) != null) {
    return JSON.parse(localStorage.getItem(key));
  } else return null;
}

function setCacheDataWithExpiration(key, value, ttl) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getCacheDataWithExpiration(key) {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

function saveSearchParameters(form, parameters) {
  localStorage.setItem(`parameters_${form}`, JSON.stringify(parameters));
}

function loadSearchParameters(form, defaultValue) {
  if (localStorage.getItem(`parameters_${form}`) != null) {
    //let values = JSON.parse(localStorage.getItem(`parameters_${form}`));
    //return Object.assign({}, defaultValue, values);
    return JSON.parse(localStorage.getItem(`parameters_${form}`));
  } else return defaultValue;
}

function openUrlInDefaultBrowser(url) {
  window.location.href = url;
}

function isValidWebhookUrl(url) {
  // https://discordapp.com/api/webhooks/1108089591740768397/AkmvMWu7_t-du9sTMpuWUNZXtR-jR_kUZZwseD48Wjgn-HqxNzDhu1Gx_7S1yCA2V577
  if (url != undefined && url != null && url != "") {
    const regex =
      /https:\/\/(ptb\.|canary\.)?|discord(app)?\.com\/api\/webhooks\/(.*)\/(.*)/gm;
    const m = regex.exec(url);

    if (m == null) return false;
    else return true;
  } else return true;
}

function setDocumentTitle(title) {
  document.title = `${title} - Trucky - The Virtual Trucker Companion App`;
}

function removeToken() {
  localStorage.removeItem("token");
  Cookies.remove("token");
  Cookies.remove('trucky-auth-token', { path: '/', domain: config.AUTH_COOKIE_DOMAIN });
}

export default {
  getToken,
  getDataFromCache,
  getCacheDataWithExpiration,
  saveSearchParameters,
  loadSearchParameters,
  setCacheDataWithExpiration,
  existsInCache,
  setCacheData,
  logError,
  writeLog,
  writeWarning,
  openUrlInDefaultBrowser,
  isValidWebhookUrl,
  setDocumentTitle,
  removeToken
};
