import { useNavigate } from "react-router";
import { Button, ButtonGroup, ButtonToggle } from "reactstrap";

export default function UserLeaderboardsNavigator({ container, active }) {
  const navigate = useNavigate();

  return (
    <ButtonGroup className="w-100 mb-3">
      <Button
        onClick={() => navigate("/leaderboards")}
        color={active == "distance" ? "success" : "primary"}
      >
        {container.localeManager.strings.distanceLeaderboards}
      </Button>
      <Button
        onClick={() => navigate("/hardcore")}
        color={active == "hardcore" ? "success" : "primary"}
      >
        {container.localeManager.strings.hardcoreLeaderboards}
      </Button>
    </ButtonGroup>
  );
}
