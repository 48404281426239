import { useEffect, useState } from "react";
import truckyService from "../../common/services/trucky-service";
import { useNavigate } from "react-router";
import Events from "../../common/constants/events";

const CompanyPresenceComponent = ({ container, fontSize }) => {
    const [users, setUsers] = useState([...container.dataService.data.companyPresenceChannelUsers]);

    useEffect(() => {
        container.eventBus.addListener(Events.PRESENCE_USER_LEFT, onUserLeft);
        container.eventBus.addListener(Events.PRESENCE_USER_JOINED, onUserJoined);
        container.eventBus.addListener(Events.PRESENCE_USERS_UPDATED, onUsersUpdated);
        container.eventBus.addListener(Events.PRESENCE_STATUS_UPDATE, onPresenceStatusUpdate);
    }, []);

    const onUserLeft = (user) => {
        truckyService.writeLog(`User left ${JSON.stringify(user)}`);

        let newUsers = [...container.dataService.data.companyPresenceChannelUsers];

        setUsers(newUsers);
    }

    const onUserJoined = (user) => {
        truckyService.writeLog(`User joined ${JSON.stringify(user)}`);

        let newUsers = [...container.dataService.data.companyPresenceChannelUsers];

        setUsers(newUsers);
    }

    const onUsersUpdated = (users) => {
        truckyService.writeLog(`Users updated ${JSON.stringify(users)}`);

        setUsers(users);
    }

    const onPresenceStatusUpdate = (user) => {

        truckyService.writeLog(`Received User Presence Update: ${JSON.stringify(user)}`);

        let newUsersArray = [...container.dataService.data.companyPresenceChannelUsers];

        setUsers(newUsersArray);
    }

    const renderPresenceStatus = (u) => {

        if (u.state) {
            if (u.state.job != null) {
                return (<>
                    {u.state.job.source_city} - {u.state.job.destination_city}
                    <br />
                    {u.state.job.cargo}
                </>);
            }

            if (u.state.game != null && u.state.game != "") {
                return (
                    <>{container.localeManager.formatString(container.localeManager.strings.playingGame, u.state.game)}</>
                );
            }
        }

        return <>{container.localeManager.strings.idling}</>;
    }

    return (
        <>
            {users != null && users.length > 0 && users.map((u) => {
                return (
                    <div class="d-flex align-items-center mb-7">
                        <div class="symbol symbol-50px me-5">
                            <img src={u.avatar_url} />
                        </div>
                        <div class="flex-grow-1">
                            <span class="text-dark fw-bold text-hover-primary fs-6">{u.name}</span>
                            <span class="text-muted d-block fw-bold">
                                {renderPresenceStatus(u)}
                            </span>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default CompanyPresenceComponent;