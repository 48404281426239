import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const SingleMemberBadge = ({ container, badge, showPeriod = false, hideAvatar = false }) => {

    const navigate = useNavigate();

    const getPeriodDescription = (month, year) => {
        const date = container.localeManager.moment().month(month - 1).year(year);
        return date.format("MMMM YYYY");
    };

    return (
        <div>
            <b>{container.localeManager.strings[`badge_${badge.badge_type}`]}</b>
            <div className="d-flex align-items-center mt-1">
                {badge.user != null && !hideAvatar &&
                    <div>
                        <img src={badge.user.avatar_url} className="avatar rounded h-50px min-w-50px me-3" />
                    </div>
                }
                {hideAvatar &&
                    <div>
                        <img src={badge.icon} className="avatar rounded h-60px min-w-60px me-3" />
                    </div>
                }
                <div className="me-auto">
                    {!hideAvatar &&
                        <>
                            {badge.user != null ? <Link to={`/user/${badge.user.id}`}>{badge.user.name}</Link> : container.localeManager.strings.deletedUser}
                            <br />
                        </>
                    }
                    {badge.value != null &&
                        <span>{container.localeManager.numeral(badge.value).format('0,0')} {badge.value_symbol}</span>
                    }
                    {showPeriod &&
                        <>
                            <br />
                            {getPeriodDescription(badge.month, badge.year)}
                        </>
                    }
                </div>
                {!hideAvatar &&
                    <div>
                        <img src={badge.icon} className="avatar rounded h-50px min-w-50px" />
                    </div>
                }
            </div>
        </div>
    )
}

export default SingleMemberBadge;