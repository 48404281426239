import { useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import { useNavigate, useParams } from "react-router";
import EnterpriseService from "../../common/services/enterprise-service";
import { EnterpriseContext } from "./EnterpriseContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from 'remark-gfm'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import truckyService from "../../common/services/trucky-service";

const FeedDetail = ({ container }) => {

    const { globalContext } = useContext(EnterpriseContext);

    const [feed, setFeed] = useState(null);
    const [loading, setLoading] = useState(true);
    const es = new EnterpriseService();
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {

        truckyService.setDocumentTitle('Company Feed');

        setLoading(true);

        es.getFeedDetail(globalContext.company.id, id).then(result => {

            truckyService.setDocumentTitle(result.title);

            setFeed(result);
            setLoading(false);
        });
    }, []);

    return (
        <>
            <LoadingOverlay
                active={loading}
                spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
            >

                <Row noGutters>
                    <Col>
                        <Card>
                            <CardBody>
                                <div className="d-flex justify-content-between mb-3 me-3">
                                    <div>
                                        {window.overwolf &&
                                            <Button size="sm" onClick={() => navigate(-1)} color="secondary"><FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>{container.localeManager.strings.back}</Button>
                                        }
                                    </div>
                                    {feed != null &&
                                        <div>
                                            <FontAwesomeIcon icon="calendar-alt"></FontAwesomeIcon>
                                            <span class="fw-bold text-gray-400">{container.localeManager.moment(feed.published_at).format('L')}</span>
                                        </div>
                                    }
                                </div>
                                {feed != null &&
                                    <>
                                        <span class="text-dark fs-2 fw-bold mb-5">
                                            {feed.title}
                                        </span>
                                        <div class="fs-5 mt-5 fw-semibold">
                                            <ReactMarkdown children={feed.content} remarkPlugins={[remarkGfm]} />
                                        </div>
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    {!container.dataService.data.enablePremiumFeature() &&
                        <aside className="ad-column-placeholder"></aside>
                    }
                </Row>
            </LoadingOverlay>
        </>
    )
};

export default FeedDetail;