import { Card, CardBody, CardTitle, Row, Col, Button, CardHeader, Table, Badge, Spinner, Modal, ModalBody, ModalHeader, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import { useNavigate, useParams } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import EnterpriseService from '../../common/services/enterprise-service';
import LoadingOverlay from 'react-loading-overlay';
import { confirmWrapper } from '../ui/askConfirm'
import { EnterpriseContext } from './EnterpriseContext';
import { toast, ToastContainer } from 'react-toastify';
import UnitUtils from '../../common/utils/UnitUtils';
import CustomBadge from '../ui/CustomBadge';
import TooltipIcon from '../ui/TooltipIcon';
import MilesTypeExplain from './MilesTypeExplain';
import ProfileV2 from '../ProfileCardV2';
import JobEventsTimeline from './JobEventsTimeline';
import UserDefinedUnits from '../../common/utils/UserDefinedUnits';
import { Link } from 'react-router-dom';
import config from '../../config';
import DesktopTakeover from '../../ads/DesktopTakeover';
import VenatusLeaderboardAd from '../../ads/VenatusLeaderboardAd';
import BoxDynamicAd from '../../ads/BoxDynamicAd';
import truckyService from '../../common/services/trucky-service';
const momentDurationFormatSetup = require("moment-duration-format");
const moment = require("moment");

const JobDetails = ({ close, container, loadContext }) => {

    momentDurationFormatSetup(container.localeManager.moment);

    const { id } = useParams();
    const navigate = useNavigate();
    const [job, setJob] = useState(null);
    const es = new EnterpriseService();
    const [loading, setLoading] = useState(false);
    const [vehicleModel, setVehicleModel] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [localJob, setLocalJob] = useState(null);
    const [reportInfo, setReportInfo] = useState({ message: '' });
    const [reportDialogOpen, toggleReportDialog] = useState(false);
    const [events, setEvents] = useState([]);
    const { globalContext } = useContext(EnterpriseContext);

    const company = globalContext.company;
    const member = globalContext.member;

    useEffect(() => {

        truckyService.setDocumentTitle('Job Details');

        getJob();
    }, []);

    useEffect(() => {
        if (job != null) {
            if (job.company_id != null && company != null && member != null && (company.company_type == 'both' || company.company_type == 'realistic')) {
                if (job.vehicle_id == null) {
                    getMatchingVehicleModel();
                }
                else {
                    getVehicle();
                }
            }
            getEvents();
        }
    }, [job]);

    const getJob = async () => {
        setLoading(true);
        const result = await es.getJob(id);
        //getLocalJob();
        setJob(result);
        setLoading(false);
    };

    const getEvents = async () => {
        const result = await es.getJobEvents(job.id);
        setEvents(result);
    }

    const getLocalJob = async () => {
        let localJob = await container.dataService.enterpriseService.getLocalJobByRemoteId(id);
        if (localJob != undefined) {
            setLocalJob(localJob);
        }
    }

    const getMarketBadge = () => {

        var market = container.localeManager.strings[job.market];

        if (job.special_job)
            market = container.localeManager.strings.specialTransport;

        return (<Badge className="badge-small ms-1" color="primary">{market}</Badge>);
    };

    const getFuelEconomy = () => {
        return UserDefinedUnits.convertFuelConsumption(member, job.game_id, job.driven_distance_km, job.fuel_used_l);
    }

    const deleteJob = async () => {
        if (await confirmWrapper(container.localeManager.strings.deleteJobConf, { container: container })) {
            setLoading(true);
            const result = await es.deleteJob(id);
            if (result.success) {
                navigate(-1);
            }
            else {
                toast.error(result.message);
            }
            setLoading(false);
        }
    }

    const canDeleteJob = () => {

        if (member == null)
            return false;

        var result = false;

        if (job.realistic_leaderboard === true)
            return false;

        if (member != null && job.user_id == member.id) {
            result = true;
        }

        if (job.company_id != null && company != null && member != null && member.company_id == job.company_id && es.can(member, 'jobs.delete')) {
            result = true;
        }

        if (job.company_id != null && job.company_id != member.company_id)
            result = false;

        return result;
    }

    const getVehicle = async () => {
        const result = await es.getVehicle(company, job.vehicle_id);
        if (result != null) {
            setVehicleModel(result.model);
            setVehicle(result);
        }
    };

    const getMatchingVehicleModel = async () => {
        const result = await es.getMatchingVehicle(job);
        setVehicleModel(result);
    };

    const resubmitJob = async () => {

    }

    const submitReport = async () => {
        if (reportInfo.message == '') {
            toast.error('Please fill the form', { position: 'top-right' });
        }
        else {
            await es.reportJob(job, reportInfo);
            toast.success('Report sent! Thank you!', { position: 'top-right' });
            toggleReportDialog(false);
        }
    }

    const getPassengersCount = () => {
        if (job.stops != undefined && job.stops != null) {
            return job.stops.reduce((prev, current) => parseInt(prev) + parseInt(current.passengers_in), 0);
        }
        else {
            return 0;
        }
    }

    const renderFinanceDetails = () => {

        /*"base" => round($baseReward, 2),
                "fixed" => $fixedReward,
                "proficiency" => round($proficiencyBonus, 2),
                "fragility" => round($fragilityBonus, 2),
                "valuable" => round($valueableBonus, 2),
                "distance" => round($longDistanceBonus, 2),
                "overweight" => round($overweightBonus, 2),
                "adr" => round($adrBonus, 2),
                "heavy" => round($heavyBonus, 2),
                "transports" => round($transportsCost, 2),
                "marketChange" => round($marketChange, 2)
                */;
        return (
            <div>
                <div className='fs-4'>{container.localeManager.strings.howThisIsCalculated}</div>
                <div className='d-flex justify-content-between'>
                    <span>{container.localeManager.strings.base}</span>
                    <span className='text-success'>{container.localeManager.numeral(job.income_details.base).format()} {job.currency}</span>
                </div>
                <div className='d-flex justify-content-between'>
                    <span>{container.localeManager.strings.fixed}</span>
                    <span className='text-success'>{container.localeManager.numeral(job.income_details.fixed).format()} {job.currency}</span>
                </div>
                <div className='d-flex justify-content-between'>
                    <span>{container.localeManager.strings.distanceBonus}</span>
                    <span className='text-success'>{container.localeManager.numeral(job.income_details.distance).format()} {job.currency}</span>
                </div>
                <div className='d-flex justify-content-between'>
                    <span>{container.localeManager.strings.proficiencyBonus}</span>
                    <span className='text-success'>{container.localeManager.numeral(job.income_details.proficiency).format()} {job.currency}</span>
                </div>
                <div className='d-flex justify-content-between'>
                    <span>{container.localeManager.strings.fragilityBonus}</span>
                    <span className='text-success'>{container.localeManager.numeral(job.income_details.fragility).format()} {job.currency}</span>
                </div>
                <div className='d-flex justify-content-between'>
                    <span>{container.localeManager.strings.valueableBonus}</span>
                    <span className='text-success'>{container.localeManager.numeral(job.income_details.valuable).format()} {job.currency}</span>
                </div>
                <div className='d-flex justify-content-between'>
                    <span>{container.localeManager.strings.overweightBonus}</span>
                    <span className='text-success'>{container.localeManager.numeral(job.income_details.overweight).format()} {job.currency}</span>
                </div>
                <div className='d-flex justify-content-between'>
                    <span>{container.localeManager.strings.heavyBonus}</span>
                    <span className='text-success'>{container.localeManager.numeral(job.income_details.heavy).format()} {job.currency}</span>
                </div>
                <div className='d-flex justify-content-between'>
                    <span>{container.localeManager.strings.adrBonus}</span>
                    <span className='text-success'>{container.localeManager.numeral(job.income_details.adr).format()} {job.currency}</span>
                </div>
                <div className='d-flex justify-content-between'>
                    <span>{container.localeManager.strings.marketChange}</span>
                    <span className={job.income_details.marketChange < 0 ? 'text-danger' : 'text-success'}>{container.localeManager.numeral(job.income_details.marketChange).format()} {job.currency}</span>
                </div>
            </div>
        )
    };

    const renderRealisticPointsCalculation = () => {

        /*"base" => round($baseReward, 2),
                "fixed" => $fixedReward,
                "proficiency" => round($proficiencyBonus, 2),
                "fragility" => round($fragilityBonus, 2),
                "valuable" => round($valueableBonus, 2),
                "distance" => round($longDistanceBonus, 2),
                "overweight" => round($overweightBonus, 2),
                "adr" => round($adrBonus, 2),
                "heavy" => round($heavyBonus, 2),
                "transports" => round($transportsCost, 2),
                "marketChange" => round($marketChange, 2)
                */;
        return (
            <div>
                <div className='fs-4'>{container.localeManager.strings.howThisIsCalculated}</div>
                {Object.keys(job.realistic_points_calculation).map((key) => {
                    return (
                        <div className='d-flex justify-content-between'>
                            <span>{container.localeManager.strings[key]}</span>
                            <span className={classnames({ 'text-success': job.realistic_points_calculation[key] >= 0, 'text-danger': job.realistic_points_calculation[key] < 0 })}>
                                {container.localeManager.numeral(job.realistic_points_calculation[key]).format('0.[0]')}
                            </span>
                        </div>
                    )
                })}
            </div>
        )
    };

    const getDeliveryRatingStars = () => {

        let color = '';

        if (job.delivery_rating >= 4)
            color = 'text-success';

        if (job.delivery_rating < 4)
            color = 'text-warning';

        if (job.delivery_rating < 3)
            color = 'text-danger';

        let stars = [];

        for (let index = 1; index <= 5; index++) {

            let icon = 'star';

            if (index <= job.delivery_rating)
                icon = 'star';
            else if (index - job.delivery_rating <= 0.5)
                icon = 'star-half-stroke';
            else
                icon = ['far', 'star'];

            stars.push(<FontAwesomeIcon icon={icon} className={color}></FontAwesomeIcon>)
        }

        return stars;
    }

    const getVehicleLink = () => {

        let linked = false;

        if (job.vehicle_id != null && member != null && member.company_id == job.company_id && job.vehicle)
            linked = true;

        if (!linked)
            return (<h5>{job.vehicle_brand_name} {job.vehicle_model_name}</h5>);
        else
            return (<h5><Link to={`/vehicle/${job.vehicle.id}`}>{job.vehicle_brand_name} {job.vehicle_model_name}</Link></h5>);
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
        >
            {!window.overwolf &&
                <DesktopTakeover container={container} className="my-3"></DesktopTakeover>
            }
            <Row noGutters className="">
                <Col>
                    <Card className="h-100">
                        <CardBody className='cards-h-no-style'>
                            {window.overwolf &&
                                <div class="mb-3">
                                    <Button size="sm" onClick={() => navigate(-1)} color="secondary"><FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>{container.localeManager.strings.back}</Button>
                                </div>
                            }
                            {job != null &&
                                <>
                                    <h5 className='d-inline-block w-100 mb-1'>
                                        #{job.id} - {container.localeManager.formatString(container.localeManager.strings.delivering, [job.cargo_name, job.source_city_name, job.destination_city_name])}
                                        <div className="float-end d-flex">
                                            {job.stats_type == 'race_miles' &&
                                                <CustomBadge size="sm" color="danger">{container.localeManager.strings.raceMiles}</CustomBadge>
                                            }
                                            {job.stats_type == 'real_miles' &&
                                                <CustomBadge size="sm" color="success">{container.localeManager.strings.realMiles}</CustomBadge>
                                            }
                                            {job.stats_type == 'transport_system' &&
                                                <CustomBadge size="sm" color="success">{container.localeManager.strings.public_transport}</CustomBadge>
                                            }
                                            {job.max_speed != null &&
                                                <CustomBadge size="sm" color="default" className="ms-1 float-end">{UserDefinedUnits.convertDistanceValue(member, job.game_id, job.max_speed_kmh)} {UserDefinedUnits.getSpeedUnitLabel(member, job.game_id)}</CustomBadge>
                                            }
                                        </div>
                                    </h5>
                                    <ProfileV2 container={container} avatarSize={100} user={job.driver} company={job.company} showLeaderboards={false} showLevel={false} role={null}></ProfileV2>
                                    {job.status != 'in_progress' &&
                                        <Card>
                                            <CardBody className="text-center">
                                                <Row>
                                                    <Col>
                                                        <FontAwesomeIcon icon="road" className="me-0 mb-3" size="3x"></FontAwesomeIcon>
                                                        <h5>
                                                            {UserDefinedUnits.convertDistance(member, job.game_id, job.driven_distance_km)}
                                                        </h5>
                                                    </Col>
                                                    <Col>
                                                        <FontAwesomeIcon icon="gas-pump" className="me-0 mb-3" size="3x"></FontAwesomeIcon>
                                                        <h5>
                                                            {getFuelEconomy()} ({UserDefinedUnits.convertVolume(member, job.game_id, job.fuel_used_l,)})
                                                        </h5>
                                                    </Col>
                                                    <Col>
                                                        <FontAwesomeIcon icon="heart-broken" className="me-0 mb-3" size="3x"></FontAwesomeIcon>
                                                        <h5>
                                                            {job.vehicle_damage + job.trailers_damage + job.cargo_damage}%
                                                        </h5>
                                                    </Col>

                                                    <Col>
                                                        <FontAwesomeIcon icon="hourglass-half" className="me-0 mb-3" size="3x"></FontAwesomeIcon>
                                                        <h5>
                                                            {job.real_driving_time_seconds != null &&
                                                                <span>{moment.duration(job.real_driving_time_seconds, 'seconds').format('h _ m _')}</span>
                                                            }
                                                            {job.real_driving_time_seconds == null &&
                                                                <span>{job.duration}</span>
                                                            }
                                                        </h5>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    }
                                    <div className="grid cols-3 no-gap">
                                        <Card className="text-center">
                                            <CardHeader>{container.localeManager.strings.from}</CardHeader>
                                            <CardBody>
                                                <h5>{job.source_city_name}</h5>
                                                {job.source_company_name}
                                            </CardBody>
                                        </Card>
                                        <Card className="text-center">
                                            <CardHeader>{container.localeManager.strings.to}</CardHeader>
                                            <CardBody>
                                                <h5>{job.destination_city_name}</h5>
                                                {job.destination_company_name}
                                            </CardBody>
                                        </Card>
                                        <Card className="text-center">
                                            <CardHeader>{container.localeManager.strings.vehicle}</CardHeader>
                                            <CardBody>
                                                {getVehicleLink()}
                                                {UserDefinedUnits.convertDistance(member, job.game_id, job.vehicle_odometer_end_km)}
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="grid cols-3 no-gap">
                                        <Card className="text-center">
                                            <CardHeader>
                                                <>
                                                    {getMarketBadge()}
                                                </>
                                            </CardHeader>
                                            <CardBody className={job.market == 'transport_system' ? 'd-flex align-items-center justify-content-center' : ''}>
                                                {job.market != 'transport_system' && <>
                                                    <h5>{job.cargo_name}</h5>
                                                    {UserDefinedUnits.convertWeight(member, job.game_id, job.cargo_mass_t)}</>
                                                }
                                                {job.market == 'transport_system' &&
                                                    <h5>{getPassengersCount()} {job.cargo_name}</h5>
                                                }
                                                {job.status == 'completed' && job.market != 'transport_system' &&
                                                    <div className="mt-3">
                                                        {job.auto_park &&
                                                            <CustomBadge size="sm" className="me-1" color="danger">{container.localeManager.strings.autoPark}</CustomBadge>
                                                        }
                                                        {!job.auto_park &&
                                                            <CustomBadge size="sm" className="me-1" color="success">{container.localeManager.strings.noAutoPark}</CustomBadge>
                                                        }
                                                        {job.market == 'cargo_market' && job.auto_load &&
                                                            <CustomBadge size="sm" className="" color="danger">{container.localeManager.strings.autoLoad}</CustomBadge>
                                                        }
                                                        {job.market == 'cargo_market' && !job.auto_load &&
                                                            <CustomBadge size="sm" className="" color="success">{container.localeManager.strings.loadedFromBay}</CustomBadge>
                                                        }
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                        <Card className="text-center">
                                            <CardHeader>{container.localeManager.strings.distance}</CardHeader>
                                            <CardBody>
                                                <h5>{container.localeManager.strings.planned}</h5>
                                                {UserDefinedUnits.convertDistance(member, job.game_id, job.planned_distance_km)}
                                                {job.status != 'in_progress' &&
                                                    <>
                                                        <h5>{container.localeManager.strings.real}</h5>
                                                        {UserDefinedUnits.convertDistance(member, job.game_id, job.driven_distance_km)}
                                                    </>
                                                }
                                            </CardBody>
                                        </Card>
                                        <Card className="text-center">
                                            <CardHeader>
                                                {job.late_delivery &&
                                                    <CustomBadge size="sm" className="ms-1" color="danger">{container.localeManager.strings.late}</CustomBadge>
                                                }
                                                {!job.late_delivery &&
                                                    <CustomBadge size="sm" className="ms-1" color="success">{container.localeManager.strings.onTime}</CustomBadge>
                                                }
                                            </CardHeader>
                                            <CardBody>
                                                <h5>{container.localeManager.strings.started}</h5>
                                                {container.localeManager.moment(job.started_at).format('LLL')}
                                                {job.status == 'completed' &&
                                                    <>
                                                        <h5>{container.localeManager.strings.end}</h5>
                                                        {container.localeManager.moment(job.completed_at).format('LLL')}
                                                    </>
                                                }
                                                {job.status == 'canceled' &&
                                                    <>
                                                        <h5>{container.localeManager.strings.end}</h5>
                                                        {container.localeManager.moment(job.canceled_at).format('LLL')}
                                                    </>
                                                }
                                            </CardBody>
                                        </Card>
                                    </div>
                                    {job.market == 'transport_system' && job.stops != undefined && job.stops != null && job.stops.length > 0 &&
                                        <Card>
                                            <CardHeader className="text-center">{container.localeManager.strings.stops}</CardHeader>
                                            <CardBody>
                                                <Table responsive striped size="sm" className="table-row-dashed table-row-gray-300 align-middle gs-0" hover>
                                                    <thead>
                                                        <tr className="fw-bolder text-gray-700 bg-light">
                                                            <th scope="col">{container.localeManager.strings.city}</th>
                                                            <th scope="col" className="text-center"><FontAwesomeIcon icon="user-plus"></FontAwesomeIcon></th>
                                                            <th scope="col" className="text-center"><FontAwesomeIcon icon="user-minus"></FontAwesomeIcon></th>
                                                            <th scope="col" className="text-center"><FontAwesomeIcon icon="route"></FontAwesomeIcon></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {job.stops.map((s) => {
                                                            return (
                                                                <tr>
                                                                    <th scope="row">{s.name}</th>
                                                                    <td className="text-center">{s.passengers_in}</td>
                                                                    <td className="text-center">{s.passengers_out}</td>
                                                                    <td className="text-center">{UserDefinedUnits.convertDistance(member, job.game_id, s.driven_distance_km)}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                        </Card>
                                    }
                                    {events.length > 0 &&
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardBody>
                                                        <JobEventsTimeline container={container} job={job} events={events}></JobEventsTimeline>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>

                                    }
                                    <div className="grid cols-2 no-gap">
                                        <Card className="text-center">
                                            <CardHeader>{container.localeManager.strings.financeDetails}</CardHeader>
                                            <CardBody>
                                                <Table responsive striped size="sm" className="table-row-dashed table-row-gray-300 align-middle gs-0" hover>
                                                    <tbody>
                                                        <tr>
                                                            <td className="font-weight-bold text-start">{job.status == 'canceled' ? container.localeManager.strings.cancelationPenalty : container.localeManager.strings.income} {job.income_details != null && <TooltipIcon className="text-muted" icon="calculator" id="jobFinanceDetails" text={renderFinanceDetails}></TooltipIcon>}</td>
                                                            <td className={classnames({ "text-end": true, "text-success": job.income > 0, "text-danger": job.income < 0 })}>{container.localeManager.numeral(job.income).format('0,0')} {job.currency}</td>
                                                        </tr>
                                                        {job.taxes > 0 &&
                                                            <tr>
                                                                <td className="font-weight-bold text-start">{container.localeManager.strings.taxes}</td>
                                                                <td className="text-end text-danger">-{container.localeManager.numeral(job.taxes).format('0,0')} {job.currency}</td>
                                                            </tr>
                                                        }
                                                        {job.other_costs_total > 0 &&
                                                            <tr>
                                                                <td className="font-weight-bold text-start">{container.localeManager.strings.otherCosts} <TooltipIcon className="text-muted" icon="info-circle" id="otherJobsToolip" text={container.localeManager.strings.otherCostsTip}></TooltipIcon></td>
                                                                <td className="text-end text-danger">-{container.localeManager.numeral(job.other_costs_total).format('0,0')} {job.currency}</td>
                                                            </tr>
                                                        }
                                                        {job.fuel_cost > 0 &&
                                                            <tr>
                                                                <td className="font-weight-bold text-start">{container.localeManager.strings.fuelCost}</td>
                                                                <td className="text-end text-danger">-{container.localeManager.numeral(job.fuel_cost).format('0,0')} {job.currency} ({container.localeManager.numeral(job.fuel_unit_price).format('0,0')} {job.currency} {job.volume_unit})</td>
                                                            </tr>
                                                        }
                                                        {job.damage_cost > 0 &&
                                                            <tr>
                                                                <td className="font-weight-bold text-start">{container.localeManager.strings.damageCost}</td>
                                                                <td className="text-end text-danger">-{container.localeManager.numeral(job.damage_cost).format('0,0')} {job.currency}</td>
                                                            </tr>
                                                        }
                                                        {job.rent_cost_total > 0 &&
                                                            <tr>
                                                                <td className="font-weight-bold text-start">{container.localeManager.strings.rentCost}</td>
                                                                <td className="text-end text-danger">-{container.localeManager.numeral(job.rent_cost_total).format('0,0')} {job.currency} ({container.localeManager.numeral(job.rent_cost_per_km).format('0,0')} {job.currency} per km)</td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td className="font-weight-bold text-start">{container.localeManager.strings.revenue}</td>
                                                            <td className={classnames({ 'text-end': true, 'text-danger': job.revenue < 0, 'text-success': job.revenue > 0 })}>{container.localeManager.numeral(job.revenue).format('0,0')} {job.currency}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                        </Card>
                                        <Card className="text-center">
                                            <CardHeader>{container.localeManager.strings.damageDetails}</CardHeader>
                                            <CardBody>
                                                <Table responsive striped size="sm" className="table-row-dashed table-row-gray-300 align-middle gs-0" hover>
                                                    <tbody>
                                                        <tr>
                                                            <td className="font-weight-bold text-start">{container.localeManager.strings.vehicleDamage}</td>
                                                            <td className={classnames({ 'text-end': true, 'text-danger': job.vehicle_damage > 0, 'text-success': job.vehicle_damage == 0 })}>{job.vehicle_damage}%</td>
                                                        </tr>
                                                        {job.market != 'transport_system' &&
                                                            <tr>
                                                                <td className="font-weight-bold text-start">{container.localeManager.strings.trailersDamage}</td>
                                                                <td className={classnames({ 'text-end': true, 'text-danger': job.trailers_damage > 0, 'text-success': job.trailers_damage == 0 })}>{job.trailers_damage}%</td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td className="font-weight-bold text-start">{container.localeManager.strings.cargoDamage}</td>
                                                            <td className={classnames({ 'text-end': true, 'text-danger': job.cargo_damage > 0, 'text-success': job.cargo_damage == 0 })}>{job.cargo_damage}%</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="float-end mt-3">
                                        {canDeleteJob() &&
                                            <Button size="sm" onClick={deleteJob} className="me-1" color="danger"><FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>{container.localeManager.strings.deleteJob}</Button>
                                        }
                                        {localJob != null && job != null && localJob.status != job.status &&
                                            <Button size="sm" color="primary" onClick={resubmitJob}><FontAwesomeIcon icon="upload"></FontAwesomeIcon>Submit Job</Button>
                                        }
                                        {job.status == 'completed' &&
                                            <Button size="sm" onClick={() => toggleReportDialog(true)} className="ms-1" color="primary"><FontAwesomeIcon icon="flag"></FontAwesomeIcon>{container.localeManager.strings.report}</Button>
                                        }
                                    </div>
                                </>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <aside className="ad-column-placeholder">
                    {job != null &&
                        job.realistic_leaderboard !== null && job.realistic_reject_reasons && config.ENABLE_PROFILE_TRACKING &&
                        <Card className="mb-3">
                            <CardHeader className='d-flex align-items-center'>
                                <FontAwesomeIcon icon="trophy"></FontAwesomeIcon>{container.localeManager.strings.hardcoreSimulation}
                            </CardHeader>
                            <CardBody>
                                <div className="fs-4"></div>
                                {job.realistic_leaderboard === true &&
                                    <div className='d-flex align-items-center flex-column'>
                                        <div className='mb-2 fs-bolder fs-5'>
                                            {container.localeManager.strings.deliveryRating}
                                        </div>
                                        {job.delivery_rating != null &&
                                            <div title={job.delivery_rating}>{getDeliveryRatingStars()}</div>
                                        }
                                        {job.realistic_points_calculation &&
                                            <div className='my-2 fs-bolder fs-5'>
                                                {container.localeManager.strings.points} {<TooltipIcon className="text-muted" icon="calculator" id="realisticPointsCalculation" text={renderRealisticPointsCalculation}></TooltipIcon>}
                                            </div>
                                        }
                                        {job.realistic_ldb_points &&
                                            <div>{job.realistic_ldb_points} {container.localeManager.strings.hardcore_simulation_pointsAbbr}</div>
                                        }
                                    </div>
                                }
                                {job.realistic_leaderboard === false && job.realistic_reject_reasons && job.realistic_reject_reasons.length > 0 &&
                                    <div className='mt-3'>
                                        {container.localeManager.strings.notValidHardcoreJobReasons} {job.realistic_reject_reasons.map(m => container.localeManager.strings[m]).join(', ')}
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    }
                    {vehicleModel != null && member != null && member.company_id == job.company_id &&
                        <Card>
                            <CardBody className='cards-h-no-style'>
                                <div className="text-center">
                                    <h5>{vehicleModel.brand.name} - {vehicleModel.name}</h5>
                                    <div>
                                        <img style={{ width: 200 }} src={vehicleModel.photo_url} />
                                    </div>
                                </div>
                                {job.vehicle_id == null &&
                                    <div>
                                        <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>{container.localeManager.strings.avoidRentingCostHint}
                                    </div>
                                }
                                {job.vehicle_id != null && vehicle != null &&
                                    <Button size="sm" className="mt-3" block color="primary" onClick={() => navigate(`/vehicle/${vehicle.id}`)}>{container.localeManager.strings.details}</Button>
                                }
                            </CardBody>
                        </Card>
                    }
                    <MilesTypeExplain container={container}></MilesTypeExplain>
                    {!window.overwolf &&
                        <BoxDynamicAd container={container} className="my-3" />
                    }
                </aside>
                <Modal unmountOnClose={true} zIndex="999999999" isOpen={reportDialogOpen} toggle={() => toggleReportDialog(false)}>
                    <ModalHeader toggle={() => toggleReportDialog(false)}
                        close={<Button color="active-light-primary" className="btn-icon btn-sm w-30px h-30px ms-auto" onClick={() => toggleReportDialog(false)}><FontAwesomeIcon icon="times"></FontAwesomeIcon></Button>}>
                        {container.localeManager.strings.details}
                    </ModalHeader>
                    <ModalBody>
                        <div className="mb-3">
                            {container.localeManager.strings.jobReportHint}
                        </div>
                        <FormGroup>
                            <Label>{container.localeManager.strings.message}</Label>
                            <Input type="textarea" rows="10" cols="10" onChange={(e) => setReportInfo({ ...reportInfo, message: e.target.value })}></Input>
                        </FormGroup>
                        <FormGroup>
                            <Button size="sm" color='primary' onClick={submitReport}>{container.localeManager.strings.submitReport}</Button>
                        </FormGroup>
                    </ModalBody>
                </Modal>
            </Row>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {!window.overwolf &&
                <VenatusLeaderboardAd container={container} className="my-3"></VenatusLeaderboardAd>
            }
        </LoadingOverlay >
    )
}

export default JobDetails;