import LocalizedStrings from 'react-localization';
// Main resource objects

export default new LocalizedStrings({
    en: require('./languages/en.json'),
}
);
/*{
    en: require('./languages/en.json'),
    it: require('./languages/it.json'),
    // credits: Hristo Spasov
    bg: require('./languages/bg.json'),
    // credits: Kevin Monteil credits: Caernage
    // https://forum.truckersmp.com/index.php?/profile/103421-caernage/
    fr: require('./languages/fr.json'),
    // Credits Jiri Innanen
    fi: require('./languages/fi.json'),
    // credits Francisco Ramirez
    es: require('./languages/es.json'),
    // credits Derk Nomden
    nl: require('./languages/nl.json'),
    // credits Piotrek Ślusarz
    pl: require('./languages/pl.json'),
    // Credits Tobias Groß
    de: require('./languages/de.json'),
    // Credits ActiV3Drifter
    // (https://forum.truckersmp.com/index.php?/profile/93979-activ3drifter/)
    cs: require('./languages/cs.json'),
    // Credits CJMAXiK (https://truckersmp.com/user/3861)
    ru: require('./languages/ru.json'),
    // Ru13z - https://forum.truckersmp.com/index.php?/profile/119633-ru13z/
    pt: require('./languages/pt-PT.json'),
    "pt-BR": require('./languages/pt-BR.json'),
    zh: require('./languages/zh-CN.json'),
    bn: require('./languages/bn-BD.json'),
    tr: require('./languages/tr.json'),
    ko: require('./languages/ko.json'),
    ro: require('./languages/ro.json'),
    // TheUnkownNO - https://forum.truckersmp.com/index.php?/profile/20457-theunknownno/
    nb: require('./languages/nb.json'),
    // fLYINGtruckS - https://forum.truckersmp.com/index.php?/profile/141304-flyingtrucks/
    et: require('./languages/et.json'),
    hu: require('./languages/hu.json'),
    lt: require('./languages/lt.json'),
    sk: require('./languages/sk.json'),
    id: require('./languages/id.json'),
    fil: require('./languages/fil.json'),
    da: require('./languages/da.json'),
    lv: require('./languages/lv-LV.json'),
    th: require('./languages/th.json'),
    sv: require('./languages/sv.json'),
    sr: require('./languages/sr.json'),
    sq: require('./languages/sq.json'),
    ja: require('./languages/ja.json'),
    fa: require('./languages/fa.json'),
    ka: require('./languages/ka.json'),
    "zh-TW": require('./languages/zh-TW.json'),
    el: require('./languages/el.json'),
    uk: require('./languages/uk.json'),
    cy: require('./languages/cy-GB.json'),
    bs: require('./languages/bs-BA.json'),
    he: require('./languages/he.json'),
    sl: require('./languages/sl.json')
}, { logsEnabled: false });
*/