export default {
    KEYS: {
        ACHIEVEMENTS: "achievements",
        DISPATCHER: "dispatcher",
        LINES_MANAGER: "lines_manager",
        LEADERBOARDS: "leaderboards",
        ENTERPRISE_MEMBERS: "enterprise_members-users",
        ENTERPRISE_JOBS: "enterprise_jobs",
        ENTERPRISE_GARAGES: "enterprise_fleet-garages",
        ENTERPRISE_VEHICLES: "enterprise_fleet-vehicles",
        ENTERPRISE_MARKET: "enterprise_market"
    }
}