import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Alert,
  ButtonGroup,
  Row,
  Spinner,
  Form,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EnterpriseContext } from "./EnterpriseContext";
import LoadingOverlay from "react-loading-overlay";
import { useNavigate, useParams } from "react-router";
import { useContext, useEffect, useState } from "react";
import Paginator from "../ui/Paginator";
import JobsTable from "./JobsTable";
import PeriodSelector from "./PeriodSelector";
import Loader from "../ui/Loader";
import Chart from "react-apexcharts";
import truckyService from "../../common/services/trucky-service";
import ButtonDropdownMenu from "../ui/ButtonDropdownMenu";
import pluginService from "../../common/services/plugin-service";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import config from "../../config";
import UnitUtils from "../../common/utils/UnitUtils";
import UserDefinedUnits from "../../common/utils/UserDefinedUnits";
const moment = require("moment");

const MemberJobs = ({ container, user }) => {
  const { globalContext } = useContext(EnterpriseContext);

  const [loadingChart, setLoadingChart] = useState(false);
  const [loadingJobsTable, setLoadingJobsTable] = useState(false);
  const es = new EnterpriseService();
  const [jobs, setJobs] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, perPage: 20 });
  const [totalPages, setTotalPages] = useState(0);
  const [currentPeriod, setCurrentPeriod] = useState(
    truckyService.loadSearchParameters("userdetail_currentPeriod", {
      month: moment().month(),
      year: moment().year(),
      date: moment().format("YYYY-MM-DD"),
      week: moment().isoWeek(),
    })
  );
  const [range, setRange] = useState(
    truckyService.loadSearchParameters("userdetail_currentRange", "monthly")
  );
  const [jobsGroupedByDate, setJobsGroupedByDate] = useState([]);
  const [searchParameters, setSearchParameters] = useState(
    truckyService.loadSearchParameters("userdetail_jobs", {
      status: "completed",
      game_id: "",
      game_mode: "",
      market: "",
      valid_hardcore_simulation: "",
      destinationCity: "",
      sourceCity: "",
      dateFrom: null,
      dateTo: null,
    })
  );

  useEffect(() => {
    getData();
  }, [user]);

  useEffect(() => {
    getMemberJobs();
  }, [pagination]);

  useEffect(() => {
    truckyService.saveSearchParameters(
      "userdetail_currentPeriod",
      currentPeriod
    );
  }, [
    currentPeriod.month,
    currentPeriod.year,
    currentPeriod.date,
    currentPeriod.week,
  ]);

  useEffect(() => {
    truckyService.saveSearchParameters("userdetail_jobs", searchParameters);
  }, [searchParameters]);

  useEffect(() => {
    truckyService.saveSearchParameters("userdetail_currentRange", range);
  }, [range]);

  const getMemberJobs = async () => {
    setLoadingJobsTable(true);
    const result = await es.getMemberJobs(
      user,
      pagination,
      currentPeriod.month + 1,
      currentPeriod.year,
      range,
      currentPeriod.date,
      searchParameters,
      currentPeriod.week
    );
    setJobs(result.data);
    setTotalPages(result.last_page);
    setLoadingJobsTable(false);
  };

  const getJobsGroupedByDate = async () => {
    setLoadingChart(true);
    const filters = {
      ...searchParameters,
      period: range,
      ...currentPeriod,
      month: currentPeriod.month + 1,
      year: currentPeriod.year,
      range,
      date: currentPeriod.date,
    };
    const result = await es.getJobsGroupedByDate(user.id, filters);
    setJobsGroupedByDate(result);
    setLoadingChart(false);
  };

  const handlePageClick = (event) => {
    setPagination({ ...pagination, page: event.selected + 1 });
  };

  const getData = () => {
    getMemberJobs();
    getJobsGroupedByDate();
  };

  const exportJobs = async () => {
    setLoadingJobsTable(true);

    const downloadUrl = es.createDownloadUserJobsUrl(user.id, searchParameters);

    if (window.overwolf) {
      const downloader = await pluginService.getPlugin("Downloader");
      const fileName = `${
        overwolf.io.paths.localAppData
      }\\TruckyOverlay\\Exports\\jobs_${moment().format(
        "MMDDYYYY_HHmmss"
      )}.xlsx`;

      downloader.onDownloadComplete.addListener(() => {
        overwolf.utils.openWindowsExplorer(fileName, () => {});
        setLoadingJobsTable(false);
      });

      downloader.downloadFile(downloadUrl, fileName, truckyService.getToken());
    } else {
      window.location.href = downloadUrl;
      setLoadingJobsTable(false);
    }
  };

  const renderJobsChart = () => {
    return (
      <Chart
        height={350}
        width={700}
        type="line"
        options={{
          chart: {
            redrawOnParentResize: true,
            type: "line",
            height: 350,
            width: 700,
            toolbar: {
              tools: {
                download: false,
              },
            },
          },
          grid: {
            borderColor: "#2b2b40",
          },
          stroke: {
            width: [0, 4],
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          labels: jobsGroupedByDate.map((m) => m.date),
          xaxis: {
            type: "datetime",
          },
          yaxis: {
            labels: {
              formatter: (value) =>
                container.localeManager.numeral(value).format("0,0") +
                " " +
                UserDefinedUnits.getDistanceUnitLabel(
                  globalContext.member,
                  null
                ),
            },
          },
        }}
        series={[
          {
            name: container.localeManager.strings.totDistance,
            type: "column",
            data: jobsGroupedByDate.map((m) =>
              UserDefinedUnits.convertDistanceValue(
                globalContext.member,
                null,
                m.total_distance_km
              )
            ),
          },
          {
            name: container.localeManager.strings.avg,
            type: "line",
            data: jobsGroupedByDate.map((m) =>
              UserDefinedUnits.convertDistanceValue(
                globalContext.member,
                null,
                m.avg_distance_km
              )
            ),
          },
        ]}
      ></Chart>
    );
  };

  const changeDatesRange = (value) => {
    if (value == null)
      setSearchParameters({
        ...searchParameters,
        dateFrom: null,
        dateTo: null,
      });
    else
      setSearchParameters({
        ...searchParameters,
        dateFrom: value[0],
        dateTo: value[1],
      });
  };

  const handleRowsPerPageChange = (perPage) => {
    setPagination({ ...pagination, page: 1, perPage: perPage });
  };

  return (
    <div className="mt-3">
      <PeriodSelector
        state={{ range, currentPeriod }}
        container={container}
        onChangePeriod={setCurrentPeriod}
        onChangeRange={setRange}
      ></PeriodSelector>
      <FormGroup row>
        <Col className="col-12 col-md">
          <Label>{container.localeManager.strings.game}</Label>
          <Input
            value={searchParameters.game_id}
            onChange={(e) =>
              setSearchParameters({
                ...searchParameters,
                game_id: e.target.value,
              })
            }
            type="select"
          >
            <option value="">{container.localeManager.strings.all}</option>
            <option value="1">Euro Truck Simulator 2</option>
            <option value="2">American Truck Simulator</option>
          </Input>
        </Col>
        <Col className="col-12 col-md">
          <Label>{container.localeManager.strings.mode}</Label>
          <Input
            value={searchParameters.game_mode}
            onChange={(e) =>
              setSearchParameters({
                ...searchParameters,
                game_mode: e.target.value,
              })
            }
            type="select"
          >
            <option value="">{container.localeManager.strings.all}</option>
            <option value="sp">
              {container.localeManager.strings.singleplayer}
            </option>
            <option value="truckersmp">TruckersMP</option>
          </Input>
        </Col>
        <Col className="col-12 col-md">
          <Label>{container.localeManager.strings.market}</Label>
          <Input
            value={searchParameters.market}
            onChange={(e) =>
              setSearchParameters({
                ...searchParameters,
                market: e.target.value,
              })
            }
            type="select"
          >
            <option value="">{container.localeManager.strings.all}</option>
            <option value="quick_job">
              {container.localeManager.strings.quick_job}
            </option>
            <option value="freight_market">
              {container.localeManager.strings.freight_market}
            </option>
            <option value="cargo_market">
              {container.localeManager.strings.cargo_market}
            </option>
            <option value="external_contracts">
              {container.localeManager.strings.external_contracts}
            </option>
            <option value="external_market">
              {container.localeManager.strings.external_market}
            </option>
            <option value="transport_system">
              {container.localeManager.strings.transport_system}
            </option>
          </Input>
        </Col>
        <Col className="col-12 col-md">
          <Label>{container.localeManager.strings.status}</Label>
          <Input
            value={searchParameters.status}
            onChange={(e) =>
              setSearchParameters({
                ...searchParameters,
                status: e.target.value,
              })
            }
            type="select"
          >
            <option value="">{container.localeManager.strings.all}</option>
            <option value="completed">
              {container.localeManager.strings.completed}
            </option>
            <option value="in_progress">
              {container.localeManager.strings.in_progress}
            </option>
            <option value="canceled">
              {container.localeManager.strings.canceled}
            </option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row className="mb-3">
        <Col>
          <Label>{container.localeManager.strings.sourceCity}</Label>
          <Input
            value={searchParameters.source_city}
            onChange={(e) =>
              setSearchParameters({
                ...searchParameters,
                source_city: e.target.value,
              })
            }
          />
        </Col>
        <Col>
          <Label>{container.localeManager.strings.destinationCity}</Label>
          <Input
            value={searchParameters.destination_city}
            onChange={(e) =>
              setSearchParameters({
                ...searchParameters,
                destination_city: e.target.value,
              })
            }
          />
        </Col>
        <Col>
          <Label>{container.localeManager.strings.datesRange}</Label>
          <div>
            <DateRangePicker
              value={[searchParameters.dateFrom, searchParameters.dateTo]}
              locale={container.localeManager.language}
              calendarIcon={
                <FontAwesomeIcon icon="calendar-alt"></FontAwesomeIcon>
              }
              clearIcon={<FontAwesomeIcon icon="times"></FontAwesomeIcon>}
              onChange={changeDatesRange}
            ></DateRangePicker>
          </div>
        </Col>
        {config.ENABLE_PROFILE_TRACKING && (
          <Col sm="auto">
            <Label>
              {container.localeManager.strings.validForHardcoreLeaderboards}
            </Label>
            <Input
              value={searchParameters.valid_hardcore_simulation}
              onChange={(e) =>
                setSearchParameters({
                  ...searchParameters,
                  valid_hardcore_simulation: e.target.value,
                })
              }
              type="select"
            >
              <option value="">{container.localeManager.strings.all}</option>
              <option value="true">
                {container.localeManager.strings.yes}
              </option>
              <option value="false">
                {container.localeManager.strings.no}
              </option>
            </Input>
          </Col>
        )}
        <Col sm="auto" className="d-flex align-items-end mt-3 mt-md-0">
          <Button size="sm" onClick={getData} color="primary">
            <FontAwesomeIcon icon="filter" className="me-0"></FontAwesomeIcon>
          </Button>
          {globalContext.member && globalContext.member.id == user.id && (
            <Button
              size="sm"
              className="ms-3"
              onClick={exportJobs}
              color="primary"
            >
              <FontAwesomeIcon
                icon="file-export"
                className="me-0"
              ></FontAwesomeIcon>
            </Button>
          )}
        </Col>
      </FormGroup>
      <div>
        {loadingChart && <Loader></Loader>}
        {!loadingChart && (
          <div className="border rounded p-3 d-flex justify-content-center mb-3">
            {renderJobsChart()}
          </div>
        )}
      </div>
      {loadingJobsTable && <Loader></Loader>}
      {!loadingJobsTable && (
        <>
          <Paginator
            rowsPerPageSelected={pagination.perPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            showRowsPerPage={true}
            forcePage={pagination.page - 1}
            totalPages={totalPages}
            handlePageClick={handlePageClick}
          ></Paginator>
          <JobsTable
            isPersonal={true}
            jobs={jobs}
            container={container}
          ></JobsTable>
          <Paginator
            rowsPerPageSelected={pagination.perPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            showRowsPerPage={true}
            forcePage={pagination.page - 1}
            totalPages={totalPages}
            handlePageClick={handlePageClick}
          ></Paginator>
        </>
      )}
    </div>
  );
};

export default MemberJobs;
