import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TransactionDetails = ({ container, company, details, onClose }) => {

    const [isOpen, toggleOpen] = useState(details != null);

    useEffect(() => {
        if (!isOpen) {
            onClose();
        }
    }, [isOpen]);

    const getSalaryTypeUnit = (detail) => {
        let ret;
        switch (detail.salary_type) {
            case 'fixed':
            case 'revenue_per_km':
                ret = 'T¢'
                break;
            case 'revenue_percentage':
                ret = '%';
                break;
        }

        return ret;
    }

    const getTotal = () => {
        return details.reduce((prev, current) => {
            return prev + current.value;
        }, 0);
    }

    return (
        <Modal size="lg" isOpen={isOpen} toggle={() => toggleOpen(false)}>
            <ModalHeader toggle={() => toggleOpen(false)}
                close={<Button color="active-light-primary" className="btn-icon btn-sm w-30px h-30px ms-auto" onClick={() => toggleOpen(false)}><FontAwesomeIcon icon="times"></FontAwesomeIcon></Button>}>
            </ModalHeader>
            <ModalBody>
                <Table responsive striped size="sm" className="table-row-dashed table-row-gray-300 align-middle gs-0" hover>
                    <thead>
                        <tr className="fw-bolder text-gray-700 bg-light">
                            <th className="text-start ps-2">{container.localeManager.strings.user}</th>
                            <th className="text-end">{container.localeManager.strings.base_salary}</th>
                            <th className="text-end">{container.localeManager.strings.additional_salary}</th>
                            <th className="text-end">{container.localeManager.strings.value}</th>
                            <th className="text-end">{container.localeManager.strings.based_on}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.map((m) => {
                            return (
                                <tr>
                                    <td className="ps-2">
                                        <div className="d-flex align-items-center">
                                            <img src={m.user.avatar_url} className="avatar h-30px rounded me-3" /> {m.user.name}
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        {container.localeManager.numeral(m.base_member_salary).format()} {getSalaryTypeUnit(m)}
                                    </td>
                                    <td className="text-end">
                                        {container.localeManager.numeral(m.additional_role_salary).format()} {getSalaryTypeUnit(m)}
                                    </td>
                                    <td className="text-end">
                                        {container.localeManager.numeral(m.value).format()} {'T¢'}
                                    </td>
                                    <td className="text-end">
                                        {m.based_on != null &&
                                            <>
                                                {container.localeManager.numeral(m.based_on).format()} {m.based_on_unit}
                                            </>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td colspan="3"></td>
                            <td className="fw-bolder text-end">
                                {container.localeManager.numeral(getTotal()).format()} {'T¢'}
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </ModalBody>
        </Modal>
    )
}

export default TransactionDetails;