import { Alert, Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import JobDetails from "./JobDetails";
import { useState, useEffect, useContext } from "react";
import Loader from "../ui/Loader";
import { useNavigate, useParams } from "react-router";
import EnterpriseService from "../../common/services/enterprise-service";
import OrderByButtons from "../ui/OrderByButtons";
import TooltipIcon from "../ui/TooltipIcon";
import ButtonDropdownMenu from "../ui/ButtonDropdownMenu";
import truckyService from "../../common/services/trucky-service";
import UserDefinedUnits from "../../common/utils/UserDefinedUnits";
import { EnterpriseContext } from "./EnterpriseContext";
import { Link } from "react-router-dom";

const availableColumns = [
    "id", "date", "game", "driver", "cargo", "distance", "revenue", "vehicle", "source", "destination", "max_speed", "damage", "duration", "market", "game_mode",
    "fuel_economy", "fuel_used", 'hardcore_simulation_points', 'delivery_rating'
];

const defaultColumns = [
    "id", "date", "game", "driver", "cargo", "distance", "revenue"
];

const JobsTable = ({ container, reload, title, jobs, isPersonal = false, changeSortingField, filters = {}, checks = false, onSelectedChanged = null }) => {

    const navigate = useNavigate();
    const [selected, setSelected] = useState([]);
    const [columns, setColumns] = useState(truckyService.loadSearchParameters('jobs_table', defaultColumns));

    const { globalContext } = useContext(EnterpriseContext);

    const member = globalContext.member;

    const selectAll = (e) => {
        if (e.target.checked) {
            setSelected(jobs.map(m => m.id));
        }
        else {
            setSelected([]);
        }
    }

    useEffect(() => {
        if (onSelectedChanged != null) {
            onSelectedChanged(selected);
        }
    }, [selected])

    useEffect(() => {
        truckyService.saveSearchParameters('jobs_table', columns);
    }, [columns.length])

    const selectJob = (checked, id) => {
        if (checked) {
            setSelected([...selected, id]);
        }
        else {
            setSelected(selected.filter(m => m != id));
        }
    };

    const toggleColumn = (checked, field) => {
        if (checked) {
            setColumns([...columns, field]);
        }
        else {
            setColumns(columns.filter(m => m != field));
        }
    }

    return (
        <>
            {jobs.length > 0 &&
                <>
                    <Table responsive striped size="sm" className="table-row-dashed table-row-gray-300 align-middle gs-0 mt-3" hover>
                        <thead>
                            <tr className="fw-bolder text-gray-700 bg-light">
                                {checks &&
                                    <th scope="col" className="ps-1">
                                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                                            <input class="form-check-input" type="checkbox" onChange={selectAll} />
                                        </div>
                                    </th>
                                }
                                {columns.includes('id') &&
                                    <th scope="col" className="ps-4">#</th>
                                }
                                {columns.includes('date') &&
                                    <th scope="col">{container.localeManager.strings.date}
                                        {changeSortingField &&
                                            <OrderByButtons
                                                container={container}
                                                currentSortingDirection={filters.sortingDirection}
                                                currentSortingField={filters.sortingField}
                                                field="updated_at" onClick={changeSortingField}>
                                            </OrderByButtons>
                                        }</th>
                                }
                                {columns.includes('game') &&
                                    <th scope="col">{container.localeManager.strings.game}</th>
                                }
                                {columns.includes('game_mode') &&
                                    <th scope="col">{container.localeManager.strings.game_mode}</th>
                                }
                                {!isPersonal && columns.includes('driver') &&
                                    <th scope="col">{container.localeManager.strings.driver}</th>
                                }
                                {columns.includes('vehicle') &&
                                    <th scope="col">{container.localeManager.strings.vehicle}</th>
                                }
                                {columns.includes('source') &&
                                    <th scope="col">{container.localeManager.strings.from}</th>
                                }
                                {columns.includes('destination') &&
                                    <th scope="col">{container.localeManager.strings.to}</th>
                                }
                                {columns.includes('cargo') &&
                                    <th scope="col">{container.localeManager.strings.cargo}</th>
                                }
                                {columns.includes('market') &&
                                    <th scope="col">{container.localeManager.strings.market}</th>
                                }
                                {columns.includes('distance') &&
                                    <th scope="col" className="text-end">{container.localeManager.strings.distance}
                                        {changeSortingField &&
                                            <OrderByButtons
                                                container={container}
                                                currentSortingDirection={filters.sortingDirection}
                                                currentSortingField={filters.sortingField}
                                                field="driven_distance_km" onClick={changeSortingField}>
                                            </OrderByButtons>
                                        }
                                    </th>
                                }
                                {columns.includes('revenue') &&
                                    <th scope="col" className="text-end">{container.localeManager.strings.revenue}
                                        {changeSortingField &&
                                            <OrderByButtons
                                                container={container}
                                                currentSortingDirection={filters.sortingDirection}
                                                currentSortingField={filters.sortingField}
                                                field="revenue" onClick={changeSortingField}>
                                            </OrderByButtons>
                                        }
                                    </th>
                                }
                                {columns.includes('max_speed') &&
                                    <th scope="col">{container.localeManager.strings.maxSpeedLabel}
                                        {changeSortingField &&
                                            <OrderByButtons
                                                container={container}
                                                currentSortingDirection={filters.sortingDirection}
                                                currentSortingField={filters.sortingField}
                                                field="max_speed" onClick={changeSortingField}>
                                            </OrderByButtons>
                                        }
                                    </th>
                                }
                                {columns.includes('fuel_used') &&
                                    <th className="text-end" scope="col">{container.localeManager.strings.fuel_used}
                                        {changeSortingField &&
                                            <OrderByButtons
                                                container={container}
                                                currentSortingDirection={filters.sortingDirection}
                                                currentSortingField={filters.sortingField}
                                                field="fuel_used_l" onClick={changeSortingField}>
                                            </OrderByButtons>
                                        }
                                    </th>
                                }
                                {columns.includes('fuel_economy') &&
                                    <th className="text-end" scope="col">{container.localeManager.strings.fuel_economy}
                                    </th>
                                }
                                {columns.includes('damage') &&
                                    <th className="text-end" scope="col">{container.localeManager.strings.damage}</th>
                                }
                                {columns.includes('duration') &&
                                    <th scope="col">{container.localeManager.strings.durationNoDays}
                                        {changeSortingField &&
                                            <OrderByButtons
                                                container={container}
                                                currentSortingDirection={filters.sortingDirection}
                                                currentSortingField={filters.sortingField}
                                                field="real_driving_time_seconds" onClick={changeSortingField}>
                                            </OrderByButtons>
                                        }
                                    </th>
                                }
                                {columns.includes('hardcore_simulation_points') &&
                                    <th scope="col">{container.localeManager.strings.hardcore_simulation_pointsAbbr}
                                        {changeSortingField &&
                                            <OrderByButtons
                                                container={container}
                                                currentSortingDirection={filters.sortingDirection}
                                                currentSortingField={filters.sortingField}
                                                field="realistic_ldb_points" onClick={changeSortingField}>
                                            </OrderByButtons>
                                        }
                                    </th>
                                }
                                {columns.includes('delivery_rating') &&
                                    <th scope="col">{container.localeManager.strings.rating}
                                        {changeSortingField &&
                                            <OrderByButtons
                                                container={container}
                                                currentSortingDirection={filters.sortingDirection}
                                                currentSortingField={filters.sortingField}
                                                field="delivery_rating" onClick={changeSortingField}>
                                            </OrderByButtons>
                                        }
                                    </th>
                                }
                                <th scope="col" className="text-end pe-3">
                                    <ButtonDropdownMenu buttonContent={<FontAwesomeIcon icon="table-columns" className="m-0"></FontAwesomeIcon>}
                                        menuTitle={container.localeManager.strings.columns}
                                        menuId="jobs_table_columns_selector"
                                        menuContent={<div className="p-3">
                                            {availableColumns.map(m => {
                                                return (
                                                    <div className="mt-2">
                                                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                            <input checked={columns.includes(m)} class="form-check-input me-1" type="checkbox" onChange={(e) => toggleColumn(e.target.checked, m)} /> {container.localeManager.strings[m]}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>}
                                        buttonClassNames="btn-text p-0"
                                        buttonColor="transparent"
                                        title="Columns">
                                    </ButtonDropdownMenu>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobs.map(m => {
                                return (
                                    <tr>
                                        {checks &&
                                            <td className="ps-1">
                                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                    <input checked={selected.includes(m.id)} class="form-check-input" type="checkbox" onChange={(e) => selectJob(e.target.checked, m.id)} />
                                                </div>
                                            </td>
                                        }
                                        {columns.includes('id') &&
                                            <td className="ps-1"><Link to={`/job/${m.id}`}>{m.id}</Link></td>
                                        }
                                        {columns.includes('date') &&
                                            <td>
                                                {m.status == 'completed' && container.localeManager.moment(m.completed_at).format('l')}
                                                {m.status == 'in_progress' && container.localeManager.moment(m.created_at).format('l')}
                                                {m.status == 'canceled' && container.localeManager.moment(m.canceled_at).format('l')}
                                            </td>
                                        }
                                        {columns.includes('game') &&
                                            <td>{m.game.code}</td>
                                        }
                                        {columns.includes('game_mode') &&
                                            <td>{container.localeManager.strings[m.game_mode]}</td>
                                        }
                                        {!isPersonal && columns.includes('driver') &&
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <Link to={`/user/${m.user_id}`}>
                                                        <img src={m.driver.avatar_url} className="avatar rounded h-30px rounded me-3" />
                                                        {m.driver.name}
                                                    </Link>
                                                </div>
                                            </td>
                                        }
                                        {columns.includes('vehicle') &&
                                            <td>{m.vehicle_brand_name} {m.vehicle_model_name}</td>
                                        }
                                        {columns.includes('source') &&
                                            <td>{m.source_city_name} - {m.source_company_name}</td>
                                        }
                                        {columns.includes('destination') &&
                                            <td>{m.destination_city_name} - {m.destination_company_name}</td>
                                        }
                                        {columns.includes('cargo') &&
                                            <td>
                                                {m.cargo_name}
                                                {m.market != 'transport_system' &&
                                                    <>
                                                        {" "}({UserDefinedUnits.convertWeight(member, m.game_id, m.cargo_mass_t)})
                                                    </>
                                                }
                                            </td>
                                        }
                                        {columns.includes('market') &&
                                            <td>{container.localeManager.strings[m.market]}</td>
                                        }
                                        {columns.includes('distance') &&
                                            <td className="text-end">{m.driven_distance_km != null && <span>{UserDefinedUnits.convertDistance(member, m.game_id, m.driven_distance_km)}</span>}</td>
                                        }
                                        {columns.includes('revenue') &&
                                            <td className={classNames({ 'text-end': true, 'text-success': m.revenue > 0, 'text-danger': m.revenue < 0 })}>
                                                {m.status != 'in_progress' &&
                                                    <span>{container.localeManager.numeral(m.revenue).format("0,0")} {m.currency}</span>
                                                }
                                            </td>
                                        }
                                        {columns.includes('max_speed') &&
                                            <td className="text-end">{m.status != 'in_progress' &&
                                                <span>
                                                    {UserDefinedUnits.convertDistanceValue(member, m.game_id, m.max_speed_kmh)} {UserDefinedUnits.getSpeedUnitLabel(member, m.game_id)}
                                                </span>
                                            }
                                            </td>
                                        }
                                        {columns.includes('fuel_used') &&
                                            <td className="text-end">
                                                {m.status != 'in_progress' &&
                                                    <>
                                                        {UserDefinedUnits.convertVolume(member, m.game_id, m.fuel_used_l,)}
                                                    </>
                                                }
                                            </td>
                                        }
                                        {columns.includes('fuel_economy') &&
                                            <td className="text-end">
                                                {m.status != 'in_progress' &&
                                                    <>
                                                        {UserDefinedUnits.convertFuelConsumption(member, m.game_id, m.driven_distance_km, m.fuel_used_l)}
                                                    </>
                                                }
                                            </td>
                                        }
                                        {columns.includes('damage') &&
                                            <td className="text-end">{m.status != 'in_progress' &&
                                                <span>
                                                    {m.vehicle_damage + m.trailers_damage + m.cargo_damage}%
                                                </span>
                                            }
                                            </td>
                                        }
                                        {columns.includes('duration') &&
                                            <td>
                                                {m.status == 'completed' &&
                                                    <span>{moment.duration(m.real_driving_time_seconds, 'seconds').format('h _ m _')}</span>
                                                }
                                            </td>
                                        }
                                        {columns.includes('hardcore_simulation_points') &&
                                            <td>
                                                {(m.status == 'completed' || m.status == 'canceled') &&
                                                    <span>{m.realistic_ldb_points}</span>
                                                }
                                            </td>
                                        }
                                        {columns.includes('delivery_rating') &&
                                            <td>
                                                {(m.status == 'completed' || m.status == 'canceled') &&
                                                    <span>{m.delivery_rating}</span>
                                                }
                                            </td>
                                        }
                                        <td className="text-center">
                                            {m.stats_type == 'race_miles' &&
                                                <TooltipIcon text={container.localeManager.strings.raceMiles} icon="tachometer-alt" className="text-danger"></TooltipIcon>
                                            }
                                            {m.stats_type == 'real_miles' &&
                                                <TooltipIcon text={container.localeManager.strings.realMiles} icon="tachometer-alt" className="text-success"></TooltipIcon>
                                            }
                                            {m.stats_type == 'transport_system' &&
                                                <TooltipIcon text={container.localeManager.strings.public_transport} icon="bus" className="text-success"></TooltipIcon>
                                            }
                                            {m.stats_type == 'invalid' &&
                                                <TooltipIcon text={container.localeManager.strings.invalid} icon="tachometer-alt" className="text-muted"></TooltipIcon>
                                            }
                                            {m.status == 'completed' &&
                                                <TooltipIcon text={container.localeManager.strings.completed} icon="check" className="text-success me-1"></TooltipIcon>
                                            }
                                            {m.status == 'in_progress' &&
                                                <TooltipIcon text={container.localeManager.strings.in_progress} icon="truck-moving" className="text-warning me-1"></TooltipIcon>
                                            }
                                            {m.status == 'canceled' &&
                                                <TooltipIcon text={container.localeManager.strings.canceled} icon="times" className="text-danger me-1"></TooltipIcon>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </>
            }
            {jobs.length == 0 &&
                <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed p-6 my-3">
                    {container.localeManager.strings.noJobs}
                </div>
            }
        </>

    )
}

export default JobsTable;