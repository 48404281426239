import { useContext, useEffect, useState } from "react";
import EnterpriseService from "../common/services/enterprise-service";
import Chart from "react-apexcharts";
import SingleStackedBarChart from "./charts/SingleStackedBarChart";
import PieChart from "./charts/PieChart";
import { getCSSVariableValue } from "../common/plugins/metronic/_utils/index";
import { Card, CardText, Col, Row, Tooltip } from "reactstrap";
import AggregatedStatsCharts from "./enterprise/AggregatedStatsCharts";
import TooltipIcon from "./ui/TooltipIcon";
import CountUp from "react-countup";
import Loader from "./ui/Loader";
import UserDefinedUnits from "../common/utils/UserDefinedUnits";
import { EnterpriseContext } from "./enterprise/EnterpriseContext";

const moment = require("moment");
const momentDurationFormatSetup = require("moment-duration-format");

const ProfileStats = ({ container, user, reserveAds = false }) => {
  momentDurationFormatSetup(container.localeManager.moment);

  const { globalContext } = useContext(EnterpriseContext);

  const member = globalContext.member;

  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [overallStats, setOverallStats] = useState(null);
  const es = new EnterpriseService();

  useEffect(() => {
    getStats();
    getOverallStats();
  }, [user]);

  const getStats = async () => {
    setLoading(true);
    const result = await es.getAggregatedStats(user.id);
    setStats(result);
    setLoading(false);
  };

  const getOverallStats = async () => {
    const result = await es.getOverallStats();
    setOverallStats(result);
  };

  const renderCommunityBenchamrkTooltip = (value, id) => {
    return (
      <TooltipIcon
        className="text-primary"
        icon="users"
        id={id}
        text={
          <div className="text-center">
            <div>{container.localeManager.strings.communityBenchmark}</div>
            {value}
          </div>
        }
      ></TooltipIcon>
    );
  };

  const showSalary = () => {
    if (globalContext.member && globalContext.member.id == user.id) return true;
    if (
      globalContext.member &&
      globalContext.member.company_id == user.company_id &&
      (es.can(globalContext.member, "company.*") ||
        es.can(globalContext.member, "members.*"))
    )
      return true;

    return false;
  };

  return (
    <>
      {loading && <Loader></Loader>}
      {stats != null && (
        <>
          <Row className="gx-0">
            <div className="grid cols-5 mb-3">
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted d-flex justify-content-between">
                  <span>
                    <CountUp
                      end={UserDefinedUnits.convertDistanceValue(
                        member,
                        null,
                        stats.distance_driven_on_job_km
                      )}
                      separator=" "
                      decimals={0}
                      decimal=","
                      suffix={` ${UserDefinedUnits.getDistanceUnitLabel(
                        member,
                        null
                      )}`}
                      className="fs-2 fw-bolder"
                    />
                  </span>
                  {overallStats != null &&
                    renderCommunityBenchamrkTooltip(
                      UserDefinedUnits.convertDistance(
                        member,
                        null,
                        overallStats.community_average_distance_per_user
                      ),
                      "community_average_distance_per_user"
                    )}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.totDistance}
                </div>
              </div>
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  <CountUp
                    end={UserDefinedUnits.convertDistanceValue(
                      member,
                      null,
                      stats.max_real_distance_km
                    )}
                    separator=" "
                    decimals={0}
                    decimal=","
                    suffix={` ${UserDefinedUnits.getDistanceUnitLabel(
                      member,
                      null
                    )}`}
                    className="fs-2 fw-bolder"
                  />
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.longedJobDistance}
                </div>
              </div>
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted d-flex justify-content-between">
                  <span>
                    <CountUp
                      end={UserDefinedUnits.convertDistanceValue(
                        member,
                        null,
                        stats.avg_real_distance_km
                      )}
                      separator=" "
                      decimals={0}
                      decimal=","
                      suffix={` ${UserDefinedUnits.getDistanceUnitLabel(
                        member,
                        null
                      )}`}
                      className="fs-2 fw-bolder"
                    />
                  </span>
                  {overallStats != null &&
                    renderCommunityBenchamrkTooltip(
                      UserDefinedUnits.convertDistance(
                        member,
                        null,
                        overallStats.overall.avg_real_distance
                      ),
                      "avg_real_distance"
                    )}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.averageJobDistance}
                </div>
              </div>
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {moment
                    .duration(stats.max_driving_time, "seconds")
                    .format("h _ m _")}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.longestJobTime}
                </div>
              </div>
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted d-flex justify-content-between">
                  <span className="fs-2 fw-bolder">
                    {moment
                      .duration(stats.avg_driving_time, "seconds")
                      .format("h _ m _")}
                  </span>
                  {overallStats != null &&
                    renderCommunityBenchamrkTooltip(
                      moment
                        .duration(
                          overallStats.overall.avg_driving_time,
                          "seconds"
                        )
                        .format("h _ m _"),
                      "avg_driving_time"
                    )}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.avgJobTime}
                </div>
              </div>
            </div>
            <div className="grid cols-4">
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted d-flex justify-content-between">
                  <CountUp
                    end={stats.jobs_delivered}
                    className="fs-2 fw-bolder"
                  />
                  {overallStats != null &&
                    renderCommunityBenchamrkTooltip(
                      overallStats.average_jobs_per_user,
                      "average_jobs_per_user"
                    )}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.jobsDelivered}
                </div>
              </div>
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {stats.preferred_vehicle}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.preferred_vehicle}
                </div>
              </div>
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {stats.most_source_city}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.most_source_city}
                </div>
              </div>
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {stats.most_delivered_cargo}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.most_delivered_cargo}
                </div>
              </div>
            </div>
            {showSalary() && stats.total_salary > 0 && (
              <div className="grid cols-2 mt-3">
                <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                  <div class="fs-2 fw-bolder counted">
                    <CountUp
                      end={stats.total_salary}
                      className="fs-2 fw-bolder"
                      suffix=" T¢"
                      separator=" "
                      decimals={0}
                    />
                  </div>
                  <div class="fw-bold fs-6 text-gray-600">
                    {container.localeManager.strings.total_salary}{" "}
                    <TooltipIcon
                      id="total_salary"
                      icon="eye"
                      text={container.localeManager.strings.visibleOnlyToYou}
                    ></TooltipIcon>
                  </div>
                </div>
                {showSalary() && stats.total_salary_current_company > 0 && (
                  <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                    <div class="fs-2 fw-bolder counted">
                      <CountUp
                        end={stats.total_salary_current_company}
                        className="fs-2 fw-bolder"
                        suffix=" T¢"
                        separator=" "
                        decimals={0}
                      />
                    </div>
                    <div class="fw-bold fs-6 text-gray-600">
                      {
                        container.localeManager.strings
                          .total_salary_current_company
                      }{" "}
                      <TooltipIcon
                        icon="eye"
                        id="total_salary_current_company"
                        text={container.localeManager.strings.visibleOnlyToYou}
                      ></TooltipIcon>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Row>
          <Row>
            <Col>
              <AggregatedStatsCharts
                overallStats={overallStats}
                container={container}
                stats={stats}
              ></AggregatedStatsCharts>
            </Col>
            {reserveAds &&
              !container.dataService.data.enablePremiumFeature() && (
                <div className="ad-column-placeholder"></div>
              )}
          </Row>
        </>
      )}
    </>
  );
};

export default ProfileStats;
