import React from 'react';
import { Navbar, Nav, Input, InputGroup, InputGroupText, Form } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import VenatusAd from '../../ads/VenatusAd';
import RichMediaAd from '../../ads/RichMediaAd';
import InStream from '../../ads/InStream';
import { Link } from 'react-router-dom';
const moment = require('moment');

class Footer extends React.Component {

    constructor(props) {
        super(props);
    }

    changeLanguage(value) {
        this.props.container.localeManager.changeLanguage(value);
        window.location.reload();
    }

    render() {
        return (
            <>
                <footer className='bg-secondary py-5 px-3 shadow'>
                    {!this.props.container.dataService.data.enablePremiumFeature() &&
                        <div className='text-center mb-5'>
                            <FontAwesomeIcon icon="star" className="text-premium mx-0"></FontAwesomeIcon> <Link className='text-premium' to="/premium">Don't like Ads? Go Premium!</Link>
                        </div>
                    }
                    <div className='d-flex flex-column flex-md-row mt-5'>
                        <div className='d-flex flex-column me-10'>
                            <a href="https://truckyapp.com" className='text-white' target="_blank"><FontAwesomeIcon fixedWidth className='me-1' icon="globe" /> Trucky Website</a>
                            <a href="https://truckyapp.com/overlay" className='text-white' target="_blank"><FontAwesomeIcon fixedWidth className='me-1' icon={['fab', 'windows']} /> Download Trucky for Windows</a>
                            <a href="https://truckyapp.com/download" className='text-white' target="_blank"><FontAwesomeIcon fixedWidth className='me-1' icon="mobile-alt" /> Download the app</a>
                        </div>
                        <div className='d-flex flex-column me-10'>
                            <a href="https://truckyapp.com/trucky-discord-bot" className='text-white' target="_blank"><FontAwesomeIcon fixedWidth className='me-1' icon={['fab', 'discord']} /> Discord Bot</a>
                            <a href="https://truckyapp.com/trucky-twitch-bot/" className='text-white' target="_blank"><FontAwesomeIcon fixedWidth className='me-1' icon={['fab', 'twitch']} /> Twitch Bot</a>
                            <a href="http://translate.truckyapp.com/" className='text-white' target="_blank"><FontAwesomeIcon fixedWidth className='me-1' icon="language" /> Translations portal</a>
                            <a href="https://truckymods.io" className='text-white' target="_blank"> <FontAwesomeIcon fixedWidth className='me-1' icon="cubes" /> TruckyMods</a>

                        </div>
                        <div className='d-flex flex-column me-10'>
                            <a href="https://truckyapp.com/platform-terms-and-conditions/" className='text-white' target="_blank"><FontAwesomeIcon fixedWidth className='me-1' icon="file" /> Terms and conditions</a>
                            <a href="https://truckyapp.com/platform-privacy-policy/" className='text-white' target="_blank"><FontAwesomeIcon fixedWidth className='me-1' icon="file" /> Privacy Policy</a>
                            <a href="https://truckyapp.com/cookie-policy/" className='text-white' target="_blank"><FontAwesomeIcon fixedWidth className='me-1' icon="cookie" /> Cookie Policy</a>
                        </div>
                        <div className='d-flex flex-column me-10'>
                            <a href="https://truckyapp.com/kb" className='text-white' target="_blank"><FontAwesomeIcon fixedWidth className='me-1' icon="life-ring" /> {this.props.container.localeManager.strings.knowledgeBase}</a>
                            <a href="https://truckyapp.com/discord" className='text-white' target="_blank"> <FontAwesomeIcon fixedWidth className='me-1' icon="question-circle" /> {this.props.container.localeManager.strings.getSupport}</a>
                        </div>
                        <div className='my-5 my-md-none ms-md-auto'>
                            <InputGroup>
                                <InputGroupText>{this.props.container.localeManager.strings.languageWithoutDots}</InputGroupText>
                                <Input onChange={(e) => this.changeLanguage(e.target.value)} defaultValue={this.props.container.localeManager.language} type="select">
                                    {this.props.container.localeManager.availableLanguages.map((l) => {
                                        return (
                                            <option key={l.code} value={l.code}>{l.displayName}</option>
                                        );
                                    })}
                                </Input>
                            </InputGroup>
                        </div>
                    </div>
                    <div className='text-center mt-7'>
                        <div>
                            <a href="https://truckyapp.com/discord" target='_blank' className='text-white'><FontAwesomeIcon fixedWidth className='me-3' size='2x' icon={['fab', 'discord']} /></a>
                            <a href="https://www.twitch.tv/truckyapp" target='_blank' className='text-white'><FontAwesomeIcon fixedWidth className='me-3' size='2x' icon={['fab', 'twitch']} /></a>
                            <a href="https://twitter.com/TruckyOfficial" target='_blank' className='text-white'><FontAwesomeIcon fixedWidth className='me-3' size='2x' icon={['fab', 'twitter']} /></a>
                            <a href="https://www.youtube.com/c/trucky" target='_blank' className='text-white'><FontAwesomeIcon fixedWidth className='me-3' size='2x' icon={['fab', 'youtube']} /></a>
                            <a href="https://github.com/Trucky" target='_blank' className='text-white'><FontAwesomeIcon fixedWidth className='me-3' size='2x' icon={['fab', 'github']} /></a>
                        </div>
                        <div className='my-5'>
                            Made with <FontAwesomeIcon icon="heart" className='me-0'></FontAwesomeIcon> from <a target='_blank' className='text-white' href="https://truckyapp.com">Trucky</a>
                        </div>
                    </div>
                </footer>
                <RichMediaAd container={this.props.container}></RichMediaAd>
            </>
        );
    }
}

export default Footer;