import { useState, createContext, useMemo, useEffect } from 'react';

export const EnterpriseContext = createContext();

export const EnterpriseContextProvider = (props) => {
    const [globalContext, setGlobalContext] = useState({ member: props.container.dataService.enterpriseService.member, company: props.container.dataService.enterpriseService.company });
    // the state that we'll be storing the username into

    /* because we will be providing an object to the provider, it is better to put the value inside a useMemo so that the component will only re-render when there's a change in the value. */

    const value = useMemo(
        () => ({ globalContext, setGlobalContext }), [globalContext])

    /*useEffect(() => {
        console.log(globalContext);
    }, [globalContext]);
    */

    return (
        <EnterpriseContext.Provider
            value={value}
        >
            {props.children}
        </EnterpriseContext.Provider>
    );
};