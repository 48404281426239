import { useContext } from "react";
import { Navigate, useLocation } from "react-router";
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import Login from "../pages/Login";
import OnlyPatreonAccess from "./OnlyPatreonAccess";
import { isTeam } from "../utils";

function RequireAuth({ children, container, onlyAngels = false }) {
    const { globalContext } = useContext(EnterpriseContext);

    if (globalContext.member == null) {
        return <Login container={container}></Login>;
    }
    else {

        if (globalContext.member.email == null || globalContext.member.email == '')
            return <Login container={container}></Login>

        //if (isTeam(globalContext.member))
        //    return children;
        
        //if (onlyAngels && !container.dataService.data.enablePremiumFeature()) {
        //    return <OnlyPatreonAccess container={container}></OnlyPatreonAccess>
        //}
    }

    return children;
}

export default RequireAuth;