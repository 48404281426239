import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import reactSelectCustomStyles from "../../common/plugins/reactSelectStyles";
import EnterpriseService from "../../common/services/enterprise-service";
import truckyService from "../../common/services/trucky-service";
import { EnterpriseContext } from "../enterprise/EnterpriseContext";
import { confirmWrapper } from "../ui/askConfirm";
import Select from "react-select";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import Tips from "../Tips";
import tipsList from "../../common/constants/tipsList";

const MySubscriptions = ({ container }) => {
  const { globalContext } = useContext(EnterpriseContext);

  const [subscriptions, setSubscriptions] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    globalContext.member.country
  );
  const es = new EnterpriseService();
  const [quantity, setQuantity] = useState(1);
  const [prices, setPrices] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [accountManagementUri, setAccountManagementUri] = useState(null);
  const [showBillingDetailModal, toggleBillingDetailModal] = useState(false);
  const [selectedSubscriptionName, setSelectedSubscriptionName] = useState("");
  const [billingData, setBillingData] = useState({
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    truckyService.setDocumentTitle("My Subscriptions");

    getCountries();
    getAvailableSubscriptions();
  }, []);

  useEffect(() => {}, [quantity]);

  const getUserCountry = async () => {
    try {
      const result = await fetch("https://api.country.is/");
      const json = await result.json();
      setSelectedCountry(json.country);
    } catch (error) {
      setSelectedCountry("US");
    }
  };

  const getCountries = async () => {
    const result = await es.getCountries();
    setCountries(result);
  };

  const getAvailableSubscriptions = async () => {
    setLoading(true);

    await getUserSubscriptions();

    await getUserCountry();

    const result = await es.getAvailableSubscriptions();
    setSubscriptions(result);

    setLoading(false);
  };

  const getUserSubscriptions = async () => {
    const result = await es.getUserSubscriptions(true);
    setUserSubscriptions(result.subscriptions);
    if (
      result.accountManagementURI != null &&
      result.accountManagementURI != ""
    ) {
      setAccountManagementUri(result.accountManagementURI);
    }
  };

  const createAccount = async () => {
    setLoading(true);
    const result = await es.createSubscriptionServiceAccount({
      ...billingData,
      country: selectedCountry,
    });

    if (result.success) {
      toggleBillingDetailModal(false);
      getSubscriptionLink(selectedSubscriptionName);
    }
  };

  const getSubscriptionLink = async (name) => {
    setLoading(true);

    const result = await es.getSubscribeLink(
      name,
      quantity,
      selectedCountry,
      container.dataService.data.enablePremiumFeature()
    );

    if (result.success) {
      if (window.overwolf) {
        truckyService.openUrlInDefaultBrowser(result.data.payLink);
      } else {
        window.open(result.data.payLink);
      }
    } else {
      setSelectedSubscriptionName(name);
      toggleBillingDetailModal(true);
    }

    setLoading(false);
  };

  const cancelSubscription = async (name) => {
    if (
      await confirmWrapper(
        container.localeManager.strings.cancelSubscriptionConfirmation,
        { container }
      )
    ) {
      setLoading(true);
      const result = await es.cancelSubscription(name);
      toast.success(container.localeManager.strings.subscriptionCanceled);
      await getUserSubscriptions();
      setLoading(false);
    }
  };

  const resumeSubscription = async (name) => {
    setLoading(true);
    const result = await es.resumeSubscription(name);
    toast.success(container.localeManager.strings.subscriptionResumed);
    await getUserSubscriptions();
    setLoading(false);
  };

  const upgradeSubscription = async (name) => {
    setLoading(true);
    const result = await es.updateSubscription(name);
    await getUserSubscriptions();
    setLoading(false);
  };

  const isSubscribed = (name) => {
    return userSubscriptions.find(
      (m) => m.plan == name && m.state != "canceled"
    );
  };

  const getSubscription = (name) => {
    return userSubscriptions.find((m) => m.plan == name);
  };

  const getSubscriptionStatus = (name) => {
    let status = "";
    let sub = getSubscription(name);

    if (sub) status = sub.state;

    return status;
  };

  const getPricing = (product) => {
    let pricing;

    if (selectedCountry == null) pricing = product.pricing["US"];
    else {
      if (product.pricing[selectedCountry])
        pricing = product.pricing[selectedCountry];
      else pricing = product.pricing["US"];
    }

    const price = Math.round(pricing.price * quantity, 2);

    return `${price} ${pricing.currency}`;
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row className="gx-0">
        <Col>
          <Card>
            <CardBody>
              {window.overwolf && (
                <div class="mb-3 d-flex justify-content-between">
                  <Button
                    size="sm"
                    onClick={() => navigate(-1)}
                    color="secondary"
                  >
                    <FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>
                    {container.localeManager.strings.back}
                  </Button>
                </div>
              )}
              <h5 className="d-inline-block w-100">
                {container.localeManager.strings.subscriptions}
              </h5>
              <FormGroup row className="my-3">
                <Col sm={4}>
                  <Select
                    onChange={(val) => setSelectedCountry(val.code)}
                    value={countries.find((m) => m.code == selectedCountry)}
                    options={countries}
                    styles={reactSelectCustomStyles}
                    getOptionLabel={(m) => m.name}
                    getOptionValue={(m) => m.code}
                  ></Select>
                </Col>
                <Col className="text-end mt-3 mt-md-0">
                  {accountManagementUri != null && (
                    <Button
                      className="me-3"
                      size="sm"
                      color="primary"
                      onClick={() =>
                        truckyService.openUrlInDefaultBrowser(
                          accountManagementUri
                        )
                      }
                    >
                      <FontAwesomeIcon icon="cog"></FontAwesomeIcon>Manage your
                      Subscription and Payment Informations
                    </Button>
                  )}
                  <Button
                    size="sm"
                    color="primary"
                    onClick={getUserSubscriptions}
                  >
                    <FontAwesomeIcon icon="refresh"></FontAwesomeIcon>
                    {container.localeManager.strings.reload}
                  </Button>
                </Col>
              </FormGroup>
              {subscriptions.map((m) => {
                return (
                  <Card className="bg-light">
                    <CardBody>
                      {getSubscriptionStatus(m.key) == "active" && (
                        <div className="notice bg-light-success rounded border-success border border-dashed p-6 mb-3 d-flex justify-content-between align-items-center">
                          <div>
                            <FontAwesomeIcon icon="check-circle"></FontAwesomeIcon>
                            {container.localeManager.formatString(
                              container.localeManager.strings.subscribedOn,
                              container.localeManager
                                .moment(getSubscription(m.key).created_at)
                                .format("l")
                            )}
                          </div>
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => cancelSubscription(m.key)}
                          >
                            {container.localeManager.strings.cancel}
                          </Button>
                        </div>
                      )}
                      {getSubscriptionStatus(m.key) == "canceled" && (
                        <div className="notice bg-light-warning rounded border-warning border border-dashed p-6 mb-3 d-flex justify-content-between align-items-center">
                          <div>
                            <FontAwesomeIcon icon="check-circle"></FontAwesomeIcon>
                            {container.localeManager.formatString(
                              container.localeManager.strings
                                .subscribedCanceledValidUntil,
                              container.localeManager
                                .moment(getSubscription(m.key).swap_at)
                                .format("l")
                            )}
                          </div>
                          <Button
                            size="sm"
                            color="success"
                            onClick={() => resumeSubscription(m.key)}
                          >
                            {container.localeManager.strings.resume}
                          </Button>
                        </div>
                      )}
                      <div className="d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap">
                        <div>
                          <div className="fs-2">{m.name}</div>
                          <div className="d-flex gap-4 mt-3">
                            <div>
                              <FontAwesomeIcon
                                icon="fas fa-dot-circle"
                                className="me-1"
                              />{" "}
                              <b>Up to 128 player slots!</b>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon="fas fa-dot-circle"
                                className="me-1"
                              />{" "}
                              Visible in Sesssions Browser
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon="fas fa-dot-circle"
                                className="me-1"
                              />{" "}
                              Multiple Regions: host your Server near you
                            </div>

                            <div>
                              <FontAwesomeIcon
                                icon="fas fa-dot-circle"
                                className="me-1"
                              />{" "}
                              Use your Mods
                            </div>
                          </div>
                          <div className="mt-3 d-flex gap-4">
                            <div>
                              <FontAwesomeIcon
                                icon="fas fa-dot-circle"
                                className="me-1"
                              />{" "}
                              Public IP
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon="fas fa-dot-circle"
                                className="me-1"
                              />{" "}
                              API to get status and logs
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon="fas fa-dot-circle"
                                className="me-1"
                              />{" "}
                              Discord Webhook
                            </div>
                            <div className="text-premium">
                              <FontAwesomeIcon icon="star" />
                              {
                                container.localeManager.strings
                                  .angelPatronGet10off
                              }
                            </div>
                          </div>
                        </div>
                        {getSubscriptionStatus(m.key) == "" && (
                          <div className="d-flex align-items-center flex-column align-content-center">
                            <div className="mb-3 text-center">
                              <span className="fs-1 text-premium fw-bolder">
                                {getPricing(m)}
                              </span>
                              <span className="fs-5">
                                {" "}
                                / {container.localeManager.strings[m.interval]}
                              </span>
                              <div>
                                {container.localeManager.strings.vatIncluded}
                              </div>
                            </div>
                            <div className="mt-3 mb-1 text-center">
                              {container.localeManager.strings.howManyServers}
                            </div>
                            <div className="mb-3">
                              <InputGroup>
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    quantity > 1
                                      ? setQuantity(quantity - 1)
                                      : null
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="me-0"
                                    icon="minus"
                                  ></FontAwesomeIcon>
                                </Button>
                                <Input
                                  className="text-center"
                                  value={quantity}
                                ></Input>
                                <Button
                                  color="primary"
                                  onClick={() => setQuantity(quantity + 1)}
                                >
                                  <FontAwesomeIcon
                                    className="me-0"
                                    icon="plus"
                                  ></FontAwesomeIcon>
                                </Button>
                              </InputGroup>
                            </div>
                            <Button
                              color="success"
                              onClick={() => getSubscriptionLink(m.key)}
                            >
                              {container.localeManager.strings.subscribe}
                            </Button>
                            {/*getSubscriptionStatus(m.key) == 'active' &&
                                                        <Button color="success" onClick={() => upgradeSubscription(m.key)}>{container.localeManager.strings.upgrade}</Button>
                                                    */}
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                );
              })}
            </CardBody>
          </Card>
        </Col>
        <aside className="ad-column-placeholder">
          <Tips
            tipKey={tipsList.KEYS.SUBSCRIPTIONS}
            localeManager={container.localeManager}
          ></Tips>
        </aside>
      </Row>
      <Modal
        isOpen={showBillingDetailModal}
        toggle={() => toggleBillingDetailModal(false)}
      >
        <ModalHeader
          toggle={() => toggleBillingDetailModal(false)}
          close={
            <Button
              color="active-light-primary"
              className="btn-icon btn-sm w-30px h-30px ms-auto"
              onClick={() => toggleBillingDetailModal(false)}
            >
              <FontAwesomeIcon icon="times"></FontAwesomeIcon>
            </Button>
          }
        >
          {container.localeManager.strings.billingInformation}
        </ModalHeader>
        <ModalBody>
          <div className="notice bg-light-success rounded border-success border border-dashed p-6 mb-3 d-flex justify-content-between align-items-center">
            <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
            {container.localeManager.strings.billingDataHelpText}
          </div>
          <FormGroup row>
            <Col sm={6}>
              <Label>{container.localeManager.strings.firstName}</Label>
              <Input
                value={billingData.firstName}
                onChange={(e) =>
                  setBillingData({ ...billingData, firstName: e.target.value })
                }
              ></Input>
            </Col>
            <Col sm={6}>
              <Label>{container.localeManager.strings.lastName}</Label>
              <Input
                value={billingData.lastName}
                onChange={(e) =>
                  setBillingData({ ...billingData, lastName: e.target.value })
                }
              ></Input>
            </Col>
            <div className="mt-5">
              <Button
                disabled={
                  billingData.firstName == "" && billingData.lastName == ""
                }
                block
                color="success"
                onClick={createAccount}
              >
                {container.localeManager.strings.continueToPayment}
                <FontAwesomeIcon
                  icon="chevron-right"
                  className="ms-3 me-0"
                ></FontAwesomeIcon>
              </Button>
            </div>
          </FormGroup>
        </ModalBody>
      </Modal>
    </LoadingOverlay>
  );
};

export default MySubscriptions;
