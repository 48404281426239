import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { Button, Table } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import TooltipIcon from "../ui/TooltipIcon";
import { useContext, useEffect, useState } from "react";
import ButtonDropdownMenu from "../ui/ButtonDropdownMenu";
import truckyService from "../../common/services/trucky-service";
import OrderByButtons from "../ui/OrderByButtons";
import UserDefinedUnits from "../../common/utils/UserDefinedUnits";
import { EnterpriseContext } from "./EnterpriseContext";
import { Link } from "react-router-dom";

const availableColumns = [
    "id", "model", "game", "garage", "price", "driver", "status", "odometer", "revenue", "fuel_used", "damage_cost", "jobs_count"
];

const defaultColumns = [
    "id", "model", "game", "garage", "price", "driver", "status", "odometer"
];

const VehiclesTable = ({ company, member, vehicles, container, showSellButton = true, showDriverName = true, sell = null,
    changeSortingField, filters = {} }) => {

    const navigate = useNavigate();
    const es = new EnterpriseService();
    const [columns, setColumns] = useState(truckyService.loadSearchParameters('vehicles_table', defaultColumns));

    useEffect(() => {
        truckyService.saveSearchParameters('vehicles_table', columns);
    }, [columns.length]);

    const toggleColumn = (checked, field) => {
        if (checked) {
            setColumns([...columns, field]);
        }
        else {
            setColumns(columns.filter(m => m != field));
        }
    }

    const getStatus = (vehicle) => {
        if (vehicle.status == 'active') {
            return (<TooltipIcon id={`vehicle_maintenance_${vehicle.id}`} icon="check" text={container.localeManager.strings.active} className="text-success"></TooltipIcon>);
        }
        if (vehicle.status == 'under_maintenance') {
            const maintenanceEnd = container.localeManager.moment(vehicle.maintenance_end_date).local().format('LLL');
            return (
                <TooltipIcon id={`vehicle_maintenance_${vehicle.id}`} icon="wrench" text={`${container.localeManager.strings.under_maintenance} - ${maintenanceEnd}`} className="text-danger"></TooltipIcon>
            );
        }
        if (vehicle.status == 'need_maintenance') {
            return (
                <TooltipIcon id={`vehicle_maintenance_${vehicle.id}`} icon="wrench" text={container.localeManager.strings.need_maintenance} className="text-warning"></TooltipIcon>
            );
        }
    }

    return (
        <Table responsive striped size="sm" className="table-row-dashed table-row-gray-300 align-middle gs-0" hover>
            <thead>
                <tr className="fw-bolder text-gray-700 bg-light">
                    {columns.includes('id') &&
                        <th scope="col" className="ps-4">#</th>
                    }
                    {columns.includes('model') &&
                        <th scope="col">{container.localeManager.strings.model}</th>
                    }
                    {columns.includes('game') &&
                        <th scope="col">{container.localeManager.strings.game}</th>
                    }
                    {columns.includes('garage') &&
                        <th scope="col">{container.localeManager.strings.garage}
                        </th>
                    }
                    {columns.includes('price') &&
                        <th scope="col">{container.localeManager.strings.price}
                            {changeSortingField &&
                                <OrderByButtons
                                    container={container}
                                    currentSortingDirection={filters.sortingDirection}
                                    currentSortingField={filters.sortingField}
                                    field="price" onClick={changeSortingField}>
                                </OrderByButtons>
                            }
                        </th>
                    }
                    {showDriverName && columns.includes('driver') &&
                        <th scope="col">{container.localeManager.strings.assignee}
                            {changeSortingField &&
                                <OrderByButtons
                                    container={container}
                                    currentSortingDirection={filters.sortingDirection}
                                    currentSortingField={filters.sortingField}
                                    field="driver" onClick={changeSortingField}>
                                </OrderByButtons>
                            }
                        </th>
                    }
                    {columns.includes('status') &&
                        <th scope="col" className="text-center">{container.localeManager.strings.status}</th>
                    }
                    {columns.includes('odometer') &&
                        <th scope="col" className="text-end">{container.localeManager.strings.odometer}
                            {changeSortingField &&
                                <OrderByButtons
                                    container={container}
                                    currentSortingDirection={filters.sortingDirection}
                                    currentSortingField={filters.sortingField}
                                    field="odometer" onClick={changeSortingField}>
                                </OrderByButtons>
                            }
                        </th>
                    }
                    {columns.includes('jobs_count') &&
                        <th scope="col" className="text-end">{container.localeManager.strings.jobs_count}
                            {changeSortingField &&
                                <OrderByButtons
                                    container={container}
                                    currentSortingDirection={filters.sortingDirection}
                                    currentSortingField={filters.sortingField}
                                    field="jobs_count" onClick={changeSortingField}>
                                </OrderByButtons>
                            }
                        </th>
                    }
                    {columns.includes('revenue') &&
                        <th scope="col" className="text-end">{container.localeManager.strings.revenue}
                            {changeSortingField &&
                                <OrderByButtons
                                    container={container}
                                    currentSortingDirection={filters.sortingDirection}
                                    currentSortingField={filters.sortingField}
                                    field="total_revenue" onClick={changeSortingField}>
                                </OrderByButtons>
                            }
                        </th>
                    }
                    {columns.includes('fuel_used') &&
                        <th scope="col" className="text-end">{container.localeManager.strings.fuel_used}
                            {changeSortingField &&
                                <OrderByButtons
                                    container={container}
                                    currentSortingDirection={filters.sortingDirection}
                                    currentSortingField={filters.sortingField}
                                    field="total_fuel_used" onClick={changeSortingField}>
                                </OrderByButtons>
                            }
                        </th>
                    }
                    {columns.includes('damage_cost') &&
                        <th scope="col" className="text-end">{container.localeManager.strings.damage_cost}
                            {changeSortingField &&
                                <OrderByButtons
                                    container={container}
                                    currentSortingDirection={filters.sortingDirection}
                                    currentSortingField={filters.sortingField}
                                    field="total_damage_cost" onClick={changeSortingField}>
                                </OrderByButtons>
                            }
                        </th>
                    }
                    <th scope="col" className="text-end pe-3">
                        <ButtonDropdownMenu buttonContent={<FontAwesomeIcon icon="table-columns" className="m-0"></FontAwesomeIcon>}
                            menuTitle={container.localeManager.strings.columns}
                            menuId="jobs_table_columns_selector"
                            menuContent={<div className="p-3">
                                {availableColumns.map(m => {
                                    return (
                                        <div className="mt-2">
                                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                <input checked={columns.includes(m)} class="form-check-input me-1" type="checkbox" onChange={(e) => toggleColumn(e.target.checked, m)} /> {container.localeManager.strings[m]}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>}
                            buttonClassNames="btn-text p-0"
                            buttonColor="transparent"
                            title="Columns">
                        </ButtonDropdownMenu>
                    </th>
                </tr>
            </thead>
            <tbody>
                {vehicles.map(g => {
                    return (
                        <tr>
                            {columns.includes('id') &&
                                <td className="ps-1">{g.id}</td>
                            }
                            {columns.includes('model') &&
                                <td>
                                    <div className="d-flex align-items-center">
                                        <img src={g.model.photo_url} className="avatar rounded h-30px me-3" />
                                        <Link to={`/vehicle/${g.id}`}>{g.model.brand.name} {g.model.name}</Link>
                                    </div>
                                </td>
                            }
                            {columns.includes('game') &&
                                <td>{g.game.code}</td>
                            }
                            {columns.includes('garage') &&
                                <td>{g.garage.city.name}</td>
                            }
                            {columns.includes('price') &&
                                <td>{container.localeManager.numeral(g.price).format("0,0")} {g.currency}</td>
                            }
                            {showDriverName && columns.includes('driver') &&
                                <td>{g.driver ? <>
                                    <div className="d-flex align-items-center">
                                        <Link to={`/user/${g.driver.id}`}><img src={g.driver.avatar_url} className="avatar rounded h-30px me-3" />
                                            {g.driver.name}</Link>
                                    </div></> : container.localeManager.strings.notAssigned}</td>
                            }
                            {columns.includes('status') &&
                                <td className="text-center">{getStatus(g)}</td>
                            }
                            {columns.includes('odometer') &&
                                <td className="text-end">{UserDefinedUnits.convertDistance(member, null, g.odometer_km)}</td>
                            }
                            {columns.includes('jobs_count') &&
                                <td className="text-end">{container.localeManager.numeral(g.jobs_count).format("0,0")}</td>
                            }
                            {columns.includes('revenue') &&
                                <td className="text-end">{container.localeManager.numeral(g.total_revenue).format("0,0")} {g.currency}</td>
                            }
                            {columns.includes('fuel_used') &&
                                <td className="text-end">{UserDefinedUnits.convertVolume(member, null, g.total_fuel_used_l)}</td>
                            }
                            {columns.includes('damage_cost') &&
                                <td className="text-end">{container.localeManager.numeral(g.total_damage_cost).format("0,0")} {g.currency}</td>
                            }
                            <td className="text-end">
                                <Button title={container.localeManager.strings.information} onClick={() => navigate(`/vehicle/${g.id}`)} className="btn-icon w-30px h-30px ms-auto" size="sm" color="active-light-primary"><FontAwesomeIcon icon="pencil-alt" className="me-0"></FontAwesomeIcon></Button>
                                {sell != null && showSellButton && company != null && member != null && es.can(member, 'vehicles.sell') &&
                                    <Button title={container.localeManager.strings.sell} onClick={() => sell(g)} className="btn-icon w-30px h-30px ms-auto" size="sm" color="active-light-danger"><FontAwesomeIcon icon="trash-alt" className="me-0" /></Button>
                                }
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    );
}

export default VehiclesTable;