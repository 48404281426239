import { Route, Routes } from "react-router";
import Servers from "../pages/Servers";
import EditProfile from "../../components/EditProfile";
import ApplicationsManagement from "../../components/enterprise/ApplicationsManagement";
import Balance from "../../components/enterprise/Balance";
import CompanyDetail from "../../components/enterprise/CompanyDetail";
import CreateCompanyLanding from "../../components/enterprise/CreateCompanyLanding";
import Directory from "../../components/enterprise/Directory";
import EditCompany from "../../components/enterprise/EditCompany";
import EnterpriseHome from "../../components/enterprise/EnterpriseHome";
import FleetManagement from "../../components/enterprise/FleetManagement";
import GarageDetail from "../../components/enterprise/GarageDetail";
import GarageManagement from "../../components/enterprise/GarageManagement";
import JobDetails from "../../components/enterprise/JobDetails";
import Maintenances from "../../components/enterprise/Maintenances";
import Market from "../../components/enterprise/Market";
import Members from "../../components/enterprise/Members";
import MonthlyCompanyStats from "../../components/enterprise/MonthlyCompanyStats";
import NoCompanyJobs from "../../components/enterprise/NoCompanyJobs";
import UserBannerConfigurator from "../../components/enterprise/UserBannerConfigurator";
import UserDetail from "../../components/enterprise/UserDetail";
import VehicleDetail from "../../components/enterprise/VehicleDetail";
import LeaderboardsV2 from "../../components/LeaderboardsV2";
import MyApplications from "../../components/MyApplications";
import TrafficTabs from "./TrafficTabs";
import Jobs from "../../components/enterprise/Jobs";
import LiveMap from "../pages/LiveMap";
import { useContext } from "react";
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import MySubscriptions from "../../components/subscriptions/MySubscriptions";
import DedicatedServerHostingList from "../../components/hosting/DedicatedServerHostingList";
import RequireAuth from "./RequireAuth";
import Login from "../pages/Login";
import DedicatedServerConfiguration from "../../components/hosting/DedicatedServerConfiguration";
import CompanyAchievements from "../../components/enterprise/CompanyAchievements";
import EditCompanyAchievement from "../../components/enterprise/EditCompanyAchievement";
import AllCompanyFeeds from "../../components/enterprise/AllCompanyFeeds";
import NewsFeedManager from "../../components/enterprise/NewsFeedManager";
import EditFeed from "../../components/enterprise/EditFeed";
import FeedDetail from "../../components/enterprise/FeedDetail";
import AuthPage from "../pages/AuthPage";
import PatreonConfirm from "../pages/PatreonConfirm";
import Chat from "../../components/messenger/Chat";
import Homepage from "../pages/Homepage";
import UserSearch from "../pages/UserSearch";
import GameDashboard from "../pages/GameDashboard";
import Premium from "../pages/Premium";
import ClientLoginCode from "../pages/ClientLoginCode";
import HardcoreLeaderboards from "../../components/HardcoreLeaderboards";
import DispatchDetail from "../pages/DispatchDetail";

const AppRoutes = ({ container }) => {

    const { globalContext } = useContext(EnterpriseContext);

    const vtcHubAccesibleOnlyToAngels = false;

    const getHomeComponent = () => {
        if (globalContext.member == null)
            return <Homepage container={container}></Homepage>;
        else {
            if (globalContext.member.company_id == null)
                return <UserDetail container={container} />;
            else {
                return (
                    <RequireAuth container={container} onlyAngels={vtcHubAccesibleOnlyToAngels}>
                        <EnterpriseHome container={container} />
                    </RequireAuth>
                )
            }
        }
    }

    return (
        <Routes>
            <Route path="/auth" element={<AuthPage container={container}></AuthPage>}></Route>
            <Route path={"/"} element={<Homepage container={container}></Homepage>} />
            <Route path={"/login"} element={<Login container={container} />}></Route>
            <Route path={"/vtchub"} element={
                <RequireAuth container={container} onlyAngels={vtcHubAccesibleOnlyToAngels}>
                    <EnterpriseHome container={container} />
                </RequireAuth>} />
            <Route path={"/users"} element={
                <RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}>
                    <Members container={container} />
                </RequireAuth>} />
            <Route path={"/roles"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><Members container={container} /></RequireAuth>} />
            <Route path={"/applications"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><ApplicationsManagement container={container} /></RequireAuth>} />
            <Route path={"/garages"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><GarageManagement container={container} /></RequireAuth>} />
            <Route path={"/vehicles"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><FleetManagement container={container} /></RequireAuth>} />
            <Route path={"/vehicles/:status"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><FleetManagement container={container} /></RequireAuth>} />
            <Route path={"/balance"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><Balance container={container} /></RequireAuth>} />
            <Route path={"/stats"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><MonthlyCompanyStats detailedJobsStats={true} detailedMembersStats={true} container={container} /></RequireAuth>} />
            <Route path={"/register-your-company"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><CreateCompanyLanding container={container} /></RequireAuth>} />
            <Route path={"/company/create"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><EditCompany container={container} /></RequireAuth>} />
            <Route path={"/company/settings"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><EditCompany container={container} /></RequireAuth>} />
            <Route path={"/company/:id"} element={<CompanyDetail container={container} />} />
            <Route path={"/vtc/:name"} element={<CompanyDetail container={container} />} />
            <Route path={"/job/:id"} element={<JobDetails loadContext={true} container={container} />} />
            <Route path={"/vehicle/:id"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><VehicleDetail container={container} /></RequireAuth>} />
            <Route path={"/maintenances"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><Maintenances container={container} /></RequireAuth>} />
            <Route path={"/user/:id"} element={<UserDetail loadContext={true} container={container} />} />
            <Route path={"/market/:game_id"} element={<Market onlyAngels={vtcHubAccesibleOnlyToAngels} container={container} />} />
            <Route path={"/user/banner"} element={<RequireAuth container={container}><UserBannerConfigurator container={container} /></RequireAuth>}></Route>
            <Route path={"/garage/:id"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><GarageDetail container={container} /></RequireAuth>}></Route>
            <Route path={"/directory"} element={<Directory container={container} />} />
            <Route path={"/profile/edit"} element={<RequireAuth container={container}><EditProfile container={container} /></RequireAuth>}></Route>
            <Route path={"/my/applications"} element={<RequireAuth container={container}><MyApplications container={container} /></RequireAuth>}></Route>
            <Route path={"/leaderboards"} element={<LeaderboardsV2 container={container} />}></Route>
            <Route path={"/hardcore"} element={<HardcoreLeaderboards container={container} />}></Route>
            <Route path={"/my/profile"} element={<RequireAuth container={container}><UserDetail container={container} /></RequireAuth>}></Route>
            <Route path={"/createcompanylanding"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><CreateCompanyLanding container={container} /></RequireAuth>}></Route>
            <Route path={"/servers"} element={<Servers container={container} />}></Route>
            <Route path={"/traffic"} element={<TrafficTabs container={container} top={false} />}></Route>
            <Route path={"/jobs"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><Jobs container={container} /></RequireAuth>}></Route>
            <Route path={"/my/subscriptions"} element={<RequireAuth container={container}><MySubscriptions container={container} /></RequireAuth>}></Route>
            <Route path={"/hosting"} element={<RequireAuth container={container}><DedicatedServerHostingList container={container} /></RequireAuth>}></Route>
            <Route path={"/jobs"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><Jobs container={container} /></RequireAuth>}></Route>
            <Route path={"/map"} element={<LiveMap container={container} user={globalContext.member} />}></Route>
            <Route path={"/hosting/server"} element={<DedicatedServerConfiguration container={container} />}></Route>
            <Route path={"/hosting/server/:id"} element={<RequireAuth container={container}><DedicatedServerConfiguration container={container} /></RequireAuth>}></Route>
            <Route path={"/company/achievements"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><CompanyAchievements container={container} /></RequireAuth>}></Route>
            <Route path={"/company/achievements/create"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><EditCompanyAchievement container={container} /></RequireAuth>}></Route>
            <Route path={"/company/achievements/:id"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><EditCompanyAchievement container={container} /></RequireAuth>}></Route>
            <Route path={"/feeds"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><AllCompanyFeeds container={container} /></RequireAuth>}></Route>
            <Route path={"/feeds/manage"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><NewsFeedManager container={container} /></RequireAuth>}></Route>
            <Route path={"/feeds/edit"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><EditFeed container={container} /></RequireAuth>}></Route>
            <Route path={"/feeds/edit/:id"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><EditFeed container={container} /></RequireAuth>}></Route>
            <Route path={"/feeds/:id"} element={<FeedDetail container={container} />}></Route>
            <Route path={"/patreon/confirm"} element={<PatreonConfirm container={container}></PatreonConfirm>}></Route>
            <Route path={"/messenger"} element={<RequireAuth onlyAngels={vtcHubAccesibleOnlyToAngels} container={container}><Chat container={container}></Chat></RequireAuth>}></Route>
            <Route path={"/search"} element={<UserSearch container={container}></UserSearch>}></Route>
            <Route path={"/gamedashboard"} element={<GameDashboard container={container}></GameDashboard>}></Route>
            <Route path={"/premium"} element={<Premium container={container}></Premium>}></Route>
            <Route path={"/otp"} element={<RequireAuth container={container}><ClientLoginCode container={container}></ClientLoginCode></RequireAuth>}></Route>
            <Route path={"/dispatch/:unique_id"} element={<DispatchDetail container={container}></DispatchDetail>}></Route>
        </Routes>
    )
};

export default AppRoutes;