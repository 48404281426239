import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import { Col, Input, Row } from "reactstrap";

const Paginator = ({
  totalPages,
  forcePage,
  handlePageClick,
  children,
  showRowsPerPage = false,
  rowsPerPageSelected = 25,
  rowsPerPage = [25, 50, 75, 100],
  handleRowsPerPageChange,
}) => {
  return (
    <>
      <div className="d-flex flex-column align-items-center flex-lg-row">
        <div className="flex-grow-1 align-items-center">{children}</div>

        <>
          <div className="d-flex">
            {showRowsPerPage && handleRowsPerPageChange && (
              <Input
                className="w-100px"
                size="sm"
                value={rowsPerPageSelected}
                onChange={(e) => handleRowsPerPageChange(e.target.value)}
                type="select"
              >
                {rowsPerPage.map((m) => {
                  return <option value={m}>{m} per page</option>;
                })}
              </Input>
            )}
            {totalPages > 1 && (
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                forcePage={forcePage}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={totalPages}
                previousLabel="<"
                renderOnZeroPageCount={null}
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination pagination-sm float-end"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
              />
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default Paginator;
