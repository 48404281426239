import { useContext, useState } from "react";
import { useEffect } from "react";
import { Alert, Button, ButtonGroup, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { confirmWrapper } from '../ui/askConfirm'
import { EnterpriseContext } from "./EnterpriseContext";
import EnterpriseNavBar from "./EnterpriseNavBar";
import { useNavigate, useParams } from "react-router";
import Tips from "../Tips";
import tipsList from "../../common/constants/tipsList";
import VehiclesTable from "./VehiclesTable";
import truckyService from "../../common/services/trucky-service";
import { useSearchParams } from "react-router-dom";
import MaintenanceAlerts from "./MaintenanceAlerts";
import VenatusLeaderboardAd from "../../ads/VenatusLeaderboardAd";
import DesktopTakeover from "../../ads/DesktopTakeover";
import BoxDynamicAd from "../../ads/BoxDynamicAd";
const classnames = require('classnames');

const FleetManagement = ({ eventBus, container }) => {

    const { globalContext, setGlobalContext } = useContext(EnterpriseContext);

    const company = globalContext.company;
    const member = globalContext.member;

    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const es = new EnterpriseService();
    const [garages, setGarages] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [newVehicleModalIsOpen, toggleNewVehicleModal] = useState(false);
    const [currentVehicle, setCurrentVehicle] = useState({});
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [currentBrand, setCurrentBrand] = useState(null);
    const [currentModel, setCurrentModel] = useState(null);
    const [members, setMembers] = useState([]);
    const [vehicleToEdit, setVehicleToEdit] = useState(null);
    const [editVehicleModalIsOpen, toggleEditVehicleModal] = useState(false);
    const navigate = useNavigate();
    const [ownedModels, setOwnedModels] = useState([]);
    const { status } = useParams();
    const [searchParameters, setSearchParameters] = useState(truckyService.loadSearchParameters('enterprise_fleet',
        { model_id: '', garage_id: '', orderBy: '', status: status ? status : '', sortingDirection: null, sortingField: null }));
    const [vehicleType, setVehicleType] = useState('truck');

    useEffect(() => {
        truckyService.saveSearchParameters('enterprise_fleet', searchParameters);
    }, [searchParameters]);

    useEffect(() => {

        truckyService.setDocumentTitle('Fleet Management');

        getGarages();
        getVehicles();
        getOwnedModels();
    }, []);

    useEffect(async () => {
        if (currentModel != null) {
            setCurrentVehicle({ ...currentVehicle, model_id: currentModel.id });
            await getGarages(currentModel);
            await getMembers();
        }
    }, [currentModel]);

    useEffect(async () => {
        if (vehicleToEdit != null) {
            await getGarages(vehicleToEdit.model);
            await getMembers();
        }
    }, [vehicleToEdit]);

    useEffect(() => {
        getBrands();
        setModels([]);
    }, [vehicleType]);

    useEffect(async () => {
        getVehicles();
    }, [searchParameters]);

    useEffect(async () => {
        if (currentBrand != null) {
            await getModels();
        }
    }, [currentBrand]);

    const getVehicles = async () => {
        setLoading(true);
        const result = await es.getVehicles(company, searchParameters);
        setVehicles(result);
        setLoading(false);
    };

    const getGarages = async () => {
        const result = await es.getGarages(company);
        if (!result.message)
            setGarages(result);
    };

    const getBrands = async () => {
        const result = await es.getBrands(vehicleType);
        setBrands(result);
    };

    const getModels = async () => {
        const result = await es.getModels(currentBrand, vehicleType);
        setModels(result);
    };

    const openNewVehicleDialog = async () => {
        toggleNewVehicleModal(true);
        getBrands();
    };

    const getOwnedModels = async () => {
        const result = await es.getOwnedModels(company);
        setOwnedModels(result);
    };

    const save = async () => {
        if (await confirmWrapper(container.localeManager.strings.buyVehicleConf, { container: container })) {
            setLoading(true);
            const result = await es.buyNewVehicle(company, currentVehicle);
            if (result.success) {
                getVehicles();
                getOwnedModels();
                toggleNewVehicleModal(false);
                setCurrentVehicle({});
                toast.success(container.localeManager.strings.deal);
            }
            else {
                toast.error(result.message);
            }
            setLoading(false);
        }
    }

    const getMembers = async () => {
        const result = await es.getMembers(company, { pagination: 1, perPage: 1000 });
        setMembers(result.data);
    };

    const sell = async (vehicle) => {
        if (await confirmWrapper(container.localeManager.strings.sellVehicleConf, { container: container })) {
            setLoading(true);
            const result = await es.deleteVehicle(company, vehicle);
            if (result.success) {
                await getVehicles();
                getOwnedModels();
                toast.success(container.localeManager.strings.vehicleSold);
            }
            else {
                toast.error(result.message);
            }
            setLoading(false);
        }
    }

    const changeSortingField = ({ field, direction }) => {
        setSearchParameters({ ...searchParameters, sortingField: field, sortingDirection: direction })
    };

    return (
        <LoadingOverlay
            active={loading}
            spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
        >
            {!window.overwolf &&
                <DesktopTakeover container={container} className="my-3"></DesktopTakeover>
            }
            <Row noGutters className="" id="enterpriseContainer">
                <Col>
                    <Row noGutters>
                        <Col>
                            <Card className="h-100">
                                <CardBody>
                                    <h5 className="d-inline-block w-100 mb-3"> {container.localeManager.strings.vehicles}
                                        <div class="float-end">
                                            {!loading && es.can(member, 'vehicles.buy') &&
                                                <Button size="sm" onClick={openNewVehicleDialog} color="primary"><FontAwesomeIcon icon="cart-plus" />{container.localeManager.strings.buyANewVehicle}</Button>
                                            }
                                        </div>
                                    </h5>
                                    {!loading && vehicles.length == 0 &&
                                        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-3">
                                            {container.localeManager.strings.garageEmpty}
                                        </div>
                                    }
                                    <FormGroup row className="mb-3">
                                        <Col>
                                            <Label>{container.localeManager.strings.models}</Label>
                                            <Input value={searchParameters.model_id} onChange={(e) => setSearchParameters({ ...searchParameters, model_id: e.target.value })} type="select">
                                                <option value="">{container.localeManager.strings.all}</option>
                                                {ownedModels.map((m) => {
                                                    return (
                                                        <option value={m.id}>{m.brand_name} {m.model_name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </Col>
                                        <Col>
                                            <Label>{container.localeManager.strings.garages}</Label>
                                            <Input value={searchParameters.garage_id} onChange={(e) => setSearchParameters({ ...searchParameters, garage_id: e.target.value })} type="select">
                                                <option value="">{container.localeManager.strings.all}</option>
                                                {garages.map((m) => {
                                                    return (
                                                        <option value={m.id}>{m.city.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </Col>
                                        <Col>
                                            <Label>{container.localeManager.strings.status}</Label>
                                            <Input value={searchParameters.status} onChange={(e) => setSearchParameters({ ...searchParameters, status: e.target.value })} type="select">
                                                <option value="">{container.localeManager.strings.all}</option>
                                                <option value="active">{container.localeManager.strings.active}</option>
                                                <option value="need_maintenance">{container.localeManager.strings.need_maintenance}</option>
                                                <option value="under_maintenance">{container.localeManager.strings.under_maintenance}</option>
                                                <option value="requested">{container.localeManager.strings.requested}</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    {!loading && vehicles.length > 0 &&
                                        <div>
                                            <VehiclesTable sell={sell} vehicles={vehicles} company={company} member={member} container={container}
                                                changeSortingField={changeSortingField}
                                                filters={searchParameters}></VehiclesTable>
                                        </div>
                                    }
                                    <Modal unmountOnClose={true} zIndex="999999999" size="xl" isOpen={newVehicleModalIsOpen} toggle={() => toggleNewVehicleModal(false)}>
                                        <ModalHeader toggle={() => toggleNewVehicleModal(false)}
                                            close={<Button color="active-light-primary" className="btn-icon btn-sm w-30px h-30px ms-auto" onClick={() => toggleNewVehicleModal(false)}><FontAwesomeIcon icon="times"></FontAwesomeIcon></Button>}>
                                            {container.localeManager.strings.buyANewVehicle}
                                        </ModalHeader>
                                        <ModalBody>
                                            <LoadingOverlay
                                                active={loading}
                                                spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
                                            >
                                                <Row className="mb-3">
                                                    <Col>
                                                        <ButtonGroup>
                                                            <Button color={vehicleType == 'truck' ? 'primary' : 'secondary'} onClick={() => setVehicleType('truck')}>Trucks</Button>
                                                            <Button color={vehicleType == 'bus' ? 'primary' : 'secondary'} onClick={() => setVehicleType('bus')}>Bus</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="mb-3">{container.localeManager.strings.chooseBrand}</div>
                                                        <div className="grid cols-4 no-gap">
                                                            {brands.map(b => {
                                                                return (
                                                                    <Card className={classnames({ 'bg-light': true, 'brand': true, 'border-success': (currentBrand != null && b.id == currentBrand.id) })}>
                                                                        <CardBody onClick={() => setCurrentBrand(b)}>
                                                                            <img className="img-fluid" alt={b.name} src={b.logo_url} />
                                                                        </CardBody>
                                                                    </Card>
                                                                )
                                                            })}
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        {models.length > 0 &&
                                                            <>
                                                                <div className="mb-3">{container.localeManager.strings.chooseModel}</div>
                                                                <div className="grid cols-2 no-gap">
                                                                    {models.map(b => {
                                                                        return (
                                                                            <Card onClick={() => setCurrentModel(b)} className={classnames({ 'bg-light': true, 'model': true, 'border-success': (currentModel != null && b.id == currentModel.id) })}>
                                                                                <CardBody>
                                                                                    <img className="img-fluid" alt={b.name} src={b.photo_url} />
                                                                                    <div>
                                                                                        <span className="float-left">{b.name}</span>
                                                                                        <span className="float-end text-success">{container.localeManager.numeral(b.price).format('0,0')} {company.currency}</span>
                                                                                    </div>
                                                                                </CardBody>
                                                                            </Card>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </>
                                                        }
                                                    </Col>
                                                </Row>
                                                {currentVehicle.model_id &&
                                                    <>
                                                        <Row className="mt-3">
                                                            <Col>
                                                                {garages.length > 0 &&
                                                                    <FormGroup>
                                                                        <Label>{container.localeManager.strings.chooseGarage}</Label>
                                                                        <Input type="select" onChange={(e) => setCurrentVehicle({ ...currentVehicle, garage_id: e.target.value })}>
                                                                            <option>{container.localeManager.strings.pleaseSelectGarage}</option>
                                                                            {garages.map(g => {
                                                                                return (
                                                                                    <option value={g.id}>{g.city.name}</option>
                                                                                )
                                                                            })}
                                                                        </Input>
                                                                    </FormGroup>
                                                                }
                                                            </Col>
                                                            <Col>
                                                                {members.length > 0 && es.can(member, 'vehicles.assign') &&
                                                                    <FormGroup>
                                                                        <Label>{container.localeManager.strings.assignedDriver}</Label>
                                                                        <Input type="select" onChange={(e) => setCurrentVehicle({ ...currentVehicle, assigned_to_user_id: e.target.value })}>
                                                                            <option value="">{container.localeManager.strings.notAssigned}</option>
                                                                            {members.map(g => {
                                                                                return (
                                                                                    <option value={g.id}>{g.name}</option>
                                                                                )
                                                                            })}
                                                                        </Input>
                                                                    </FormGroup>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        {currentVehicle.garage_id && currentVehicle.model_id &&
                                                            <Row>
                                                                <Col>

                                                                    <FormGroup className="mt-3">
                                                                        <Button onClick={save} color="primary">{container.localeManager.strings.buy}</Button>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </>
                                                }
                                            </LoadingOverlay>
                                        </ModalBody>
                                    </Modal>
                                </CardBody>
                            </Card>
                        </Col>
                        <aside className="ad-column-placeholder">
                            <Tips tipKey={tipsList.KEYS.ENTERPRISE_VEHICLES} localeManager={container.localeManager}></Tips>
                            <MaintenanceAlerts company={company} container={container}></MaintenanceAlerts>
                            {!window.overwolf &&
                        <BoxDynamicAd container={container} className="my-3" />
                    }
                        </aside>
                    </Row>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Col>
            </Row >
            {!window.overwolf &&
                <VenatusLeaderboardAd container={container} className="my-3"></VenatusLeaderboardAd>
            }
        </LoadingOverlay >
    )
};

export default FleetManagement;