import { useEffect } from 'react';
import { Button } from 'reactstrap';
import { MenuComponent } from '../../common/plugins/metronic/components/MenuComponent';
import classNames from 'classnames';

const ButtonDropdownMenu = ({ trigger = "hover", buttonColor = "primary",
    buttonClassNames, buttonContent, menuTitle, menuItems, menuContent, menuWidth = 200,
    menuPlacement = "bottom", title = null,
    menuId = null, menuToggleOnClick = true
}) => {

    useEffect(() => {
        setTimeout(() => {
            if (menuId == null)
                MenuComponent.bootstrap();
            else
                MenuComponent.createInsance(`#${menuId}`);
        }, 500);
    }, []);

    return (
        <>
            <Button title={title} tag="a" className={buttonClassNames} 
                color={buttonColor} data-kt-menu-trigger={trigger} data-kt-menu-attach="parent" data-kt-menu-toggle={menuToggleOnClick} data-kt-menu-placement={menuPlacement}>
                {buttonContent}
            </Button>
            <div class={`menu menu-sub menu-sub-dropdown menu-column w-${menuWidth}px menu-state-bg menu-title-gray-700 menu-state-title-primary pb-3 pt-3`} id={menuId} data-kt-menu="true">
                {menuTitle &&
                    <div class="menu-content fw-bold text-white pb-2 px-3 pt-3 fs-7 text-uppercase d-flex">{menuTitle}</div>
                }
                {menuContent && menuContent}
                {menuItems && menuItems.map(m => {
                    if (!m.divider) {
                        if (!m.disabled) {
                            return (
                                <div class={classNames({ "menu-item": true, "px-3": true, "active": m.active })}>
                                    <a onClick={m.click} className={classNames({ "menu-link": true, "px-3": true, "text-muted": m.disabled })}>
                                        {m.icon &&
                                            <span className='menu-icon'>{m.icon}</span>
                                        }
                                        <span className='menu-title'>{m.title}</span>
                                    </a>
                                </div>
                            );
                        }
                        else {
                            return (<div class={classNames({ "menu-item": true, "px-3": true, "active": m.active })}>
                                <a className={classNames({ "menu-link": true, "px-3": true, "text-muted": m.disabled })}>
                                    {m.icon &&
                                        <span className='menu-icon'>{m.icon}</span>
                                    }
                                    <span className='menu-title'>{m.title}</span>
                                </a>
                            </div>);
                        }
                    }
                    else {
                        return (<div className='separator my-2 w-100'></div>)
                    }
                })}
            </div>
        </>
    )
};

export default ButtonDropdownMenu;