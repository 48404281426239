import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import { Link } from "react-router-dom";

const BottomBar = ({ container }) => {

    const { globalContext } = useContext(EnterpriseContext);

    return (
        <div className="bottombar justify-content-between align-center align-items-center">
            {globalContext.member != null &&
                <Link to="/my/profile" className="text-white"><FontAwesomeIcon icon="user" className="mx-0"></FontAwesomeIcon></Link>
            }
            {globalContext.member != null && globalContext.member.company_id != null &&
                <Link to="/vtchub" className="text-white"><FontAwesomeIcon icon="building" className="mx-0"></FontAwesomeIcon></Link>
            }
            <Link to="/servers" className="text-white"><FontAwesomeIcon icon="server" className="mx-0"></FontAwesomeIcon></Link>
            <Link to="/directory" className="text-white"><FontAwesomeIcon icon="search" className="mx-0"></FontAwesomeIcon></Link>
            <Link to="/traffic" className="text-white"><FontAwesomeIcon icon="traffic-light" className="mx-0"></FontAwesomeIcon></Link>
            <Link to="/map" className="text-white"><FontAwesomeIcon icon="map" className="mx-0"></FontAwesomeIcon></Link>
        </div>
    )
}

export default BottomBar;