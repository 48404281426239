import { useEffect, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import truckyService from "../../common/services/trucky-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmWrapper } from "../ui/askConfirm";
import config from "../../config";

const CompanyWebhooks = ({ company, container, platform, reloadCompany }) => {
  const eventGroups = [
    {
      group: "jobs",
      perGame: true,
      events: [
        "JOB_CREATED",
        "JOB_COMPLETED",
        "JOB_CANCELED",
        "JOB_EVENT_CREATED",
      ],
    },
    {
      group: "applications",
      perGame: false,
      events: [
        "APPLICATION_CREATED",
        "APPLICATION_ACCEPTED",
        "APPLICATION_REJECTED",
        "APPLICATION_RETIRED",
      ],
    },
    {
      group: "fleet",
      perGame: false,
      events: [
        "VEHICLE_CREATED",
        "VEHICLE_UPDATED",
        "VEHICLE_ASSIGNED",
        "VEHICLE_DELETED",
        "VEHICLE_NEED_MAINTENANCE",
        "VEHICLE_MAINTENANCE_COMPLETE",
      ],
    },
    {
      group: "garages",
      perGame: false,
      events: ["GARAGE_CREATED", "GARAGE_UPGRADED", "GARAGE_DELETED"],
    },
    {
      group: "members",
      perGame: false,
      events: ["USER_JOINED_COMPANY", "MEMBER_KICKED", "MEMBER_LEFT"],
    },
    {
      group: "roles",
      perGame: false,
      events: ["ROLE_CREATED", "ROLE_UPDATED", "ROLE_DELETED"],
    },
    {
      group: "newsFeed",
      perGame: false,
      events: ["NEWSFEED_PUBLISHED"],
    },
    {
      group: "achievements",
      perGame: false,
      events: [
        "ACHIEVEMENT_CREATED",
        //'ACHIEVEMENT_REACHED'
      ],
    },
    {
      group: "dispatcher",
      perGame: false,
      events: ["JOB_DISPATCHED", "DISPATCH_CLAIMED"],
    },
  ];
  const es = new EnterpriseService();
  const [data, setData] = useState([]);
  const [webhook, setWebhook] = useState({ platform: platform, url: null });
  const [secretKey, setSecretKey] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await es.getWebhooks(company, platform);
    setData(result);
  };

  const addWebhook = async () => {
    const result = await es.createWebhook(company, webhook);

    if (result.success) {
      getData();
      setWebhook({ ...webhook, url: null });
    } else {
      toast.error(container.localeManager.strings.webhookError);
    }
  };

  const testWebhook = async (url) => {
    if (truckyService.isValidWebhookUrl(url)) {
      const response = await es.testWebhook(company, url);
      if (response.result) {
        toast.success(container.localeManager.strings.webhookSucceded);
      } else {
        toast.success(container.localeManager.strings.webhookError);
      }
    } else {
      toast.error(container.localeManager.strings.discordWebhookUrlNotValid);
    }
  };

  const createSecret = async () => {
    const result = await es.createWebhookSecret(company);
    if (result.success) {
      setSecretKey(result.secret);
    }
  };

  const renderWebhookCard = (item) => {
    return (
      <Card className="border-gray-300 rounded mb-3 mx-0">
        <CardBody>
          <FormGroup row>
            <Col sm={9}>
              <Label>URL</Label>
              <InputGroup>
                <Input
                  type="url"
                  value={item.url}
                  onChange={(e) =>
                    setWebhookInArray({ ...item, url: e.target.value })
                  }
                ></Input>
                {platform == "discord" && (
                  <Button
                    color="secondary"
                    onClick={() => testWebhook(item.url)}
                    disabled={!item.url}
                  >
                    {container.localeManager.strings.test}
                  </Button>
                )}
              </InputGroup>
            </Col>
            <Col className="d-flex align-items-end mt-3 mt-md-0">
              <Button size="sm" color="success" onClick={() => save(item)}>
                <FontAwesomeIcon icon="save"></FontAwesomeIcon>
                {container.localeManager.strings.save}
              </Button>
              <Button
                size="sm"
                color="danger"
                className="ms-3"
                onClick={() => deleteItem(item)}
              >
                <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                {container.localeManager.strings.delete}
              </Button>
            </Col>
          </FormGroup>
          {platform == "discord" && (
            <FormGroup row>
              <Col sm={6}>
                <Label>{container.localeManager.strings.game}</Label>
                <Input
                  value={item.game_id}
                  type="select"
                  onChange={(e) =>
                    setWebhookInArray({
                      ...item,
                      game_id: e.target.value == "" ? null : e.target.value,
                    })
                  }
                >
                  <option value="">
                    {container.localeManager.strings.all}
                  </option>
                  <option value={1}>Euro Truck Simulator 2</option>
                  <option value={2}>American Truck Simulator</option>
                </Input>
              </Col>
            </FormGroup>
          )}
          {item.game_id != null &&
            renderEvents(
              item,
              eventGroups.filter((m) => m.perGame)
            )}
          {item.game_id == null && renderEvents(item, eventGroups)}
        </CardBody>
      </Card>
    );
  };

  const renderEvents = (item, groups) => {
    return groups.map((g) => {
      return (
        <div className="mb-3">
          <h5>{container.localeManager.strings[g.group]}</h5>
          <Row>
            {g.events.map((evt) => {
              return (
                <Col sm={3} className="mb-1">
                  <label class="form-check form-switch form-check-custom form-check-solid">
                    <input
                      onChange={(e) =>
                        setPermission(e.target.checked, item, evt)
                      }
                      class="form-check-input"
                      type="checkbox"
                      checked={item.events?.includes(evt)}
                    />
                    <span class="form-check-label">
                      {container.localeManager.strings[evt]}
                    </span>
                  </label>
                </Col>
              );
            })}
          </Row>
        </div>
      );
    });
  };

  const setPermission = (checked, item, event) => {
    if (!item.events) {
      item.events = [];
    }

    if (checked) {
      item.events = [...item.events, event];
    } else {
      item.events = item.events.filter((m) => m != event);
    }

    setWebhookInArray(item);
  };

  const setWebhookInArray = (item) => {
    const index = data.findIndex((m) => m.id == item.id);
    const newData = [...data];
    newData[index] = item;
    setData(newData);
  };

  const save = async (item) => {
    const result = await es.updateWebhook(company, item);
    if (result.success) {
      getData();
      toast.success(container.localeManager.strings.dataUpdated);
    } else {
      toast.error(result.message);
    }
  };

  const deleteItem = async (item) => {
    if (
      await confirmWrapper(container.localeManager.strings.areYouSure, {
        container: container,
      })
    ) {
      const result = await es.deleteWebhook(company, item.id);
      getData();
      toast.success(container.localeManager.strings.webhookDeleted);
    }
  };

  const migrate = async () => {
    await es.migrateWebhooks(company);
    if (reloadCompany) reloadCompany();
    getData();
  };

  return (
    <>
      {(config.FEATURES_VERSION == "Development" ||
        config.FEATURES_VERSION == "Testers") &&
        (company.integrations.discord_webhook_url ||
          company.integrations.discord_webhook_url_application_accepted ||
          company.integrations.discord_webhook_url_application_rejected) && (
          <div className="mb-3">
            This feature is available only for Testers: if you want to try the
            new Discord Integration, you should first migrate from old
            configurration. Once live, all companies will be migrated
            automatically to the new system.
            <br />
            <Button
              color="primary"
              onClick={migrate}
              className="mt-2"
              size="sm"
            >
              Migrate from old Configuration
            </Button>
          </div>
        )}
      {platform == "api" && (
        <FormGroup row className="mb-5">
          <Col sm={9}>
            <FormText className="mb-3">
              <FontAwesomeIcon icon="warning"></FontAwesomeIcon>
              {container.localeManager.strings.webookSecretKeyInfo}
            </FormText>
            <Label>{container.localeManager.strings.webookSecretKey}</Label>
            <InputGroup>
              <Input disabled={secretKey == ""} value={secretKey}></Input>
              {secretKey != "" && (
                <Button
                  color="secondary"
                  title={container.localeManager.strings.copy}
                  onClick={() => overwolf.utils.placeOnClipboard(secretKey)}
                >
                  <FontAwesomeIcon
                    className="m-0"
                    icon="copy"
                  ></FontAwesomeIcon>
                </Button>
              )}
            </InputGroup>
          </Col>
          <Col className="d-flex align-items-end mt-3 mt-md-0">
            <Button size="sm" color="success" onClick={() => createSecret()}>
              <FontAwesomeIcon icon="lock"></FontAwesomeIcon>
              {container.localeManager.strings.createSecret}
            </Button>
          </Col>
        </FormGroup>
      )}
      <FormGroup row>
        <Col sm={9}>
          <Label>URL</Label>
          <InputGroup>
            <Input
              type="url"
              onChange={(e) => setWebhook({ ...webhook, url: e.target.value })}
            ></Input>
            {platform == "discord" && (
              <Button
                color="secondary"
                onClick={() => testWebhook(webhook.url)}
                disabled={!webhook.url}
              >
                {container.localeManager.strings.test}
              </Button>
            )}
          </InputGroup>
        </Col>
        <Col className="d-flex align-items-end mt-3 mt-md-0">
          <Button
            disabled={!webhook.url}
            color="success"
            size="sm"
            onClick={addWebhook}
          >
            <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
            {container.localeManager.strings.add}
          </Button>
        </Col>
      </FormGroup>
      <div className="my-3">
        {data.map((m) => {
          return renderWebhookCard(m);
        })}
      </div>
    </>
  );
};

export default CompanyWebhooks;
