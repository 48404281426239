import globalConstants from "../constants/global-constants";
import UserDefinedUnits from "./UserDefinedUnits";

function getUnitSystemForGame() {
  if (window.dataService.data.game == "ATS")
    return window.dataService.settings.unitSystem_ats;

  if (window.dataService.data.game == "ETS2")
    return window.dataService.settings.unitSystem;

  return "metric";
}

function convertDistance(value) {
  /*if (getUnitSystemForGame() == 'metric')
    return value;
  else
    return parseInt(Math.round(value / 1.609));
  */
  return UserDefinedUnits.convertDistanceValue(window.dataService.enterpriseService.member, getGameId(), value);
}

function convertDistanceToMiles(value, unit) {
  return parseInt(Math.round(value / 1.609));
}

function formatDistance(value) {
  //return window.localeManager.numeral(this.convertDistance(value)).format('0,0');
  return UserDefinedUnits.convertDistanceValue(window.dataService.enterpriseService.member, getGameId(), value);
}

function formatSpeed(value) {
  //return window.localeManager.numeral(this.convertDistance(parseInt(value))).format();
  return UserDefinedUnits.convertDistanceValue(window.dataService.enterpriseService.member, getGameId(), value);
}


function formatWeight(value) {
  /*if (getUnitSystemForGame() == 'metric')
    return window.localeManager.numeral(value).format('0,0');
  else
    return window.localeManager.numeral(parseInt(Math.round(value * 2204.62262))).format('0,0');
  */
  return UserDefinedUnits.convertWeightValue(window.dataService.enterpriseService.member, getGameId(), value);
}

function getDistanceUnit() {
  /*if (getUnitSystemForGame() == 'metric')
    return window.localeManager.strings.kilometers;
  else
    return window.localeManager.strings.miles;*/

  return UserDefinedUnits.getDistanceUnitLabel(window.dataService.enterpriseService.member, getGameId());
}

function getSpeedUnit() {
  /*if (getUnitSystemForGame() == 'metric')
    return window.localeManager.strings.kilometersPerHour;
  else
    return window.localeManager.strings.milesPerHour;
  */
  return UserDefinedUnits.getSpeedUnitLabel(window.dataService.enterpriseService.member, getGameId());
}

function getVolumeUnit() {
  /*if (getUnitSystemForGame() == 'metric')
    return window.localeManager.strings.litresShort;
  else
    return window.localeManager.strings.gallons;
  */
  return UserDefinedUnits.getVolumeUnitLabel(window.dataService.enterpriseService.member, getGameId());
}

function formatVolume(value) {
  /*if (getUnitSystemForGame() == 'metric')
    return window.localeManager.numeral(value).format('0,0');
  else
    return window.localeManager.numeral(parseInt(Math.round(value / 3.785))).format('0,0');
  */
  return UserDefinedUnits.convertVolumeValue(window.dataService.enterpriseService.member, getGameId(), value);
}

function getMassUnit() {
  /*if (getUnitSystemForGame() == 'metric')
    return window.localeManager.strings.tons;
  else
    return window.localeManager.strings.libres;
  */
  return UserDefinedUnits.getWeightUnitLabel(window.dataService.enterpriseService.member, getGameId());
}

function formatMoney(value) {
  return window.localeManager.numeral(convertCurrency(value)).format('0,0');
  //new Intl.NumberFormat(window.dataService.settings.language, { style: 'currency', currency: getCurrencyForGame(), maximumSignificantDigits: 2 }).format(convertCurrency(value));
}

function getMoneyUnit() {
  let currency = getCurrencyForGame();
  if (window.dataService.data.game == "ATS")
    return globalConstants.CURRENCIES.ATS[currency].symbol;
  else
    return globalConstants.CURRENCIES.ETS2[currency].symbol;
}

function getCurrencyForGame() {
  if (window.dataService.data.game != "ATS")
    return window.dataService.settings.currency;
  else
    return window.dataService.settings.currency_ats;
}

function convertCurrency(value) {
  let currency = getCurrencyForGame();
  if (window.dataService.data.game == "ATS")
    return Math.round(value * globalConstants.CURRENCIES.ATS[currency].rate, 2);
  else
    return Math.round(value * globalConstants.CURRENCIES.ETS2[currency].rate, 2);
}

function getGameId() {
  if (window.dataService.data.game == 'ETS2')
    return 1;
  if (window.dataService.data.game == 'ATS')
    return 2;
}

function getFuelConsumptionUnit() {
  return UserDefinedUnits.getFuelConsumptionUnit(window.dataService.enterpriseService.member, getGameId());
}

export default {
  convertDistance,
  formatDistance,
  formatWeight,
  formatMoney,
  getMoneyUnit,
  getMassUnit,
  formatVolume,
  getSpeedUnit,
  getVolumeUnit,
  getDistanceUnit,
  getUnitSystemForGame,
  formatSpeed,
  convertCurrency,
  getCurrencyForGame,
  convertDistanceToMiles,
  getGameId,
  getFuelConsumptionUnit
};