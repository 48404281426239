import { useEffect, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import { useParams } from "react-router";
import { Alert, Card, CardBody, Spinner } from "reactstrap";
import DesktopTakeover from "../../ads/DesktopTakeover";

export default function DispatchDetail({ container }) {
  const es = new EnterpriseService();
  const [dispatch, setDispatch] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const { unique_id } = useParams();
  const { loading, setLoading } = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await es.getDispatchByUniqueId(unique_id);

    if (result) {
      if (!result.error) {
        setDispatch(result);
      } else {
        setErrorMessage(result.error);
      }
    } else {
      setErrorMessage("Cannot load Dispatch Data");
    }
    setLoading(false);
  };
  return (
    <div>
      <DesktopTakeover container={container} className="my-3"></DesktopTakeover>
      {loading && <Spinner />}
      {!loading && errorMessage != "" && (
        <Alert color="danger">{errorMessage}</Alert>
      )}
      {!loading && dispatch && (
        <Card>
          <CardBody>
            
          </CardBody>
        </Card>
      )}
    </div>
  );
}
