import { Container } from "reactstrap";
import Header from "../components/Header";
import ScrollToTop from "../components/ScrollToTop";
import AppRoutes from "../components/Routes";
import Footer from "../components/Footer";
import Drawer from "../components/Drawer";
import CompanyPresenceDrawer from "../../components/enterprise/CompanyPresenceDrawer";
import { useLocation } from "react-router";
import GameDashboard from "./GameDashboard";
import VenatusAd from "../../ads/VenatusAd";
import { useSearchParam } from "react-use";
import { useEffect, useState } from "react";
import BottomBar from "../components/BottomBar";

const Layout = ({ container }) => {

    const location = useLocation();
    const inapp = useSearchParam('inapp');
    const [contentContainerHeight, setContentContainerHeight] = useState(null);

    useEffect(() => {
        if (location.pathname == '/map') {
            document.getElementsByTagName('body')[0].classList.add('livemap');
        }
        else {
            document.getElementsByTagName('body')[0].classList.remove('livemap');
        }
    }, [location]);

    useEffect(() => {
        if (inapp === 'true' || sessionStorage.getItem('inapp') === 'true') {
            sessionStorage.setItem('inapp', 'true');
            document.getElementsByTagName('body')[0].classList.add('inapp');
            setContentContainerHeight(window.outerHeight - 100);

            window.addEventListener('resize', () => {
                setContentContainerHeight(window.innerHeight - 100);
            });

            window.addEventListener('orientationchange', () => {
                setContentContainerHeight(window.innerHeight - 100);
            });
        }
    }, []);

    return (
        <>
            {location.pathname != '/gamedashboard' &&
                <>
                    <ScrollToTop></ScrollToTop>
                    <Header container={container}></Header>
                    <Container fluid className="content-container mb-7 min-h-600px pt-3" style={{ height: contentContainerHeight }}>
                        <AppRoutes container={container}></AppRoutes>
                        <BottomBar container={container}></BottomBar>
                    </Container>
                    <Footer container={container}></Footer>
                    <Drawer container={container}></Drawer>
                    <CompanyPresenceDrawer container={container}></CompanyPresenceDrawer>
                </>
            }
            {location.pathname == '/gamedashboard' &&
                <GameDashboard container={container}></GameDashboard>
            }
        </>
    )
}

export default Layout;