import { useEffect, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import { Button, Col, Input, InputGroup, Row } from "reactstrap";
import { Link, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/ui/Paginator";
import DesktopTakeover from "../../ads/DesktopTakeover";
import VenatusLeaderboardAd from "../../ads/VenatusLeaderboardAd";
import truckyService from "../../common/services/trucky-service";

const UserSearch = ({ container }) => {

    const es = new EnterpriseService();
    const [users, setUsers] = useState();
    const [searchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState(searchParams.get('term'));
    const [pagination, setPagination] = useState({ page: 1, perPage: 25 });
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {

        truckyService.setDocumentTitle('User Search');

        if (searchParams.has('term')) {
            search();
        }
    }, []);

    useEffect(() => {
        search();
    }, [pagination.page])

    const search = async () => {
        if (searchTerm != '' && searchTerm.length >= 3) {
            const result = await es.searchUsers(searchTerm, 'like', true, pagination.page);
            setUsers(result.data);
            setTotalPages(result.last_page);
        }
    }

    const getColoredSpan = (member, value) => {
        if (member.role != null) {
            return (
                <span style={{ color: member.role.color }}>{value}</span>
            )
        }
        else {
            return (<span>{value}</span>);
        }
    }

    const navigateToSearch = () => {
        setPagination({ ...pagination, page: 1 });
        search();
    }

    const handlePageClick = (event) => {
        setPagination({ ...pagination, page: event.selected + 1 });
    };

    return (
        <div>
            <DesktopTakeover container={container} className="my-3"></DesktopTakeover>
            <h5 className="d-inline-block w-100 mb-3">{container.localeManager.strings.searchResults}
            </h5>
            <div className="d-flex mb-5 flex-center mx-1 mx-md-none">
                <InputGroup className="w-md-500px">
                    <Input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder={container.localeManager.strings.searchForPlayerName} onKeyDown={(e) => e.key == 'Enter' ? navigateToSearch() : true}></Input>
                    <Button onClick={navigateToSearch}><FontAwesomeIcon icon="search" className="mx-0"></FontAwesomeIcon></Button>
                </InputGroup>

            </div>
            <div className="my-3">
                <Paginator forcePage={pagination.page - 1} totalPages={totalPages} handlePageClick={handlePageClick}>
                </Paginator>
            </div>
            <div className="grid cols-4 gap-4 px-0">
                {users && users.map(m => {
                    return (
                        <div class="card">
                            <div class="card-body d-flex align-items-center flex-column py-3 px-3">
                                <div class="symbol symbol-100px mb-5">
                                    <img className="rounded" src={m.avatar_url} />
                                </div>
                                <Link to={`/user/${m.id}`} class=" fs-4 fw-bolder text-white text-hover-primary fw-bold mb-0">{m.name}</Link>
                                {m.company &&
                                    <Link to={`/vtc/${m.company.slug}`} class="text-white fw-semibold fs-5">{m.company.name}</Link>
                                }
                                {m.role &&
                                    <div class="fw-semibold text-gray-400 mb-6">{m.role != null ? getColoredSpan(m, m.role.name) : ''}</div>
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="my-3">
                <Paginator forcePage={pagination.page - 1} totalPages={totalPages} handlePageClick={handlePageClick}>
                </Paginator>
            </div>
            <VenatusLeaderboardAd container={container} className="my-3"></VenatusLeaderboardAd>
        </div>
    )
}

export default UserSearch;