import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import EnterpriseService from '../../common/services/enterprise-service';
import SingleCompanyAward from './SingleCompanyAward';

const CompanyAwards = ({ company, container, month, year }) => {

    const es = new EnterpriseService();
    const [awards, setAwards] = useState([]);

    useEffect(async () => {
        const result = await es.getCompanyAwardsMonthly(company, month, year);
        setAwards(result);
    }, [month, year]);

    return (
        <>
            {awards.length > 0 &&
                <Row>
                    <Col>
                        <Card className="mx-0">
                            <CardHeader>{container.localeManager.strings.companyAwards}</CardHeader>
                            <CardBody>
                                <div className={`grid cols-${awards.length}`}>
                                    {awards.map((m) => <SingleCompanyAward container={container} award={m}></SingleCompanyAward>)}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </>
    )
};

export default CompanyAwards;