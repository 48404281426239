import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, ButtonGroup, Card, CardBody, Table } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import truckyService from "../../common/services/trucky-service";
import OrderByButtons from "../ui/OrderByButtons";
import UserDefinedUnits from "../../common/utils/UserDefinedUnits";
import { EnterpriseContext } from "./EnterpriseContext";
import { Link } from "react-router-dom";
require("linqjs");

const CompanyMemberStats = ({ container, company, currentPeriod, period }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const es = new EnterpriseService();
  const [membersStats, setMembersStats] = useState(null);
  const [searchParameters, setSearchParameters] = useState(
    truckyService.loadSearchParameters("enterprise_member_stats", {
      sortingField: "driven_distance",
      sortingDirection: "desc",
      paginate: false,
      game_id: -1,
      stats_type: "",
    })
  );

  const { globalContext } = useContext(EnterpriseContext);

  const member = globalContext.member;

  useEffect(() => {
    truckyService.saveSearchParameters(
      "enterprise_member_stats",
      searchParameters
    );
  }, [searchParameters]);

  useEffect(() => {
    getMembersStats();
  }, [
    currentPeriod.month,
    currentPeriod.year,
    currentPeriod.week,
    period,
    searchParameters.sortingDirection,
    searchParameters.sortingField,
    searchParameters.game_id,
    searchParameters.stats_type,
  ]);

  const getMembersStats = async () => {
    const result = await es.getMembersCompanyStatsByPeriod(company.id, {
      week: currentPeriod.week,
      month: currentPeriod.month + 1,
      year: currentPeriod.year,
      period,
      ...searchParameters,
    });

    setMembersStats(result);
  };

  const changeSortingField = ({ field, direction }) => {
    setSearchParameters({
      ...searchParameters,
      sortingField: field,
      sortingDirection: direction,
    });
  };

  const getCellBackground = (user_id, field) => {
    const minMax = {
      "td-success": false,
      "td-danger": false,
    };

    const ordered = membersStats.members.orderBy((t) => t[field]);

    if (ordered != null && ordered.length > 1) {
      if (ordered[0].user_id == user_id) minMax["td-danger"] = true;

      if (ordered[ordered.length - 1].user_id == user_id)
        minMax["td-success"] = true;
    }

    return classNames(minMax);
  };

  const showSalary = () => {
    if (company.company_type == "realistic" || company.company_type == "both") {
      if (
        globalContext.member &&
        globalContext.member.company_id == company.id &&
        (es.can(globalContext.member, "company.*") ||
          es.can(globalContext.member, "members.*"))
      )
        return true;
    }

    return false;
  };

  return (
    <>
      {membersStats != null && (
        <Card>
          <CardBody>
            <div className="mb-3">
              <ButtonGroup>
                <Button
                  color={
                    searchParameters.game_id == -1 ? "primary" : "secondary"
                  }
                  onClick={() =>
                    setSearchParameters({ ...searchParameters, game_id: -1 })
                  }
                >
                  {container.localeManager.strings.all}
                </Button>
                <Button
                  color={
                    searchParameters.game_id == 1 ? "primary" : "secondary"
                  }
                  onClick={() =>
                    setSearchParameters({ ...searchParameters, game_id: 1 })
                  }
                >
                  ETS2
                </Button>
                <Button
                  color={
                    searchParameters.game_id == 2 ? "primary" : "secondary"
                  }
                  onClick={() =>
                    setSearchParameters({ ...searchParameters, game_id: 2 })
                  }
                >
                  ATS
                </Button>
              </ButtonGroup>

              <ButtonGroup className="mt-3 mt-md-0 ms-0 ms-md-3">
                <Button
                  color={
                    searchParameters.stats_type == "" ? "primary" : "secondary"
                  }
                  onClick={() =>
                    setSearchParameters({ ...searchParameters, stats_type: "" })
                  }
                >
                  {container.localeManager.strings.all}
                </Button>
                <Button
                  color={
                    searchParameters.stats_type == "real_miles"
                      ? "primary"
                      : "secondary"
                  }
                  onClick={() =>
                    setSearchParameters({
                      ...searchParameters,
                      stats_type: "real_miles",
                    })
                  }
                >
                  {container.localeManager.strings.realMiles}
                </Button>
                <Button
                  color={
                    searchParameters.stats_type == "race_miles"
                      ? "primary"
                      : "secondary"
                  }
                  onClick={() =>
                    setSearchParameters({
                      ...searchParameters,
                      stats_type: "race_miles",
                    })
                  }
                >
                  {container.localeManager.strings.raceMiles}
                </Button>
              </ButtonGroup>
            </div>
            <Table
              responsive
              striped
              size="sm"
              className="table-row-dashed table-row-gray-300 align-middle gs-0"
              hover
            >
              <thead>
                <tr className="fw-bolder text-gray-700 bg-light">
                  <th className="ps-4">
                    {container.localeManager.strings.member}
                  </th>
                  <th>
                    {container.localeManager.strings.distance}
                    <OrderByButtons
                      container={container}
                      currentSortingDirection={
                        searchParameters.sortingDirection
                      }
                      currentSortingField={searchParameters.sortingField}
                      field="driven_distance"
                      onClick={changeSortingField}
                    ></OrderByButtons>
                  </th>
                  <th>
                    {container.localeManager.strings.mass}
                    <OrderByButtons
                      container={container}
                      currentSortingDirection={
                        searchParameters.sortingDirection
                      }
                      currentSortingField={searchParameters.sortingField}
                      field="cargo_mass"
                      onClick={changeSortingField}
                    ></OrderByButtons>
                  </th>
                  <th>
                    {container.localeManager.strings.revenue}
                    <OrderByButtons
                      container={container}
                      currentSortingDirection={
                        searchParameters.sortingDirection
                      }
                      currentSortingField={searchParameters.sortingField}
                      field="revenue"
                      onClick={changeSortingField}
                    ></OrderByButtons>
                  </th>
                  <th>
                    {container.localeManager.strings.jobs}
                    <OrderByButtons
                      container={container}
                      currentSortingDirection={
                        searchParameters.sortingDirection
                      }
                      currentSortingField={searchParameters.sortingField}
                      field="jobs"
                      onClick={changeSortingField}
                    ></OrderByButtons>
                  </th>
                  {showSalary() && (
                    <th>
                      {container.localeManager.strings.total_earned}
                      <OrderByButtons
                        container={container}
                        currentSortingDirection={
                          searchParameters.sortingDirection
                        }
                        currentSortingField={searchParameters.sortingField}
                        field="total_earned"
                        onClick={changeSortingField}
                      ></OrderByButtons>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {membersStats.members.map((m) => {
                  return (
                    <tr>
                      <td className="ps-4">
                        <Link to={`/user/${m.user_id}`}>{m.name}</Link>
                      </td>
                      <td
                        className={getCellBackground(
                          m.user_id,
                          "driven_distance_km"
                        )}
                      >
                        {UserDefinedUnits.convertDistance(
                          member,
                          null,
                          m.driven_distance_km
                        )}
                      </td>
                      <td
                        className={getCellBackground(m.user_id, "cargo_mass_t")}
                      >
                        {UserDefinedUnits.convertWeight(
                          member,
                          null,
                          m.cargo_mass_t
                        )}
                      </td>
                      <td className={getCellBackground(m.user_id, "revenue")}>
                        {container.localeManager
                          .numeral(m.revenue)
                          .format("0,0")}{" "}
                        {company.currency}
                      </td>
                      <td className={getCellBackground(m.user_id, "jobs")}>
                        {m.jobs}
                      </td>
                      {showSalary() && (
                        <td
                          className={getCellBackground(
                            m.user_id,
                            "total_earned"
                          )}
                        >
                          {container.localeManager
                            .numeral(m.total_earned)
                            .format()}{" "}
                          T¢
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default CompanyMemberStats;
