import { useEffect, useState } from "react";
import { Badge, Button, Card, CardBody, Col, Row, Spinner, Table } from "reactstrap";
import EnterpriseService from "../common/services/enterprise-service";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomBadge from "./ui/CustomBadge";
import truckyService from "../common/services/trucky-service";

const MyApplications = ({ container }) => {

    const es = new EnterpriseService();
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        truckyService.setDocumentTitle('My Applications');
    }, []);

    useEffect(() => {
        getApplications();
    }, []);

    const getApplications = async () => {
        setLoading(true);
        const result = await es.getUserApplications();
        setApplications(result);
        setLoading(false);
    };

    const getStatusBadge = (status) => {
        var color = 'warning';

        if (status == 'accepted')
            color = "success";
        if (status == 'rejected')
            color = 'danger';
        if (status == 'in_progress')
            color = 'info';
        if (status == 'retired')
            color = 'danger';

        return <CustomBadge size="sm" color={color}>{container.localeManager.strings[status]}</CustomBadge>;
    };

    const deleteApplication = async (application) => {
        await es.deleteUserApplication(application.id);
        getApplications();
    };

    return (
        <>
            <LoadingOverlay
                active={loading}
                spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
            >
                <Row noGutters>
                    <Col>
                        <Card className="h-100">
                            <CardBody>
                                <h5>{container.localeManager.strings.applications}</h5>
                                {!loading && applications.length == 0 &&
                                    <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed p-6 mb-3">
                                        {container.localeManager.strings.noNewApplicationsHere}
                                    </div>
                                }
                                {!loading && applications.length > 0 &&
                                    <Table className="table-row-dashed table-row-gray-300 align-middle gs-0 mt-3" striped size="sm" responsive hover>
                                        <thead>
                                            <tr className="fw-bolder text-gray-700 bg-light">
                                                <th scope="col" className="ps-4">#</th>
                                                <th scope="col">{container.localeManager.strings.date}</th>
                                                <th scope="col">{container.localeManager.strings.company}</th>
                                                <th scope="col">{container.localeManager.strings.status}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {applications.map(m => {
                                                return (
                                                    <tr>
                                                        <td className="ps-1">{m.id}</td>
                                                        <td>{container.localeManager.moment(m.created_at).format('l')}</td>
                                                        <td>{m.company != null ? m.company.name : ''}</td>
                                                        <td>{getStatusBadge(m.status)}</td>
                                                        <td className="text-end" style={{ width: 90 }}>
                                                            <Button disabled={m.status != 'pending'} size="sm" onClick={() => deleteApplication(m)} className="btn-icon w-30px h-30px ms-auto" color="active-light-danger"><FontAwesomeIcon className="me-0" icon="trash-alt"></FontAwesomeIcon></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <aside className="ad-column-placeholder">
                    </aside>
                </Row>
            </LoadingOverlay>
        </>
    )
};

export default MyApplications;