import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useNavigate } from 'react-router';
import { Button, Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import EnterpriseService from '../../common/services/enterprise-service';
import truckyService from '../../common/services/trucky-service';
import CustomBadge from '../ui/CustomBadge';
import ProvisioningStatusBadge from './ProvisioningStatusBadge';
import ServerStatusBadge from './ServerStatusBadge';

const DedicatedServerHostingList = ({ container }) => {

    const [servers, setServers] = useState([]);
    const navigate = useNavigate();
    const es = new EnterpriseService();
    const [subscriptionsChecked, setSubscriptionsChecked] = useState(false);
    const [dedicatedServerSubscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);

    useEffect(() => {
        truckyService.setDocumentTitle('Dedicated Servers');

        getData();
    }, []);

    useEffect(() => {
        if (subscriptionsChecked && dedicatedServerSubscription == null) {

        }
    }, [subscriptionsChecked, dedicatedServerSubscription]);

    const getData = async () => {
        setLoading(true);
        setLoadingSubscriptions(true);

        const servers = await es.getUserDedicatedServers();

        setServers(servers);

        const userSubscriptions = await es.getUserSubscriptions();

        if (userSubscriptions.subscriptions.length > 0) {

            const dedicatedServerSubscription = userSubscriptions.subscriptions.find(m => m.plan == 'dedicated-server-hosting' || m.plan == 'dedicated-server-hosting-yearly');

            if (dedicatedServerSubscription) {
                setSubscription(dedicatedServerSubscription);
            }
        }

        setLoading(false);
        setLoadingSubscriptions(false);

        if (userSubscriptions.subscriptions.length == 0 && servers.length == 0)
            navigate('/my/subscriptions');
    };

    const getProvisioningStatus = (status) => {
        let color = "danger";

        switch (status) {
            case 'not_provisioned':
                color = "danger";
                break;
            case "provisioned":
                color = "success";
                break;
            case "provisioning":
                color = "warning";
                break;
            case "creating":
                color = "warning";
                break;
        }

        return <CustomBadge color={color}>{container.localeManager.strings[status]}</CustomBadge>
    }

    return (
        <>
            <LoadingOverlay
                active={loading}
                spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
            >
                <Row className="gx-0">
                    <Col>
                        <Card>
                            <CardBody>
                                <div class="mb-3 d-flex justify-content-between">
                                    {window.overwolf &&
                                        <Button size="sm" onClick={() => navigate(-1)} color="secondary"><FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>{container.localeManager.strings.back}</Button>
                                    }
                                    {!loadingSubscriptions && dedicatedServerSubscription != null && servers.length > 0 &&
                                        <div>
                                            <Button disabled={dedicatedServerSubscription.quantity == servers.length} color='primary' size='sm' onClick={() => navigate('/hosting/server')}><FontAwesomeIcon icon="plus"></FontAwesomeIcon>{container.localeManager.strings.createANewServer} ({container.localeManager.strings.quota} {servers.length}/{dedicatedServerSubscription.quantity})</Button>
                                        </div>
                                    }
                                </div>
                                <h5 className='d-inline-block w-100 mb-5'>{container.localeManager.strings.dedicatedServers}</h5>
                                {dedicatedServerSubscription != null && !loadingSubscriptions && servers.length == 0 &&
                                    <div className='text-center my-5'>
                                        <Button color='primary' size='lg' onClick={() => navigate('/hosting/server')}><FontAwesomeIcon icon="plus"></FontAwesomeIcon>{container.localeManager.strings.createYourFirstServer}</Button>
                                    </div>
                                }
                                {servers.length > 0 &&
                                    <div className='grid cols-2'>
                                        {servers != null && servers.map(m => {
                                            return (
                                                <Card className='bg-light mb-3 border-primary border-hover' onClick={() => navigate(`/hosting/server/${m.id}`)}>
                                                    <CardBody className='text-center'>
                                                        <div className='fs-1'>{m.lobby_name} ({m.game.code})</div>
                                                        <div className='mt-5'>
                                                            <ProvisioningStatusBadge container={container} status={m.provisioning_status}></ProvisioningStatusBadge>
                                                            {m.provisioning_status == 'provisioned' &&
                                                                <ServerStatusBadge container={container} server={m} className="ms-3"></ServerStatusBadge>
                                                            }
                                                        </div>
                                                        {m.droplet_ip_address != null &&
                                                            <div className='mt-5'>
                                                                IP: {m.droplet_ip_address} - {m.region_id}
                                                            </div>
                                                        }
                                                        <div className='mt-5'>
                                                            <Button size="sm" onClick={() => navigate(`/hosting/server/${m.id}`)} color='primary'><FontAwesomeIcon className='mx-0' icon="pencil"></FontAwesomeIcon></Button>
                                                            {m.server_status_url != null &&
                                                                <Button size="sm" className='ms-3' onClick={() => truckyService.openUrlInDefaultBrowser(m.server_status_url)} color='primary'>API</Button>
                                                            }
                                                            {m.server_logs_url != null &&
                                                                <Button size="sm" className='ms-3' onClick={() => truckyService.openUrlInDefaultBrowser(m.server_logs_url)} color='primary'>Logs</Button>
                                                            }
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            )
                                        })}
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <aside className="ad-column-placeholder">
                    </aside>
                </Row>
            </LoadingOverlay>
        </>
    )
};

export default DedicatedServerHostingList;