import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Button, FormGroup, Input, InputGroup, Label, Spinner } from "reactstrap";
import LoadingOverlay from 'react-loading-overlay';
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import truckyService from "../../common/services/trucky-service";
import EnterpriseService from "../../common/services/enterprise-service";
import { random } from 'underscore';
import config from "../../config";
import VenatusAd from "../../ads/VenatusAd";
import VenatusLeaderboardAd from "../../ads/VenatusLeaderboardAd";
import DesktopTakeover from "../../ads/DesktopTakeover";

const Login = ({ container }) => {

    const navigate = useNavigate();

    const loginUrl = `${config.ENTERPRISE_URL}/authentication/login/steam?returnUrl=auth${encodeURIComponent(`?returnUrl=${window.location.pathname}`)}`;   

    const [mustCompleteRegistration, setMustCompleteRegistration] = useState(false);
    const [mustRegister, setMustRegister] = useState(true);
    const [registrationData, setRegistrationData] = useState({ name: '', email: '', password: '', password_confirmation: '', privacy_agreement: false });
    const [loading, setLoading] = useState(false);
    const { globalContext, setGlobalContext } = useContext(EnterpriseContext);
    const [imageId, setImageId] = useState(null);

    const es = new EnterpriseService();

    useEffect(() => {

        truckyService.setDocumentTitle('Login');

        const imageGroups = ['ATS', 'ETS2', 'TMP'];
        setImageId(`${imageGroups[random(0, 2)]}${random(1, 6)}`);

        changeMustCompleteRegistration();
    }, []);

    const changeMustCompleteRegistration = () => {

        if (!truckyService.getToken()) {
            setMustRegister(true);
        }
        else {
            setMustRegister(false);

            if (container.dataService.enterpriseService.member == null) {
                setMustCompleteRegistration(true);
            }
            else {

                if (container.dataService.enterpriseService.member.email == null) {
                    setMustCompleteRegistration(true);
                    setRegistrationData({ ...registrationData, name: container.dataService.enterpriseService.member.name });
                }
                else {

                    setGlobalContext({ member: container.dataService.enterpriseService.member, company: container.dataService.enterpriseService.company });
                    navigate(`/`, { state: { hideBack: true } });
                }
            }
        }
    };

    const completeRegistration = async () => {

        if (registrationData.privacy_agreement) {
            setLoading(true);

            const result = await es.completeRegistration(registrationData);

            if (result.error) {
                toast.error(result.message);
                setLoading(false);
            }
            else {
                await container.dataService.enterpriseService.loadEnterpriseMemberData(true);

                setLoading(false);

                setGlobalContext({ member: container.dataService.enterpriseService.member, company: container.dataService.enterpriseService.company });

                navigate(`/`, { state: { hideBack: true } });
            }
        }
        else {
            toast.error(container.localeManager.strings.pleaseAcceptPrivacy);
        }
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
        >
            <DesktopTakeover container={container} className="my-3"></DesktopTakeover>
            <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100" style={{ backgroundPosition: 'right', minHeight: '700px', backgroundImage: `url('/assets/backgrounds/${imageId}.webp')`}}>
                <div class="d-flex flex-column flex-lg-row-fluid">
                    <div class="d-flex flex-center flex-column flex-column-fluid">
                        <div className="w-lg-700px p-10 p-lg-15 mx-auto">
                            <div class="card card-body">
                                <div className="text-center">
                                    <img alt="Logo" style={{ width: 264, marginLeft: 'auto', marginRight: 'auto' }} src="/assets/aside-logo.png" className="py-9 mb-5" />
                                </div>
                                <div className="mb-10 text-center">
                                    <h1 className="text-dark mb-3">
                                        {mustRegister && container.localeManager.strings.signInToTrucky}
                                        {mustCompleteRegistration && container.localeManager.strings.completeRegistration}
                                    </h1>
                                </div>
                                {mustRegister &&
                                    <>
                                        <Button color="primary" href={loginUrl} className="fw-bolder w-100 mb-10"><FontAwesomeIcon icon={['fab', 'steam']}></FontAwesomeIcon>{container.localeManager.strings.continueWithSteam}</Button>
                                        <div className="my-3 text-center">
                                            <a href="https://truckyapp.com/platform-privacy-policy" target="_blank" className="me-5">{container.localeManager.strings.privacyPolicy}</a><a href="https://truckyapp.com/platform-terms-and-conditions" target="_blank">{container.localeManager.strings.terms}</a>
                                        </div>
                                    </>
                                }
                                {container.dataService.data.steamUser != null &&
                                    <div className="notice d-flex bg-light-success rounded border-warning border border-dashed p-6 mb-10">
                                        {container.localeManager.formatString(container.localeManager.strings.loggedInAs, container.dataService.data.steamUser.personaname)}
                                    </div>
                                }
                                {mustCompleteRegistration &&
                                    <>
                                        <FormGroup>
                                            <Label>{container.localeManager.strings.username}</Label>
                                            <Input type="text" className="form-control" value={registrationData.name} onChange={(e) => setRegistrationData({ ...registrationData, name: e.target.value })}></Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{container.localeManager.strings.email}</Label>
                                            <Input type="email" className="form-control" value={registrationData.email} onChange={(e) => setRegistrationData({ ...registrationData, email: e.target.value })}></Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <label class="form-check form-switch form-check-custom form-check-solid">
                                                <input onChange={(e) => setRegistrationData({ ...registrationData, privacy_agreement: e.target.checked })} class="form-check-input" type="checkbox" checked={registrationData.privacy_agreement} />
                                                <span class="form-check-label">{container.localeManager.strings.acceptPrivacy}</span>
                                            </label>
                                        </FormGroup>
                                        <div className="my-3 text-center">
                                            <a href="https://truckyapp.com/platform-privacy-policy" target="_blank" className="me-5">{container.localeManager.strings.privacyPolicy}</a><a href="https://truckyapp.com/platform-terms-and-conditions" target="_blank">{container.localeManager.strings.terms}</a>
                                        </div>
                                        <div className="text-center mt-10">
                                            <Button onClick={completeRegistration} color="primary" size="sm">
                                                {container.localeManager.strings.submit}
                                            </Button>
                                        </div>
                                    </>
                                }
                            </div>                           
                        </div>
                    </div>
                    <VenatusLeaderboardAd container={container} className="my-3"></VenatusLeaderboardAd>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </LoadingOverlay>
    )
};

export default Login;