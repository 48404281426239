import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody } from "reactstrap";
import truckyService from "../../common/services/trucky-service";
import { useEffect } from "react";

const PatreonConfirm = ({ container }) => {

    useEffect(() => {
        truckyService.setDocumentTitle('Patreon Link');
    }, []);

    const params = new URLSearchParams(window.location.search);

    return (
        <Card>
            <CardBody className="text-center">
                {params.get('member') == 'false' &&
                    <>
                        <FontAwesomeIcon icon="frown" size="5x" color="orange" className="mt-3 mb-3" />
                        <div>
                            <h2>{container.localeManager.strings.sorryYouAreNotAPatron}</h2>
                        </div>
                    </>
                }
                {params.get('member') == undefined &&
                    <>
                        <FontAwesomeIcon icon="check-circle" size="5x" color="green" className="mt-3 mb-3" />
                        <div>
                            <h2>{container.localeManager.strings.connectedWithPatreon}</h2>
                        </div>
                    </>
                }
            </CardBody>
        </Card>
    );
}

export default PatreonConfirm;