import { Alert } from "reactstrap";

const ErrorBox = ({ errors }) => {

    return (
        <Alert color="danger">
            <ul className="list-unstyled">
                {Object.keys(errors).map(m => {
                    return (
                        <li>{errors[m]}</li>)
                })}
            </ul>
        </Alert>
    );
}

export default ErrorBox;