import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Button, ButtonGroup, Card, CardBody, CardHeader, CardTitle, Col, ListGroup, ListGroupItem, Nav, NavItem, NavLink, Row, Spinner, Table } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import Loader from "../ui/Loader";
import { EnterpriseContext } from "./EnterpriseContext";
import EnterpriseNavBar from "./EnterpriseNavBar";
import MonthlyCompanyStatsInnerComponent from "./MonthlyCompanyStatsInnerComponent";
import VenatusLeaderboardAd from "../../ads/VenatusLeaderboardAd";
import DesktopTakeover from "../../ads/DesktopTakeover";
import BoxDynamicAd from "../../ads/BoxDynamicAd";
import truckyService from "../../common/services/trucky-service";

const MonthlyCompanyStats = ({ container, detailedMembersStats = false, detailedJobsStats = false }) => {

    const { globalContext } = useContext(EnterpriseContext);

    const company = globalContext.company;

    useEffect(() => {
        truckyService.setDocumentTitle('Company Stats');
    }, []);

    const [period, setPeriod] = useState('monthly');

    return (
        <>
            {!window.overwolf &&
                <DesktopTakeover container={container} className="my-3"></DesktopTakeover>
            }
            <Row noGutters className="" id="enterpriseContainer">
                <Col>
                    <Row noGutters>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h5>{container.localeManager.strings.statistics}</h5>
                                    <MonthlyCompanyStatsInnerComponent period={period} company={company} container={container} enableNavigation={true}
                                        detailedMembersStats={true} detailedJobsStats={true} showAggregatedStats={true} showAggregatedJobsChart={true}>
                                    </MonthlyCompanyStatsInnerComponent>
                                </CardBody>
                            </Card>
                        </Col>
                        {!container.dataService.data.enablePremiumFeature() &&
                            <aside className="ad-column-placeholder">
                                {!window.overwolf &&
                                    <BoxDynamicAd container={container} className="my-3" />
                                }
                            </aside>
                        }
                    </Row>
                </Col>
            </Row>
            {!window.overwolf &&
                <VenatusLeaderboardAd container={container} className="my-3"></VenatusLeaderboardAd>
            }
        </>
    )
}

export default MonthlyCompanyStats;