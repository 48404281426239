import { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import ServerStatusBadge from './ServerStatusBadge';

const CompanyServers = ({ container, company_id }) => {

    const [servers, setServers] = useState([]);
    const es = new EnterpriseService();
    const [serversStatus, setServersStatus] = useState({});

    useEffect(() => {
        getServers();
    }, []);

    const getServers = async () => {
        const result = await es.getCompanyServers(company_id);
        setServers(result);
    };

    return (
        <>
            {servers.map(m => {
                return (
                    <div class="border border-gray-300 border rounded min-w-125px py-3 px-4 my-3 mx-1 bg-light">
                        {m.lobby_name} ({m.game.code})
                        <br /><ServerStatusBadge container={container} server={m} size="sm" showPassword={true}></ServerStatusBadge>
                    </div>
                )
            })}
        </>
    );
};

export default CompanyServers;
