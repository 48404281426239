import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import classNames from "classnames";

const CompanyFeedItems = ({ container, items }) => {

    return (
        <>
            {items.map(m => {
                return (
                    <Card className={classNames({ 'bg-light': m.pinned, 'mb-3': true })}>
                        <CardBody>
                            <div className="">
                                <div className="mb-6">
                                    <Link to={`/feeds/${m.id}`} className="fw-bold text-dark mb-4 fs-4 lh-base text-hover-primary">
                                        {m.pinned &&
                                            <FontAwesomeIcon icon="thumbtack" className="text-premium"></FontAwesomeIcon>
                                        }{m.title}</Link>
                                    <div className="fw-semibold fs-6 mt-4 text-gray-600 text-dark">
                                        {m.summary}
                                    </div>
                                </div>
                                <div className="d-flex flex-stack flex-wrap">
                                    <div className="d-flex align-items-center pe-2">
                                        <div className="symbol symbol-35px symbol rounded me-3">
                                            <img src={m.author.avatar_url} />
                                        </div>
                                        <div className="fs-5 fw-bold">
                                            <Link to={`/user/${m.author.id}`} className="text-gray-700 text-hover-primary">{m.author.name}</Link>
                                            <span className="text-muted"> - {container.localeManager.moment(m.published_at).format('L')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                )
            })}
        </>
    )
}

export default CompanyFeedItems;