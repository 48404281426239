import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmDialog = ({ show, proceed, confirmation, options }) => (
    <Modal zIndex="9999999999" centered toggle={() => proceed(false)} isOpen={show}>
        <ModalBody>
            {confirmation}
        </ModalBody>
        <ModalFooter>
            <Button size="sm" color="danger" onClick={() => proceed(false)}>{options.container.localeManager.strings.cancel}</Button>
            <Button size="sm" color="success" onClick={() => proceed(true)}>{options.container.localeManager.strings.ok}</Button>
        </ModalFooter>
    </Modal>
)

ConfirmDialog.propTypes = {
    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ConfirmDialog);