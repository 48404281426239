import truckyService from "./trucky-service";
import Events from "../constants/events";
import config from "../../config";
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

//var wsUrl = 'wss://realtime.truckyapp.com';

//wsUrl = 'ws://localhost:5001';

export default class RealTimeService {
  constructor(es, container) {
    this.es = es;
    this.container = container;
  }

  connectToEcho() {
    if (truckyService.getToken()) {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: config.PUSHER_APP_KEY,
        wsHost: config.PUSHER_HOST,
        wsPort: config.PUSHER_PORT,
        wssPort: config.PUSHER_PORT,
        forceTLS: config.PUSHER_FORCE_TLS,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        authEndpoint: config.PUSHER_AUTH_URL,
        auth: {
          headers: {
            Accept: 'application/json',
            'x-access-token': truckyService.getToken()
          }
        },

      });

      truckyService.writeLog(`[ECHO] - Connected to ${config.PUSHER_HOST}`);

      if (this.es.member != null) {
        window.Echo.private('App.Models.Users.User.' + this.es.member.id)
          .notification(async (notification) => {
            truckyService.writeLog('[ECHO] Received new notification');
            truckyService.writeLog(JSON.stringify(notification));

            let localNotification = {
              id: notification.id,
              created_at: new Date(),
              data: {
                text: notification.text,
                route: notification.route
              }
            };

            this.container.dataService.data.notifications.push(localNotification);

            this.container.eventBus.trigger(Events.NOTIFICATIONS_RECEIVED, localNotification);
          })
          .listen('.achievement.processing.completed', () => {
            this.container.eventBus.trigger(Events.ACHIEVEMENTS_PROCESSING_COMPLETE, {});
          })
          .listen('.message.received', (data) => {
            this.container.eventBus.trigger(Events.MESSAGE_RECEIVED, data);
          })
          .listen('thread.started', (data) => {
            //console.log(data);
            this.container.eventBus.trigger(Events.THREAD_STARTED, data);
          })
          .listen('.external-auth.linked', (data) => {
            this.container.eventBus.trigger(Events.SOCIAL_ACCOUNT_LINKED, data);
          })
          .error(console.error);
        /*.listenToAll((event, data) => {
          console.log(event, data)
        });*/

        this.joinCompanyPresenceChannel();
      }
    }
  }

  getPresenceCompanyChannelName() {
    return 'Presence.App.Models.Companies.Company.' + this.es.member.company_id;
  }

  joinCompanyPresenceChannel() {
    if (window.Echo != undefined && window.Echo != null && this.es.member.company_id != null) {

      truckyService.writeLog('[ECHO] Joining Company Presence Channel');

      window.Echo.join(this.getPresenceCompanyChannelName())
        .subscribed(() => {
          truckyService.writeLog('[ECHO] Company Presence Channel JOINED');

          /*let state = {
            game: this.container.dataService.data.game,
            job: null
          };

          if (this.container.dataService.data.currentJob != null) {
            state.job = this.buildJobPresenceData(this.container.dataService.data.currentJob);
          }

          truckyService.writeLog(`Updating Company Presence: ${JSON.stringify(state)}`);

          this.es.updateUserStatus(this.es.member.company_id, state);
          */
        })
        .here((users) => {

          truckyService.writeLog('[ECHO] Received current users in company channel');
          truckyService.writeLog(JSON.stringify(users));

          this.es.getCompanyPresenceStatus(this.es.member.company_id).then((result) => {
            if (result != null) {
              for (const user of users) {
                let userPresenceState = result.find(m => m.id = user.id);

                if (userPresenceState) {
                  user.state = userPresenceState;
                }
              }

              this.container.dataService.data.companyPresenceChannelUsers = users;

              this.container.eventBus.trigger(Events.PRESENCE_USERS_UPDATED, users);
            }
          });
        })
        .joining((user) => {

          truckyService.writeLog(`[ECHO] ${user.name} joined Company Presence Channel`);

          this.container.dataService.data.companyPresenceChannelUsers.push(user);

          this.container.eventBus.trigger(Events.PRESENCE_USER_JOINED, user);
        })
        /*.listen('.job.started', (data) => {
          //console.log(data);
          this.container.eventBus.trigger(Events.PRESENCE_STATUS_UPDATE, data);
        })
        .listen('.job.completed', (data) => {
          this.container.eventBus.trigger(Events.PRESENCE_STATUS_UPDATE, data);
        })
        .listen('.job.cancelled', (data) => {
          this.container.eventBus.trigger(Events.PRESENCE_STATUS_UPDATE, data);
        })
        */
        .leaving((user) => {
          //truckyService.writeLog('[ECHO] Left Company Presence Channel');

          truckyService.writeLog(`[ECHO] ${user.name} left Company Presence Channel`);

          this.container.dataService.data.companyPresenceChannelUsers = this.container.dataService.data.companyPresenceChannelUsers.filter(m => m.id != user.id);

          this.container.eventBus.trigger(Events.PRESENCE_USER_LEFT, user);
        })
        .listenForWhisper('user_location', (data) => {
          this.container.eventBus.trigger(Events.USER_LOCATION_UPDATED, data);
        })
        .listen('.status.update', (data) => {

          truckyService.writeLog(`Received status update: ${JSON.stringify(data)}`);

          this.container.dataService.data.companyPresenceChannelUsers[this.container.dataService.data.companyPresenceChannelUsers.findIndex(m => m.id == data.id)] = { ...data };

          this.container.eventBus.trigger(Events.PRESENCE_STATUS_UPDATE, data);
        })
        /*.listenToAll((event, data) => {
          console.log(event, data);
        })*/
        .error((error) => {
          console.error(error);
        });
    }
  }

  buildJobPresenceData(job) {
    return {
      source_city: job.source_city,
      destination_city: job.destination_city,
      cargo: job.cargo,
      cargo_mass: job.cargo_mass,
      job_market: job.job_market,
      planned_distance: job.planned_distance
    };
  }

  updatePresenceCurrentJob(job = null) {
    if (window.Echo && this.es.member != null && this.es.member.company_id != null) {

      let state = {
        game: this.container.dataService.data.game,
        job: null
      };

      if (job != null) {
        state.job = this.buildJobPresenceData(job)
      }

      truckyService.writeLog(`Updating Company Presence: ${JSON.stringify(state)}`);

      this.es.updateUserStatus(this.es.member.company_id, state);
    }
  }

  updatePresence(gameIsRunning) {
    if (window.Echo && this.es.member != null && this.es.member.company_id != null) {

      let state = {
        game: gameIsRunning ? this.container.dataService.data.game : null,
        job: null
      };

      truckyService.writeLog(`Updating Company Presence: ${JSON.stringify(state)}`);

      this.es.updateUserStatus(this.es.member.company_id, state);
    }
  }

  leaveCompanyPresenceChannel() {
    if (window.Echo && this.es.member != null && this.es.member.company_id != null) {
      truckyService.writeLog(`[ECHO] Leaving Company Presence Channel`);

      window.Echo.leave(this.getPresenceCompanyChannelName());

      this.container.eventBus.trigger(Events.PRESENCE_USER_LEFT, this.es.member);

      this.es.deleteUserPresence(this.es.member.company_id);
    }
  }

  shareRealtimeLocation(telemetry) {
    let data = {
      game: this.container.dataService.data.game,
      server: this.container.dataService.data.mpServer,
      user: {
        id: this.es.member.id,
        name: this.es.member.name,
        avatar_url: this.es.member.avatar_url,
      },
      company: null,
      location: {
        x: telemetry.truck.position.x,
        y: telemetry.truck.position.y,
        z: telemetry.truck.position.z
      },
      vehicle: {
        brand: telemetry.truck.brand,
        model: telemetry.truck.name,
        speed: telemetry.truck.speed,
        odometer: telemetry.truck.odometer,
        total_damage: telemetry.total_damage,
      },
      job: null,
      navigation: null
    };

    if (telemetry.has_job) {
      data.job = {
        source_city: telemetry.job.source_city,
        destination_city: telemetry.job.destination_city,
        source_company: telemetry.job.source_company,
        destination_company: telemetry.job.destination_company,
        cargo: telemetry.job.cargo,
        mass: telemetry.job.cargo_mass
      };
    }

    if (telemetry.navigation != null && telemetry.navigation.hasPath) {
      data.navigation = {
        distance: telemetry.navigation.distance,
        arrival_time: telemetry.navigation.real_life_arrival_time
      };
    }

    if (this.es.company != null) {
      data.company = {
        id: this.es.company.id,
        name: this.es.company.name
      };
    }

    window.echo.channel(this.getPresenceCompanyChannelName())
      .whisper('user_location', data);
  }

  disconnect() {
    window.Echo.disconnect();
  }
}