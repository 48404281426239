
import config from "../../config";
import truckyService from "./trucky-service";

const fetch = require("node-fetch");

//var apiUrl = 'https://api.truckyapp.com';

//if (config.ENV == 'development')
//  apiUrl = 'http://localhost:5000';

async function executeRequest(url, method = 'GET', data = null, auth = false, token = '') {
  try {
    var fetchInit = {
      headers: {
        "content-type": "application/json",
        "User-Agent": "Trucky Overlay"
      },
      method: method,
      mode: 'cors'
    };

    if (auth) {
      fetchInit.headers["x-access-token"] = localStorage.token;
    }

    if (method == "POST" && data != null) {
      fetchInit.body = JSON.stringify(data)
    }

    var response = await fetch(config.API_URL + url, fetchInit);
    return await response.json();
  }
  catch (ex) {
    truckyService.writeLog(`Error executing request to: ${config.API_URL + url}`);
    console.error(ex);
    throw ex;
  }
}

async function getRandomGalleryItem() {
  var json = await executeRequest(
    "/v2/wot/gallery/editorsPick"
  );
  var random =
    json.response.gallery[
    Math.floor(Math.random() * json.response.gallery.length)
    ];

  return random;
}

async function getTruckersMPID(steamID) {
  var json = await executeRequest("/v2/truckersmp/player?playerID=" + steamID);

  return json.response.response.id;
}

async function getTruckersMPUser(steamID) {
  var json = await executeRequest("/v2/truckersmp/player?playerID=" + steamID);

  return json.response.response;
}

async function getSteamUser(steamID) {
  var json = await executeRequest("/v2/steam/getPlayerSummaries?steamid=" + steamID);

  return json.response.playerInfo;
}

async function game_time() {
  var response = await executeRequest('/v2/truckersmp/time');
  return response;
}

async function getServersRaw() {
  var json = await executeRequest("/v2/truckersmp/servers?_=" + (new Date).getTime());

  return json.response;
}

async function getServers() {
  var json = await executeRequest("/v2/truckersmp/servers?_=" + (new Date).getTime());

  return json.response.servers;
}

async function getMapServers() {
  var json = await executeRequest("/v2/map/servers?_=" + (new Date).getTime());

  return json.response;
}

async function getFriends(steamID) {
  var json = await executeRequest('/v2/steam/getFriendsData?steamid=' + steamID + '&onlineState=true&_=' + (new Date).getTime());

  return json.response.filter(f => f.onlineStatus != undefined && f.onlineStatus.online == true);
}

async function getAllFriends(steamID) {
  var json = await executeRequest('/v2/steam/getFriendsData?steamid=' + steamID);

  return json.response.filter(f => f.truckersMPUser != undefined && f.truckersMPUser != null);
}

async function getLanguages() {
  var json = await executeRequest('/v2/trucky/localizations/languages');
  return json;
}

async function getTranslations() {
  var json = await executeRequest('/v2/trucky/localizations/translations');
  return json;
}

async function getTranslationsByLanguage(language) {
  var json = await executeRequest(`/v2/trucky/localizations/translations/language/${language}`);
  return json;
}

async function getAlerts(server, range) {
  var json = await executeRequest(
    "/v2/map/alerts/overlay/area?server=" +
    server +
    "&x1=" +
    range.x1 +
    "&y1=" +
    range.y1 +
    "&x2=" +
    range.x2 +
    "&y2=" +
    range.y2 +
    "&_=" + (new Date).getTime()
  );
  return json;
}

async function getAllTrafficData(game, server) {
  var json = await executeRequest(
    "/v2/traffic?game=" +
    game +
    "&server=" +
    server +
    "&_=" + (new Date).getTime()
  );
  return json;
}

async function getTopTraffic(game, server) {
  var json = await executeRequest(
    "/v2/traffic/top?game=" +
    game +
    "&server=" +
    server +
    "&_=" + (new Date).getTime()
  );

  return json;
}

async function getAllAreas(game) {
  var json = await executeRequest(
    "/v2/map/allAreas?game=" +
    game +
    "&_=" + (new Date).getTime()
  );
  return json;
}

async function sendAlert(reportData, token) {
  var data = await executeRequest(
    "/v2/auth/traffic/alerts/overlay/create",
    'POST', reportData, true, token
  );
  return data;
}

async function getRadios() {
  var json = await executeRequest('/v2/radios');
  return json;
}

async function getNowPlaying(id) {
  var json = await executeRequest('/v2/radios/' + id + '/playing');
  return json;
}

async function trackLocation(data, token) {
  var data = await executeRequest(
    "/v2/auth/locationHistory/create",
    'POST', data, true, token
  );
  return data;
}

async function createConvoy(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/convoy/create",
    'POST', data, true, token
  );
  return data;
}

async function getConvoy(id) {
  var data = await executeRequest(
    "/v2/convoymanagement/convoy/get?id=" + id
  );
  return data;
}

async function getConvoyRoutes(id) {
  var data = await executeRequest(
    "/v2/convoymanagement/convoy/getRoutes?id=" + id
  );
  return data;
}

async function getConvoyRoute(id) {
  var data = await executeRequest(
    "/v2/convoymanagement/route/get?id=" + id
  );
  return data;
}

async function getRouteCheckpoints(id) {
  var data = await executeRequest(
    "/v2/convoymanagement/route/checkpoints?id=" + id
  );
  return data;
}

async function getConvoysByUser(token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/convoy/getByUser", 'GET', null, true, token
  );
  return data;
}

async function updateConvoy(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/convoy/update",
    'POST', data, true, token
  );
  return data;
}

async function deleteConvoy(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/convoy/delete",
    'POST', data, true, token
  );
  return data;
}

async function createRoute(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/route/create",
    'POST', data, true, token
  );
  return data;
}

async function updateRoute(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/route/update",
    'POST', data, true, token
  );
  return data;
}

async function deleteRoute(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/route/delete",
    'POST', data, true, token
  );
  return data;
}

async function createCheckpoint(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/checkpoint/create",
    'POST', data, true, token
  );
  return data;
}

async function updateCheckpoint(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/checkpoint/update",
    'POST', data, true, token
  );
  return data;
}


async function deleteCheckpoint(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/checkpoint/delete",
    'POST', data, true, token
  );
  return data;
}

async function grantPermission(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/convoy/permissions/grant",
    'POST', data, true, token
  );
  return data;
}

async function removePermission(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/convoy/permissions/remove",
    'POST', data, true, token
  );
  return data;
}

async function updateConvoyScripts(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/convoy/scripts",
    'POST', data, true, token
  );
  return data;
}

async function startConvoy(id, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/convoy/start?id=" + id,
    'GET', data, true, token
  );
  return data;
}

async function stopConvoy(id, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/convoy/start?id=" + id,
    'GET', data, true, token
  );
  return data;
}

async function updateCovoyScripts(data, token) {
  var data = await executeRequest(
    "/v2/auth/convoymanagement/convoy/scripts",
    'POST', data, true, token
  );
  return data;
}

async function getPatreonMember(token) {
  var data = await executeRequest(
    "/v2/auth/patreon/getMemberBySteamID",
    'POST', {}, true, token
  );
  return data;
}

async function uploadGameStats(data) {
  var postData = {
    data: data.gameStats,
    gameInfo: {
      isMultiplayer: data.isMultiplayer == '1' ? true : false,
      game: data.game,
      mpServer: data.mpServer
    }
  };

  truckyService.writeLog(`Game stats to upload: ${JSON.stringify(postData)}`);

  var savedSessionResponse = await executeRequest(
    "/v2/auth/gameStats/upload",
    'POST', postData, true
  );

  return savedSessionResponse;
}

async function getAchievementsCompletion() {
  var data = await executeRequest(
    "/v2/auth/achievements/completion",
    'GET', {}, true
  );
  return data;
}

async function getSteamUserLevelInfo() {
  var data = await executeRequest(
    "/v2/auth/gameStats/levelinfo",
    'GET', {}, true
  );
  return data;
}

async function getCompleteGameStats(steamID) {
  var data = await executeRequest(
    `/v2/trucky/gameStats?steamID=${steamID}`,
    'GET', {}, true
  );
  return data;
}

async function getGameStatsStatistics(steamID, period = '', game = '', mode = '') {
  var data = await executeRequest(
    `/v2/trucky/gameStats/global?steamID=${steamID}&period=${period}&game=${game}&mode=${mode}`,
    'GET', {}, true
  );
  return data;
}

async function getGlobalStatisticsPerGame(steamID) {
  var data = await executeRequest(
    `/v2/trucky/gameStats/globalPerGame?steamID=${steamID}`,
    'GET', {}, true
  );
  return data;
}

async function getRoute(city, destination) {
  var data = await executeRequest(
    `/v3/map/routing/getRoute?city=${city}&destination=${destination}`,
    'GET', {}, false
  );
  return data;
}

async function uploadRoutes(routes) {
  var data = await executeRequest(
    `/v3/map/routing/uploadRoutes`,
    'POST', routes, false
  );
  return data;
}

async function createSharedSaveEdit(data) {
  var data = await executeRequest(
    `/v2/auth/saveEdits/create`,
    'POST', data, true
  );
  return data;
}

async function deleteSharedSaveEdit(id) {
  var data = await executeRequest(
    `/v2/auth/saveEdits/delete?uniqueID=${id}`,
    'GET', null, true
  );
  return data;
}

async function getSharedSaveEdit(uniqueID) {
  var data = await executeRequest(
    `/v2/trucky/saveEdits/get?uniqueID=${uniqueID}`,
    'GET', {}, false
  );
  return data;
}

async function getLeaderboards(period, game = '', mode = '', username = '', country = '', page = 1, limit = 50) {
  var response = await executeRequest(`/v3/trucky/leaderboards?period=${period}&game=${game}&mode=${mode}&username=${username}&country=${country}&page=${page}&limit=${limit}`, 'GET', {}, false);
  return response;
}

async function getSteamID() {
  var response = await executeRequest(`/v2/auth/getSteamID`, 'GET', {}, true);
  return response;
}

async function getLatestSharedSaveEdits(game, type, company_id = -1) {
  var response = await executeRequest(`/v2/trucky/sharedSaveEdits/latest?game=${game}&type=${type}&company_id=${company_id}`, 'GET', {}, false);
  return response;
}

async function getLatestSharedSaveEditsByUser(game, type) {
  var response = await executeRequest(`/v2/auth/saveEdits/latest?game=${game}&type=${type}`, 'GET', {}, true);
  return response;
}

async function getCompanyPlacements(parameters) {
  var response = await executeRequest(`/v2/trucky/companyPlacements`, 'POST', parameters, false);
  return response;
}

async function getRoutesCount() {
  var data = await executeRequest(
    `/v3/map/routing/count`,
    'GET', {}, false
  );
  return data;
}

async function getRoutesByDistance(city, min, max) {
  var data = await executeRequest(
    `/v3/map/routing/getRoutesByDistance?city=${city}&min=${min}&max=${max}`,
    'GET', {}, false
  );
  return data;
}

async function createTransportSystemJob(data) {
  var data = await executeRequest(
    `/v2/auth/transportSystem/create`,
    'POST', data, true
  );
  return data;
}

async function updateTransportSystemJob(data) {
  var data = await executeRequest(
    `/v2/auth/transportSystem/update`,
    'POST', data, true
  );
  return data;
}

async function getMyTransportSystemJobs() {
  var data = await executeRequest(
    `/v2/auth/transportSystem/jobs/my`,
    'GET', {}, true
  );
  return data;
}

async function getGameDataCompanies() {
  var data = await executeRequest(
    `/v2/gameData/companies/all`,
    'GET', {}, true
  );
  return data;
}

async function getGameDataCargoes() {
  var data = await executeRequest(
    `/v2/gameData/cargoes`,
    'GET', {}, true
  );
  return data;
}

async function getGameDataCities() {
  var data = await executeRequest(
    `/v2/gameData/cities/all`,
    'GET', {}, true
  );
  return data;
}

async function getCountries(game, mod) {
  var response = await executeRequest('/v2/map/countries?game=' + game + '&mod=' + mod, 'GET');
  return response;
}

async function getAllLayers(game, mod) {
  var response = await executeRequest('/v2/map/layers?game=' + game + '&mod=' + mod, 'GET');
  return response;
}

async function isOnline(playerID) {
  var response = await executeRequest('/v3/map/online?playerID=' + playerID);
  return response;
}

export default {
  getCountries,
  getAllLayers,
  getRandomGalleryItem,
  getTruckersMPID,
  getServers,
  getMapServers,
  getFriends,
  getLanguages,
  getTranslations,
  getAlerts,
  getAllTrafficData,
  getTopTraffic,
  getAllAreas,
  sendAlert,
  getRadios,
  getNowPlaying,
  trackLocation,
  createConvoy,
  updateConvoy,
  deleteConvoy,
  createRoute,
  updateRoute,
  deleteRoute,
  createCheckpoint,
  deleteCheckpoint,
  getConvoy,
  getConvoysByUser,
  getAllFriends,
  getRouteCheckpoints,
  getConvoyRoutes,
  grantPermission,
  removePermission,
  getConvoyRoute,
  getSteamUser,
  getTruckersMPUser,
  updateConvoyScripts,
  startConvoy,
  stopConvoy,
  updateCovoyScripts,
  updateCheckpoint,
  getPatreonMember,
  uploadGameStats,
  getAchievementsCompletion,
  getSteamUserLevelInfo,
  getCompleteGameStats,
  getGameStatsStatistics,
  getGlobalStatisticsPerGame,
  getRoute,
  uploadRoutes,
  createSharedSaveEdit,
  getSharedSaveEdit,
  getLeaderboards,
  getSteamID,
  getLatestSharedSaveEdits,
  getLatestSharedSaveEditsByUser,
  getCompanyPlacements,
  getRoutesCount,
  getRoutesByDistance,
  getTranslationsByLanguage,
  createTransportSystemJob,
  updateTransportSystemJob,
  getMyTransportSystemJobs,
  getGameDataCompanies,
  getGameDataCargoes,
  getGameDataCities,
  deleteSharedSaveEdit,
  game_time,
  getServersRaw,
  isOnline
};
