import { Card, CardBody, CardText, Col, Row } from "reactstrap";
import { getCSSVariableValue } from "../../common/plugins/metronic/_utils";
import PieChart from "../charts/PieChart";
import SingleStackedBarChart from "../charts/SingleStackedBarChart";

const AggregatedStatsCharts = ({ container, stats }) => {

    const renderJobsTypePie = () => {

        return (
            <PieChart title={container.localeManager.strings.jobsPerMarketType} series={[stats.company_trailer_deliveries, stats.owned_trailer_deliveries, stats.wotr_contracts]}
                labels={[container.localeManager.strings.freight_market, container.localeManager.strings.cargo_market, "WOTR"]}></PieChart>
        )
    };

    const renderStatsTypePie = () => {

        return (
            <PieChart title={container.localeManager.strings.jobPerSpeedStats} series={[stats.real_stats_jobs, stats.race_stats_jobs]}
                labels={[container.localeManager.strings.realMiles, container.localeManager.strings.raceMiles]}></PieChart>
        )
    };

    const renderLateBar = () => {

        const value = parseInt((stats.total_jobs_late / stats.jobs_delivered) * 100);

        return (
            <SingleStackedBarChart title={container.localeManager.strings.lateOrOnTimeJobs} category={container.localeManager.strings.onTime} series={[
                {
                    name: container.localeManager.strings.onTime,
                    data: [100 - value],
                    color: getCSSVariableValue('--bs-success')
                },
                {
                    name: container.localeManager.strings.late,
                    data: [value],
                    color: getCSSVariableValue('--bs-danger')
                }
            ]}></SingleStackedBarChart>);
    }

    const renderAutoParkBar = () => {

        const value = parseInt((stats.total_jobs_manual_park / stats.jobs_delivered) * 100);

        return (
            <SingleStackedBarChart title={container.localeManager.strings.parkingMode} category={container.localeManager.strings.parkingType} series={[
                {
                    name: container.localeManager.strings.auto,
                    data: [100 - value],
                    color: getCSSVariableValue('--bs-danger')
                },
                {
                    name: container.localeManager.strings.manual,
                    data: [value],
                    color: getCSSVariableValue('--bs-success')
                }
            ]}></SingleStackedBarChart>);
    }

    const renderAutoLoadBar = () => {

        const value = parseInt((stats.total_jobs_manual_load / stats.owned_trailer_deliveries) * 100);

        return (
            <SingleStackedBarChart title={container.localeManager.strings.cargoLoadingMode}category={container.localeManager.strings.cargoLoad} series={[
                {
                    name: container.localeManager.strings.auto,
                    data: [100 - value],
                    color: getCSSVariableValue('--bs-danger')
                },
                {
                    name: container.localeManager.strings.manual,
                    data: [value],
                    color: getCSSVariableValue('--bs-success')
                }
            ]}></SingleStackedBarChart>);
    }

    const renderNoDamageJobs = () => {

        const value = parseInt((stats.no_damage_jobs / stats.jobs_delivered) * 100);

        return (
            <SingleStackedBarChart title={container.localeManager.strings.jobsWithDamage} category={container.localeManager.strings.damageOnJobs} series={[
                {
                    name: container.localeManager.strings.damageLess5,
                    data: [value],
                    color: getCSSVariableValue('--bs-warning')
                },
                {
                    name: container.localeManager.strings.damageMore5,
                    data: [100 - value],
                    color: getCSSVariableValue('--bs-danger')
                }
            ]}></SingleStackedBarChart>);
    }

    return (
        <>
            <Card body className="bordered mx-0 mt-3">
                <Row className="gx-0">
                    <Col sm={6}>
                        {renderLateBar()}
                    </Col>
                    <Col sm={6}>
                        {renderAutoLoadBar()}
                    </Col>
                </Row>
                <Row className="gx-0">
                    <Col sm={6}>
                        {renderAutoParkBar()}
                    </Col>
                    <Col sm={6}>
                        {renderNoDamageJobs()}
                    </Col>
                </Row>
            </Card>
            <Row className="gx-0 mt-3">
                <Col sm={6}>
                    <Card body className="bordered ms-0">
                        <CardBody className="d-flex justify-content-center">
                            {renderJobsTypePie()}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={6}>
                    <Card body className="bordered me-0">
                        <CardBody className="d-flex justify-content-center">
                            {renderStatsTypePie()}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

export default AggregatedStatsCharts;