import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, CardBody } from "reactstrap"

const MilesTypeExplain = ({ container }) => {

    return (
        <Card className="bg-light mt-3">
            <CardBody>
                <div className="fs-4">{container.localeManager.strings.milesStats}</div>
                <div>
                    <FontAwesomeIcon icon="tachometer-alt" className="text-success"></FontAwesomeIcon> {container.localeManager.strings.realMilesExplain}
                    <br />
                    <FontAwesomeIcon icon="tachometer-alt" className="text-danger"></FontAwesomeIcon> {container.localeManager.strings.raceMilesExplain}
                    <br />
                    <FontAwesomeIcon icon="tachometer-alt" className="text-muted"></FontAwesomeIcon> {container.localeManager.strings.invalidMilesExplain}
                </div>
            </CardBody>
        </Card>
    )
};

export default MilesTypeExplain;