const SingleCompanyAward = ({ container, award }) => {

    const getPeriodDescription = (month, year) => {
        const date = container.localeManager.moment().month(month - 1).year(year);
        return date.format("MMMM YYYY");
    };

    return (
        <div>
            <div className="d-flex align-items-center">
                <div>
                    <img src={award.icon} className="avatar rounded h-50px min-w-50px" />
                </div>
                <div>
                    <b>{container.localeManager.strings[`award_${award.stats_type}`]}</b>
                    <br />
                    {getPeriodDescription(award.month, award.year)} - <b>{award.game.code}</b>
                </div>
            </div>
        </div>
    )
}

export default SingleCompanyAward;