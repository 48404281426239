import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Alert, Badge, Button, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import CustomBadge from "../ui/CustomBadge";
import { Link } from "react-router-dom";

const MaintenanceAlerts = ({ container, company }) => {

    const es = new EnterpriseService();
    const [alerts, setAlerts] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        getMaintenanceAlerts();
    }, []);

    const getMaintenanceAlerts = async () => {
        const result = await es.getMaintenanceAlerts(company);
        setAlerts(result);
    };

    return (
        <>
            {alerts != null && (alerts.need_maintenance > 0 || alerts.under_maintenance > 0) &&
                <div className="notice d-flex bg-light rounded border-warning border border-dashed p-6 mt-3 mx-1 flex-column">
                    <div className="fs-6 mb-2"><FontAwesomeIcon icon="wrench" className="me-1"></FontAwesomeIcon>{container.localeManager.strings.maintenanceAlerts}</div>
                    {alerts.need_maintenance > 0 &&
                        <div className="d-flex justify-content-between w-100">
                            <Link to="/vehicles/need_maintenance"><span className="text-warning">{container.localeManager.strings.need_maintenance}</span></Link>
                            <CustomBadge color="warning" square={true} size="sm">{alerts.need_maintenance}</CustomBadge>
                        </div>
                    }
                    {alerts.under_maintenance > 0 &&
                        <div className="d-flex justify-content-between w-100 mt-1">
                            <Link to="/vehicles/under_maintenance"><span className="text-danger">{container.localeManager.strings.under_maintenance}</span></Link>
                            <CustomBadge color="danger" square={true} size="sm">{alerts.under_maintenance}</CustomBadge>
                        </div>
                    }
                </div>
            }
        </>
    );
};

export default MaintenanceAlerts;