import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Button, Col, FormText, InputGroup, Row } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import AsyncSelect from 'react-select/async';
import reactSelectCustomStyles from "../../common/plugins/reactSelectStyles";
import { EnterpriseContext } from "../enterprise/EnterpriseContext";
import CustomBadge from "../ui/CustomBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Events from "../../common/constants/events";
import { Link } from "react-router-dom";
import truckyService from "../../common/services/trucky-service";

const Chat = ({ container }) => {

    const { globalContext } = useContext(EnterpriseContext);

    const [threads, setThreads] = useState([]);
    const [currentThread, setCurrentThread] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const es = new EnterpriseService();
    const [participants, setParticipants] = useState([]);
    const [messageText, setMessageText] = useState('');
    const [newMessage, setNewMessage] = useState(null);
    const navigate = useNavigate();

    let query = useQuery();

    const { id } = useParams();

    // A custom hook that builds on useLocation to parse
    // the query string for you.
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    useEffect(() => {
        truckyService.setDocumentTitle('Messenger');
    }, []);

    useEffect(() => {
        resizeThreadsContainer();

        window.addEventListener('resize', resizeChatBody);

        getThreads();

        if (id) {
            getThread(id);
        }

        if (query.has("user_id")) {
            getUser();
        }

        container.eventBus.addListener(Events.MESSAGE_RECEIVED, setNewMessage);
    }, []);

    useEffect(() => {

        if (currentThread != null) {
            resizeChatBody();

            document.getElementById('messageBox').focus();
        }

    }, [currentThread]);

    useEffect(() => {
        var objDiv = document.getElementById("chat_body");
        if (objDiv != null) {
            objDiv.scroll({ top: objDiv.scrollHeight, behavior: "auto" });
        }
    }, [messages.length]);

    useEffect(() => {
        if (newMessage != null) {
            if (currentThread != null && newMessage.thread.id == currentThread.id) {
                addNewMessage(newMessage.message);
            }
        }
    }, [newMessage]);

    const getUser = async () => {
        const es = new EnterpriseService();
        const user = await es.getUser(query.get("user_id"));
        onSelectMember(user);
    }

    const resizeChatBody = () => {
        if (document.getElementById('chat_body') != null) {
            document.getElementById('chat_body').setAttribute('style', `height: ${window.innerHeight - 234}px`);
        }
    }

    const resizeThreadsContainer = () => {
        if (document.getElementById('threads') != null) {
            document.getElementById('threads').setAttribute('style', `height: ${window.innerHeight - 160}px`);
        }
    }

    const getThreads = async () => {
        const result = await es.getPersonalThreads();
        setThreads(result);
    }

    const getThread = async (id) => {
        setMessages([]);
        const result = await es.getThread(id);
        setCurrentThread(result);
        setMessages(result.messages);
        container.eventBus.trigger(Events.UNREAD_MESSAGES_COUNT_UPDATE, result.unread_messages_count);
    }

    const searchMembers = async (inputValue) => {
        if (inputValue.length > 3) {
            const result = await es.getMembers(globalContext.company, { page: 1, perPage: 1000 }, { name: inputValue });
            return result.data.filter(m => m.id != globalContext.member.id);
        }
    };

    const onSelectMember = (selected) => {
        setParticipants([...participants, selected]);
    }

    const removeParticipant = (id) => {
        setParticipants(participants.filter(m => m.id != id));
    }

    const onMessageBoxKeyPressed = (event) => {
        if (event.key == 'Enter' && messageText != '') {
            sendMessage();
            return false;
        }
    }

    const renderMessageBox = () => {
        return (
            <div class="card-footer pt-4" id="kt_chat_messenger_footer">
                <textarea id="messageBox" onKeyUp={onMessageBoxKeyPressed} value={messageText} onChange={(e) => setMessageText(e.target.value)}
                    class="form-control form-control-flush bg-light mb-3" rows="1" data-kt-element="input" placeholder="Type a message">
                </textarea>
            </div>
        );
    }

    const sendMessage = async () => {
        setLoading(true);

        let result;

        if (currentThread == null) {
            result = await es.createThread({
                subject: 'a new message',
                message: messageText,
                recipients: participants.map(m => m.id)
            });

            if (result.success) {
                const newThreads = [...threads, result.thread];
                setThreads(newThreads);
                getThread(result.thread.id);
            }
        }
        else {
            result = await es.replyToThread(currentThread.id, {
                message: messageText,
                recipients: participants.map(m => m.id)
            });

            if (result.success) {
                addNewMessage(result.message);
            }
        }

        if (result.success) {
            setMessageText('');
        }

        setLoading(false);
    }

    const getParticipants = (thread) => {
        let usersNoCurrent = thread.users.filter(m => m.id != globalContext.member.id);

        if (usersNoCurrent.length > 1) {
            return (
                <>
                    <div onClick={() => getThread(thread.id)} class="symbol-group symbol-hover">
                        {usersNoCurrent.map(u => {
                            return (
                                <div class="symbol symbol-35px symbol rounded"><img title={u.name} alt={u.name} src={u.avatar_url} /></div>
                            )
                        })}
                    </div>
                    <a onClick={() => getThread(thread.id)} className="ms-3 fs-5 fw-bold text-gray-900 text-white">
                        {usersNoCurrent.map(m => m.name).join(', ')}
                    </a>
                </>
            )
        }
        else {
            return (
                <>
                    <div onClick={() => getThread(thread.id)} class="symbol symbol-35px symbol rounded">
                        <img src={usersNoCurrent[0].avatar_url} />
                    </div>
                    <div onClick={() => getThread(thread.id)} class="ms-3">
                        <a class="fs-5 fw-bold text-gray-900 text-white">{usersNoCurrent[0].name}</a>
                    </div>
                </>
            )
        }
    }

    const getUserFromParticipants = (thread, message) => {
        const user = thread.users.find(m => message.user_id == m.id);

        return (
            <>
                <div class="symbol symbol-35px symbol rounded "><img src={user.avatar_url} /></div>
                <div class="ms-3">
                    <Link to={`/user/${user.id}`} class="fs-5 fw-bold text-gray-900 text-hover-primary me-1">{user.name}</Link>
                    <span class="text-muted fs-7 mb-1">{container.localeManager.moment(message.created_at).fromNow()}</span>
                </div>
            </>);
    }

    const addNewMessage = (message) => {
        const newMessages = [...messages, message];
        setMessages(newMessages);
    }

    return (
        <>
            <Row noGutters className="mt-3">
                <Col>
                    <div class="d-flex flex-column flex-lg-row">
                        <div class="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
                            <div class="card card-flush">
                                <div class="card-header pt-3" id="kt_chat_contacts_header">
                                    <Button color="primary" block onClick={() => setCurrentThread(null)}>{container.localeManager.strings.startNewThread}</Button>
                                </div>
                                <div class="card-body pt-5" id="kt_chat_contacts_body">
                                    <div id="threads" class="scroll-y me-n5 pe-5">
                                        {threads.map((t, index) => {
                                            return (
                                                <>
                                                    <div class="d-flex flex-stack py-4">
                                                        <div class="d-flex align-items-center">
                                                            {getParticipants(t)}
                                                        </div>
                                                        {t.unread > 0 &&
                                                            <div class="d-flex flex-column align-items-end ms-2">
                                                                <span class="badge badge-sm badge-circle badge-light-success">{t.unread}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                    {index != threads.length &&
                                                        <div class="separator separator-dashed"></div>
                                                    }
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-lg-row-fluid ms-1">
                            <div class="card" id="kt_chat_messenger">
                                {currentThread == null &&
                                    <>
                                        <div class="card-header" id="kt_chat_messenger_header">
                                            <div class="card-title">
                                                <div class="d-flex justify-content-center flex-column me-3">
                                                    <span class="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">{container.localeManager.strings.newThread}</span>

                                                </div>
                                            </div>
                                            <div className="mt-3 w-100">
                                                <div className="mb-3">
                                                    <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>{container.localeManager.strings.startNewThreadInformation}
                                                </div>
                                                <AsyncSelect noOptionsMessage={({ inputValue }) => !inputValue ? container.localeManager.strings.searchForACompanyMember : container.localeManager.strings.noResults}
                                                    onChange={(selected) => onSelectMember(selected)}
                                                    cacheOpti
                                                    ons getOptionValue={m => m.id}
                                                    getOptionLabel={m => m.name}
                                                    styles={reactSelectCustomStyles}
                                                    loadOptions={searchMembers}>
                                                </AsyncSelect>
                                            </div>
                                            {participants.length > 0 &&
                                                <div className="mt-3">
                                                    {participants.map(p => {
                                                        return (
                                                            <CustomBadge className="me-3" color="dark">{p.name} <a onClick={() => removeParticipant(p.id)}><FontAwesomeIcon icon="times" className="ms-3 me-0"></FontAwesomeIcon></a></CustomBadge>
                                                        )
                                                    })}
                                                </div>
                                            }
                                            <div class="card-toolbar">

                                            </div>
                                        </div>
                                        {participants.length > 0 && renderMessageBox()}
                                    </>
                                }
                                {currentThread != null &&
                                    <>
                                        <div class="card-header" id="kt_chat_messenger_header">
                                            <div class="card-title">
                                                <div class="d-flex align-items-center flex-row w-100 me-3">
                                                    {getParticipants(currentThread)}
                                                </div>
                                            </div>
                                            <div class="card-toolbar">

                                            </div>
                                        </div>
                                        <div class="card-body" id="kt_chat_messenger_body">
                                            <div id="chat_body" class="scroll-y me-n5 pe-5">
                                                {messages.map((m) => {
                                                    return (
                                                        <div class="d-flex justify-content-start mb-5">
                                                            <div class={classNames({ "w-100": true, "d-flex": true, "flex-column": true, "align-items-start": m.user_id != globalContext.member.id, "align-items-end": m.user_id == globalContext.member.id })}>
                                                                <div class="d-flex align-items-center mb-2">
                                                                    {getUserFromParticipants(currentThread, m)}
                                                                </div>
                                                                <div class="p-5 rounded bg-light-info text-start">
                                                                    {m.body}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        {renderMessageBox()}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
                {!container.dataService.data.enablePremiumFeature() &&
                    <aside className="ad-column-placeholder"></aside>
                }
            </Row>
        </>
    )
};

export default Chat;