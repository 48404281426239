import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Badge, Card, CardBody, CardDeck, CardText, CardTitle, Col, Progress, Row, Spinner } from "reactstrap";
import truckyapiClientService from "../../common/services/truckyapi-client-service";
import CustomBadge from "../../components/ui/CustomBadge";
import VenatusAd from "../../ads/VenatusAd";
import VenatusLeaderboardAd from "../../ads/VenatusLeaderboardAd";
import DesktopTakeover from "../../ads/DesktopTakeover";
import truckyService from "../../common/services/trucky-service";

const Servers = ({ container }) => {

    const [servers, setServers] = useState([]);
    const [totalPlayers, setTotalPlayers] = useState(0);
    const [calculatedGameTime, setCalculatedGameTime] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(async () => {

        truckyService.setDocumentTitle('Servers');

        setLoading(true);
        await getServers();
        getGameTime();
        setLoading(false);
    }, []);

    const getServers = async () => {
        const result = await truckyapiClientService.getServersRaw();
        setServers(result.servers);
        setTotalPlayers(result.totalPlayers);
    }

    const getGameTime = async () => {
        const result = await truckyapiClientService.game_time();
        setCalculatedGameTime(result.response.calculated_game_time);
    };

    return (
        <div>
          <DesktopTakeover container={container} className="my-3"></DesktopTakeover>
            {loading && <Spinner />}
            {!loading && (
                <>
                    <div className="d-flex justify-content-center my-5 flex-grow-1 w-100 fs-3">
                        <div>
                            <FontAwesomeIcon icon="user" /> {totalPlayers}
                        </div>
                        <div className="ms-5">
                            <FontAwesomeIcon icon="clock" />{" "}
                            {container.localeManager
                                .moment(calculatedGameTime)
                                .format("dd HH:mm")}
                        </div>
                    </div>

                    <div className="grid cols-3 gx-0">
                        {servers.map(s => {
                            var cardTitleClass = s.online ? 'text-success' : 'text-danger';
                            var percentage = (s.players / s.maxplayers) * 100;
                            var color = "success";
                            if (percentage > 50) color = "warning";
                            if (percentage > 75) color = "danger";

                            return (
                                <Card className="server mb-5">
                                    <div className={`fs-5 ${cardTitleClass} p-3 fw-bolder text-center`}>
                                        {s.game} - {s.name} - {s.shortname}
                                    </div>
                                    <CardBody className="text-left">
                                        <CardText className="server-players text-center">
                                            <CustomBadge color={color}>{s.players} / {s.maxplayers}</CustomBadge>
                                        </CardText>
                                        <div className="mb-3">
                                            <Progress value={percentage} color={color} />
                                        </div>
                                        <ul className="list-unstyled">
                                            <li className="server-info-row">
                                                <FontAwesomeIcon key={Math.random} icon="pause" />{" "}
                                                {s.queue}{" "}
                                                {container.localeManager.strings.playersInQueue}
                                            </li>
                                            <li className="server-info-row">
                                                <FontAwesomeIcon
                                                    key={Math.random}
                                                    icon="tachometer-alt"
                                                />{" "}
                                                {s.speedlimiter == 1
                                                    ? container.localeManager.strings
                                                        .speedLimiterEnabled
                                                    : container.localeManager.strings
                                                        .speedLimiterDisabled}
                                            </li>
                                            <li className="server-info-row">
                                                <FontAwesomeIcon key={Math.random} icon="car" />{" "}
                                                {s.carsforplayers
                                                    ? container.localeManager.strings
                                                        .carsForPlayersEnabled
                                                    : container.localeManager.strings
                                                        .carsForPlayersDisabled}
                                            </li>
                                            <li className="server-info-row">
                                                <FontAwesomeIcon key={Math.random} icon="bomb" />{" "}
                                                {s.collisions
                                                    ? container.localeManager.strings
                                                        .collisionsEnabled
                                                    : container.localeManager.strings
                                                        .collisionsDisabled}
                                            </li>
                                        </ul>
                                    </CardBody>
                                </Card>
                            );
                        })}
                    </div>
                    <VenatusLeaderboardAd container={container} className="my-3"></VenatusLeaderboardAd>
                </>
            )}
        </div>
    );
};

export default Servers;