import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { Button, Col, Row } from "reactstrap";

const PeriodSelector = ({ container, state, onChangeRange, onChangePeriod, enableDaily = true }) => {

    const [range, setRange] = useState(state.range);
    const [currentPeriod, setCurrentPeriod] = useState(state.currentPeriod);

    useEffect(() => {
        onChangeRange(range);
    }, [range]);

    useEffect(() => {
        onChangePeriod(currentPeriod);
    }, [currentPeriod]);

    const isPrevDisabled = () => {
        if (range == "monthly") {

        }
        else {
            if (currentPeriod.year == 2022)
                return true;
        }

        return false;
    }

    const isNextDisabled = () => {
        if (range == "monthly") {
            var newDate = moment().year(currentPeriod.year).month(currentPeriod.month).add(1, 'month');
            var endDate = moment().add(1, 'month');

            if (newDate.month() == endDate.month() && newDate.year() == endDate.year())
                return true;
        }
        else if (range == "weekly") {
            var newDate = moment().year(currentPeriod.year).isoWeek(currentPeriod.week).add(1, 'isoWeek');
            var endDate = moment().add(1, 'isoWeek');

            if (newDate.isoWeek() == endDate.isoWeek() && newDate.year() == endDate.year())
                return true;
        }
        else if (range == 'yearly') {
            if (currentPeriod.year + 1 > moment().year())
                return true;
        }
        else {
            if (currentPeriod.date.add(1, 'days') > moment())
                return true;
        }

        return false;
    }

    const getPeriodDescription = () => {
        if (range == 'monthly') {
            return (<>
                {container.localeManager.moment().year(currentPeriod.year).month(currentPeriod.month).format("MMMM")} {currentPeriod.year}
            </>);
        }
        else if (range == 'yearly') {
            return currentPeriod.year;
        }
        else if (range == 'weekly') {
            const weekDate = moment().isoWeek(currentPeriod.week + 1).year(currentPeriod.year);
            return (<>
                {container.localeManager.moment(weekDate).startOf('isoWeek').format('L')} - {container.localeManager.moment(weekDate).endOf('isoWeek').format('L')}
            </>)
        }
        else {
            return container.localeManager.moment(currentPeriod.date).format('LL');
        }
    }

    const changeDate = (value) => {
        let date = moment(value);
        setCurrentPeriod({ ...currentPeriod, date: date.format('YYYY-MM-DD') });
    }

    const nextPeriod = () => {
        if (range == 'monthly') {
            var newDate = moment().year(currentPeriod.year).month(currentPeriod.month).add(1, 'month');

            setCurrentPeriod({ ...currentPeriod, month: newDate.month(), year: newDate.year() });
        }
        else if (range == 'weekly') {
            var newDate = moment().year(currentPeriod.year).isoWeek(currentPeriod.week ? currentPeriod.week : moment().isoWeek()).add(1, 'isoWeek');

            setCurrentPeriod({ ...currentPeriod, week: newDate.isoWeek(), month: newDate.month(), year: newDate.year() });
        }
        else if (range == 'yearly') {
            setCurrentPeriod({ ...currentPeriod, year: currentPeriod.year + 1 });
        }
        else {
            setCurrentPeriod({ ...currentPeriod, date: currentPeriod.date.add(1, 'days') });
        }
    }

    const prevPeriod = () => {
        if (range == 'monthly') {
            var newDate = moment().year(currentPeriod.year).month(currentPeriod.month).subtract(1, 'month');

            setCurrentPeriod({ ...currentPeriod, month: newDate.month(), year: newDate.year() });
        }
        else if (range == 'weekly') {
            var newDate = moment().year(currentPeriod.year).isoWeek(currentPeriod.week ? currentPeriod.week : moment().isoWeek()).subtract(1, 'isoWeek');

            setCurrentPeriod({ ...currentPeriod, week: newDate.isoWeek(), month: newDate.month(), year: newDate.year() });
        }
        else if (range == 'yearly') {
            setCurrentPeriod({ ...currentPeriod, year: currentPeriod.year - 1 });
        }
        else {
            setCurrentPeriod({ ...currentPeriod, date: currentPeriod.date.subtract(1, 'days') });
        }
    }

    return (
        <div className="d-flex mb-3 align-items-center flex-column flex-md-row">
            <div>
                {enableDaily &&
                    <Button className="btn-color-muted btn-active fw-bolder px-4" size="sm" onClick={() => setRange('daily')} color={range == 'daily' ? 'active-secondary active' : 'active-secondary'}>{container.localeManager.strings.daily}</Button>
                }
                <Button className="btn-color-muted btn-active fw-bolder px-4" size="sm" onClick={() => setRange('weekly')} color={range == 'weekly' ? 'active-secondary active' : 'active-secondary'}>{container.localeManager.strings.weekly}</Button>
                <Button className="btn-color-muted btn-active fw-bolder px-4" size="sm" onClick={() => setRange('monthly')} color={range == 'monthly' ? 'active-secondary active' : 'active-secondary'}>{container.localeManager.strings.monthly}</Button>
                <Button className="btn-color-muted btn-active fw-bolder px-4" size="sm" onClick={() => setRange('yearly')} color={range == 'yearly' ? 'active-secondary active' : 'active-secondary'}>{container.localeManager.strings.yearly}</Button>
            </div>
            {range != 'daily' &&
                <div>
                    <Button disabled={isPrevDisabled()} className="btn-icon w-30px h-30px me-3" color="active-light-primary" onClick={() => prevPeriod()} size="sm"><FontAwesomeIcon className="me-0" icon="chevron-left"></FontAwesomeIcon></Button>
                    {getPeriodDescription()}
                    <Button disabled={isNextDisabled()} className="btn-icon w-30px h-30px ms-3" color="active-light-primary" onClick={() => nextPeriod()} size="sm"><FontAwesomeIcon className="me-0" icon="chevron-right"></FontAwesomeIcon></Button>
                </div>
            }
            {range == 'daily' &&
                <div>
                    <DatePicker locale={container.localeManager.language}
                        maxDate={new Date()}
                        calendarIcon={<FontAwesomeIcon icon="calendar-alt"></FontAwesomeIcon>}
                        clearIcon={null}
                        onChange={changeDate}
                        value={moment(currentPeriod.date).toDate()}
                    />
                </div>
            }
        </div>
    )
};

export default PeriodSelector;