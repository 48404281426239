import { useEffect, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import { Button, Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ClientLoginCode = ({ container }) => {
  const [loginCode, setLoginCode] = useState(null);

  const es = new EnterpriseService();

  useEffect(() => {
    es.claimClientLoginCode().then((result) => {
      setLoginCode(result.code);
    });
  }, []);

  return (
    <div className="mt-10 flex w-100 text-center">
      <Card>
        <CardBody>
          <div className="border p-3">
            <div className="my-5 fs-1">
              Your Email
              <br />
              {container.dataService.enterpriseService.member.email}{" "}
              <Button
                className="ms-3"
                size="sm"
                color="primary"
                onClick={() =>
                  navigator.clipboard.writeText(
                    container.dataService.enterpriseService.member.email
                  )
                }
              >
                <FontAwesomeIcon icon="copy"></FontAwesomeIcon>{" "}
                {container.localeManager.strings.copy}
              </Button>
            </div>
            <div className="fs-1">Your One Time Password is:</div>
            <div
              className="mt-5 fs-1 fw-bolder"
              style={{ letterSpacing: "1rem" }}
            >
              {loginCode}{" "}
              <Button
                size="sm"
                color="primary"
                onClick={() => navigator.clipboard.writeText(loginCode)}
              >
                <FontAwesomeIcon icon="copy"></FontAwesomeIcon>{" "}
                {container.localeManager.strings.copy}
              </Button>
            </div>
          </div>
          <div className="mt-3 fs-3">
            Now you can switch to Trucky, insert your email and the OTP like the
            example below
          </div>
          <div className="mt-5">
            <img src="/assets/login_example.gif" className="w-500px" />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ClientLoginCode;
