const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");
import React, { PureComponent } from "react";

class Rating extends PureComponent {
    renderStars(stars) {
        const container = [];

        for (var i = 1; i <= 5; i++) {
            if (stars >= i)
                container.push(<FontAwesomeIcon icon="star" color="yellow"></FontAwesomeIcon>);
            else
                container.push(<FontAwesomeIcon icon={[ "far", "star"]}></FontAwesomeIcon>);
        }

        return container;
    }

    render() {
        return this.renderStars(this.props.rating);
    }
}

export default Rating;