import { Spinner } from "reactstrap";

const Loader = () => {
    return (
        <div className="text-center mt-3 mb-3">
            <Spinner color="primary" />
        </div>
    )
}

export default Loader;