import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import EnterpriseService from "../../common/services/enterprise-service";
import reactSelectStylesCreatable from "../../common/plugins/reactSelectStylesCreatable";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import { EnterpriseContext } from "../enterprise/EnterpriseContext";
import truckyService from "../../common/services/trucky-service";
import { confirmWrapper } from "../ui/askConfirm";
import CustomBadge from "../ui/CustomBadge";
import CreatableSelect from "react-select/creatable";
import ProvisioningStatusBadge from "./ProvisioningStatusBadge";
import ServerStatusBadge from "./ServerStatusBadge";

const DedicatedServerConfiguration = ({ container }) => {
  const { globalContext } = useContext(EnterpriseContext);

  const [server, setServer] = useState({
    game_id: 1,
    max_players: 8,
    max_vehicles_total: 100,
    max_ai_vehicles_player: 50,
    max_ai_vehicles_player_spawn: 50,
    player_damage: true,
    traffic: true,
    hide_colliding: true,
    name_tags: true,
    show_server: true,
    moderator_list: [
      container.dataService.enterpriseService.member.steam.external_user_id,
    ],
    mods_optioning: false,
    timezones: "0",
    user_id: globalContext.member.id,
  });

  const navigate = useNavigate();
  let { id } = useParams();
  const es = new EnterpriseService();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [serverPackagesDat, setServerPackagesDat] = useState(null);
  const [serverPackagesSii, setServerPackagesSii] = useState(null);
  const [regions, setRegions] = useState([]);
  const [moderatorsListTextValue, setModeratorsListTexValue] = useState("");
  const [users, setUsers] = useState([]);
  const [usernameToSearch, setUsernameToSearch] = useState("");
  const [betas, setBetas] = useState([]);

  useEffect(() => {
    truckyService.setDocumentTitle("Dedicated Server");

    getRegions();

    if (id) {
      getServer(id);
    }
  }, []);

  useEffect(() => {
    if (server.game_id) {
      getGameBetas();
    }
  }, [server.game_id]);

  const getRegions = async () => {
    const result = await es.getRegions();
    setRegions(result);
  };

  const getServer = async () => {
    setLoading(true);
    const result = await es.getDedicatedServerConfiguration(id);
    setServer(result);
    setLoading(false);
    getUsers();
  };

  const getGameBetas = async () => {
    const result = await es.getGameBetas(server.game_id);
    setBetas(result);
  };

  const save = async () => {
    if (!truckyService.isValidWebhookUrl(server.discord_webhook_url)) {
      toast.error(container.localeManager.strings.discordWebhookUrlNotValid);
      return;
    }

    setLoading(true);

    let result;
    let creating = false;

    if (!server.id) {
      creating = true;

      if (serverPackagesDat != null && serverPackagesSii != null) {
        result = await es.createDedicatedServerConfiguration(server);
      } else {
        toast.error(container.localeManager.strings.pleaseUploadServerPackages);
        setLoading(false);
        return;
      }
    } else {
      result = await es.updateDedicatedServerConfiguration(server);
    }

    if (result.success) {
      id = result.server.id;

      setServer(result.server);

      if (serverPackagesDat != null) {
        await es.uploadDedicatedServerConfiguration(
          result.server,
          "server_packages_dat",
          serverPackagesDat
        );
      }

      if (serverPackagesSii != null) {
        await es.uploadDedicatedServerConfiguration(
          result.server,
          "server_packages_sii",
          serverPackagesSii
        );
      }

      toast.success("Server Created");
    } else {
      toast.error(result.message);
    }

    setLoading(false);

    if (
      result.server &&
      result.success &&
      result.server.provisioning_status == "not_provisioned"
    ) {
      if (
        await confirmWrapper(
          container.localeManager.strings.readyToBeProvisioned,
          { container }
        )
      ) {
        provisionServer(result.server.id);
      }
    }
  };

  const getSwitch = (key) => {
    const updateCheck = (value) => {
      let newServerData = { ...server };
      newServerData[key] = value;
      setServer(newServerData);
    };

    return (
      <label class="form-check form-switch form-check-custom form-check-solid">
        <input
          disabled={!hasPermission("general")}
          onChange={(e) => updateCheck(e.target.checked)}
          class="form-check-input"
          type="checkbox"
          checked={server[key]}
        />
        <span class="form-check-label">
          {container.localeManager.strings[key]}
        </span>
      </label>
    );
  };

  const deleteServer = async () => {
    if (
      await confirmWrapper(
        container.localeManager.strings.deleteServerConfirm,
        { container }
      )
    ) {
      setLoading(true);
      await es.deleteDedicatedServerConfiguration(server.id);
      toast.success("Server Deleted");
      navigate("/hosting");
      setLoading(false);
    }
  };

  const provisionServer = async (id) => {
    setLoading(true);
    const provisionResult = await es.provisionDedicatedServerConfiguration(id);

    if (provisionResult.success) {
      setLoadingMessage(container.localeManager.strings.creating);
      setTimeout(() => updateProvisioningStatus(id), 30000);
    } else {
      setLoading(false);
      toast.error(provisionResult.message);
    }
  };

  const updateProvisioningStatus = async (id) => {
    let serverStatus = await es.getDedicatedServerConfiguration(id);
    setLoadingMessage(
      container.localeManager.strings[serverStatus.provisioning_status]
    );
    if (serverStatus.provisioning_status == "provisioned") {
      setLoading(false);
      getServer();
    } else {
      setTimeout(() => updateProvisioningStatus(id), 30000);
    }
  };

  const getProvisioningStatus = (status) => {
    let color = "danger";

    switch (status) {
      case "not_provisioned":
        color = "danger";
        break;
      case "provisioned":
        color = "success";
        break;
      case "provisioning":
        color = "warning";
        break;
      case "creating":
        color = "warning";
        break;
    }

    return (
      <CustomBadge color={color} size="lg">
        {container.localeManager.strings[status]}
      </CustomBadge>
    );
  };

  const handleKeyDown = (event) => {
    if (!moderatorsListTextValue) return;
    if (moderatorsListTextValue.trim() == "") return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        const reg = new RegExp("^[0-9]+$");
        if (reg.test(moderatorsListTextValue)) {
          let modsList = server.moderator_list;
          if (!modsList) {
            modsList = [];
          }
          modsList.push(moderatorsListTextValue);

          modsList = modsList.filter((m) => m.trim() != "");

          setModeratorsListTexValue("");
          setServer({ ...server, moderator_list: modsList });
          event.preventDefault();
        } else {
          toast.error(
            "Moderator Steam ID is invalid, only numbers accepted, no whitespaces"
          );
          setModeratorsListTexValue("");
        }
    }
  };

  const addModerator = () => {
    if (moderatorsListTextValue && moderatorsListTextValue.trim() != "") {
      let id = moderatorsListTextValue.trim();

      const reg = new RegExp("^[0-9]+$");
      if (reg.test(id)) {
        let modsList = server.moderator_list;
        if (!modsList) {
          modsList = [];
        }
        modsList.push(id);
        setModeratorsListTexValue("");
        setServer({ ...server, moderator_list: modsList });
      } else {
        toast.error(
          "Moderator Steam ID is invalid, only numbers accepted, no whitespaces"
        );
      }
    } else {
      toast.error("Please insert a valid Steam ID");
    }
  };

  const restartServer = async () => {
    setLoading(true);
    await es.restartServer(server.id);
    setLoading(false);
  };

  const getUsers = async () => {
    const result = await es.getDedicatedServerUsers(id);
    setUsers(result);
  };

  const searchUser = async () => {
    if (usernameToSearch != "") {
      setLoading(true);
      const result = await es.searchUsers(usernameToSearch);
      if (result.length > 0) {
        const addUserResult = await es.addDedicatedServerUser(
          server.id,
          result[0]
        );

        if (addUserResult.success) {
          setUsers([...users, addUserResult.user]);
          toast.success("User added");
        } else {
          toast.error(addUserResult.message);
        }
      } else {
        toast.error("User not found");
      }
      setLoading(false);
    }
  };

  const removeUser = async (ds_user_id) => {
    setLoading(true);
    const result = await es.removeDedicatedServerUser(server.id, ds_user_id);
    setUsers(users.filter((m) => m.id != ds_user_id));
    setLoading(false);
  };

  const updateUser = async (ds_user_id) => {
    setLoading(true);
    await es.updateDedicatedServerUser(
      server.id,
      users.find((m) => m.id == ds_user_id)
    );
    toast.success("User permissions saved");
    setLoading(false);
  };

  const togglePermissions = (ds_user_id, permission, checked) => {
    const newUsers = users.map((m) => {
      if (m.id == ds_user_id) {
        if (!m.permissions) m.permissions = [];

        if (checked) {
          m.permissions.push(permission);
        } else {
          m.permissions = m.permissions.filter((m) => m != permission);
        }

        return m;
      } else return m;
    });

    setUsers(newUsers);
  };

  const hasPermission = (permission) => {
    if (server != null && server.user_id == globalContext.member.id)
      return true;

    if (users.length > 0) {
      const user = users.find((m) => m.user_id == globalContext.member.id);

      if (server != null && user != null) {
        if (user.permissions && user.permissions.includes(permission))
          return true;
      }
    }

    return false;
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        text={<div className="text-center mt-3">{loadingMessage}</div>}
        spinner={
          <Spinner
            color="primary"
            style={{ width: "4rem", height: "4rem" }}
          ></Spinner>
        }
      >
        <Row className="gx-0">
          <Col>
            <Card>
              <CardBody>
                <div class="mb-3 d-flex justify-content-between">
                  {window.overwolf && (
                    <Button
                      size="sm"
                      onClick={() => navigate(-1)}
                      color="secondary"
                    >
                      <FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>
                      {container.localeManager.strings.back}
                    </Button>
                  )}
                </div>
                <h5 className="d-inline-block w-100 mb-5">
                  {container.localeManager.strings.serverConfiguration}
                </h5>
                {server.id && (
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <div>
                      <ProvisioningStatusBadge
                        container={container}
                        status={server.provisioning_status}
                        size="lg"
                      ></ProvisioningStatusBadge>
                      {server.provisioning_status == "provisioned" && (
                        <ServerStatusBadge
                          container={container}
                          server={server}
                          size="lg"
                          className="ms-3"
                        ></ServerStatusBadge>
                      )}
                    </div>
                    <div>
                      {hasPermission("restart") &&
                        server.server_packages_dat != null &&
                        server.server_packages_sii != null &&
                        server.droplet_id == null &&
                        server.provisioning_status == "not_provisioned" && (
                          <Button
                            size="sm"
                            color="success"
                            onClick={() => provisionServer(server.id)}
                            className="me-3"
                          >
                            <FontAwesomeIcon icon="rocket-launch"></FontAwesomeIcon>
                            {container.localeManager.strings.provisionServer}
                          </Button>
                        )}
                      {server.user_id == globalContext.member.id && (
                        <Button size="sm" color="danger" onClick={deleteServer}>
                          <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                          {container.localeManager.strings.deleteServer}
                        </Button>
                      )}
                      {hasPermission("restart") &&
                        server.droplet_id != null &&
                        server.provisioning_status == "provisioned" && (
                          <Button
                            size="sm"
                            color="danger"
                            onClick={restartServer}
                            className="ms-3"
                          >
                            <FontAwesomeIcon icon="refresh"></FontAwesomeIcon>
                            {container.localeManager.strings.restartServer}
                          </Button>
                        )}
                    </div>
                  </div>
                )}
                {server.provisioning_status == "provisioned" && (
                  <Card body className="bg-light my-3">
                    <div className="grid cols-5 d-flex align-items-center">
                      <div>IP: {server.droplet_ip_address}</div>
                      <Button
                        color="primary"
                        size="sm"
                        className="border rounded"
                        onClick={() =>
                          truckyService.openUrlInDefaultBrowser(
                            server.packages_dat_url
                          )
                        }
                      >
                        Download Server Packages .dat
                      </Button>
                      <Button
                        color="primary"
                        size="sm"
                        className="border rounded"
                        onClick={() =>
                          truckyService.openUrlInDefaultBrowser(
                            server.packages_sii_url
                          )
                        }
                      >
                        Download Server Packages .sii
                      </Button>
                      <Button
                        tag="a"
                        target="_blank"
                        href={server.server_status_url}
                        color="primary"
                        size="sm"
                        className="border rounded"
                      >
                        Status API
                      </Button>
                      <Button
                        tag="a"
                        href={server.server_logs_url}
                        target="_blank"
                        color="primary"
                        size="sm"
                        className="border rounded"
                      >
                        Server Logs
                      </Button>
                    </div>
                  </Card>
                )}
                <FormGroup row>
                  <Col sm={6}>
                    <Label>{container.localeManager.strings.game} *</Label>
                    <Input
                      disabled={server.id != undefined}
                      value={server.game_id}
                      type="select"
                      onChange={(e) =>
                        setServer({ ...server, game_id: e.target.value })
                      }
                    >
                      <option value={1}>Euro Truck Simulator 2</option>
                      <option value={2}>American Truck Simulator</option>
                    </Input>
                  </Col>
                  {globalContext.member.company_id != null && (
                    <Col sm={6} className="pt-6">
                      <label class="form-check form-switch form-check-custom form-check-solid">
                        <input
                          disabled={!hasPermission("general")}
                          onChange={(e) =>
                            setServer({
                              ...server,
                              company_id: e.target.checked
                                ? globalContext.member.company_id
                                : null,
                            })
                          }
                          class="form-check-input"
                          type="checkbox"
                          checked={
                            globalContext.member.company_id == server.company_id
                          }
                        />
                        <span class="form-check-label">
                          {container.localeManager.strings.companyServer}
                        </span>
                      </label>
                      <FormText color="muted mt-1">
                        {container.localeManager.strings.companyServerHelp}
                      </FormText>
                    </Col>
                  )}
                </FormGroup>
                <Row className="mb-3">
                  <Col>
                    <div className="grid cols-6">
                      {regions.map((m) => {
                        return (
                          <Card
                            onClick={() =>
                              server.id == undefined
                                ? setServer({ ...server, region_id: m.slug })
                                : false
                            }
                            body
                            className={classNames({
                              "bg-light": true,
                              disabled: server.id != undefined,
                              "text-center": true,
                              "border border-success":
                                server != null && server.region_id == m.slug,
                            })}
                          >
                            {m.name}
                          </Card>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
                <FormGroup row>
                  <Col>
                    <Label>
                      {container.localeManager.strings.lobby_name} *
                    </Label>
                    <Input
                      disabled={!hasPermission("general")}
                      value={server.lobby_name}
                      type="text"
                      maxLength={63}
                      onChange={(e) =>
                        setServer({ ...server, lobby_name: e.target.value })
                      }
                    ></Input>
                    <FormText color="muted">
                      {container.localeManager.strings.siiMaxChars}
                    </FormText>
                  </Col>
                  <Col>
                    <Label>{container.localeManager.strings.description}</Label>
                    <Input
                      disabled={!hasPermission("general")}
                      value={server.description}
                      type="description"
                      maxLength={63}
                      onChange={(e) =>
                        setServer({ ...server, description: e.target.value })
                      }
                    ></Input>
                    <FormText color="muted">
                      {container.localeManager.strings.siiMaxChars}
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col>
                    <Label>
                      {container.localeManager.strings.welcome_message}
                    </Label>
                    <Input
                      disabled={!hasPermission("general")}
                      value={server.welcome_message}
                      type="welcome_message"
                      maxLength={127}
                      onChange={(e) =>
                        setServer({
                          ...server,
                          welcome_message: e.target.value,
                        })
                      }
                    ></Input>
                    <FormText color="muted">
                      {container.localeManager.strings.siiMaxChars}
                    </FormText>
                  </Col>
                  <Col>
                    <Label>{container.localeManager.strings.password}</Label>
                    <Input
                      disabled={!hasPermission("password")}
                      value={server.password}
                      type="text"
                      onChange={(e) =>
                        setServer({ ...server, password: e.target.value })
                      }
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col>
                    <Label>
                      {container.localeManager.strings.max_players} *
                    </Label>
                    <Input
                      disabled={!hasPermission("general")}
                      value={server.max_players}
                      type="number"
                      max={8}
                      min={1}
                      onChange={(e) =>
                        setServer({ ...server, max_players: e.target.value })
                      }
                    ></Input>
                    <FormText color="muted">
                      Max 8 Players &lt;= 1.49 - Max 128 players &gt;= 1.50
                    </FormText>
                    {server.max_players > 8 && (
                      <div className="text-warning">
                        <a
                          target="_blank"
                          href="https://truckyapp.com/kb/cant-find-my-server-with-more-than-8-slots-in-convoy-browser/"
                          className="text-warning"
                        >
                          <FontAwesomeIcon icon="info-circle" className="me-2"></FontAwesomeIcon>
                          You are using a server with more than 8 slots: to see
                          it in Convoy Browser, follow this guide{" "}
                          <FontAwesomeIcon icon="up-right-from-square" className="ms-2"></FontAwesomeIcon>
                        </a>
                      </div>
                    )}
                  </Col>
                  <Col>
                    <Label>
                      {container.localeManager.strings.max_vehicles_total} *
                    </Label>
                    <Input
                      disabled={!hasPermission("general")}
                      value={server.max_vehicles_total}
                      type="number"
                      onChange={(e) =>
                        setServer({
                          ...server,
                          max_vehicles_total: e.target.value,
                        })
                      }
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col>
                    <Label>
                      {container.localeManager.strings.max_ai_vehicles_player} *
                    </Label>
                    <Input
                      disabled={!hasPermission("general")}
                      value={server.max_ai_vehicles_player}
                      type="number"
                      onChange={(e) =>
                        setServer({
                          ...server,
                          max_ai_vehicles_player: e.target.value,
                        })
                      }
                    ></Input>
                  </Col>
                  <Col>
                    <Label>
                      {
                        container.localeManager.strings
                          .max_ai_vehicles_player_spawn
                      }{" "}
                      *
                    </Label>
                    <Input
                      disabled={!hasPermission("general")}
                      value={server.max_ai_vehicles_player}
                      type="number"
                      onChange={(e) =>
                        setServer({
                          ...server,
                          max_ai_vehicles_player_spawn: e.target.value,
                        })
                      }
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col>
                    <Label>
                      {container.localeManager.strings.server_logon_token}
                    </Label>
                    <Input
                      disabled={!hasPermission("token")}
                      value={server.server_logon_token}
                      type="text"
                      onChange={(e) =>
                        setServer({
                          ...server,
                          server_logon_token: e.target.value,
                        })
                      }
                    ></Input>
                    <FormText>
                      <a
                        className="text-warning"
                        onClick={() =>
                          truckyService.openUrlInDefaultBrowser(
                            "https://truckyapp.com/kb/how-to-create-a-server-logon-token/"
                          )
                        }
                      >
                        <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                        {container.localeManager.strings.serverLogonTokenHelp}
                      </a>
                    </FormText>
                  </Col>
                  <Col>
                    <Label>
                      {container.localeManager.strings.moderator_list}
                    </Label>
                    <InputGroup>
                      <Input
                        disabled={!hasPermission("moderators")}
                        value={moderatorsListTextValue}
                        placeholder="Steam ID"
                        type="text"
                        onChange={(e) =>
                          setModeratorsListTexValue(e.target.value)
                        }
                      ></Input>
                      <Button color="success" onClick={addModerator}>
                        <FontAwesomeIcon
                          icon="plus"
                          className="mx-0"
                        ></FontAwesomeIcon>
                      </Button>
                    </InputGroup>

                    {server.moderator_list &&
                      server.moderator_list.length > 0 && (
                        <>
                          <div className="mt-3">
                            {hasPermission("moderators") &&
                              server.moderator_list.map((m) => {
                                return (
                                  <div
                                    className="badge badge-secondary square me-3 mb-1 border-gray-500 border-hover text-normal"
                                    onClick={() =>
                                      setServer({
                                        ...server,
                                        moderator_list:
                                          server.moderator_list.filter(
                                            (k) => k != m
                                          ),
                                      })
                                    }
                                  >
                                    {m}{" "}
                                    <FontAwesomeIcon
                                      className="ms-1 me-0"
                                      icon="times"
                                    ></FontAwesomeIcon>
                                  </div>
                                );
                              })}
                            {!hasPermission("moderators") &&
                              server.moderator_list.map((m) => {
                                return (
                                  <div className="badge badge-secondary square me-3 mb-1 border-gray-500 border-hover text-normal">
                                    {m}
                                  </div>
                                );
                              })}
                          </div>
                        </>
                      )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={3}>{getSwitch("player_damage")}</Col>
                  <Col sm={3}>{getSwitch("traffic")}</Col>
                  <Col sm={3}>{getSwitch("hide_in_company")}</Col>
                  <Col sm={3}>{getSwitch("hide_colliding")}</Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={3}>{getSwitch("force_speed_limiter")}</Col>
                  <Col sm={3}>{getSwitch("service_no_collision")}</Col>
                  <Col sm={3}>{getSwitch("name_tags")}</Col>
                  <Col sm={3}>{getSwitch("friends_only")}</Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={3}>{getSwitch("show_server")}</Col>
                  <Col sm={3}>{getSwitch("mods_optioning")}</Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={3}>
                    <Label>{container.localeManager.strings.timezones}</Label>
                    <Input
                      disabled={!hasPermission("general")}
                      value={server.timezones}
                      type="number"
                      min="0"
                      max="2"
                      onChange={(e) =>
                        setServer({ ...server, timezones: e.target.value })
                      }
                    ></Input>
                  </Col>
                  <Col sm={4}>
                    <Label>Betas</Label>
                    <Input
                      type="select"
                      value={server.beta}
                      onChange={(e) =>
                        setServer({ ...server, beta: e.target.value })
                      }
                    >
                      <option value="">Public</option>
                      {betas.map((m) => {
                        return <option value={m.name}>{m.description}</option>;
                      })}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col>
                    <Label>
                      {container.localeManager.strings.server_packages_dat} *
                    </Label>
                    <Input
                      disabled={!hasPermission("server_packages")}
                      accept="server_packages.dat"
                      type="file"
                      onChange={(e) => setServerPackagesDat(e.target.files[0])}
                    ></Input>
                    <FormText>
                      <a
                        className="text-warning"
                        onClick={() =>
                          truckyService.openUrlInDefaultBrowser(
                            "https://truckyapp.com/kb/how-to-export-server-packages-dat-and-sii/"
                          )
                        }
                      >
                        <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                        {
                          container.localeManager.strings
                            .exportServerPackagesHelp
                        }
                      </a>
                    </FormText>
                  </Col>
                  <Col>
                    <Label>
                      {container.localeManager.strings.server_packages_sii} *
                    </Label>
                    <Input
                      disabled={!hasPermission("server_packages")}
                      accept="server_packages.sii"
                      type="file"
                      onChange={(e) => setServerPackagesSii(e.target.files[0])}
                    ></Input>
                    <FormText>
                      <a
                        className="text-warning"
                        onClick={() =>
                          truckyService.openUrlInDefaultBrowser(
                            "https://truckyapp.com/kb/how-to-export-server-packages-dat-and-sii/"
                          )
                        }
                      >
                        <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                        {
                          container.localeManager.strings
                            .exportServerPackagesHelp
                        }
                      </a>
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Label>
                      {container.localeManager.strings.discord_webhook_url} *
                    </Label>
                    <Input
                      disabled={!hasPermission("webhook")}
                      value={server.discord_webhook_url}
                      type="text"
                      onChange={(e) =>
                        setServer({
                          ...server,
                          discord_webhook_url: e.target.value,
                        })
                      }
                    ></Input>
                    <FormText color="muted">
                      {container.localeManager.strings.discordWebhookHelp}
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col>
                    <Button onClick={save} color="primary">
                      {container.localeManager.strings.save}
                    </Button>
                  </Col>
                </FormGroup>
                {server != null &&
                  server.id &&
                  server.user_id == globalContext.member.id && (
                    <div className="mt-3">
                      <div className="mb-3">
                        <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>{" "}
                        {container.localeManager.strings.additionalUsersHint}
                      </div>
                      <FormGroup row>
                        <Col sm={4}>
                          <InputGroup>
                            <Input
                              placeholder="Search by Trucky username"
                              value={usernameToSearch}
                              onChange={(e) =>
                                setUsernameToSearch(e.target.value)
                              }
                            ></Input>
                            <Button onClick={searchUser} color="secondary">
                              {container.localeManager.strings.search}
                            </Button>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                      {users.length > 0 && (
                        <div className="mb-3">
                          <Table
                            responsive
                            striped
                            size="sm"
                            className="table-row-dashed table-row-gray-300 align-middle gs-0"
                            hover
                          >
                            <thead>
                              <tr className="fw-bolder text-gray-700 bg-light">
                                <th className="text-start ps-2">
                                  {container.localeManager.strings.user}
                                </th>
                                <th className="text-start">
                                  {container.localeManager.strings.permissions}
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {users.map((m) => {
                                return (
                                  <tr>
                                    <td className="ps-2">
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={m.user.avatar_url}
                                          className="avatar h-30px rounded me-3"
                                        />{" "}
                                        {m.user.name}
                                      </div>
                                    </td>
                                    <td>
                                      <Row>
                                        <Col sm={3}>
                                          <label class="form-check form-switch form-check-custom form-check-solid me-3 mb-2">
                                            <input
                                              checked={m.permissions?.includes(
                                                "general"
                                              )}
                                              class="form-check-input"
                                              type="checkbox"
                                              onChange={(e) =>
                                                togglePermissions(
                                                  m.id,
                                                  "general",
                                                  e.target.checked
                                                )
                                              }
                                            />
                                            <span class="form-check-label">
                                              General Properties
                                            </span>
                                          </label>
                                          <label class="form-check form-switch form-check-custom form-check-solid me-3 mb-2">
                                            <input
                                              checked={m.permissions?.includes(
                                                "restart"
                                              )}
                                              class="form-check-input"
                                              type="checkbox"
                                              onChange={(e) =>
                                                togglePermissions(
                                                  m.id,
                                                  "restart",
                                                  e.target.checked
                                                )
                                              }
                                            />
                                            <span class="form-check-label">
                                              Restart Server
                                            </span>
                                          </label>
                                          <label class="form-check form-switch form-check-custom form-check-solid me-3 mb-2">
                                            <input
                                              checked={m.permissions?.includes(
                                                "webhook"
                                              )}
                                              class="form-check-input"
                                              type="checkbox"
                                              onChange={(e) =>
                                                togglePermissions(
                                                  m.id,
                                                  "webhook",
                                                  e.target.checked
                                                )
                                              }
                                            />
                                            <span class="form-check-label">
                                              Change Discord Webhook
                                            </span>
                                          </label>
                                        </Col>
                                        <Col sm={3}>
                                          <label class="form-check form-switch form-check-custom form-check-solid me-3 mb-2">
                                            <input
                                              checked={m.permissions?.includes(
                                                "server_packages"
                                              )}
                                              class="form-check-input"
                                              type="checkbox"
                                              onChange={(e) =>
                                                togglePermissions(
                                                  m.id,
                                                  "server_packages",
                                                  e.target.checked
                                                )
                                              }
                                            />
                                            <span class="form-check-label">
                                              Upload Server Packages
                                            </span>
                                          </label>

                                          <label class="form-check form-switch form-check-custom form-check-solid me-3 mb-2">
                                            <input
                                              checked={m.permissions?.includes(
                                                "password"
                                              )}
                                              class="form-check-input"
                                              type="checkbox"
                                              onChange={(e) =>
                                                togglePermissions(
                                                  m.id,
                                                  "password",
                                                  e.target.checked
                                                )
                                              }
                                            />
                                            <span class="form-check-label">
                                              Change Password
                                            </span>
                                          </label>
                                        </Col>
                                        <Col sm={3}>
                                          <label class="form-check form-switch form-check-custom form-check-solid me-3 mb-2">
                                            <input
                                              checked={m.permissions?.includes(
                                                "token"
                                              )}
                                              class="form-check-input"
                                              type="checkbox"
                                              onChange={(e) =>
                                                togglePermissions(
                                                  m.id,
                                                  "token",
                                                  e.target.checked
                                                )
                                              }
                                            />
                                            <span class="form-check-label">
                                              Change Token
                                            </span>
                                          </label>

                                          <label class="form-check form-switch form-check-custom form-check-solid me-3 mb-2">
                                            <input
                                              checked={m.permissions?.includes(
                                                "moderators"
                                              )}
                                              class="form-check-input"
                                              type="checkbox"
                                              onChange={(e) =>
                                                togglePermissions(
                                                  m.id,
                                                  "moderators",
                                                  e.target.checked
                                                )
                                              }
                                            />
                                            <span class="form-check-label">
                                              Change Moderators
                                            </span>
                                          </label>
                                        </Col>
                                      </Row>
                                    </td>
                                    <td className="text-end pe-3">
                                      <Button
                                        className="btn-icon w-30px h-30px ms-auto me-3"
                                        color="active-light-success"
                                      >
                                        <FontAwesomeIcon
                                          className="me-0"
                                          icon="check"
                                          onClick={() => updateUser(m.id)}
                                        ></FontAwesomeIcon>
                                      </Button>
                                      <Button
                                        className="btn-icon w-30px h-30px ms-auto"
                                        color="active-light-danger"
                                        size="small"
                                        onClick={() => removeUser(m.id)}
                                      >
                                        <FontAwesomeIcon
                                          className="me-0"
                                          icon="times"
                                          onClick={() => updateUser(m.id)}
                                        ></FontAwesomeIcon>
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </div>
                  )}
              </CardBody>
            </Card>
          </Col>
          <aside className="ad-column-placeholder"></aside>
        </Row>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </LoadingOverlay>
    </>
  );
};

export default DedicatedServerConfiguration;
