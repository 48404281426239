import EnterpriseService from "../../common/services/enterprise-service";
import CustomBadge from "../ui/CustomBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardTitle } from "reactstrap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
var Carousel = require('react-responsive-carousel').Carousel;
import "react-responsive-carousel/lib/styles/carousel.min.css";

const RandomCompanies = ({ container, carousel = false, cards = true }) => {

    const es = new EnterpriseService();
    const [companyStats, setCompanyStats] = useState(null);

    useEffect(() => {
        es.getGlobalCompanyStats().then(setCompanyStats);
    }, []);

    const getCompanyLogo = (company) => {
        if (company.avatar_url == null)
            return <img class="mb-3 rounded" style={{ maxHeight: 100, alignSelf: 'center' }} src="assets/company-placeholder.png" />
        else
            return <img class="mb-3 rounded" style={{ maxHeight: 100, alignSelf: 'center' }} src={company.avatar_url} onError={(el) => el.src = "assets/company-placeholder.png"} />
    }

    const getCompanyStyleBadge = (company) => {
        if (company.company_type == 'realistic')
            return <CustomBadge size="small" color="primary">{container.localeManager.strings.realisticEconomy}</CustomBadge>

        if (company.company_type == 'miles')
            return <CustomBadge size="small" color="primary">{container.localeManager.strings.enterpriseMiles}</CustomBadge>

        if (company.company_type == 'both')
            return <CustomBadge size="small" color="primary">{container.localeManager.strings.ecoAndMiles}</CustomBadge>
    }

    return (
        <>
            {companyStats != null &&
                <>
                    <div className="text-center fs-2 fw-bolder mb-3">
                        {container.localeManager.formatString(container.localeManager.strings.joinClaim, companyStats.count)}
                    </div>
                    {carousel &&
                        <div className="mb-5">
                            <Carousel showArrows={true} showIndicators={false} showStatus={false}
                                autoPlay={true} infiniteLoop={true} showThumbs={false}
                                stopOnHover={true} renderThumbs={false}>
                                {companyStats.companies.map((m) => {
                                    return (
                                        <div class="h-max-400px h-min-400px py-10" style={{
                                            backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("${m.cover_url}"`
                                        }}>
                                            <div class="d-flex flex-column" style={{ opacity: 1 }}>
                                                <Link to={`/company/${m.id}`} className="text-white">
                                                    {getCompanyLogo(m)}
                                                    <h1>{m.name}</h1>
                                                    <p className="font-italic">{m.slogan}</p>
                                                    <div>
                                                        {m.flag_url != null &&
                                                            <img src={m.flag_url} className="me-1" />
                                                        }
                                                        {getCompanyStyleBadge(m)}
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </div>
                    }
                    {cards &&
                        <div className="fs-2 mb-5 text-center fw-bolder w-100">
                            <div className="mt-5 grid cols-4 d-none d-md-grid">
                                {companyStats.companies.map((m) => {
                                    return (
                                        <Card className="directory-company-card text-white">
                                            <CardBody className="text-center d-flex flex-column">
                                                <Link to={`/company/${m.id}`} className="text-white">
                                                    {getCompanyLogo(m)}
                                                    <CardTitle className="text-uppercase font-weight-bold"><h4>{m.name}</h4></CardTitle>
                                                    <p className="mt-1 font-italic fs-6">
                                                        {m.slogan}
                                                    </p>
                                                    <div className="mt-auto">
                                                        {m.flag_url != null &&
                                                            <img src={m.flag_url} className="me-1" />
                                                        }
                                                        {getCompanyStyleBadge(m)}
                                                    </div>
                                                </Link>
                                            </CardBody>
                                        </Card>

                                    )
                                })}
                            </div>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default RandomCompanies;