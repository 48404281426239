import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import LoadingOverlay from "react-loading-overlay";
import { Badge, Button, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row, Spinner, Table } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import Loader from "../ui/Loader";
import Paginator from "../ui/Paginator";
import EcoStatsCard from "./EcoStatsCards";
import JobsTable from "./JobsTable";
import MileStatsCards from "./MileStatsCards";
import PeriodSelector from "./PeriodSelector";

const UserMonthlyStats = ({ period = "monthly", user, container }) => {

    const es = new EnterpriseService();
    const [stats, setStats] = useState(null);
    const [currentPeriod, setCurrentPeriod] = useState({ month: moment().month(), year: moment().year(), date: moment().format('YYYY-MM-DD'),
        week: moment().isoWeek() });
    const [loading, setLoading] = useState(false);
    const [ecoStats, setEcoStats] = useState(null);
    const [range, setRange] = useState('monthly');

    useEffect(() => {
        loadData();
    }, [user, currentPeriod.month, currentPeriod.year, range, currentPeriod.date, currentPeriod.week]);

    const loadData = async () => {
        getStats();
    }

    const getStats = async () => {
        setLoading(true);
        let result;

        result = await es.getUserMilesStatsByDate(user, range, currentPeriod.month + 1, currentPeriod.year, currentPeriod.date, currentPeriod.week);

        setStats(result);

        if (user.company != null && (user.company.company_type == 'realistic' || user.company.company_type == 'both')) {
            await getEcoStats();
        }

        setLoading(false);
    }

    const getEcoStats = async () => {
        let result;

        result = await es.getUserEcoStatsByDate(user, range, currentPeriod.month + 1, currentPeriod.year, currentPeriod.date, currentPeriod.week);

        setEcoStats(result);
    }

    return (
        <div className="mt-3">
            {loading && <Loader></Loader>}
            {!loading &&
                <PeriodSelector state={{ range, currentPeriod }} container={container} onChangePeriod={setCurrentPeriod} onChangeRange={setRange}></PeriodSelector>
            }
            {!loading && stats != null &&
                <MileStatsCards period={range} stats={stats} container={container} showPositionBadge={false} ets2_preferred_distance_unit={stats.ets2.distance_unit} ats_preferred_distance_unit={stats.ats.distance_unit}></MileStatsCards>
            }
            {!loading && ecoStats != null &&
                <EcoStatsCard period={range} container={container} ecoStats={ecoStats} currency={ecoStats.currency} volume_unit={ecoStats.volume_unit}
                    distance_unit={ecoStats.distance_unit} weight_unit={ecoStats.weight_unit} user_id={user.id} company_id={user.company_id}></EcoStatsCard>
            }
        </div>
    )
}

export default UserMonthlyStats;