import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import CompanyFeedItems from "./CompanyFeedItems";
import truckyService from "../../common/services/trucky-service";

const LastCompanyFeeds = ({ container, company, limit }) => {

    const es = new EnterpriseService();
    const [pinned, setPinned] = useState([]);
    const [feed, setFeed] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getFeeds();
    }, []);

    const getFeeds = async () => {
        const pinnedResult = await es.getPublishedFeed(company.id, { pinned: 'onlyPinned', limit: limit, });

        setPinned(pinnedResult);

        if (pinnedResult.length < limit) {
            const notPinnedResult = await es.getPublishedFeed(company.id, { pinned: 'notPinned', limit: limit - pinnedResult.length });
            setFeed(notPinnedResult);
        }
    }

    return (
        <>
            {(feed.length > 0 || pinned.length > 0) &&
                <div>
                    <div className="d-flex align-items-center mb-2 mt-3">
                        <div className="flex-grow-1">
                            <h5>{container.localeManager.strings.newsFeed}</h5>
                        </div>
                        <div>
                            <Button size="sm" color="secondary" onClick={() => navigate("/feeds")}><FontAwesomeIcon icon="newspaper"></FontAwesomeIcon>{container.localeManager.strings.all}</Button>
                        </div>
                    </div>
                    {pinned.length > 0 &&
                        <CompanyFeedItems container={container} items={pinned}></CompanyFeedItems>
                    }
                    {feed.length > 0 &&
                        <CompanyFeedItems container={container} items={feed}></CompanyFeedItems>
                    }
                </div>
            }
        </>
    )
}

export default LastCompanyFeeds;