import { Card, CardBody, CardTitle, Row, Col, Button, CardHeader, Table, Badge, Spinner, ListGroup, ListGroupItem, FormGroup, Label, Input, FormText, Nav, TabContent, NavLink, NavItem, TabPane } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate, useParams } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import EnterpriseService from '../../common/services/enterprise-service';
import LoadingOverlay from 'react-loading-overlay';
import { EnterpriseContext } from './EnterpriseContext';
import Tips from '../Tips';
import JobsTable from "./JobsTable";
import UserMonthlyStats from './UserMonthlyStats';
import { toast, ToastContainer } from "react-toastify";
import SingleMemberBadge from './SingleMemberBadge';
import VehiclesTable from './VehiclesTable';
import EnterpriseNavBar from './EnterpriseNavBar';
import ProfileV2 from '../ProfileCardV2';
import classnames from 'classnames';
import MemberJobs from './MemberJobs';
import truckyService from '../../common/services/trucky-service';
import ProfileStats from '../ProfileStats';
import AggregatedStatsPerGame from './AggregatedStatsPerGame';
import AchievementsList from '../AchievementsList';
import VenatusLeaderboardAd from '../../ads/VenatusLeaderboardAd';
import DesktopTakeover from '../../ads/DesktopTakeover';
import BoxDynamicAd from '../../ads/BoxDynamicAd';

const UserDetail = ({ container, loadContext }) => {

    const { globalContext } = useContext(EnterpriseContext);

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [vehicles, setVehicles] = useState([]);

    let { id } = useParams();

    if (!id) {
        id = container.dataService.enterpriseService.member.id;
    }

    const navigate = useNavigate();
    const es = new EnterpriseService();
    const [badges, setBadges] = useState([]);
    const location = useLocation();
    const [activeTab, toggleTab] = useState(truckyService.loadSearchParameters('userdetail_activetab', 'highlights'));
    const [showVehicles, setShowVehicles] = useState(false);
    const [showAchievements, setShowAchievements] = useState(false);
    const [achievements, setAchievements] = useState([]);

    useEffect(() => {
        truckyService.setDocumentTitle('User Detail');
    }, []);

    useEffect(() => {
        getUser();
    }, [id]);

    useEffect(() => {
        truckyService.saveSearchParameters('userdetail_activetab', activeTab);
    }, [activeTab]);

    useEffect(() => {

        if (user != null) {
            if (activeTab == 'badges') {
                getBadges();
            }

            if (globalContext.company != null && user.company_id == globalContext.company.id) {
                setShowVehicles(true);
                setShowAchievements(true);

                if (activeTab == 'vehicles') {
                    getVehicles();
                }

                if (activeTab == 'achievements') {
                    getCompanyAchievements();
                }
            }
        }
    }, [user, activeTab]);

    const getUser = async () => {
        setLoading(true);
        const result = await es.getUser(id);
        setUser(result);
        setLoading(false);
    };

    const getVehicles = async () => {
        const result = await es.getMemberVehicles(user);
        setVehicles(result);
    }

    const getBadges = async () => {
        const result = await es.getUserBadges(user);
        setBadges(result);
    }

    const getCompanyAchievements = async () => {
        const result = await es.getValidCompanyAchievementForUser(globalContext.company, user.id);
        setAchievements(result);
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
        >
            {!window.overwolf &&
                <DesktopTakeover container={container} className="my-3"></DesktopTakeover>
            }
            <Row noGutters className="">
                <Col>
                    <Row noGutters>
                        <Col>
                            <Card className="h-100">
                                <CardBody>
                                    {((location && location.state && !location.state.hideBack) || location.state == undefined) && window.overwolf &&
                                        <div class="mb-3">
                                            <Button size="sm" onClick={() => navigate(-1)} color="secondary"><FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>{container.localeManager.strings.back}</Button>
                                        </div>
                                    }
                                    {user != null &&
                                        <>
                                            <Row>
                                                <Col>
                                                    <ProfileV2 container={container} user={user} company={user.company} role={user.role}></ProfileV2>
                                                </Col>
                                            </Row>
                                            <Nav className="nav-custom nav-line-tabs nav-line-tabs-2x fs-6 fw-bold mb-3" tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 'highlights' })}
                                                        onClick={() => { toggleTab('highlights'); }}
                                                    >
                                                        {container.localeManager.strings.highlights}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 'periodStats' })}
                                                        onClick={() => { toggleTab('periodStats'); }}
                                                    >
                                                        {container.localeManager.strings.periodStats}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 'perGameStats' })}
                                                        onClick={() => { toggleTab('perGameStats'); }}
                                                    >
                                                        {container.localeManager.strings.perGameStats}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 'jobs' })}
                                                        onClick={() => { toggleTab('jobs'); }}
                                                    >
                                                        {container.localeManager.strings.jobs}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 'badges' })}
                                                        onClick={() => { toggleTab('badges'); }}
                                                    >
                                                        {container.localeManager.strings.memberAward}
                                                    </NavLink>
                                                </NavItem>
                                                {showVehicles &&
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === 'vehicles' })}
                                                            onClick={() => { toggleTab('vehicles'); }}
                                                        >
                                                            {container.localeManager.strings.vehicles}
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                                {showAchievements &&
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === 'achievements' })}
                                                            onClick={() => { toggleTab('achievements'); }}
                                                        >
                                                            {container.localeManager.strings.companyAchievements}
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                            </Nav>
                                            <TabContent className="mt-3" activeTab={activeTab}>
                                                {activeTab == 'highlights' &&
                                                    <TabPane tabId="highlights">
                                                        <ProfileStats container={container} user={user}></ProfileStats>
                                                    </TabPane>
                                                }
                                                {activeTab == 'periodStats' &&
                                                    <TabPane tabId="periodStats">
                                                        <UserMonthlyStats container={container} user={user}></UserMonthlyStats>
                                                    </TabPane>
                                                }
                                                {activeTab == 'perGameStats' &&
                                                    <TabPane tabId="perGameStats">
                                                        <AggregatedStatsPerGame container={container} user={user}></AggregatedStatsPerGame>
                                                    </TabPane>
                                                }
                                                {activeTab == 'jobs' &&
                                                    <TabPane tabId="jobs">
                                                        <MemberJobs container={container} user={user}></MemberJobs>
                                                    </TabPane>
                                                }
                                                <TabPane tabId="vehicles">
                                                    <VehiclesTable vehicles={vehicles} company={globalContext.company} member={user} container={container} showDriverName={false} showSellButton={false}></VehiclesTable>
                                                </TabPane>
                                                <TabPane tabId="achievements">
                                                    <AchievementsList container={container} achievements={achievements}></AchievementsList>
                                                </TabPane>
                                                <TabPane tabId="badges">
                                                    <div className='grid cols-5'>
                                                        {badges.map((m) =>
                                                            <div className='border rounded bg-light p-3 mb-2'>
                                                                <SingleMemberBadge showPeriod={true} hideAvatar={true} container={container} badge={m}></SingleMemberBadge>
                                                            </div>
                                                        )}
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        {!container.dataService.data.enablePremiumFeature() &&
                            <aside className="ad-column-placeholder">
                                {!window.overwolf &&
                                    <BoxDynamicAd container={container} className="my-3" />
                                }
                            </aside>
                        }
                    </Row>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Col>
            </Row>
            {!window.overwolf &&
                <VenatusLeaderboardAd container={container} className="my-3"></VenatusLeaderboardAd>
            }
        </LoadingOverlay>
    );

};

export default UserDetail;