import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { EnterpriseContext } from "./EnterpriseContext";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import RandomCompanies from "./RandomCompanies";
import truckyService from "../../common/services/trucky-service";

const CreateCompanyLanding = ({ container }) => {

    const { globalContext, setGlobalContext } = useContext(EnterpriseContext);
    const [loading, setLoading] = useState(false);
    const enterpriseService = new EnterpriseService();
    const [loadingMessage, setLoadingMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {

        truckyService.setDocumentTitle('Register Your Company');

        if (globalContext.company != null) {
            navigate('/vtchub');
            return;
        }
    }, []);

    const importCompanyFromTmp = async () => {
        setLoading(true);
        const existsTmpCompany = await enterpriseService.checkTmpCompany();
        if (existsTmpCompany.found && existsTmpCompany.company.is_owner) {

            setLoadingMessage(container.localeManager.formatString(container.localeManager.strings.importing, (existsTmpCompany.company.name)));

            const _company = await enterpriseService.createCompanyFromTmp();

            const _member = await enterpriseService.me();

            setGlobalContext({ member: _member, company: _company });

            navigate('/vtchub');
        }
        else {
            toast.error(container.localeManager.strings.noTmpCompanyOrNotOwner);
        }
        setLoading(false);
    };

    return (
        <LoadingOverlay
            active={loading}
            text={<div className="text-center mt-3">{loadingMessage}</div>}
            spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
        >
            <Row>
                <Col>
                    <div className="align-items-center d-flex flex-column align-content-center h-100 justify-content-center mt-10 px-10">
                        <RandomCompanies container={container}></RandomCompanies>
                        <div className="my-auto mb-10 w-100">
                            <Card>
                                <CardBody className="text-center">
                                    <h2 class="fs-2x fw-bold mb-0">{container.localeManager.strings.createOwnCompany}</h2>
                                    <p class="text-gray-400 fs-4 fw-semibold py-7">{container.localeManager.strings.chooseCompanyStyle}</p>
                                    <Button className="fs-6 px-8 py-4" onClick={() => navigate(`/company/create`)} color="primary"><FontAwesomeIcon icon="plus"></FontAwesomeIcon>{container.localeManager.strings.createANewCompany}</Button>
                                </CardBody>
                            </Card>
                            <Card className="mt-5">
                                <CardBody className="text-center">
                                    {container.localeManager.strings.tmpCompanyOwner}
                                    <div className="mt-3">
                                        <Button onClick={importCompanyFromTmp} color="primary"><FontAwesomeIcon icon="file-import"></FontAwesomeIcon>{container.localeManager.strings.importYourCompany}</Button>
                                        <div className="mt-3 text-center">
                                            <small>
                                                {container.localeManager.strings.couldTakeSomeTime}
                                            </small>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Col>
                <aside className="ad-column-placeholder">
                </aside>
            </Row>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </LoadingOverlay>
    )
};

export default CreateCompanyLanding;