import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import Loader from "../ui/Loader";
import { EnterpriseContext } from "./EnterpriseContext";
import EnterpriseNavBar from "./EnterpriseNavBar";
import { useNavigate } from "react-router";
import MemberBadges from "./MemberBadges";
import CompanyAwards from "./CompanyAwards";
import MileStatsCards from "./MileStatsCards";
import EcoStatsCard from "./EcoStatsCards";
import CustomBadge from "../ui/CustomBadge";
import truckyService from "../../common/services/trucky-service";
import AggregatedStatsCharts from "./AggregatedStatsCharts";
import CountUp from "react-countup";
import Chart from "react-apexcharts";
import PeriodSelector from "./PeriodSelector";
import CompanyMemberStats from "./CompanyMemberStats";
import UserDefinedUnits from "../../common/utils/UserDefinedUnits";

const moment = require("moment");
const momentDurationFormatSetup = require("moment-duration-format");

const MonthlyCompanyStatsInnerComponent = ({
  company,
  container,
  detailedMembersStats = false,
  detailedJobsStats = false,
  enableNavigation = true,
  showAggregatedStats = false,
  showAggregatedJobsChart = false,
}) => {
  momentDurationFormatSetup(container.localeManager.moment);

  const es = new EnterpriseService();
  const [stats, setStats] = useState(null);
  const [currentPeriod, setCurrentPeriod] = useState(
    enableNavigation
      ? truckyService.loadSearchParameters("companystats_currentPeriod2", {
          month: moment().month(),
          year: moment().year(),
          week: moment().isoWeek(),
        })
      : {
          month: moment().month(),
          year: moment().year(),
          week: moment().isoWeek(),
        }
  );
  const [loading, setLoading] = useState(false);
  const [membersStats, setMembersStats] = useState(null);
  const [ecoStats, setEcoStats] = useState(null);
  const navigate = useNavigate();
  const [period, setPeriod] = useState(
    enableNavigation
      ? truckyService.loadSearchParameters("companystats_period", "monthly")
      : "monthly"
  );
  const [aggregatedStats, setAggregatedStats] = useState(null);
  const [loadingChart, setLoadingChart] = useState(false);
  const [jobsGroupedByDate, setJobsGroupedByDate] = useState([]);

  const { globalContext } = useContext(EnterpriseContext);

  const member = globalContext.member;

  useEffect(() => {
    if (showAggregatedStats) getAggregatedStats();
  }, []);

  useEffect(() => {
    loadData();
  }, [period, currentPeriod.month, currentPeriod.year, currentPeriod.week]);

  useEffect(() => {
    if (enableNavigation)
      truckyService.saveSearchParameters("companystats_period", period);
  }, [period]);

  useEffect(() => {
    if (enableNavigation)
      truckyService.saveSearchParameters(
        "companystats_currentPeriod2",
        currentPeriod
      );
  }, [currentPeriod.month, currentPeriod.year, currentPeriod.week]);

  const loadData = async () => {
    getStats();

    if (
      detailedJobsStats &&
      (company.company_type == "realistic" || company.company_type == "both")
    ) {
      getEcoStats();
    }

    if (showAggregatedJobsChart) {
      getJobsGroupedByDate();
    }
  };

  const getAggregatedStats = async () => {
    const result = await es.getCompanyAggregatedStats(company.id);
    setAggregatedStats(result);
  };

  const getStats = async () => {
    let result;

    setLoading(true);

    if (period == "monthly" || period == "weekly")
      result = await es.getCompanyStatsMonthly(
        company.id,
        currentPeriod.month + 1,
        currentPeriod.year
      );
    else
      result = await es.getCompanyStatsYearly(company.id, currentPeriod.year);

    setStats(result);
    setLoading(false);
  };

  const getEcoStats = async () => {
    let result;

    if (period == "monthly" || period == "weekly")
      result = await es.getEcoCompanyStatsMonthly(
        company.id,
        currentPeriod.month + 1,
        currentPeriod.year
      );
    else
      result = await es.getEcoCompanyStatsYearly(
        company.id,
        currentPeriod.year
      );

    setEcoStats(result);
  };

  const getJobsGroupedByDate = async () => {
    setLoadingChart(true);
    const filters = {
      period: period,
      month: currentPeriod.month + 1,
      year: currentPeriod.year,
      week: currentPeriod.week,
    };
    const result = await es.getCompanyJobsGroupedByDate(company.id, filters);
    setJobsGroupedByDate(result);
    setLoadingChart(false);
  };

  const renderJobsChart = () => {
    return (
      <Chart
        height={350}
        width={700}
        type="line"
        options={{
          chart: {
            redrawOnParentResize: true,
            type: "line",
            height: 350,
            width: 700,
            toolbar: {
              tools: {
                download: false,
              },
            },
          },
          grid: {
            borderColor: "#2b2b40",
          },
          stroke: {
            width: [0, 4],
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          labels: jobsGroupedByDate.map((m) => m.date),
          xaxis: {
            type: "datetime",
          },
          yaxis: {
            labels: {
              formatter: (value) =>
                container.localeManager.numeral(value).format("0,0") +
                " " +
                UserDefinedUnits.getDistanceUnitLabel(
                  globalContext.member,
                  null
                ),
            },
          },
        }}
        series={[
          {
            name: container.localeManager.strings.totDistance,
            type: "column",
            data: jobsGroupedByDate.map((m) =>
              UserDefinedUnits.convertDistanceValue(
                member,
                null,
                m.total_distance_km
              )
            ),
          },
          {
            name: container.localeManager.strings.avg,
            type: "line",
            data: jobsGroupedByDate.map((m) =>
              UserDefinedUnits.convertDistanceValue(
                member,
                null,
                m.avg_distance_km
              )
            ),
          },
        ]}
      ></Chart>
    );
  };

  return (
    <>
      {aggregatedStats != null && showAggregatedStats && (
        <div>
          <Row className="gx-0">
            <div className="grid cols-3 mb-3">
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  <CountUp
                    className="fs-2 fw-bolder"
                    end={aggregatedStats.jobs_delivered}
                  ></CountUp>
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.jobsDelivered}
                </div>
              </div>
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  <CountUp
                    className="fs-2 fw-bolder"
                    end={UserDefinedUnits.convertDistanceValue(
                      member,
                      null,
                      aggregatedStats.distance_driven_on_job_km
                    )}
                    suffix={
                      " " + UserDefinedUnits.getDistanceUnitLabel(member, null)
                    }
                    decimal={0}
                  ></CountUp>
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.totDistance}
                </div>
              </div>
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  <CountUp
                    className="fs-2 fw-bolder"
                    end={UserDefinedUnits.convertDistanceValue(
                      member,
                      null,
                      aggregatedStats.max_real_distance_km
                    )}
                    suffix={
                      " " + UserDefinedUnits.getDistanceUnitLabel(member, null)
                    }
                    decimal={0}
                  ></CountUp>
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.longedJobDistance}
                </div>
              </div>
            </div>
            <div className="grid cols-3 mb-3">
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  <CountUp
                    className="fs-2 fw-bolder"
                    end={UserDefinedUnits.convertDistanceValue(
                      member,
                      null,
                      aggregatedStats.avg_real_distance_km
                    )}
                    suffix={
                      " " + UserDefinedUnits.getDistanceUnitLabel(member, null)
                    }
                    decimal={0}
                  ></CountUp>
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.averageJobDistance}
                </div>
              </div>
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {moment
                    .duration(aggregatedStats.max_driving_time, "seconds")
                    .format("h _ m _")}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.longestJobTime}
                </div>
              </div>
              <div className="border rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {moment
                    .duration(aggregatedStats.avg_driving_time, "seconds")
                    .format("h _ m _")}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.avgJobTime}
                </div>
              </div>
            </div>
          </Row>
          <AggregatedStatsCharts
            container={container}
            stats={aggregatedStats}
          ></AggregatedStatsCharts>
        </div>
      )}
      {!loading && enableNavigation && (
        <PeriodSelector
          enableDaily={false}
          container={container}
          onChangeRange={setPeriod}
          onChangePeriod={setCurrentPeriod}
          state={{ range: period, currentPeriod: currentPeriod }}
        ></PeriodSelector>
      )}
      {showAggregatedJobsChart && loadingChart && <Loader></Loader>}
      {showAggregatedJobsChart && !loadingChart && (
        <div className="border rounded p-3 d-flex justify-content-center my-3">
          {renderJobsChart()}
        </div>
      )}
      {!loading && stats != null && (
        <MileStatsCards
          period={period}
          container={container}
          stats={stats}
          company={company}
          ets2_preferred_distance_unit={company.preferred_distance_unit}
          ats_preferred_distance_unit={company.preferred_distance_unit}
        ></MileStatsCards>
      )}
      {(period == "monthly" || period == "weekly") && (
        <>
          <CompanyAwards
            company={company}
            month={currentPeriod.month + 1}
            year={currentPeriod.year}
            container={container}
          ></CompanyAwards>
          <MemberBadges
            company={company}
            month={currentPeriod.month + 1}
            year={currentPeriod.year}
            container={container}
          ></MemberBadges>
        </>
      )}
      {!loading && ecoStats != null && (
        <EcoStatsCard
          container={container}
          ecoStats={ecoStats}
          company={company}
          currency={company.currency}
          volume_unit={company.preferred_volume_unit}
          distance_unit={company.preferred_distance_unit}
          weight_unit={company.preferred_weight_unit}
        ></EcoStatsCard>
      )}
      {detailedMembersStats && (
        <CompanyMemberStats
          company={company}
          container={container}
          currentPeriod={currentPeriod}
          period={period}
        ></CompanyMemberStats>
      )}
    </>
  );
};

export default MonthlyCompanyStatsInnerComponent;
