const Events = {
    CLOSE_APP: "CLOSE_APP",
    SETTINGS_UPDATED: "SETTINGS_UPDATED",
    TELEMETRY_UPDATE: "TELEMETRY_UPDATE",
    SERVERS_UPDATE: "SERVERS_UPDATE",
    FRIENDS_UPDATE: "FRIENDS_UPDATE",
    GAME_IS_RUNNING: "GAME_IS_RUNNING",
    FIRST_STEAMID_UPDATE: "FIRST_STEAMID_UPDATE",
    MP_SERVER_UPDATE: "MP_SERVER_UPDATE",
    MAP_SERVER_UPDATE: "MAP_SERVER_UPDATE",
    AREA_TRAFFIC_REPORT: "AREA_TRAFFIC_REPORT",
    TRAFFIC_ALERT: "TRAFFIC_ALERT",
    HIDE_ALERT: "HIDE_ALERT",
    UPDATE_NOTIFY_RESULT: "UPDATE_NOTIFY_RESULT",
    PLAYING_RADIO: "PLAYING_RADIO",
    RADIO_STOPPED: "RADIO_STOPPED",
    NOW_PLAYING: "NOW_PLAYING",
    CONVOY_SELECTED: "CONVOY_SELECTED",
    ROUTE_SELECTED: "ROUTE_SELECTED",
    CONVOY_JOINED: "CONVOY_JOINED",
    OPEN_CONVOY: "OPEN_CONVOY",
    SEND_CONVOY_MESSAGE: "SEND_CONVOY_MESSAGE",
    CONVOY_MESSAGE_RECEIVED: "CONVOY_MESSAGE_RECEIVED",
    CONVOY_ACTION_RECEIVED: "CONVOY_ACTION_RECEIVED",
    CONVOY_JOIN_STATUS_CHANGE: "CONVOY_JOIN_STATUS_CHANGE",
    GAME_SESSION_STATS_UPDATE: "GAME_SESSION_STATS_UPDATE",
    MEDIA_ACQUIRED: "MEDIA_ACQUIRED",
    REALOPS_MESSAGE_RECEIVED: "REALOPS_MESSAGE_RECEIVED",
    TMP_CHAT_MESSAGE: "TMP_CHAT_MESSAGE",
    TRAFFIC_REPORT_RECEIVED: "TRAFFIC_REPORT_RECEIVED",
    TRAFFIC_DATA_UPDATED: "TRAFFIC_DATA_UPDATED",
    TOP_TRAFFIC_DATA_UPDATED: "TOP_TRAFFIC_DATA_UPDATED",
    MOD_START_DOWNLOAD: "MOD_START_DOWNLOAD",
    MOD_DOWNLOAD_STARTED: "MOD_DOWNLOAD_STARTED",
    MOD_DOWNLOAD_COMPLETED: "MOD_DOWNLOAD_COMPLETED",
    MOD_DOWNLOAD_ERROR: "MOD_DOWNLOAD_ERROR",
    MOD_DOWNLOAD_PROGRESS_CHANGE: "MOD_DOWNLOAD_PROGRESS_CHANGE",
    MOD_DOWNLOAD_STATUS_UPDATE: "MOD_DOWNLOAD_STATUS_UPDATE",
    MOD_DB_UPDATED: "MOD_DB_UPDATED",
    MOD_SHOW_PROJECT: "MOD_SHOW_PROJECT",
    MOD_LIBRARY_UPDATED: "MOD_LIBRARY_UPDATED",
    LANGUAGE_CHANGED: "LANGUAGE_CHANGED",
    SCS_MP_SESSION_CHANGED: "SCS_MP_SESSION_CHANGED",
    BUS_LINE_STARTED: "BUS_LINE_STARTED",
    BUS_LINE_STOP_REACHED: "BUS_LINE_STOP_REACHED",
    BUS_LINE_END: "BUS_LINE_END",
    BUS_LINE_CANCELLED: "BUS_LINE_CANCELLED",
    BUS_ROUTE_STATE_CHANGED: "BUS_ROUTE_STATE_CHANGED",
    SHOW_GAME_SUMMARY: "SHOW_GAME_SUMMARY",
    REWARDS_DATA_UPDATED: "REWARDS_DATA_UPDATED",
    REFRESH_DESKTOP_HELPER: "REFRESH_DESKTOP_HELPER",
    SWOW_DESKTOP_HELPER: "SWOW_DESKTOP_HELPER",
    MAXIMIZE_DESKTOP_HELPER: "MAXIMIZE_DESKTOP_HELPER",
    JOB_STARTED: "JOB_STARTED",
    JOB_CANCELED: "JOB_CANCELED",
    JOB_DELIVERED: "JOB_DELIVERED",
    FINED: "FINED",
    TRANSPORT_USED: "TRANSPORT_USED",
    TOLLGATE_PAID: "TOLLGATE_PAID",
    GAMEPLAY_COLLISION: "GAMEPLAY_COLLISION",
    GAMEPLAY_EVENT: "GAMEPLAY_EVENT",
    BROWSER_LOGIN_RECEIVED: "BROWSER_LOGIN_RECEIVED",
    BROWSER_LOGIN_COMPLETED: "BROWSER_LOGIN_COMPLETED",
    PROFILE_UPDATED: "PROFILE_UPDATED",
    OFFLINE_ONLINE_STATUS_CHANGED: "OFFLINE_ONLINE_STATUS_CHANGED",
    NOTIFICATIONS_CLEARED: "NOTIFICATIONS_CLEARED",
    NOTIFICATIONS_RECEIVED: "NOTIFICATIONS_RECEIVED",
    DO_NAVIGATION: "DO_NAVIGATION",
    UPDATE_PROFILE_ON_GAME_SUMMARY: "UPDATE_PROFILE_ON_GAME_SUMMARY",
    ACHIEVEMENTS_PROCESSING_COMPLETE: "ACHIEVEMENTS_PROCESSING_COMPLETE",
    MESSAGE_RECEIVED: "MESSAGE_RECEIVED",
    THREAD_STARTED: "THREAD_STARTED",
    PRESENCE_USERS_UPDATED: "PRESENCE_USERS_UPDATED",
    PRESENCE_USER_JOINED: "PRESENCE_USER_JOINED",
    PRESENCE_USER_LEFT: "PRESENCE_USER_LEFT",
    PRESENCE_STATUS_UPDATE: "PRESENCE_STATUS_UPDATE",
    UNREAD_MESSAGES_COUNT_UPDATE: "UNREAD_MESSAGES_COUNT_UPDATE",
    SOCIAL_ACCOUNT_LINKED: "SOCIAL_ACCOUNT_LINKED",
    USER_LOCATION_UPDATED: "USER_LOCATION_UPDATED"
};

export default Events;