import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

const OrderByButtons = ({ field, currentSortingField, currentSortingDirection, onClick, container }) => {

    const isArrowSelected = (direction) => {
        if (currentSortingField == field && currentSortingDirection == direction)
            return true;
        else
            return false;
    }

    return (
        <>
            <a><FontAwesomeIcon
                style={{ fontSize: '0.8rem' }}
                title={container.localeManager.strings.orderByAscending}
                className={classnames({ "ms-1": true, "me-0": true, "active": isArrowSelected("asc"), "text-muted": !isArrowSelected("asc") })}
                icon="arrow-up" onClick={() => onClick({ field: field, direction: 'asc' })}>
            </FontAwesomeIcon></a>
            <a><FontAwesomeIcon
                style={{ fontSize: '0.8rem' }}
                className={classnames({ "ms-1": true, "active": isArrowSelected("desc"), "text-muted": !isArrowSelected("desc") })}
                title={container.localeManager.strings.orderByDescending}
                icon="arrow-down" onClick={() => onClick({ field: field, direction: 'desc' })}></FontAwesomeIcon></a>
        </>
    )
}

export default OrderByButtons;