import Chart from "react-apexcharts";
import { getCSSVariableValue } from '../../common/plugins/metronic/_utils';

const PieChart = ({ labels, series, width = 380, height = 200, title }) => {
    return (
        <Chart options={{
            title: {
                text: title,
                margin: 0
            },
            chart: {
                redrawOnParentResize: true,
                width: width,
                type: 'pie',
            },
            theme: {
                palette: 'palette6'
            },
            stroke: {
                colors: ["#535A6C"],
                width: 1
            },
            labels: labels
        }}
            height={height}
            series={series}
            type="pie"
            width={width}
        />
    )
}

export default PieChart;