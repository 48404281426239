import { useContext } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { EnterpriseContext } from "./EnterpriseContext";
import UserDefinedUnits from "../../common/utils/UserDefinedUnits";
import CountUp from "react-countup";
import TooltipIcon from "../ui/TooltipIcon";
import EnterpriseService from "../../common/services/enterprise-service";

const EcoStatsCard = ({
  container,
  ecoStats,
  currency,
  volume_unit,
  distance_unit,
  weight_unit,
  user_id = null,
  company_id = null,
}) => {
  const { globalContext } = useContext(EnterpriseContext);

  const es = new EnterpriseService();
  const member = globalContext.member;

  const showSalary = () => {
    if (globalContext.member && globalContext.member.id == user_id) return true;
    if (
      globalContext.member &&
      globalContext.member.company_id == company_id &&
      (es.can(globalContext.member, "company.*") ||
        es.can(globalContext.member, "members.*"))
    )
      return true;

    return false;
  };

  return (
    <Row className="mt-3">
      <Col>
        <Card className="mx-0">
          <CardHeader>
            {container.localeManager.strings.economyStats}
          </CardHeader>
          <CardBody>
            <div class="grid cols-4 mb-3">
              <div class="border border-success border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {container.localeManager
                    .numeral(ecoStats.revenue)
                    .format("0,0")}{" "}
                  {currency}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.revenue}
                </div>
              </div>
              <div class="border border-danger border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {container.localeManager
                    .numeral(ecoStats.taxes)
                    .format("0,0")}{" "}
                  {currency}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.taxes}
                </div>
              </div>
              <div class="border border-danger border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {container.localeManager
                    .numeral(ecoStats.rent_cost)
                    .format("0,0")}{" "}
                  {currency}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.rentCost}
                </div>
              </div>
              <div class="border border-danger border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {container.localeManager
                    .numeral(ecoStats.fuel_cost)
                    .format("0,0")}{" "}
                  {currency}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.fuelCost}
                </div>
              </div>
            </div>
            <div class="grid cols-5 mb-3">
              <div class="border border-primary border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">{ecoStats.jobs}</div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.jobs}
                </div>
              </div>
              <div class="border border-primary border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {UserDefinedUnits.convertVolume(
                    member,
                    null,
                    ecoStats.fuel_used_l
                  )}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.fuelConsumed}
                </div>
              </div>
              <div class="border border-danger border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {container.localeManager
                    .numeral(ecoStats.damage_cost)
                    .format("0,0")}{" "}
                  {currency}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.damageCost}
                </div>
              </div>
              <div class="border border-primary border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {UserDefinedUnits.convertDistance(
                    member,
                    null,
                    ecoStats.driven_distance_km
                  )}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.drivenDistance}
                </div>
              </div>
              <div class="border border-primary border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {UserDefinedUnits.convertWeight(
                    member,
                    null,
                    ecoStats.cargo_mass_t
                  )}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.massTransported}
                </div>
              </div>
            </div>
            {user_id && showSalary() && ecoStats.total_salary > 0 && (
              <div className="grid cols-2 mt-3">
                <div className="border border-success border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                  <div class="fs-2 fw-bolder counted">
                    {container.localeManager
                      .numeral(ecoStats.total_salary)
                      .format()}{" "}
                    T¢
                  </div>
                  <div class="fw-bold fs-6 text-gray-600">
                    {container.localeManager.strings.total_salary}{" "}
                    <TooltipIcon
                      icon="eye"
                      id="total_salary"
                      text={container.localeManager.strings.visibleOnlyToYou}
                    ></TooltipIcon>
                  </div>
                </div>
                {user_id &&
                  showSalary() &&
                  ecoStats.total_salary_current_company > 0 && (
                    <div className="border border-success border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                      <div class="fs-2 fw-bolder counted">
                        {container.localeManager
                          .numeral(ecoStats.total_salary_current_company)
                          .format()}{" "}
                        T¢
                      </div>
                      <div class="fw-bold fs-6 text-gray-600">
                        {
                          container.localeManager.strings
                            .total_salary_current_company
                        }{" "}
                        <TooltipIcon
                          icon="eye"
                          id="total_salary_current_company"
                          text={
                            container.localeManager.strings.visibleOnlyToYou
                          }
                        ></TooltipIcon>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default EcoStatsCard;
