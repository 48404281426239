/* global overwolf */

import QueryString from "qs";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import config from "../../config";
import { Card, CardBody, CardTitle, Row, Col, Button, CardHeader, Table, Badge, Spinner, ListGroup, ListGroupItem, FormGroup, Label, Input } from 'reactstrap';
import EnterpriseNavBar from "./EnterpriseNavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EnterpriseContext } from "./EnterpriseContext";
import Switch from 'react-switch';
import { useNavigate, useParams } from 'react-router';
import LoadingOverlay from "react-loading-overlay";
import { toast, ToastContainer } from "react-toastify";

const UserBannerConfigurator = ({ container }) => {

    var defaultSettings = {
        showStats: 'right', showCompanyName: true, showRole: true, showCompanyLogo: true, roundCompanyLogo: true,
        roundUserAvatar: true, effect: 'none', textColor: '#FFFFFF', overlayColor: '#000000', blur: 50, opacity: 80,
        background: "ets1"
    };

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const member = container.dataService.enterpriseService.member;

    const es = new EnterpriseService();

    const [configuration, setConfiguration] = useState(defaultSettings);

    const previewBannerUrl = useMemo(() => {
        setLoading(true);
        return `${config.ENTERPRISE_URL}/user/${member.id}/badge?mode=preview&${QueryString.stringify(configuration)}`;
    }, [configuration]);

    useEffect(() => {
        if (member.banner_settings != null)

            if (member.banner_settings.user)
                delete member.banner_settings.user;

        setConfiguration({ ...configuration, ...member.banner_settings });
    }, []);

    const getImageUrl = () => {
        return `${config.ENTERPRISE_URL}/user/${member.id}/badge`;
    }

    const getHtmlCode = () => {
        return `<img src="${getImageUrl()}" />`;
    }

    const getMarkdown = () => {
        return `![My Trucky VTC Hub Profile](${getImageUrl()})`;
    }

    const getBBCode = () => {
        return `[img]${getImageUrl()}[/img]`;
    }

    const save = async () => {
        setLoading(true);
        await es.updateUserBannerSettings(configuration);
        toast.success('Banner Settings saved!');
        setLoading(false);
    }

    const onPreviewLoaded = () => {
        setLoading(false);
    }

    const reset = () => {
        setConfiguration(defaultSettings);
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
        >
            <Row noGutters>
                <Col>
                    <Card className="h-100">
                        <CardBody>
                            {window.overwolf &&
                                <div class="mb-3">
                                    <Button size="sm" onClick={() => navigate(-1)} color="secondary"><FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>{container.localeManager.strings.back}</Button>
                                </div>
                            }
                            <div className="mb-3 text-center">
                                <img onLoad={onPreviewLoaded} src={previewBannerUrl} style={{ maxHeight: 150 }} className="img-fluid" />
                            </div>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <label class="form-check form-switch form-check-custom form-check-solid">
                                            <input onChange={(e) => setConfiguration({ ...configuration, showCompanyName: e.currentTarget.checked })} class="form-check-input" type="checkbox" checked={configuration.showCompanyName} />
                                            <span class="form-check-label">{container.localeManager.strings.showCompanyName}</span>
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <label class="form-check form-switch form-check-custom form-check-solid">
                                            <input onChange={(e) => setConfiguration({ ...configuration, showCompanyLogo: e.currentTarget.checked })} class="form-check-input" type="checkbox" checked={configuration.showCompanyLogo} />
                                            <span class="form-check-label">{container.localeManager.strings.showCompanyLogo}</span>
                                        </label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <label class="form-check form-switch form-check-custom form-check-solid">
                                            <input onChange={(e) => setConfiguration({ ...configuration, showRole: e.currentTarget.checked })} class="form-check-input" type="checkbox" checked={configuration.showRole} />
                                            <span class="form-check-label">{container.localeManager.strings.showRole}</span>
                                        </label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <label class="form-check form-switch form-check-custom form-check-solid">
                                            <input onChange={(e) => setConfiguration({ ...configuration, roundCompanyLogo: e.currentTarget.checked })} class="form-check-input" type="checkbox" checked={configuration.roundCompanyLogo} />
                                            <span class="form-check-label">{container.localeManager.strings.roundCompanyLogo}</span>
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <label class="form-check form-switch form-check-custom form-check-solid">
                                            <input onChange={(e) => setConfiguration({ ...configuration, roundUserAvatar: e.currentTarget.checked })} class="form-check-input" type="checkbox" checked={configuration.roundUserAvatar} />
                                            <span class="form-check-label">{container.localeManager.strings.roundUserAvatar}</span>
                                        </label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Label>{container.localeManager.strings.showStats}</Label>
                                    <Input type="select" value={configuration.showStats} onChange={(e) => setConfiguration({ ...configuration, showStats: e.target.value })}>
                                        <option value="bottom">{container.localeManager.strings.bottom}</option>
                                        <option value="right">{container.localeManager.strings.right}</option>
                                    </Input>
                                </Col>
                                <Col sm={6}>
                                    <Label>{container.localeManager.strings.textColor}</Label>
                                    <div>
                                        <Input type="color" value={configuration.textColor} className="mt-1" onChange={(e) => setConfiguration({ ...configuration, textColor: e.target.value })}></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Label>{container.localeManager.strings.background}</Label>
                                    <Input type="select" value={configuration.background} onChange={(e) => setConfiguration({ ...configuration, background: e.target.value })}>
                                        <option value="ets1">ETS2 1</option>
                                        <option value="ats1">ATS 1</option>
                                        <option value="ats2">ATS 2</option>
                                    </Input>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Label>{container.localeManager.strings.backgroundEffect}</Label>
                                    <Input type="select" value={configuration.effect} onChange={(e) => setConfiguration({ ...configuration, effect: e.target.value })}>
                                        <option value="none">{container.localeManager.strings.none}</option>
                                        <option value="blur">{container.localeManager.strings.blur}</option>
                                        <option value="opacity">{container.localeManager.strings.opacity}</option>
                                        <option value="overlay">{container.localeManager.strings.coloredOverlay}</option>
                                    </Input>
                                </Col>
                                {configuration.effect == 'overlay' &&
                                    <Col sm={6}>
                                        <Label>{container.localeManager.strings.overlayColor}</Label>
                                        <div>
                                            <Input type="color" value={configuration.overlayColor} className="mt-1" onChange={(e) => setConfiguration({ ...configuration, overlayColor: e.target.value })}></Input>
                                        </div>
                                    </Col>
                                }
                                {configuration.effect == 'blur' &&
                                    <Col sm={6}>
                                        <Label>{container.localeManager.strings.blurStrength}</Label>
                                        <Input type="number" value={configuration.blur} min="0" max="100" onChange={(e) => setConfiguration({ ...configuration, blur: e.target.value })}></Input>
                                    </Col>
                                }
                            </Row>
                            {(configuration.effect == 'opacity' || configuration.effect == 'overlay') &&
                                <FormGroup row>

                                    <Col sm={6}>
                                        <Label>{container.localeManager.strings.opacity}</Label>
                                        <Input type="number" value={configuration.opacity} min="0" max="100" onChange={(e) => setConfiguration({ ...configuration, opacity: e.target.value })}></Input>
                                    </Col>
                                </FormGroup>
                            }
                            <FormGroup>
                                <Label>{container.localeManager.strings.htmlCode}</Label>
                                <div>
                                    <code>{getHtmlCode()}</code><a className="ms-1" title={container.localeManager.strings.copy} onClick={() => overwolf.utils.placeOnClipboard(getHtmlCode())}><FontAwesomeIcon icon="copy"></FontAwesomeIcon></a>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Col sm={6}>
                                    <Label>{container.localeManager.strings.imageUrl}</Label>
                                    <div>
                                        <code>{getImageUrl()}</code><a className="ms-1" title={container.localeManager.strings.copy} onClick={() => overwolf.utils.placeOnClipboard(getImageUrl())}><FontAwesomeIcon icon="copy"></FontAwesomeIcon></a>
                                    </div>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>{container.localeManager.strings.markdown}</Label>
                                <div>
                                    <code>{getMarkdown()}</code><a className="ms-1" title={container.localeManager.strings.copy} onClick={() => overwolf.utils.placeOnClipboard(getMarkdown())}><FontAwesomeIcon icon="copy"></FontAwesomeIcon></a>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>{container.localeManager.strings.bbCode}</Label>
                                <div>
                                    <code>{getBBCode()}</code><a className="ms-1" title={container.localeManager.strings.copy} onClick={() => overwolf.utils.placeOnClipboard(getBBCode())}><FontAwesomeIcon icon="copy"></FontAwesomeIcon></a>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Button size="sm" color="secondary" className="me-1" onClick={reset}>{container.localeManager.strings.resetToDefaults}</Button>
                                <Button size="sm" color="primary" onClick={save}>{container.localeManager.strings.save}</Button>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
                <aside className="ad-column-placeholder">
                </aside>
            </Row>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </LoadingOverlay>
    )
}

export default UserBannerConfigurator;