import { useContext, useEffect, useState } from "react";
import CountUp from "react-countup";
import EnterpriseService from "../../common/services/enterprise-service";
import Loader from "../ui/Loader";
import UserDefinedUnits from "../../common/utils/UserDefinedUnits";
import { EnterpriseContext } from "./EnterpriseContext";

const AggregatedStatsPerGame = ({ container, user }) => {

    const [stats, setStats] = useState(null);
    const es = new EnterpriseService();
    const [loading, setLoading] = useState(false);

    const { globalContext } = useContext(EnterpriseContext);

    const member = globalContext.member;

    useEffect(() => {
        getStats();
    }, [user]);

    const getStats = async () => {
        setLoading(true);
        const result = await es.getAggregatedStatsPerGame(user.id, 1);
        const result2 = await es.getAggregatedStatsPerGame(user.id, 2);
        setStats({ ets2: result, ats: result2 });
        setLoading(false);
    };

    const renderStatsRow = (label, key, counted, toFixed, unit) => {
        var translatedLabel = container.localeManager.strings[label];
        const games = ['ets2', 'ats'];
        const games_ids = [1, 2];
        return (
            <div className="d-flex justify-content-between align-items-center h-100px h-md-50px mb-1 bg-hover-secondary px-1 mb-3 mb-md-none">
                <div className="fw-bold fs-6 text-gray-600 me-1 me-md-none">
                    {translatedLabel}
                </div>
                <div className="d-flex flex-column flex-md-row gap-1 gap-md-3">
                    {games.map((game, index) => {
                        if (stats[game] != undefined && stats[game][key] != undefined) {

                            var rawValue = stats[game][key];
                            var _userUnit = unit;

                            if (counted && rawValue == "-")
                                counted = false;

                            if (counted && rawValue == 0) {
                                rawValue = "-";
                                counted = false;
                            }

                            if (unit == 'KM') {
                                _userUnit = UserDefinedUnits.getDistanceUnitLabel(member, games_ids[index]);
                                rawValue = UserDefinedUnits.convertDistanceValue(member, games_ids[index], rawValue);
                            }

                            return renderStatsCell(rawValue, counted, _userUnit);
                        }
                    })}
                </div>
            </div>
        )
    }

    const renderStatsCell = (value, counted, unit) => {
        return (
            <div className="border rounded text-center p-3 w-200px w-md-200px w-lg-300px">
                {counted &&
                    <CountUp separator=" "
                        decimals={0}
                        decimal="," end={value} suffix={unit ? ` ${unit}` : ''} className="fs-4 fw-bolder"></CountUp>
                }
                {!counted && <span className="fs-4 fw-bolder">{value}</span>}
            </div>
        )
    }

    return (
        <>
            {loading && <Loader></Loader>}
            {!loading && stats != null &&
                <>
                    <div className="d-flex justify-content-between align-items-center h-70px mb-1 px-1">
                        <div className="d-none d-md-inline-block"></div>
                        <div className="d-flex">
                            <div className="w-md-200px w-lg-300px p-3 text-center">
                                <img src={window.overwolf ? 'assets/games/ets2.png' : '/assets/games/ets2.png'} className="h-70px" />
                            </div>
                            <div className="w-md-200px w-lg-300px p-3 text-center">
                                <img src={window.overwolf ? 'assets/games/ats.png' : '/assets/games/ats.png'} className="h-70px" />
                            </div>
                        </div>
                    </div>
                    {renderStatsRow('preferred_brand', 'preferred_brand')}
                    {renderStatsRow('distance_driven_on_job', 'distance_driven_on_job_km', true, true, 'KM')}
                    {renderStatsRow('jobs_delivered', 'jobs_delivered', true)}
                    {renderStatsRow('max_real_distance', 'max_real_distance_km', true, true, 'KM')}
                    {renderStatsRow('avg_real_distance', 'avg_real_distance_km', true, true, 'KM')}
                    {renderStatsRow('most_source_company', 'most_source_company')}
                    {renderStatsRow('company_trailer_deliveries', 'company_trailer_deliveries', true)}
                    {renderStatsRow('owned_trailer_deliveries', 'owned_trailer_deliveries', true)}
                    {renderStatsRow('wotr_contracts', 'wotr_contracts', true)}
                    {renderStatsRow('most_source_city', 'most_source_city')}
                    {renderStatsRow('most_destination_company', 'most_destination_company')}
                    {renderStatsRow('most_delivered_cargo', 'most_delivered_cargo')}
                </>
            }
        </>
    )
};

export default AggregatedStatsPerGame;