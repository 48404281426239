import classNames from "classnames";
import { useEffect, useState } from "react";
import { Card, CardBody, CardColumns, CardTitle, Col, ListGroup, ListGroupItem, Nav, NavItem, Row, Spinner, TabContent, TabPane, NavLink } from "reactstrap";
import truckyapiClientService from "../../common/services/truckyapi-client-service";
import CustomBadge from "../../components/ui/CustomBadge";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import VenatusAd from "../../ads/VenatusAd";
import VenatusLeaderboardAd from "../../ads/VenatusLeaderboardAd";
import DesktopTakeover from "../../ads/DesktopTakeover";
import truckyService from "../../common/services/trucky-service";

const TrafficTabs = ({ container, top = false }) => {

    const [activeTab, setActiveTab] = useState(null);
    const [currentTrafficData, setCurrentTrafficData] = useState([]);
    const [trafficServers, setServers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        truckyService.setDocumentTitle('Traffic');
        getServers();
    }, []);

    useEffect(() => {
        if (activeTab != null) {
            if (top) {
                truckyapiClientService.getTopTraffic(activeTab.game, activeTab.code).then((result) => {
                    setCurrentTrafficData(result.response);
                });
            } else {
                truckyapiClientService.getAllTrafficData(activeTab.game, activeTab.code).then((result) => {
                    setCurrentTrafficData(result.response);
                });
            }
        }
    }, [activeTab]);

    const getServers = async () => {
        const result = await truckyapiClientService.getMapServers();
        setServers(result);
        setActiveTab({ game: result[0].game.toLowerCase(), code: result[0].code });
    };

    const renderCountry = (country) => {
        return (
            <div>
                <ListGroup flush={true}>
                    {renderLocations(country.locations)}
                </ListGroup>
            </div>
        );
    }

    const renderLocations = (locations) => {
        return locations.map(l => {
            var severityColor = "success";

            if (l.newSeverity == "Low") severityColor = "success";
            if (l.newSeverity == "Fluid") severityColor = "success";
            if (l.newSeverity == "Moderate") severityColor = "warning";
            if (l.newSeverity == "Heavy") severityColor = "danger";
            if (l.newSeverity == "Congested") severityColor = "danger";

            var speed = "";

            if (l.newSeverity != "Low" && l.averageSpeed > 1)
                speed = props.localeManager.strings.averageSpeed + " " + l.averageSpeed.toFixed(0) + " km/h";

            return (
                <ListGroupItem>
                    <div className="d-flex justify-content-between">
                        <div>
                            {l.name} - <span style={{ color: l.color }}>{l.newSeverity}</span>
                            {l.trafficJams > 0 &&
                                <div>
                                    <span className="text-danger font-weight-bold">{l.trafficJams} traffic jam{l.trafficJams > 1 ? 's' : ''} involving {l.playersInvolvedInTrafficJams} players</span>
                                </div>
                            }
                        </div>
                        <div>
                            <CustomBadge
                                color={severityColor}
                                className="traffic-badge float-right"
                            >
                                {l.players}
                            </CustomBadge>
                        </div>
                    </div>
                </ListGroupItem>
            );
        });
    }

    return (
        <div>
            <div>
                <DesktopTakeover container={container} className="my-3"></DesktopTakeover>
                <Nav className="nav-custom nav-line-tabs nav-line-tabs-2x fs-6 fw-bold mb-3" tabs>
                    {activeTab != null && trafficServers.map(t => {
                        return (
                            <NavItem key={t.game.toLowerCase() + t.code}>
                                <NavLink
                                    className={classNames({ active: activeTab.game.toLowerCase() == t.game.toLowerCase() && activeTab.code == t.code })}
                                    onClick={() => setActiveTab({ game: t.game.toLowerCase(), code: t.code })}>
                                    {t.game.toUpperCase()} - {t.shortname}
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>
                {!top && activeTab != null && (
                    <div>
                        <TabContent activeTab={activeTab.game.toLowerCase() + activeTab.code}>
                            <TabPane tabId={activeTab.game.toLowerCase() + activeTab.code}>
                                <ResponsiveMasonry
                                    gutter="10px"
                                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                                >
                                    <Masonry>
                                        {currentTrafficData.map(t => {
                                            return (
                                                <Card>
                                                    <CardBody>
                                                        <div className="fs-3 fw-bolder text-center">{t.country}</div>
                                                        {renderCountry(t)}
                                                    </CardBody>
                                                </Card>
                                            );
                                        })}
                                    </Masonry>
                                </ResponsiveMasonry>
                                <Row className="row-cols-1 row-cols-md-3 g-4">

                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>
                )}
                {top &&
                    <div>
                        <ListGroup flush>
                            {renderLocations(currentTrafficData)}
                        </ListGroup>
                    </div>
                }
            </div>
            <VenatusLeaderboardAd className="my-3" container={container} />
        </div>
    );
};

export default TrafficTabs;