import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { EnterpriseContext } from "./EnterpriseContext";
import { Link } from "react-router-dom";

const PersonalMaintenanceAlerts = ({ container }) => {

    const { globalContext, setGlobalContext } = useContext(EnterpriseContext);
    const es = new EnterpriseService();
    const [alerts, setAlerts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getMaintenanceAlerts();
    }, []);

    const getMaintenanceAlerts = async () => {
        const result = await es.getMemberVehiclesWithMaintenance(globalContext.member);
        setAlerts(result);
    };

    return (
        <>
            {alerts.length > 0 &&
                <Row>
                    <Col>
                        <div className="notice d-flex bg-light rounded border-warning border border-dashed p-6 mt-3 flex-column">
                            <div className="fs-6 mb-2"><FontAwesomeIcon icon="wrench" className="me-1"></FontAwesomeIcon>{container.localeManager.strings.maintenanceAlerts}</div>
                            <ul className="list-unstyled mb-0">
                                {alerts.map((m) => {
                                    return (
                                        <>
                                            {m.status == "need_maintenance" &&
                                                <li><Link className="text-warning" to={`/vehicle/${m.id}`}>{container.localeManager.formatString(container.localeManager.strings.vehicleNeedMaintenance, [`${m.model.brand.name} ${m.model.name}`])}</Link></li>
                                            }
                                            {m.status == "under_maintenance" &&
                                                <li><Link className="text-danger" to={`/vehicle/${m.id}`}>{container.localeManager.formatString(container.localeManager.strings.vehicleUnderMaintenance, [`${m.model.brand.name} ${m.model.name}`, container.localeManager.moment(m.maintenance_end_date).format('LLL')])}</Link></li>
                                            }
                                        </>
                                    )
                                })}
                            </ul>
                        </div>
                    </Col>
                </Row>
            }
        </>
    );
}

export default PersonalMaintenanceAlerts;