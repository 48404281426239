import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import Loader from "../ui/Loader";
import LastCompanyFeeds from "./CompanyFeeds";
import DailyCompanyMemberStats from "./DailyCompanyMemberStats";
import { EnterpriseContext } from "./EnterpriseContext";
import JobsTable from "./JobsTable";
import MonthlyCompanyStatsInnerComponent from "./MonthlyCompanyStatsInnerComponent";
import PersonalMaintenanceAlerts from "./PersonalMaintenanceAlerts";
import truckyService from "../../common/services/trucky-service";

const Dashboard = ({ container, stats }) => {

    const { globalContext } = useContext(EnterpriseContext);

    const company = globalContext.company;

    const [loading, setLoading] = useState(false);
    const es = new EnterpriseService();
    const [jobs, setJobs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        truckyService.setDocumentTitle('Your Company');

        getJobs();
    }, []);

    const getJobs = async () => {
        setLoading(true);
        const result = await es.getJobs(company, 10);
        setJobs(result.data);
        setLoading(false);
    }

    return (
        <>
            {(globalContext.company != null && (globalContext.company.company_type == 'realistic' || globalContext.company.company_type == 'both')) &&
                <PersonalMaintenanceAlerts container={container}></PersonalMaintenanceAlerts>
            }
            <LastCompanyFeeds container={container} company={company} limit="4"></LastCompanyFeeds>
            <DailyCompanyMemberStats container={container} company={company}></DailyCompanyMemberStats>
            <div className="mt-3">
                <div className="d-flex align-items-center mb-3">
                    <div className="flex-grow-1">
                        <h5>{container.localeManager.strings.currentMonthStats}</h5>
                    </div>
                    <div>
                        <Button size="sm" color="secondary" onClick={() => navigate("/stats")}><FontAwesomeIcon icon="chart-bar"></FontAwesomeIcon>{container.localeManager.strings.companyInsights}</Button>
                    </div>
                </div>
                <MonthlyCompanyStatsInnerComponent company={company} container={container} enableNavigation={false} detailedMembersStats={false} detailedJobsStats={true}></MonthlyCompanyStatsInnerComponent>
            </div>
            <Row className="mt-3">
                <Col>
                    {loading && <Loader></Loader>}
                    {!loading &&
                        <Card body className="bordered">
                            <div className="d-flex align-items-center mb-3">
                                <div className="flex-grow-1">
                                    <h5>{container.localeManager.strings.lastJobs}</h5>
                                </div>
                                <div>
                                    <Button size="sm" color="secondary" onClick={() => navigate("/jobs")}><FontAwesomeIcon icon="suitcase"></FontAwesomeIcon>{container.localeManager.strings.all}</Button>
                                </div>
                            </div>
                            <JobsTable jobs={jobs} container={container}></JobsTable>
                        </Card>
                    }
                </Col>
            </Row>
        </>
    )
};

export default Dashboard;