import CustomBadge from "../ui/CustomBadge";

const ProvisioningStatusBadge = ({ container, status, size = "sm" }) => {

    let color = "danger";

    switch (status) {
        case 'not_provisioned':
            color = "danger";
            break;
        case "provisioned":
            color = "success";
            break;
        case "provisioning":
            color = "warning";
            break;
        case "creating":
            color = "warning";
            break;
    }

    return (
        <CustomBadge size={size} color={color}>{container.localeManager.strings[status]}</CustomBadge>
    )
};

export default ProvisioningStatusBadge;