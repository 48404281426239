import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "reactstrap";
import truckyService from "../common/services/trucky-service";

const Plans = ({ container }) => {
  const renderPlan = (name, month, year) => {
    return (
      <div className="mb-7 text-center">
        <div className="text-primary mb-5 fw-boldest">
          <h1>{name}</h1>
        </div>
        <div class="text-center">
          <span
            class="fs-3 fw-bolder text-danger"
            data-kt-plan-price-month="39"
            data-kt-plan-price-annual="399"
          >
            {" "}
            {container.localeManager.formatString(
              container.localeManager.strings.subscriptionPricing,
              [month, year]
            )}
          </span>
        </div>
      </div>
    );
  };

  const renderBenefits = (items) => {
    return items.map((m) => {
      return (
        <div class="d-flex align-items-center mb-5">
          <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3">
            {m.title}
          </span>
          {m.enabled && (
            <FontAwesomeIcon
              className="text-success"
              icon="check-circle"
            ></FontAwesomeIcon>
          )}
          {!m.enabled && (
            <FontAwesomeIcon
              className="text-muted"
              icon="times-circle"
            ></FontAwesomeIcon>
          )}
        </div>
      );
    });
  };

  const renderSubscriptionsPageButton = (size) => {
    return (
      <Button
        tag="a"
        target="_blank"
        size={size}
        href="https://www.patreon.com/truckyapp"
        color="patreon-button"
      >
        <FontAwesomeIcon icon={["fab", "patreon"]}></FontAwesomeIcon>
        {container.localeManager.strings.becomeAPatron}
      </Button>
    );
  };

  return (
    <>
      <div class="my-3 text-center mb-5">
        <h3>{container.localeManager.strings.becomePatronText}</h3>
      </div>
      <Row>
        <Col>
          <div className="d-flex h-100 align-items-center">
            <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light py-15 px-10 mb-3 mb-lg-0">
              {renderPlan("Friend", 1, 10)}
              <div className="w-100 mb-5">
                {renderBenefits([
                  {
                    title: "Support Trucky",
                    enabled: true,
                  },
                  {
                    title: "Place on Patrons Wall of Fame",
                    enabled: true,
                  },
                  {
                    title: "Premium Features",
                    enabled: false,
                  },
                  {
                    title: "No Ads",
                    enabled: false,
                  },
                  {
                    title: "Access to preview releases",
                    enabled: false,
                  },
                ])}
              </div>
              {renderSubscriptionsPageButton("sm")}
            </div>
          </div>
        </Col>
        <Col>
          <div className="d-flex h-100 align-items-center">
            <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light py-20 px-10 mb-3 mb-lg-0">
              {renderPlan("Angel", 5, 54)}
              <div className="w-100 mb-5">
                {renderBenefits([
                  {
                    title: "Support Trucky",
                    enabled: true,
                  },
                  {
                    title: "Place on Patrons Wall of Fame",
                    enabled: true,
                  },
                  {
                    title: "Premium Features",
                    enabled: true,
                  },
                  {
                    title: "No Ads",
                    enabled: true,
                  },
                  {
                    title: "Access to preview releases",
                    enabled: true,
                  },
                ])}
              </div>
              {renderSubscriptionsPageButton("lg")}
            </div>
          </div>
        </Col>
        <Col>
          <div className="d-flex h-100 align-items-center">
            <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light py-15 px-10 mb-3 mb-lg-0">
              {renderPlan("Fan", 3, 32)}
              <div className="w-100 mb-5">
                {renderBenefits([
                  {
                    title: "Support Trucky",
                    enabled: true,
                  },
                  {
                    title: "Place on Patrons Wall of Fame",
                    enabled: true,
                  },
                  {
                    title: "Premium Features",
                    enabled: true,
                  },
                  {
                    title: "No Ads",
                    enabled: true,
                  },
                  {
                    title: "Access to preview releases",
                    enabled: false,
                  },
                ])}
              </div>
              {renderSubscriptionsPageButton("sm")}
            </div>
          </div>
        </Col>
      </Row>
      <div className="text-center my-5">
        <a
          className="fs-3"
          onClick={() =>
            truckyService.openUrlInDefaultBrowser(
              "https://truckyapp.com/kb/how-to-connect-the-app-to-patreon/"
            )
          }
        >
          {container.localeManager.strings.connectPatreonLink}
        </a>
      </div>
    </>
  );
};

export default Plans;
