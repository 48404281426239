/* global overwolf */

import _strings from "./resources";
import moment from 'moment/min/moment-with-locales'
import truckyService from "../common/services/trucky-service";
const localStorage_LAST_TRANSLATIONS_UPDATE_KEY = "lastTranslationsUpdate";
var numeral = require("numeral");
require("linqjs");
const format = require("string-format");
format.extend(String.prototype, {});
/* eslint-disable */

/**
 * Language and locale management. It contains an instance of LocalizedStrings and a localized instance of moment to datetime parsing and formatting
 *
 * @class LocaleManager
 */
class LocaleManager {
  constructor() {
    this._availableLanguages = [];
  }

  async initialize(downloadTranslations) {
    //var settings = await AppSettings.getSettings();

    this.language = this.normalizeLanguage(window.navigator.language);

    if (localStorage.getItem('language')) {
      this.language = localStorage.getItem('language');
    }

    truckyService.writeLog("User language detected is " + this.language);

    this.setLanguage(this.language);

    await this.downloadStrings();
  }

  async downloadStrings() {

    const lastTranslationsUpdate = localStorage.getItem(localStorage_LAST_TRANSLATIONS_UPDATE_KEY);
    var needToRefreshTranslations = true;

    /*if (lastTranslationsUpdate) {
      const lastTranslationsUpdateMoment = moment.unix(parseInt(lastTranslationsUpdate) / 1000);

      if (moment().diff(lastTranslationsUpdateMoment, 'hour') < 24) {
        needToRefreshTranslations = false;
      }
    }*/

    let newLanguageStrings;

    let languageToDownload = this.language;

    if (this.language == 'pt')
      languageToDownload = 'pt-PT';

    if (this.language == 'ca')
      languageToDownload = 'es';

    if (this.language == 'zh')
      languageToDownload = 'zh-CN';

    try {

      const url = `/languages/${languageToDownload}.json`;

      //if (needToRefreshTranslations) {
      const newLanguageStringsResponse = await fetch(url);
      newLanguageStrings = await newLanguageStringsResponse.json();

      //const cache = await caches.open('language-cache');
      //cache.put(url, new Response(JSON.stringify(newLanguageStrings)));

      localStorage.setItem(localStorage_LAST_TRANSLATIONS_UPDATE_KEY, moment().format('x'));
      //}
      //else {
      //const cache = await caches.open('language-cache');
      //const newLanguageStringsResponse = await cache.match(url);

      //if (newLanguageStringsResponse) {
      //  newLanguageStrings = await newLanguageStringsResponse.json();
      //}
      //}

      const newStrings = {};
      newStrings[this.language] = newLanguageStrings;
      _strings.setContent(newStrings);
    }
    catch {
      console.error('Error loading languages string for ' + this.language);
    }
  }

  async getLanguages() {
    truckyService.writeLog("Downloading languages");

    var languages = await TruckyAPIClient.getLanguages();

    for (let index = 0; index < languages.response.length; index++) {
      this._availableLanguages.push({
        displayName: languages.response[index].local_name,
        code: languages.response[index].code,
      });
    }
  }

  get availableLanguages() {
    return [
      {
        displayName: "Albanian",
        code: "sq",
        flag: "al",
      },
      {
        displayName: "Arab",
        code: "ar",
        flag: "sa",
      },
      {
        displayName: "Bulgarian",
        code: "bg",
        flag: "bg",
      },
      {
        displayName: "Bengali",
        code: "bn-BD",
        flag: "bd",
      },
      {
        displayName: "Bosnian",
        code: "bs",
        flag: "ba",
      },
      {
        displayName: "Chinese (Simplified)",
        code: "zh-CN",
        flag: "cn",
      },
      {
        displayName: "Chinese (Traditional)",
        code: "zh-TW",
        flag: "cn",
      },
      {
        displayName: "Czech",
        code: "cs",
        flag: "cz",
      },
      {
        displayName: "Danish",
        code: "da",
        flag: "dk",
      },
      {
        displayName: "Dutch",
        code: "nl",
        flag: "nl",
      },
      {
        displayName: "German",
        code: "de",
        flag: "de",
      },
      {
        displayName: "English",
        code: "en",
        flag: "gb",
      },
      {
        displayName: "Estonian",
        code: "et",
        flag: "ee",
      },
      {
        displayName: "Finnish",
        code: "fi",
        flag: "fi",
      },
      {
        displayName: "French",
        code: "fr",
        flag: "fr",
      },
      {
        displayName: "Georgian",
        code: "ka",
        flag: "ge",
      },
      {
        displayName: "Greek",
        code: "el",
        flag: "gr",
      },
      {
        displayName: "Farsi (Persian)",
        code: "fa",
        flag: "ir",
      },
      {
        displayName: "Hebrew",
        code: "he",
        flag: "il",
      },
      {
        displayName: "Hungarian",
        code: "hu",
        flag: "hu",
      },
      {
        displayName: "Italian",
        code: "it",
        flag: "it",
      },
      {
        displayName: "Indonesian",
        code: "id",
        flag: "id",
      },
      {
        displayName: "Latvian",
        code: "lv-LV",
        flag: "lv",
      },
      {
        displayName: "Lithuanian",
        code: "lt",
        flag: "lt",
      },
      {
        displayName: "Japanese",
        code: "ja",
        flag: "jp",
      },
      {
        displayName: "Korean",
        code: "ko",
        flag: "kr",
      },
      {
        displayName: "Norwegian",
        code: "nb",
        flag: "no",
      },
      {
        displayName: "Polish",
        code: "pl",
        flag: "pl",
      },
      {
        displayName: "Portuguese",
        code: "pt-PT",
        flag: "pt",
      },
      {
        displayName: "Portuguese (Brazilian)",
        code: "pt-BR",
        flag: "br",
      },
      {
        displayName: "Romanian",
        code: "ro",
        flag: "ro",
      },
      {
        displayName: "Russian",
        code: "ru",
        flag: "ru",
      },
      {
        displayName: "Serbian",
        code: "sr",
        flag: "rs",
      },
      {
        displayName: "Slovak",
        code: "sk",
        flag: "sk",
      },
      {
        displayName: "Slovenian",
        code: "sl",
        flag: "si",
      },
      {
        displayName: "Spanish",
        code: "es",
        flag: "es",
      },
      {
        displayName: "Swedish",
        code: "sv",
        flag: "se",
      },
      {
        displayName: "Thai",
        code: "th",
        flag: "th",
      },
      {
        displayName: "Turkish",
        code: "tr",
        flag: "tr",
      },
      {
        displayName: "Ukranian",
        code: "uk",
        flag: "ua",
      },
      {
        displayName: "Welsh",
        code: "cy",
        flag: "gb",
      },
    ];
    //return this._availableLanguages;
  }
  // http://www.lingoes.net/en/translator/langcode.htm
  get supportedLanguages() {
    return [
      "en",
      "it",
      "en-US",
      "en-GB",
      "it-IT",
      "it-CH",
      "fr-FR",
      "bg-BG",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-LU",
      "fr-MC",
      "fr",
      "bg",
      "en-AU",
      "en-CA",
      "en-IE",
      "en-JM",
      "en-NZ",
      "en-ZA",
      "en-PH",
      "fi",
      "fi-FI",
      "es",
      "es-ES",
      "es-AR",
      "es-BO",
      "es-CL",
      "es-CO",
      "es-CR",
      "es-DO",
      "es-EC",
      "es-GT",
      "es-HN",
      "es-MX",
      "es-NI",
      "es-PA",
      "es-PE",
      "es-PR",
      "es-PY",
      "es-SV",
      "es-UY",
      "es-VE",
      "nl",
      "nl-NL",
      "nl-BE",
      "pl",
      "ps-PL",
      "de",
      "de-AT",
      "de-CH",
      "de-DE",
      "de-LI",
      "de-LU",
      "cs-CZ",
      "cs",
      "ru",
      "ru-RU",
      "pt-PT",
      "pt-BR",
      "zh", //	Chinese
      "zh-CN", //Chinese (S)
      "zh-HK", //Chinese (Hong Kong)
      "zh-MO", //	Chinese (Macau)
      "zh-SG", //	Chinese (Singapore)
      "zh-TW", // Chinese (T)
      "bn",
      "tr-TR",
      "tr",
      "ko",
      "ko-KR",
      "ro",
      "ro-RO",
      "nb",
      "nb-NO",
      "et",
      "et-EE",
      "hu",
      "hu-HU",
      "lt",
      "lt-LT",
      "sk",
      "sk-SK",
      "id",
      "id-ID",
      "da",
      "da-DK",
      "uk",
      "uk-UA",
      "lv-LV",
      "lv",
      "th",
      "th-TH",
      "sv",
      "sv-SE",
      "sv-FI",
      "el",
      "el-GR",
      "sr",
      "sr-BA",
      "sr-SP",
      "sq",
      "sq-AL",
      "ja",
      "ja-JP",
      "fa",
      "fa-IR",
      "ka",
      "ka-GE",
      "cy",
      "cy-GB",
      "bs-BA",
      "he",
      "sl",
      "sl-SI",
      "ar",
      "ar-AE",
      "ar-BH",
      "ar-DZ",
      "ar-EG", //	Arabic (Egypt)
      "ar-IQ", //	Arabic (Iraq)
      "ar-JO", //	Arabic (Jordan)
      "ar-KW", //	Arabic (Kuwait)
      "ar-LB", //	Arabic (Lebanon)
      "ar-LY", //	Arabic (Libya)
      "ar-MA", //	Arabic (Morocco)
      "ar-OM", //	Arabic (Oman)
      "ar-QA", //	Arabic (Qatar)
      "ar-SA", //	Arabic (Saudi Arabia)
      "ar-SY", //	Arabic (Syria)
      "ar-TN", //	Arabic (Tunisia)
      "ar-YE", //	Arabic (Yemen)
    ];
  }

  interfaceLanguageIsSupported() {
    return this.supportedLanguages.includes(this.interfaceLanguage);
  }

  normalizeLanguage(language) {
    var specialRegionalLanguage = this.availableLanguages.first(function (m) {
      return m.code == language;
    });

    if (specialRegionalLanguage != null) return specialRegionalLanguage.code;

    return language.split("-")[0].toLowerCase();
  }

  get interfaceLanguage() {
    return navigator.language || navigator.userLanguage;
  }

  get strings() {
    return _strings;
  }

  get moment() {
    return moment;
  }

  get numeral() {
    return numeral;
  }

  getMomentInstance() {
    return moment;
  }

  localeData() {
    return moment.localeData();
  }

  loadMomentLocale() {
    var momentLocaleConfiguration;
    var numeralLocale;
    var numeralLocaleString = "en";
    var momentLocale = 'en';

    switch (this.language.toLowerCase()) {
      case "it":
        momentLocaleConfiguration = require("moment/locale/it");
        numeralLocale = require("numeral/locales/it");
        numeralLocaleString = "it";
        momentLocale = 'it';
        break;
      case "fr":
        momentLocaleConfiguration = require("moment/locale/fr");
        numeralLocale = require("numeral/locales/fr");
        numeralLocaleString = "fr";
        momentLocale = 'fr';
        break;
      case "bg":
        momentLocaleConfiguration = require("moment/locale/bg");
        numeralLocale = require("numeral/locales/bg");
        numeralLocaleString = "bg";
        momentLocale = 'bg';
        break;
      case "fi":
        momentLocaleConfiguration = require("moment/locale/fi");
        numeralLocale = require("numeral/locales/fi");
        numeralLocaleString = "fi";
        momentLocale = 'fi';
        break;
      case "es":
        momentLocaleConfiguration = require("moment/locale/es");
        numeralLocale = require("numeral/locales/es");
        numeralLocaleString = "es";
        momentLocale = 'es';
        break;
      case "nl":
        momentLocaleConfiguration = require("moment/locale/nl");
        numeralLocale = require("numeral/locales/nl-nl");
        numeralLocaleString = "nl-nl";
        momentLocale = 'nl';
        break;
      case "pl":
        momentLocaleConfiguration = require("moment/locale/pl");
        numeralLocale = require("numeral/locales/pl");
        numeralLocaleString = "pl";
        momentLocale = 'pl';
        break;
      case "de":
        momentLocaleConfiguration = require("moment/locale/de");
        numeralLocale = require("numeral/locales/de");
        numeralLocaleString = "de";
        momentLocale = 'de';
        break;
      case "cs":
        momentLocaleConfiguration = require("moment/locale/cs");
        numeralLocale = require("numeral/locales/cs");
        numeralLocaleString = "cs";
        momentLocale = 'cs';
        break;
      case "ru":
        momentLocaleConfiguration = require("moment/locale/ru");
        numeralLocale = require("numeral/locales/ru");
        numeralLocaleString = "ru";
        momentLocale = 'ru';
        break;
      case "pt":
      case "pt-pt":
      case "pt-PT":
        momentLocaleConfiguration = require("moment/locale/pt");
        numeralLocale = require("numeral/locales/pt-pt");
        numeralLocaleString = "pt-pt";
        momentLocale = 'pt';
        break;
      case "pt-BR":
      case "pt-br":
        momentLocaleConfiguration = require("moment/locale/pt-br");
        numeralLocale = require("numeral/locales/pt-br");
        numeralLocaleString = "pt-br";
        momentLocale = 'pt-br';
        break;
      case "zh":
      case "zh-cn":
        momentLocaleConfiguration = require("moment/locale/zh-cn");
        momentLocale = 'zh-cn';
        break;
      case "zh-tw":
        momentLocaleConfiguration = require("moment/locale/zh-tw");
        momentLocale = 'zh-tw';
        break;
      case "bn":
        momentLocaleConfiguration = require("moment/locale/bn");
        momentLocale = 'bn';
        break;
      case "tr":
        momentLocaleConfiguration = require("moment/locale/tr");
        numeralLocale = require("numeral/locales/tr");
        numeralLocaleString = "tr";
        momentLocale = 'tr';
        break;
      case "ko":
        momentLocaleConfiguration = require("moment/locale/ko");
        momentLocale = 'ko';
        break;
      case "ro":
        momentLocaleConfiguration = require("moment/locale/ro");
        momentLocale = 'ro';
        break;
      case "nb":
        momentLocaleConfiguration = require("moment/locale/nb");
        numeralLocale = require("numeral/locales/no");
        numeralLocaleString = "no";
        momentLocale = 'no';
        break;
      case "et":
        momentLocaleConfiguration = require("moment/locale/et");
        numeralLocale = require("numeral/locales/et");
        numeralLocaleString = "et";
        momentLocale = 'et';
        break;
      case "hu":
        momentLocaleConfiguration = require("moment/locale/hu");
        numeralLocale = require("numeral/locales/hu");
        numeralLocaleString = "hu";
        momentLocale = 'hu';
        break;
      case "lt":
        momentLocaleConfiguration = require("moment/locale/lt");
        momentLocale = 'lt';
        break;
      case "sk":
        momentLocaleConfiguration = require("moment/locale/sk");
        numeralLocale = require("numeral/locales/sk");
        numeralLocaleString = "sk";
        momentLocale = 'sk';
        break;
      case "id":
        momentLocaleConfiguration = require("moment/locale/id");
        momentLocale = 'id';
        break;
      case "fil":
        momentLocaleConfiguration = require("moment/locale/tl-ph");
        momentLocale = 'tl-ph';
        break;
      case "da":
        momentLocaleConfiguration = require("moment/locale/da");
        numeralLocale = require("numeral/locales/da-dk");
        numeralLocaleString = "da-dk";
        momentLocale = 'da';
        break;
      case "uk":
        momentLocaleConfiguration = require("moment/locale/uk");
        numeralLocale = require("numeral/locales/uk-ua");
        numeralLocaleString = "uk-ua";
        momentLocale = 'uk';
        break;
      case "sv":
        momentLocaleConfiguration = require("moment/locale/sv");
        momentLocale = 'sv';
        break;
      case "el":
        momentLocaleConfiguration = require("moment/locale/el");
        momentLocale = 'el';
        break;
      case "th":
        momentLocaleConfiguration = require("moment/locale/th");
        numeralLocale = require("numeral/locales/th");
        numeralLocaleString = "th";
        momentLocale = 'th';
        break;
      case "lv":
      case "lv-LV":
        momentLocaleConfiguration = require("moment/locale/lv");
        numeralLocale = require("numeral/locales/lv");
        numeralLocaleString = "lv";
        momentLocale = 'lv';
        break;
      case "sr":
        momentLocaleConfiguration = require("moment/locale/sr");
        momentLocale = 'sr';
        break;
      case "sq":
        momentLocaleConfiguration = require("moment/locale/sq");
        momentLocale = 'sq';
        break;
      case "ja":
        momentLocaleConfiguration = require("moment/locale/ja");
        numeralLocale = require("numeral/locales/ja");
        numeralLocaleString = "ja";
        momentLocale = 'ja';
        break;
      case "fa":
        momentLocaleConfiguration = require("moment/locale/fa");
        momentLocale = 'fa';
        break;
      case "ka":
        momentLocaleConfiguration = require("moment/locale/ka");
        momentLocale = 'ka';
        break;
      case "he":
        momentLocaleConfiguration = require("moment/locale/he");
        momentLocale = 'he';
        break;
      case "cy":
        momentLocaleConfiguration = require("moment/locale/cy");
        momentLocale = 'cy';
        break;
      case "he":
        momentLocaleConfiguration = require("moment/locale/bs");
        momentLocale = 'bs';
        break;
      case "sl":
        momentLocaleConfiguration = require("moment/locale/sl");
        numeralLocale = require("numeral/locales/sl");
        numeralLocaleString = "sl";
        momentLocale = 'sl';
        break;
      case "ar":
        momentLocaleConfiguration = require("moment/locale/ar");
        momentLocale = 'ar';
        break;
      default:
        momentLocaleConfiguration = require("moment/locale/en-gb");
        numeralLocale = require("numeral/locales/en-gb");
        numeralLocaleString = "en";
        momentLocale = 'en';
        break;
    }

    moment.locale(momentLocale);

    //console.log(numeral.locales);
    numeral.locale(numeralLocaleString);

    return momentLocale;
  }

  async changeLanguage(language) {
    localStorage.removeItem(localStorage_LAST_TRANSLATIONS_UPDATE_KEY);
    localStorage.setItem('language', language);
    await this.setLanguage(language);
  }

  async setLanguage(language) {

    this.language = language;
    _strings.setLanguage(language);

    this.loadMomentLocale();

    truckyService.writeLog("Language changed to " + this.language);
  }

  formatString(format, values) {
    if (!Array.isArray(values)) {
      return format.format(values);
    } else {
      for (var i = 0; i < values.length; i++) {
        format = format.replace("{" + i.toString() + "}", values[i]);
      }

      return format;
    }
  }
}

export default LocaleManager;
