import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import CustomBadge from "../ui/CustomBadge";
import EnterpriseService from "../../common/services/enterprise-service";

const ServerStatusBadge = ({
  container,
  server,
  size = "sm",
  className,
  showPassword = false,
}) => {
  const es = new EnterpriseService();
  const [state, setState] = useState({
    status: "offline",
    color: "danger",
    players: 0,
    slots: 8,
    serverRunning: false,
    sessionID: null,
  });

  useEffect(() => {
    getState();
  }, []);

  const getState = async () => {
    try {
      const response = await es.getDedicatedServerStatus(server.id);

      if (response.serverRunning) {
        setState({
          status: "online",
          color: "success",
          players: response.connectedPlayers.length,
          slots: response.slots,
          sessionID: response.sessionID,
          serverRunning: response.serverRunning,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="mt-2">
        <CustomBadge className={className} color={state.color} size={size}>
          {container.localeManager.strings[state.status]} - {state.players}/
          {state.slots}
        </CustomBadge>
      </div>
      {state.serverRunning &&
        state.sessionID != null &&
        state.sessionID != "" && (
          <>
            <div className="mt-2">
              <FontAwesomeIcon
                icon="magnifying-glass"
                className="me-2"
              ></FontAwesomeIcon>
              {container.localeManager.strings.sessionID}: {state.sessionID}
            </div>
            {showPassword && server.password && server.password != "" && (
              <div className="mt-2">
                <FontAwesomeIcon icon="lock" className="ms-3"></FontAwesomeIcon>
                {container.localeManager.strings.password}: {server.password}
              </div>
            )}
          </>
        )}
    </>
  );
};

export default ServerStatusBadge;
