export default {
    PATREON_MEMBER: "patreonMember",
    STEAM_ID: "steamID",
    TOKEN: "token",
    STEAM_USER: "steamUser",
    TRUCKERSMP_USER: "truckersMPUser",
    ENTERPRISE_MEMBER: "enterpriseMember",
    GAMEDATA_COMPANIES: "gameData_companies",
    GAMEDATA_CITIES: "gameData_cities",
    GAMEDATA_CARGOES: "gameData_cargoes"
}