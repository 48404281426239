import { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { Alert, Button, ButtonDropdown, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table, UncontrolledButtonDropdown } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import reactSelectCustomStyles from "../../common/plugins/reactSelectStyles";
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import LoadingButton from "../ui/LoadingButton";
import { confirmWrapper } from '../ui/askConfirm'
import Loader from "../ui/Loader";
import { EnterpriseContext } from "./EnterpriseContext";
import LoadingOverlay from "react-loading-overlay";
import EnterpriseNavBar from "./EnterpriseNavBar";
import Tips from "../Tips";
import tipsList from "../../common/constants/tipsList";
import { useNavigate, useParams } from "react-router";

import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import VehiclesTable from "./VehiclesTable";
import { Link } from "react-router-dom";
import VenatusLeaderboardAd from "../../ads/VenatusLeaderboardAd";
import DesktopTakeover from "../../ads/DesktopTakeover";
import BoxDynamicAd from "../../ads/BoxDynamicAd";
import truckyService from "../../common/services/trucky-service";

const GarageDetail = ({ container }) => {

    const es = new EnterpriseService();
    const [garage, setGarage] = useState(null);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [point, setPoint] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [mechanics, setMechanics] = useState([]);
    const [newPlaces, setNewPlaces] = useState(1);
    const [randomMechanics, setRandomMechanics] = useState([]);
    const [randomMechanicsModalIsOpen, toggleRandomMechanicsModalIsOpen] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const navigate = useNavigate();

    const { globalContext } = useContext(EnterpriseContext);

    const company = globalContext.company;
    const member = globalContext.member;

    useEffect(() => {

        truckyService.setDocumentTitle('Garage Management');

        getGarage();
        getVehicles();
        getMechanics();
    }, []);

    const getGarage = async () => {
        setLoading(true);
        const result = await es.getGarage(company.id, id);
        setPoint([result.city.latitude, result.city.longitude]);
        setGarage(result);
        setLoading(false);
    };

    const getVehicles = async () => {
        const result = await es.getGarageVehicles(company.id, id);
        setVehicles(result);
    };

    const getMechanics = async () => {
        const result = await es.getGarageMechanics(company.id, id);
        setMechanics(result);
    };

    const upgradeGarage = async () => {
        if (await confirmWrapper(container.localeManager.strings.garageUpgrade, { container: container })) {
            setLoading(true);
            const result = await es.upgradeGarage(company, garage, newPlaces);
            if (result.success) {
                toast.success(container.localeManager.strings.garageUpgraded);
                getGarage();
            }
            else {
                toast.error(result.message);
            }
            setLoading(false);
        }
    };

    const generateMechanic = async () => {
        toggleRandomMechanicsModalIsOpen(true);
        setLoadingModal(true);
        const result = await es.generateMechanic(3);
        setRandomMechanics(result);
        setLoadingModal(false);
    };

    const hireMechanic = async (mechanic) => {
        setLoadingModal(true);
        const result = await es.hireMechanic(company.id, garage.id, mechanic);
        if (result.success) {
            setRandomMechanics(randomMechanics.filter(m => m.name != mechanic.name));
            getMechanics();
        }
        else {
            toast.error(result.message);
        }
        setLoadingModal(false);
    };

    const fireMechanic = async (mechanic) => {
        setLoading(true);
        const result = await es.fireMechanic(company.id, garage.id, mechanic);
        if (result.success) {
            getMechanics();
        }
        else {
            toast.error(result.message);
        }
        setLoading(false);
    };

    const renderMechanicsTable = (list, showVehicle, showBusyUntil, showFireButton, showHireButton) => {

        return (<Table responsive striped size="sm" className="table-row-dashed table-row-gray-300 align-middle gs-0" hover>
            <thead>
                <tr className="fw-bolder text-gray-700 bg-light">
                    {!showHireButton &&
                        <th scope="col" className="ps-4">#</th>
                    }
                    <th scope="col" className="text-center">{container.localeManager.strings.name}</th>
                    <th scope="col" className="text-end">{container.localeManager.strings.skill}</th>
                    <th scope="col" className="text-center">{container.localeManager.strings.speed}</th>
                    {showHireButton &&
                        <td scope="col" className="text-end">{container.localeManager.strings.hiringCost}</td>
                    }
                    <th scope="col" className="text-end">{container.localeManager.strings.weeklySalary}</th>
                    {showVehicle &&
                        <th scope="col">{container.localeManager.strings.vehicle}</th>
                    }
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {list.map((m) => {
                    return (
                        <tr>
                            {m.id &&
                                <td className="ps-2">{m.id}</td>
                            }
                            <td>
                                <div className="d-flex align-items-center">
                                    <img src={m.avatar} className="avatar rounded h-30px me-3" />
                                    <span>{m.name}</span>
                                </div>
                            </td>
                            <td className="text-end">{container.localeManager.strings[m.skill]}</td>
                            <td className="text-center">{m.speed}</td>
                            {showHireButton &&
                                <td className="text-end">{container.localeManager.numeral(m.hiring_cost).format('0,0')} T¢</td>
                            }
                            <td className="text-end">{container.localeManager.numeral(m.weekly_salary).format('0,0')} T¢</td>
                            {showVehicle &&
                                <td>
                                    <td>
                                        {m.vehicle != null &&
                                            <div className="d-flex align-items-center">
                                                <img src={m.vehicle.photo_url} className="avatar rounded h-30px me-3" />
                                                <Link to={`/vehicle/${m.vehicle.id}`}>{m.vehicle.model.brand.name} {m.vehicle.model.name}</Link>
                                            </div>

                                        }
                                        {m.vehicle == null &&
                                            <span className="text-success">{container.localeManager.strings.free}</span>
                                        }
                                    </td>
                                </td>
                            }
                            <td className="text-end">
                                {showFireButton && es.can(member, 'garages.manage-mechanics') &&
                                    <Button onClick={() => fireMechanic(m)} className="btn-icon w-30px h-30px ms-auto" size="sm" color="active-light-danger"><FontAwesomeIcon icon="trash-alt" className="me-0" /></Button>
                                }
                                {showHireButton &&
                                    <Button onClick={() => hireMechanic(m)} className="btn-icon w-30px h-30px ms-auto" size="sm" color="active-light-success"><FontAwesomeIcon icon="user-plus" className="me-0" /></Button>
                                }
                            </td>

                        </tr>
                    )
                })}
            </tbody>
        </Table>)
    };

    return (
        <>
            <LoadingOverlay
                active={loading}
                spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
            >
                {!window.overwolf &&
                    <DesktopTakeover container={container} className="my-3"></DesktopTakeover>
                }
                <Row noGutters className="" id="enterpriseContainer">
                    <Col>
                        <Row noGutters>
                            <Col>
                                <Card className="h-100">
                                    <CardBody>
                                        {garage != null &&
                                            <>
                                                <h5>#{garage.id} - {garage.city.name}</h5>
                                                <MapContainer style={{ width: '100%', height: 200 }} center={point} zoom={11}>
                                                    <TileLayer
                                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />
                                                </MapContainer>
                                                {es.can(member, 'garages.upgrade') &&
                                                    <Card className="mt-3">
                                                        <CardHeader>{container.localeManager.strings.upgrade}</CardHeader>
                                                        <CardBody>
                                                            <div className="mb-3 text-center fs-2">
                                                                {container.localeManager.strings.garagePlaces}: {garage.vehicles_count} / {garage.max_vehicles}
                                                            </div>

                                                            <InputGroup>
                                                                <Input value={newPlaces} type="numeric" min="1" onChange={(e) => setNewPlaces(e.target.value)}></Input>
                                                                <Button color="success" onClick={upgradeGarage}><FontAwesomeIcon icon="plus"></FontAwesomeIcon>{container.localeManager.strings.addSlots}</Button>
                                                            </InputGroup>
                                                        </CardBody>
                                                    </Card>
                                                }
                                                {vehicles.length > 0 &&
                                                    <Card className="mt-3">
                                                        <CardHeader>
                                                            {container.localeManager.strings.vehicles}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <VehiclesTable company={company} container={container} vehicles={vehicles} showDriverName={true} showSellButton={false}></VehiclesTable>
                                                        </CardBody>
                                                    </Card>
                                                }
                                                <>
                                                    <Card className="mt-3">
                                                        <CardHeader>
                                                            {container.localeManager.strings.mechanics}
                                                        </CardHeader>
                                                        <CardBody>
                                                            {es.can(member, 'garages.manage-mechanics') &&
                                                                <div className="d-flex align-items-center">
                                                                    <Button size="sm" onClick={generateMechanic} color="secondary" className="me-3"><FontAwesomeIcon icon="dice"></FontAwesomeIcon>{container.localeManager.strings.generateRandomMechanic}</Button>
                                                                </div>
                                                            }
                                                            {mechanics.length > 0 &&
                                                                <div className="mt-3">
                                                                    {renderMechanicsTable(mechanics, true, true, true, false)}
                                                                </div>
                                                            }
                                                        </CardBody>
                                                    </Card>
                                                </>
                                            </>
                                        }
                                        <ToastContainer
                                            position="top-right"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <aside className="ad-column-placeholder">
                                <Tips tipKey={tipsList.KEYS.ENTERPRISE_GARAGES} localeManager={container.localeManager}></Tips>
                                {!window.overwolf &&
                                    <BoxDynamicAd container={container} className="my-3" />
                                }
                            </aside>
                        </Row>
                    </Col>
                </Row>
                <Modal zIndex="999999999" unmountOnClose={true} size="lg" isOpen={randomMechanicsModalIsOpen} toggle={() => toggleRandomMechanicsModalIsOpen(false)}>
                    <ModalHeader toggle={() => toggleRandomMechanicsModalIsOpen(false)}
                        close={<Button color="active-light-primary" className="btn-icon btn-sm w-30px h-30px ms-auto" onClick={() => toggleRandomMechanicsModalIsOpen(false)}><FontAwesomeIcon icon="times"></FontAwesomeIcon></Button>}>
                        {container.localeManager.strings.mechanics}
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-muted mb-3">
                            <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>{container.localeManager.strings.randomMechanicsHint}
                        </div>
                        {!loadingModal &&
                            <div className="text-center mb-3">
                                <Button size="sm" onClick={generateMechanic} color="secondary" className="me-3"><FontAwesomeIcon icon="dice"></FontAwesomeIcon>{container.localeManager.strings.generateRandomMechanic}</Button>
                            </div>
                        }
                        {loadingModal &&
                            <div className="text-center mt-5">
                                <Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>
                            </div>
                        }
                        {!loadingModal && renderMechanicsTable(randomMechanics, false, false, false, true)}
                    </ModalBody>
                </Modal>
                {!window.overwolf &&
                    <VenatusLeaderboardAd container={container} className="my-3"></VenatusLeaderboardAd>
                }
            </LoadingOverlay>
        </>
    )

};

export default GarageDetail;