import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import PropTypes from 'prop-types';

const LoadingButton = ({ loading, color, className, children, size, onClick }) => {

    return (
        <Button disabled={loading} color={color} className={className} size={size} onClick={onClick}>
            {loading &&
                <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
            }
            {children}
        </Button>
    )
};

export default LoadingButton;