import { BrowserRouter } from 'react-router-dom';
import Layout from './web/pages/Layout';

window.$ = window.jQuery = require('jquery');

function App({ container }) {

  return (
    <BrowserRouter>
      <Layout container={container}></Layout>
    </BrowserRouter>
  );
}

export default App;
