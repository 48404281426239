import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  Input,
  Spinner,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Badge,
  FormText,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import truckyService from "../../common/services/trucky-service";
import { toast, ToastContainer } from "react-toastify";
import LoadingButton from "../ui/LoadingButton";
import moment from "moment";
import MonthlyCompanyStats from "./MonthlyCompanyStats";
import { useNavigate, useParams } from "react-router";
import LoadingOverlay from "react-loading-overlay";
import MonthlyCompanyStatsInnerComponent from "./MonthlyCompanyStatsInnerComponent";
import classnames from "classnames";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import SingleCompanyAward from "./SingleCompanyAward";
import CustomBadge from "../ui/CustomBadge";
import { Link } from "react-router-dom";
import CompanyFeedItems from "./CompanyFeedItems";
import Paginator from "../ui/Paginator";
import VenatusLeaderboardAd from "../../ads/VenatusLeaderboardAd";
import DesktopTakeover from "../../ads/DesktopTakeover";
import BoxDynamicAd from "../../ads/BoxDynamicAd";

const CompanyDetail = ({ container, close }) => {
  const es = new EnterpriseService();
  let { id, name } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [members, setMembers] = useState([]);
  const [showApplyModal, toggleApplyModal] = useState(false);
  const [application, setApplication] = useState({ message: "" });
  const [loadingButton, setLoadingButton] = useState(false);
  const [activeTab, toggleTab] = useState("about");
  const [awards, setAwards] = useState([]);
  const [feeds, setFeeds] = useState([]);
  const [pinnedFeeds, setPinnedFeeds] = useState([]);
  const [feedsPage, setFeedsPage] = useState(1);
  const [feedsTotalPages, setFeedsTotalPage] = useState(0);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    if (company != null) {
      getMembers();
      getAwards();
      getPinnedFeeds();
      getNotPinnedFeeds();
    }
  }, [company]);

  useEffect(() => {
    if (company != null) {
      getNotPinnedFeeds();
    }
  }, [feedsPage]);

  useEffect(() => {
    if (feeds.length > 0 || pinnedFeeds.length > 0) {
      toggleTab("feeds");
    }
  }, [feeds.length, pinnedFeeds.length]);

  const getCompany = async () => {
    setLoading(true);
    let company = null;

    if (id) company = await es.getCompany(id);
    else company = await es.getCompanyBySlug(name);

    if (company && company.id) {
      setCompany(company);

      if (company.about == null) toggleTab("members");
    } else {
      setNotFound(true);
    }
    setLoading(false);
  };

  const getMembers = async () => {
    const result = await es.getMembers(company, {
      pagination: 1,
      perPage: 1000,
    });
    setMembers(result.data);
  };

  const getPinnedFeeds = () => {
    es.getPublicPublishedFeed(company.id, { pinned: "onlyPinned" }).then(
      setPinnedFeeds
    );
  };

  const getNotPinnedFeeds = async () => {
    const result = await es.getPublicPublishedFeed(company.id, {
      paginate: true,
      pinned: "notPinned",
      page: feedsPage,
    });
    setFeeds(result.data);
    setFeedsTotalPage(result.last_page);
  };

  const getColoredSpan = (member, value) => {
    if (member.role != null) {
      return <span style={{ color: member.role.color }}>{value}</span>;
    } else {
      return <span>{value}</span>;
    }
  };

  const apply = async () => {
    if (application.message == "") {
      toast.error("Please insert an application message");
    } else {
      setLoadingButton(true);
      const result = await es.applyToCompany(company, application);
      if (result.success) {
        toast.success("Application sent!");
        setLoadingButton(false);
        toggleApplyModal(false);
      } else {
        toast.error(result.message);
        setLoadingButton(false);
      }
    }
  };

  const getAwards = async () => {
    const result = await es.getCompanyAwards(company);
    setAwards(result);
  };

  const handlePageClick = (event) => {
    setFeedsPage(event.selected + 1);
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      {!window.overwolf && (
        <DesktopTakeover
          container={container}
          className="my-3"
        ></DesktopTakeover>
      )}
      <Row noGutters className="d-flex flex-nowrap">
        <Col>
          <Card className="h-100">
            <CardBody>
              {notFound && (
                <div className="alert alert-danger">Company not Found</div>
              )}
              {company != null && (
                <>
                  {window.overwolf && (
                    <div class="mb-3">
                      <Button
                        size="sm"
                        onClick={() => navigate(-1)}
                        color="secondary"
                      >
                        <FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>
                        {container.localeManager.strings.back}
                      </Button>
                    </div>
                  )}

                  <Row noGutters>
                    <Col>
                      <div
                        class="hero-image"
                        style={{
                          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${company.cover_url}"`,
                        }}
                      >
                        <div class="hero-text">
                          <img
                            src={company.avatar_url}
                            className="avatar rounded h-100px mb-2"
                          />
                          <h1>{company.name}</h1>
                          <p>{company.slogan}</p>
                        </div>
                        <div class="buttons mx-3">
                          {window.overwolf && (
                            <Col sm="auto">
                              <Button
                                onClick={() =>
                                  truckyService.openUrlInDefaultBrowser(
                                    company.public_url
                                  )
                                }
                                color="secondary"
                              >
                                {container.localeManager.strings.publicPage}
                              </Button>
                            </Col>
                          )}
                        </div>
                      </div>
                      <div className="my-3">
                        <MonthlyCompanyStatsInnerComponent
                          company={company}
                          container={container}
                          enableNavigation={true}
                          detailedMembersStats={false}
                          detailedJobsStats={false}
                        ></MonthlyCompanyStatsInnerComponent>
                      </div>
                      <>
                        <Nav
                          className="nav-custom nav-line-tabs nav-line-tabs-2x fs-6 fw-bold mb-3"
                          tabs
                        >
                          {(feeds.length > 0 || pinnedFeeds.length > 0) && (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "feeds",
                                })}
                                onClick={() => {
                                  toggleTab("feeds");
                                }}
                              >
                                {container.localeManager.strings.newsFeed}
                              </NavLink>
                            </NavItem>
                          )}
                          {company.about != null && (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "about",
                                })}
                                onClick={() => {
                                  toggleTab("about");
                                }}
                              >
                                {container.localeManager.strings.about}
                              </NavLink>
                            </NavItem>
                          )}
                          {company.rules != null && (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "rules",
                                })}
                                onClick={() => {
                                  toggleTab("rules");
                                }}
                              >
                                {container.localeManager.strings.rules}
                              </NavLink>
                            </NavItem>
                          )}
                          {company.requirements != null && (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "requirements",
                                })}
                                onClick={() => {
                                  toggleTab("requirements");
                                }}
                              >
                                {container.localeManager.strings.requirements}
                              </NavLink>
                            </NavItem>
                          )}
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "members",
                              })}
                              onClick={() => {
                                toggleTab("members");
                              }}
                            >
                              {container.localeManager.strings.members}{" "}
                              <CustomBadge color="primary" className="ms-3">
                                {members.length}
                              </CustomBadge>
                            </NavLink>
                          </NavItem>
                          {awards.length > 0 && (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "awards",
                                })}
                                onClick={() => {
                                  toggleTab("awards");
                                }}
                              >
                                {container.localeManager.strings.companyAwards}{" "}
                                <CustomBadge color="primary" className="ms-3">
                                  {awards.length}
                                </CustomBadge>
                              </NavLink>
                            </NavItem>
                          )}
                        </Nav>
                        <TabContent className="mt-3" activeTab={activeTab}>
                          {company.about != null && (
                            <TabPane tabId="about">
                              <Row>
                                <Col>
                                  <ReactMarkdown
                                    children={company.about}
                                    remarkPlugins={[remarkGfm]}
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                          )}
                          {(feeds.length > 0 || pinnedFeeds.length > 0) && (
                            <TabPane tabId="feeds">
                              <Row>
                                <Col>
                                  {pinnedFeeds.length > 0 && (
                                    <CompanyFeedItems
                                      container={container}
                                      items={pinnedFeeds}
                                    ></CompanyFeedItems>
                                  )}
                                  {feeds.length > 0 && (
                                    <div className="my-3">
                                      <Paginator
                                        forcePage={feedsPage - 1}
                                        totalPages={feedsTotalPages}
                                        handlePageClick={handlePageClick}
                                      />
                                      <CompanyFeedItems
                                        container={container}
                                        items={feeds}
                                      ></CompanyFeedItems>
                                      <Paginator
                                        forcePage={feedsPage - 1}
                                        totalPages={feedsTotalPages}
                                        handlePageClick={handlePageClick}
                                      />
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </TabPane>
                          )}
                          {company.requirements != null && (
                            <TabPane tabId="requirements">
                              <Row>
                                <Col>
                                  <ReactMarkdown
                                    children={company.requirements}
                                    remarkPlugins={[remarkGfm]}
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                          )}
                          {company.rules != null && (
                            <TabPane tabId="rules">
                              <Row>
                                <Col>
                                  <ReactMarkdown
                                    children={company.rules}
                                    remarkPlugins={[remarkGfm]}
                                  />
                                </Col>
                              </Row>
                            </TabPane>
                          )}
                          <TabPane tabId="members">
                            <Row className="mb-3 mb-xl-9 gx-0">
                              {members.map((m) => {
                                return (
                                  <Col className="col-12 col-md-4 col-lg-4">
                                    <div class="card">
                                      <div class="card-body d-flex flex-center flex-column py-3 px-3">
                                        <div class="symbol symbol-65px mb-5">
                                          <img
                                            className="rounded"
                                            src={m.avatar_url}
                                          />
                                        </div>
                                        <Link
                                          to={`/user/${m.id}`}
                                          class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
                                        >
                                          {m.name}
                                        </Link>
                                        <div class="fw-semibold text-gray-400 mb-6">
                                          {m.role != null
                                            ? getColoredSpan(m, m.role.name)
                                            : ""}
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </TabPane>
                          <TabPane tabId="awards">
                            <div className="grid cols-3 gap-3">
                              {awards.map((m) => (
                                <div class="border rounded p-3 mb-3 bg-light">
                                  <SingleCompanyAward
                                    container={container}
                                    award={m}
                                  ></SingleCompanyAward>
                                </div>
                              ))}
                            </div>
                          </TabPane>
                        </TabContent>
                      </>
                      <Modal
                        size="lg"
                        isOpen={showApplyModal}
                        toggle={() => toggleApplyModal(false)}
                      >
                        <ModalHeader
                          toggle={() => toggleApplyModal(false)}
                          close={
                            <Button
                              color="active-light-primary"
                              className="btn-icon btn-sm w-30px h-30px ms-auto"
                              onClick={() => toggleApplyModal(false)}
                            >
                              <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                            </Button>
                          }
                        >
                          {container.localeManager.formatString(
                            container.localeManager.strings.joinCompany,
                            company.name
                          )}
                        </ModalHeader>
                        <ModalBody>
                          <FormGroup row>
                            <Col md={6}>
                              <Label>
                                {container.localeManager.strings.discord}{" "}
                                {container.localeManager.strings.discordExample}
                              </Label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  setApplication({
                                    ...application,
                                    discord: e.target.value,
                                  })
                                }
                              ></Input>
                            </Col>
                            <Col md={6}>
                              <Label>
                                {container.localeManager.strings.email}
                              </Label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  setApplication({
                                    ...application,
                                    email: e.target.value,
                                  })
                                }
                              ></Input>
                              <FormText className="muted">
                                {
                                  container.localeManager.strings
                                    .emailApplication
                                }
                              </FormText>
                            </Col>
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              {container.localeManager.strings.message} *
                            </Label>
                            <Input
                              rows="10"
                              cols="10"
                              type="textarea"
                              onChange={(e) =>
                                setApplication({
                                  ...application,
                                  message: e.target.value,
                                })
                              }
                            ></Input>
                            {company.application_requirements == null && (
                              <FormText className="muted">
                                {
                                  container.localeManager.strings
                                    .applicationMessage
                                }
                              </FormText>
                            )}
                            {company.application_requirements != null && (
                              <div className="mt-3">
                                <ReactMarkdown
                                  children={company.application_requirements}
                                  remarkPlugins={[remarkGfm]}
                                />
                              </div>
                            )}
                          </FormGroup>
                          <FormGroup className="mt-3">
                            <LoadingButton
                              size="sm"
                              loading={loadingButton}
                              onClick={apply}
                              color="primary"
                            >
                              {container.localeManager.strings.sendApplication}
                            </LoadingButton>
                          </FormGroup>
                        </ModalBody>
                      </Modal>
                      <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
        <aside className="ad-column-placeholder">
          {company != null && (
            <>
              <Card className="bg-light">
                <CardBody>
                  <div>
                    {container.localeManager.strings.owner}
                    <Link
                      to={`/user/${company.owner.id}`}
                      className="float-end text-white text-hover-primary"
                    >
                      {company.owner.name}
                    </Link>
                  </div>
                  {company.language != null && (
                    <>
                      <div class="separator border-gray-400 my-3"></div>
                      <div>
                        {container.localeManager.strings.settingsHeaderLanguage}
                        <span className="float-end">{company.language}</span>
                      </div>
                    </>
                  )}
                  {company.tag != null && (
                    <>
                      <div class="separator border-gray-400 my-3"></div>
                      <div>
                        {container.localeManager.strings.tag}
                        <span className="float-end">{company.tag}</span>
                      </div>
                    </>
                  )}
                  {company.discord != null && (
                    <>
                      <div class="separator border-gray-400 my-3"></div>
                      <div class="d-flex">
                        <FontAwesomeIcon
                          className="text-discord"
                          size="2x"
                          icon={["fab", "discord"]}
                        />
                        <div class="d-flex flex-column">
                          <a
                            title="Discord"
                            className="text-white text-hover-primary"
                            onClick={() =>
                              truckyService.openUrlInDefaultBrowser(
                                company.discord
                              )
                            }
                          >
                            Discord
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                  {company.facebook != null && (
                    <>
                      <div class="separator border-gray-400 my-3"></div>
                      <div class="d-flex">
                        <FontAwesomeIcon
                          className="text-facebook"
                          size="2x"
                          icon={["fab", "facebook-square"]}
                        />
                        <div class="d-flex flex-column">
                          <a
                            title="Facebook"
                            className="text-white text-hover-primary"
                            onClick={() =>
                              truckyService.openUrlInDefaultBrowser(
                                company.facebook
                              )
                            }
                          >
                            Facebook
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                  {company.twitter != null && (
                    <>
                      <div class="separator border-gray-400 my-3"></div>
                      <div class="d-flex">
                        <FontAwesomeIcon
                          className="text-twitter"
                          size="2x"
                          icon={["fab", "twitter-square"]}
                        />
                        <div class="d-flex flex-column">
                          <a
                            title="Twitter"
                            className="text-white text-hover-primary"
                            onClick={() =>
                              truckyService.openUrlInDefaultBrowser(
                                company.twitter
                              )
                            }
                          >
                            Twitter
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                  {company.youtube != null && (
                    <>
                      <div class="separator border-gray-400 my-3"></div>
                      <div class="d-flex">
                        <FontAwesomeIcon
                          className="text-youtube"
                          size="2x"
                          icon={["fab", "youtube-square"]}
                        />
                        <div class="d-flex flex-column">
                          <a
                            title="Youtube"
                            className="text-white text-hover-primary"
                            onClick={() =>
                              truckyService.openUrlInDefaultBrowser(
                                company.youtube
                              )
                            }
                          >
                            Youtube
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                  {company.twitch != null && (
                    <>
                      <div class="separator border-gray-400 my-3"></div>
                      <div class="d-flex">
                        <FontAwesomeIcon
                          className="text-twitch"
                          size="2x"
                          icon={["fab", "twitch"]}
                        />
                        <div class="d-flex flex-column">
                          <a
                            title="Twitch"
                            className="text-white text-hover-primary"
                            onClick={() =>
                              truckyService.openUrlInDefaultBrowser(
                                company.twitch
                              )
                            }
                          >
                            Twitch
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                  {company.website != null && (
                    <>
                      <div class="separator border-gray-400 my-3"></div>
                      <div class="d-flex">
                        <FontAwesomeIcon icon="sitemap"></FontAwesomeIcon>
                        <div class="d-flex flex-column">
                          <a
                            className="text-white text-hover-primary"
                            onClick={() =>
                              truckyService.openUrlInDefaultBrowser(
                                company.website
                              )
                            }
                          >
                            {company.website}
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                  {company.recruitment == "open" &&
                    truckyService.getToken() && (
                      <div className="mt-5">
                        <Button
                          size="sm"
                          onClick={() => toggleApplyModal(true)}
                          block
                          color="primary"
                        >
                          <FontAwesomeIcon icon="hand-point-right"></FontAwesomeIcon>
                          {container.localeManager.strings.applyNow}
                        </Button>
                      </div>
                    )}
                </CardBody>
              </Card>
            </>
          )}
          {!window.overwolf && (
            <BoxDynamicAd container={container} className="my-3" />
          )}
        </aside>
      </Row>
      {!window.overwolf && (
        <VenatusLeaderboardAd
          container={container}
          className="my-3"
        ></VenatusLeaderboardAd>
      )}
    </LoadingOverlay>
  );
};

export default CompanyDetail;
