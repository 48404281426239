import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Input, InputGroup } from "reactstrap";
import { useOrientation, useWindowSize } from 'react-use';
import truckyService from "../../common/services/trucky-service";

const GameDashboard = ({ container }) => {

    const [telemetryServerIp, setTelemetryServerIp] = useState(localStorage.getItem('telemetryServerIp'));
    const [savedTelemetryServer, setSavedTelemetryServer] = useState(localStorage.getItem('telemetryServerIp'));

    const orientation = useOrientation();
    const windowSize = useWindowSize();

    useEffect(() => {
        truckyService.setDocumentTitle('Mobile Dashboard');
    }, []);

    useEffect(() => {
    }, []);

    const saveTelemetryServer = () => {
        if (telemetryServerIp && telemetryServerIp != '') {
            localStorage.setItem('telemetryServerIp', telemetryServerIp);
            setSavedTelemetryServer(telemetryServerIp);
            setTelemetryServerIp(telemetryServerIp);
        }
    }

    return (
        <div className="vh-100">
            {savedTelemetryServer &&
                <iframe key={orientation.type + windowSize.width + windowSize.height} src={`https://gamedashboard.truckyapp.com/?telemetryServer=${savedTelemetryServer}`} className="w-100 h-100"></iframe>
            }
            {!savedTelemetryServer &&
                <div className="d-flex flex-center mt-5 px-5 flex-column">
                    <div className="fs-3 mb-3 text-center">
                        {container.localeManager.strings.mobileDashboardInfo1}
                        <br />
                        {container.localeManager.strings.mobileDashboardInfo2}
                    </div>
                    <InputGroup>
                        <Input onChange={(e) => setTelemetryServerIp(e.target.value)} value={telemetryServerIp} placeholder="Telemetry Server IP"></Input>
                        <Button color="success" onClick={saveTelemetryServer}>{container.localeManager.strings.save}</Button>
                    </InputGroup>
                </div>
            }
        </div>
    )
}

export default GameDashboard;