import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TooltipIcon from "../ui/TooltipIcon";

const JobEventsTimeline = ({ container, job, events }) => {

    const getIcon = (event) => {

        let icon;

        switch (event.event_type) {
            case 'job_resumed':
                icon = <FontAwesomeIcon icon="refresh" className="timeline-icon me-0"></FontAwesomeIcon>
                break;
            case 'collision':
                icon = <FontAwesomeIcon icon="explosion" className="timeline-icon me-0"></FontAwesomeIcon>
                break;
            case 'refuel':
                icon = <FontAwesomeIcon icon="gas-pump" className="timeline-icon me-0"></FontAwesomeIcon>
                break;
            case 'fined':
                icon = <FontAwesomeIcon icon="sack-dollar" className="timeline-icon me-0"></FontAwesomeIcon>
                break;
            case 'tollgate_paid':
                icon = <FontAwesomeIcon icon="road-barrier" className="timeline-icon me-0"></FontAwesomeIcon>
                break;
            case 'transport_used':
                let transportIcon = 'ship';

                if (event.attributes && event.attributes.transport_type == 'train')
                    transportIcon = 'train';

                icon = <FontAwesomeIcon icon={transportIcon} className="timeline-icon me-0"></FontAwesomeIcon>
                break;
            case 'truck_fixed':
                icon = <FontAwesomeIcon icon="wrench" className="timeline-icon me-0"></FontAwesomeIcon>
                break;
            case 'teleport':
                icon = <FontAwesomeIcon icon="truck-fast" className="timeline-icon me-0"></FontAwesomeIcon>
                break;
        }

        return icon;
    }

    const renderEventsHorizontal = () => {
        return (
            <div className="job-events">
                <ul class="timeline">
                    {events.map(m => {
                        return (
                            <li>
                                {getIcon(m)}
                                <div>
                                    <small><FontAwesomeIcon icon="clock"></FontAwesomeIcon>{container.localeManager.moment(m.created_at).format('L LT')}</small>
                                    <br />
                                    <span className="fs-6 fw-bolder">{container.localeManager.strings[m.event_type]} {getDetailsTooltip(m)}</span>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    }

    const getDetailsTooltip = (event) => {

        let details = null;

        if (event.event_type == 'collision' && event.attributes && event.attributes.damage) {
            details = <>
                {container.localeManager.strings.vehicleDamage}: {event.attributes.damage.chassisDamage}%
                <br />
                {container.localeManager.strings.trailersDamage}: {event.attributes.damage.trailersDamage}%
                <br />
                {container.localeManager.strings.cargoDamage}: {event.attributes.damage.cargoDamage}%
            </>
        }

        if (event.event_type == 'transport_used' && event.attributes) {
            details = <>
                {event.attributes.source} - {event.attributes.target}
            </>
        }

        if (event.event_type == 'fined' && event.attributes && event.attributes.offence) {

            /*
             * @li crash
 * @li avoid_sleeping
 * @li wrong_way
 * @li speeding_camera
 * @li no_lights
 * @li red_signal
 * @li speeding
 * @li avoid_weighing
 * @li illegal_trailer
 * @li avoid_inspection
 * @li illegal_border_crossing
 * @li hard_shoulder_violation
 * @li damaged_vehicle_usage
 * @li generic
 */
            details = <>
                {container.localeManager.strings[event.attributes.offence]}
            </>
        }

        if (details != null) {
            return (<TooltipIcon id={`event_${event.id}`} icon="circle-info" text={details}></TooltipIcon>);
        }
    }

    return (
        <>
            {renderEventsHorizontal()}
        </>
    )
};

export default JobEventsTimeline;