import Chart from "react-apexcharts";

const SingleStackedBarChart = ({ series, category, height = 50, title }) => {
    return (<Chart
        options={{
            title: {
                text: title,
                margin: 0
            },
            chart: {
                redrawOnParentResize: true,
                stacked: true,
                stackType: "100%",
                toolbar: {
                    show: false
                },
                grid: {
                    show: false,
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
            },
            legend: {
                show: false
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            dataLabels: {
                offsetY: -5,
                dropShadow: {
                    enabled: false
                }
            },
            stroke: {
                width: 0
            },
            xaxis: {
                show: false,
                categories: [category],
                formatter: () => "",
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                offsetX: 0,
                offsetY: 0,
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                },
            },
            fill: {
                opacity: 1,
                type: "gradient",
                gradient: {
                    shade: "dark",
                    type: "vertical",
                    shadeIntensity: 0.35,
                    gradientToColors: undefined,
                    inverseColors: false,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [90, 0, 100]
                }
            },
            grid: {
                padding: {
                    top: -30,
                    bottom: -30
                },
                show: false,
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
            },
            legend: {
                show: false
            }
        }}
        series={series}
        height={height}
        type="bar"
    //width={width}
    />)
}

export default SingleStackedBarChart;