import * as React from "react";
import config from "../config";

export default class VenatusAd extends React.Component {

    constructor(props) {
        super(props);
        this.adRef = React.createRef();
    }

    componentDidMount() {

        if (!config.ENABLE_ADS)
            return;

        (window.top).__vm_add = (window.top).__vm_add || [];
        (window.top).__vm_add.push(this.adRef.current);
    }

    componentWillUnmount() {

        if (!config.ENABLE_ADS)
            return;
        
        (window.top).__vm_remove = (window.top).__vm_remove || [];
        (window.top).__vm_remove.push(this.adRef.current);
    }

    render() {

        if (!config.ENABLE_ADS)
            return null;

        const { className, container, placementId, mobilePlacementId, dataDisplayType, dataFormat } = this.props;
        let selectedPlacementId = null;

        if (window.innerWidth < 1000 && mobilePlacementId)
            selectedPlacementId = mobilePlacementId;
        else
            selectedPlacementId = placementId;

        if (!selectedPlacementId) {
            return null;
        }

        if (container.dataService.data.enablePremiumFeature())
            return null;

        return <div className={className}>
            <div className="vm-placement" data-id={selectedPlacementId} ref={this.adRef} data-display-type={dataDisplayType} data-format={dataFormat}></div>
        </div>;
    }
}