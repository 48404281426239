import React, { PureComponent } from "react";
import { Card } from "reactstrap";
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");

class Tips extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {

        const resources = Object.keys(this.props.localeManager.strings).map(m => {
            if (m.includes(`tip_${this.props.tipKey}_`))
                return m;
        }).filter(m => m != undefined);
        return (
            <Card body>
                <div>
                    <FontAwesomeIcon icon="lightbulb" className="premium-color"></FontAwesomeIcon>{this.props.localeManager.strings.tipsAndTricks}
                </div>
                <ul className="list-unstyled mt-3">
                    {resources.map(m => {
                        return (
                            <li>
                                <FontAwesomeIcon icon="hand-point-right"></FontAwesomeIcon>{this.props.localeManager.strings[m]}
                            </li>
                        )
                    })}
                </ul>
            </Card>
        )
    }
}

export default Tips;