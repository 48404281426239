import { Badge, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CustomBadge from "../ui/CustomBadge";
import UserDefinedUnits from "../../common/utils/UserDefinedUnits";
import { useContext } from "react";
import { EnterpriseContext } from "./EnterpriseContext";

const MileStatsCards = ({
  period = "monthly",
  container,
  stats,
  showPositionBadge = true,
  ets2_preferred_distance_unit,
  ats_preferred_distance_unit,
}) => {
  const { globalContext } = useContext(EnterpriseContext);

  const member = globalContext.member;

  const getPositionBadge = (value) => {
    if (value > 0 && showPositionBadge) {
      return <CustomBadge color="primary">#{value}</CustomBadge>;
    }
  };

  return (
    <Row noGutters>
      <Col className="col-12 col-md-12 col-lg-6">
        <Card className="ms-0">
          <CardHeader>
            {period == "monthly" &&
              container.localeManager.strings.ets2MonthlyStats}
            {(period == "yearly" || period == "daily" || period == "weekly") &&
              "ETS2"}
          </CardHeader>
          <CardBody>
            <div class="grid cols-2 mb-3">
              <div class="border border-primary border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {UserDefinedUnits.convertDistance(
                    member,
                    1,
                    stats.ets2.total_km
                  )}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.total}
                </div>
              </div>
              <div class="border border-primary border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {stats.ets2.total_jobs}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.jobs}
                </div>
              </div>
            </div>
            <div class="grid cols-2">
              <div class="border border-success border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {UserDefinedUnits.convertDistance(
                    member,
                    1,
                    stats.ets2.real_km
                  )}{" "}
                  {showPositionBadge &&
                    getPositionBadge(
                      stats.ets2.leaderbords_position_real_miles
                    )}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.realMiles}
                </div>
              </div>
              <div class="border border-warning border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {UserDefinedUnits.convertDistance(
                    member,
                    1,
                    stats.ets2.race_km
                  )}{" "}
                  {showPositionBadge &&
                    getPositionBadge(
                      stats.ets2.leaderbords_position_race_miles
                    )}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.raceMiles}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col className="col-12 col-md-12 col-lg-6">
        <Card className="me-0">
          <CardHeader>
            {period == "monthly" &&
              container.localeManager.strings.atsMonthlyStats}
            {(period == "yearly" || period == "daily" || period == "weekly") &&
              "ATS"}
          </CardHeader>
          <CardBody>
            <div class="grid cols-2 mb-3">
              <div class="border border-primary border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {UserDefinedUnits.convertDistance(
                    member,
                    2,
                    stats.ats.total_km
                  )}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.total}
                </div>
              </div>
              <div class="border border-primary border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">{stats.ats.total_jobs}</div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.jobs}
                </div>
              </div>
            </div>
            <div class="grid cols-2">
              <div class="border border-success border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {UserDefinedUnits.convertDistance(
                    member,
                    2,
                    stats.ats.real_km
                  )}{" "}
                  {showPositionBadge &&
                    getPositionBadge(stats.ats.leaderbords_position_real_miles)}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.realMiles}
                </div>
              </div>
              <div class="border border-warning border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                <div class="fs-2 fw-bolder counted">
                  {UserDefinedUnits.convertDistance(
                    member,
                    2,
                    stats.ats.race_km
                  )}{" "}
                  {showPositionBadge &&
                    getPositionBadge(stats.ats.leaderbords_position_race_miles)}
                </div>
                <div class="fw-bold fs-6 text-gray-600">
                  {container.localeManager.strings.raceMiles}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default MileStatsCards;
