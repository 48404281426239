import { useContext, useEffect, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service"
import { EnterpriseContext } from "./EnterpriseContext";
import { useNavigate } from "react-router";
import Paginator from "../ui/Paginator";
import { Button, Card, CardBody, Col, FormGroup, Row, Spinner, Table } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import { confirmWrapper } from "../ui/askConfirm";
import truckyService from "../../common/services/trucky-service";

const CompanyAchievements = ({ container }) => {
    const es = new EnterpriseService();
    const { globalContext } = useContext(EnterpriseContext);
    const navigate = useNavigate();
    const [achievements, setAchievements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ page: 1, perPage: 25 });
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        truckyService.setDocumentTitle('Company Achievements');
    }, []);

    useEffect(() => {
        getAchievements();
    }, []);

    useEffect(() => {
        getAchievements();
    }, [pagination.page]);

    const getAchievements = async () => {
        setLoading(true);
        const result = await es.getAllCompanyAchievements(globalContext.company.id, pagination.page);
        setAchievements(result.data);
        setTotalPages(result.last_page);
        setLoading(false);
    }

    const handlePageClick = (event) => {
        setPagination({ ...pagination, page: event.selected + 1 });
    };

    const deleteAchievement = async (ach) => {

        if (await confirmWrapper(container.localeManager.strings.areYouSure, { container: container })) {
            setLoading(true);
            const result = await es.deleteAchievement(globalContext.company, ach.id);
            if (result.success) {
                getAchievements();
            }
            else {
                toast.error(result.message);
            }
            setLoading(false);
        }
    }

    return (
        <>
            <LoadingOverlay
                active={loading}
                spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
            >
                <Row noGutters className="" id="enterpriseContainer">
                    <Col>
                        <Row noGutters>
                            <Col>
                                <Card className="h-100">
                                    <CardBody>
                                        <h5 className="d-inline-block w-100 mb-3">{container.localeManager.strings.companyAchievements}
                                            <div className="float-end">
                                                <Button size="sm" color="primary" onClick={() => navigate(`/company/achievements/create`)}><FontAwesomeIcon icon="plus"></FontAwesomeIcon>{container.localeManager.strings.createNewAchievement}</Button>
                                            </div>
                                        </h5>
                                        <FormGroup row></FormGroup>
                                        {!loading &&
                                            <>
                                                {totalPages > 1 &&
                                                    <div className="mb-3">
                                                        <Paginator forcePage={pagination.page - 1} totalPages={totalPages} handlePageClick={handlePageClick} />
                                                    </div>
                                                }
                                                <Table responsive striped size="sm" className="table-row-dashed table-row-gray-300 align-middle gs-0" hover>
                                                    <thead>
                                                        <tr className="fw-bolder text-gray-700 bg-light">
                                                            <th className="ps-2">#</th>
                                                            <th>{container.localeManager.strings.title}</th>
                                                            <th>{container.localeManager.strings.period}</th>
                                                            <th>{container.localeManager.strings.goal}</th>
                                                            <th>{container.localeManager.strings.goal_type}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {achievements.map(m => {
                                                            return (
                                                                <tr>
                                                                    <td className="ps-2">{m.id}</td>
                                                                    <td>{m.computed_title}</td>
                                                                    <td>{container.localeManager.strings[m.period]}</td>
                                                                    <td>{m.control_value}</td>
                                                                    <td>{container.localeManager.strings[m.control_value_type]}</td>
                                                                    <td className="text-end">
                                                                        <Button onClick={() => navigate(`/company/achievements/${m.id}`)} className="btn-icon w-30px h-30px ms-auto" size="sm" color="active-light-primary"><FontAwesomeIcon icon="pencil-alt" className="me-0" /></Button>
                                                                        <Button onClick={() => deleteAchievement(m)} className="btn-icon w-30px h-30px ms-auto" size="sm" color="active-light-danger"><FontAwesomeIcon icon="trash-alt" className="me-0" /></Button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <aside className="ad-column-placeholder">
                    </aside>
                </Row>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </LoadingOverlay>
        </>

    );
}

export default CompanyAchievements;