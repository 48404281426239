import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, UncontrolledTooltip } from "reactstrap";
import uniqid from 'uniqid';

const TooltipIcon = ({ icon, className, text, position = 'top', id, trigger = "hover focus", autohide = true }) => {

    const elementId = id ? id : uniqid();

    return (
        <>
            <span id={elementId}><FontAwesomeIcon className={className} icon={icon}></FontAwesomeIcon></span>
            <UncontrolledTooltip
                target={elementId}
                placement={position}
                trigger={trigger}
                autohide={autohide}
            >
                {text}
            </UncontrolledTooltip>
        </>
    )
};

export default TooltipIcon;