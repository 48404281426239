import { useContext } from "react";
import UnitUtils from "../common/utils/UnitUtils";
import UserDefinedUnits from "../common/utils/UserDefinedUnits";
import CustomBadge from "./ui/CustomBadge";
import classNames from "classnames";

const AchievementsList = ({ container, achievements }) => {

    const renderAchievementCards = (achievements) => {
        return achievements.map((element) => {
            let currentValue = element.completion != null ? element.completion.current_value : 0;
            let percentage = (currentValue / element.control_value) * 100;

            if (percentage > 100)
                percentage = 100;

            const reached = (element.completion != null && element.completion.reached);

            const pgBarColor = reached ? 'bg-success' : 'bg-primary';
            return (
                <div className={classNames({ 'rounded': true, 'p-3': true, 'bg-light': true, 'mb-2': true, 'border': true, 'border-success': reached })}>
                    <div className="d-flex align-items-center flex-row">
                        <div className="d-flex flex-column flex-grow-1">
                            <div className="fs-5">{element.computed_title}</div>
                            {element.story != null && element.story != '' &&
                                <div className="my-3 fs-7">
                                    {element.story}
                                </div>
                            }
                            <div className="mt-1 w-100">
                                <div class="progress h-15px">
                                    <div class={`progress-bar h-15px ${pgBarColor}`} role="progressbar" style={{ width: `${percentage}%` }} aria-valuenow={currentValue}>
                                        {container.localeManager.numeral(currentValue).format()}
                                    </div>
                                </div>
                                <div className="mt-1 w-100 d-flex justify-content-between text-gray-600">
                                    <span>0</span>
                                    <span>{container.localeManager.numeral(element.control_value).format()}</span>
                                </div>
                            </div>
                        </div>
                        {element.points != null && element.points > 0 &&
                            <div className="w-150px text-end">
                                <span className="fs-4 points">{container.localeManager.formatString(container.localeManager.strings.pointsFormatted, element.points)}</span>
                            </div>
                        }
                    </div>
                    <div className="mt-2">
                        {element.game_id == null &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">{container.localeManager.strings.allGames}</CustomBadge>
                        }
                        {element.game_id == 1 &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">ETS2</CustomBadge>
                        }
                        {element.game_id == 2 &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">ATS</CustomBadge>
                        }
                        {element.game_mode == null &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">{container.localeManager.strings.allGameModes}</CustomBadge>
                        }
                        {element.game_mode == 'sp' &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">{container.localeManager.strings[element.game_mode]}</CustomBadge>
                        }
                        {element.game_mode == 'tmp' &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">TruckersMP</CustomBadge>
                        }
                        {element.max_damage == 0 &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">{container.localeManager.strings.noDamage}</CustomBadge>
                        }
                        {element.is_wotr &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">{container.localeManager.strings.onlyWotrJobs}</CustomBadge>
                        }
                        {element.owned_trailer &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">{container.localeManager.strings.onlyOwnedTrailer}</CustomBadge>
                        }
                        {element.market != null && element.market.length > 0 &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">{container.localeManager.strings[element.market]}</CustomBadge>
                        }
                        {element.min_job_length_km != null &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">{container.localeManager.formatString(container.localeManager.strings.minimumDistance, UserDefinedUnits.convertDistance(container.dataService.enterpriseService.member, element.game_id, element.min_job_length_km))}</CustomBadge>
                        }
                        {element.stats_type != null &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">{container.localeManager.strings[element.stats_type]}</CustomBadge>
                        }
                        {element.special_job &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">{container.localeManager.strings.specialJobs}</CustomBadge>
                        }
                        {element.manual_parking &&
                            <CustomBadge light={false} color="light-warning" size="sm" className="me-2">{container.localeManager.strings.manualParking}</CustomBadge>
                        }
                    </div>
                </div>
            )
        })
    };

    return (
        <>
            {achievements.find(m => m.type == "community") != undefined &&
                <></>
            }
            {achievements.filter(m => m.period == 'weekly' || m.period == 'recurring_weekly').length > 0 &&
                <>
                    <div className="fs-3 my-2 ps-3 fw-bolder">{container.localeManager.strings.weekly}</div>
                    {renderAchievementCards(achievements.filter(m => m.period == 'weekly' || m.period == 'recurring_weekly'))}
                </>
            }
            {achievements.filter(m => m.period == 'monthly' || m.period == 'recurring_monthly').length > 0 &&
                <>
                    <div className="fs-3 my-2 ps-3 fw-bolder">{container.localeManager.strings.monthly}</div>
                    {renderAchievementCards(achievements.filter(m => m.period == 'monthly' || m.period == 'recurring_monthly'))}
                </>
            }
            {achievements.filter(m => m.period == 'yearly' || m.period == 'recurring_yearly').length > 0 &&
                <>
                    <div className="fs-3 my-2 ps-3 fw-bolder">{container.localeManager.strings.yearly}</div>
                    {renderAchievementCards(achievements.filter(m => m.period == 'yearly' || m.period == 'recurring_yearly'))}
                </>
            }
        </>
    )
}

export default AchievementsList;