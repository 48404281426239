import truckyService from "./trucky-service";

let _listeners = [];

function addListener(eventName, eventHandler, source = '', logWhenFired = false) {

	if (process.env.TRUCKY_ENV == "development" && source != '')
		truckyService.writeLog(`Adding listener for ${eventName} from ${source}`);

	_listeners.push({ eventName: eventName, handler: eventHandler, source: source, logWhenFired: logWhenFired });
}

function trigger(eventName, data, log = false) {
	for (let listener of _listeners) {
		if (listener.eventName == eventName) {
			//console.log(`Firing ${listener.eventName}`);

			if (process.env.TRUCKY_ENV == "development" && listener.logWhenFired && listener.source != '')
				truckyService.writeLog(`Firing ${eventName} to ${listener.source}`);

			listener.handler(data);
		}
	}
}

export default {
	addListener,
	trigger
}