import classNames from "classnames";
import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import moment from 'moment';
import SingleCompanyAward from "./SingleCompanyAward";
import truckyService from "../../common/services/trucky-service";

const CompanyStats = ({ company, stats, container }) => {

    const es = new EnterpriseService();
    const [milesStats, setMilesStats] = useState(null);
    const [awards, setAwards] = useState([]);

    useEffect(() => {

        getMilesStats();
        getAwards();
    }, [])

    const getMilesStats = async () => {
        const result = await es.getCompanyStatsMonthly(company.id, moment().month() + 1, moment().year());
        setMilesStats(result);
    }

    const getAwards = async () => {
        const result = await es.getCompanyAwards(company);
        setAwards(result);
    }

    return (
        <>
            <Card>
                <CardBody>
                    <div class="grid cols-2">
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4  mb-3">
                            <div class="fs-2 fw-bolder counted">{stats.members}</div>
                            <div class="fw-bold fs-6 text-gray-600">{container.localeManager.strings.members}</div>
                        </div>
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4  mb-3">
                            <div class="fs-2 fw-bolder counted"> {stats.garages}</div>
                            <div class="fw-bold fs-6 text-gray-600">{container.localeManager.strings.garages}</div>
                        </div>
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4  mb-3">
                            <div class="fs-2 fw-bolder counted">{stats.vehicles}</div>
                            <div class="fw-bold fs-6 text-gray-600">{container.localeManager.strings.vehicles}</div>
                        </div>
                        <div class={"border border-gray-300 border-dashed rounded min-w-125px py-3 px-4  mb-3 " + (stats.balance < 0 ? 'text-danger' : 'text-success')}>
                            <div class="fs-2 fw-bolder counted">{container.localeManager.numeral(stats.balance).format('0,0')} {company.currency}</div>
                            <div class="fw-bold fs-6 text-gray-600"> {container.localeManager.strings.balance}</div>
                        </div>
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 text-success">
                            <div class="fs-2 fw-bolder counted">  {container.localeManager.numeral(stats.revenues).format('0,0')} {company.currency}</div>
                            <div class="fw-bold fs-6 text-gray-600"> {container.localeManager.strings.revenue}</div>
                        </div>
                    </div>
                </CardBody>
            </Card>            
            {awards.length > 0 &&
                <Card>
                    <CardHeader>{container.localeManager.strings.companyAwards}</CardHeader>
                    <CardBody>
                        {awards.map((m) => <div class="mb-1"><SingleCompanyAward container={container} award={m}></SingleCompanyAward></div>)}
                    </CardBody>
                </Card>
            }
        </>
    )
};

export default CompanyStats;